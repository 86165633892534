
export function validatePriceRange (from,to) {
    let min = parseFloat(from) ?? 0;
    let max = parseFloat(to) ?? 9999999999.99;
    if (max< min) {
        return 'Максимальное значение должно равняться или быть больше чем '+min
    }
    if (min>max) {
        return 'Минимальное значение должно равняться или быть меньше чем '+max
    }
    return false
}
export function validateAreaRange (from,to) {
    let min = parseFloat(from) ?? 0;
    let max = parseFloat(to) ?? 1000000.00;
    if (max< min) {
        return 'Максимальное значение должно равняться или быть больше чем '+min
    }
    if (min>max) {
        return 'Минимальное значение должно равняться или быть меньше чем '+max
    }
    return false
}