import { Fragment } from 'react';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import React from 'react';
import globalDictionary from 'assets/translations/globalDictionary';
import { getActiveLanguageFromLS } from 'utils/index';
import { Container } from '@mui/system';
import countries from '../../assets/translations/countries';
import {observer} from "mobx-react-lite";

/**
 *
 * @param data:{<br>
 *     country:String,<br>
 *     city:String,<br>
 *     street:String,<br>
 *     streetNumber:String<br>
 * }
 * @param data
 * @param dataError
 * @param parentField
 * @param handleInputChange
 * @returns {JSX.Element}
 * @constructor
 */
const FullAddressBlock = observer(({ data, dataError, parentField = '', handleInputChange,countryTranslateKey }) => {
    const isLatin = (value) => {
        const pattern = /^[a-zA-Z0-9.,-@/\s]+$/;
        return pattern.test(value);
    };
  return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="select-country">
              {!!countryTranslateKey ? globalDictionary.get(countryTranslateKey) : globalDictionary.get('property_add_common_country_placeholder', )}
            </InputLabel>
            <Select
              variant="outlined"
              labelId="select-country"
              id="select-type"
              label={globalDictionary.get('property_add_common_country', )}
              required
              value={countries.getCode(data.country)}
              name={parentField ? parentField + '.' + 'country' : 'country'}
              onChange={handleInputChange}
              error={_.get(dataError,parentField +'.country')}
              helperText={_.get(dataError,parentField +'.country')}
            >
              {countries.getAllValueLabel().map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
              <FormHelperText error={_.get(dataError,parentField +'.country')}>{_.get(dataError,parentField +'.country')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            placeholder={globalDictionary.get('property_add_common_city_placeholder', )}
            label={globalDictionary.get('property_add_common_city', )}
            variant="outlined"
            fullWidth
            required
            value={isLatin(data.city) ? data.city : ''}
            name={parentField ? parentField + '.' + 'city' : 'city'}
            onChange={handleInputChange}
            error={_.get(dataError,parentField +'.city')}
            helperText={_.get(dataError,parentField +'.city')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            placeholder={globalDictionary.get('property_add_common_street_placeholder', )}
            label={globalDictionary.get('property_add_common_street', )}
            variant="outlined"
            required
            fullWidth
            value={isLatin(data.street) ? data.street : ''}
            name={parentField ? parentField + '.' + 'street' : 'street'}
            onChange={handleInputChange}
            error={_.get(dataError,parentField +'.street')}
            helperText={_.get(dataError,parentField +'.street')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            placeholder={globalDictionary.get('property_add_common_house_placeholder', )}
            label={globalDictionary.get('property_add_common_house', )}
            variant="outlined"
            required
            fullWidth
            value={isLatin(data.streetNumber) ? data.streetNumber : ''}
            name={parentField ? parentField + '.' + 'streetNumber' : 'streetNumber'}
            onChange={handleInputChange}
            error={_.get(dataError,parentField +'.streetNumber')}
            helperText={_.get(dataError,parentField +'.streetNumber')}
          />
        </Grid>
      </Grid>
  );
})

export default FullAddressBlock;
