import {observer} from "mobx-react-lite";
import {Box, Button, Grid, Typography} from "@mui/material";
import {PhotosLoader} from "../../index";
import React from "react";
import ImageCircle from '../PhotosLoader/image_circle.svg';

const BlockForm = observer(({
                                title,
                                data,
                                setData,
                                children,
                                imageConfigs,
                                buttonText,
                                addItem,
                                buttonDisabled
                            }) => {
    const handleInputPhotos = (field, value) => {
        setData({
            ...data,
            [field]: value
        })
    }
    return (
        <>
            <Box sx={{backgroundColor: 'white', padding: '20px', borderRadius: ' 5px 5px 5px 5px', width:'100%'}}>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        {children}
                    </Grid>
                    <Grid item xs={12}>
                        <PhotosLoader
                            _data={data}
                            _setData={handleInputPhotos}

                            config={imageConfigs}
                        />

                        <Button
                            variant={'contained'}
                            onClick={addItem}
                            sx={{
                                display: buttonDisabled ? 'none' : 'flex'
                            }}
                            disabled={buttonDisabled}
                        >
                            <img src={ImageCircle}/>
                            <Typography ml={'10px'}>
                                {buttonText}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </>
    )

})

export default BlockForm;