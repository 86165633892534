import projectDictionary from "../../../../../assets/translations/Project/projectDictionary";
import CommonForm from "../components/land/CommonForm";
import PhotosForm from "../components/land/PhotosForm";
import DocumentsForm from "../components/land/DocumentsForm";
import commonDictionary from "../../../../../assets/translations/commonDictionary";

class LandStore{

    steps = [
        {
            label: () =>commonDictionary.get('characteristics'),
            content: CommonForm,
        },
        {
            label: ()=>projectDictionary.get('documents_step'),
            content: DocumentsForm,
        },
        {
            label: ()=>projectDictionary.get('photo_step'),
            content: PhotosForm,
        },
    ];

    fields={
        areaTotal:'',
        marketValue:'',
        purpose:'',
        utilitySystem: [],
        locationDescription:'',
        concept:'',
        presentation:{},
        visualization:[],
        documents:{
            owner:true,
            ownerDocument:{},
            agreementDocument:{},
            landPlan:[],
            zoningPlan:[],
            experts:{},
            permission:true,
            permissionDocument: {},
            construction:true,
            constructionPlan:{},
            pledge:'',
            pledgeDocument:{},
            extra:[{
                document: {},
                description: ''
            }]
        }
    }
    reqFields = [];


    constructor(props) {
        //makeAutoObservable(this)
        this.steps = [...props?.steps ?? [], ...this.steps]
        this.fields = {...props?.fields ?? {}, project:{...this.fields} }
        this.reqFields = [...props?.reqFields ?? '',...this.reqFields];

    }
}

export default LandStore;