import React, {useState} from 'react';
import {IconButton, Menu, MenuItem, Typography, useMediaQuery, useTheme, Box} from '@mui/material';
import {observer} from 'mobx-react-lite';
import globalDictionary from 'assets/translations/globalDictionary';
import {getActiveLanguageFromLS, setActiveLanguageToLS} from 'utils';
import {findFlagUrlByCountryName} from 'country-flags-svg';
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import languageStore from "assets/translations/languageStore";


const HeaderLanguageMenu = observer(() => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [language, setLanguage] = useState(getActiveLanguageFromLS());
    const flags = {
        ukr: findFlagUrlByCountryName('Ukraine'),
        rus: findFlagUrlByCountryName('Russia'),
        eng: findFlagUrlByCountryName('United Kingdom'),
        deu: findFlagUrlByCountryName('Germany'),
    };

    const setNewLanguage = (language) => {
        setActiveLanguageToLS(language);
        setLanguage(language);
        languageStore.activeLanguage = language
        //window.location.reload();
    };

    const handleMenuOpen = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box onClick={handleMenuOpen} sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '7px'
            }}>
                <Typography sx={{
                    fontSize: '16px',
                    fontFamily: "Sofia Sans Condensed",
                    textTransform: 'uppercase',
                }}>
                    {globalDictionary.get(`layout_lang_menu_${language}`).slice(0, 2)}
                </Typography>
                <img
                    src={arrowDownIcon}
                    height={15}
                    alt={globalDictionary.get('layout_cabinet_header_logo')}
                />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="lang-menu"
                open={openMenu}
                onMouseLeave={handleMenuClose}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                left={0}
                PaperProps={{
                    style: {
                        right: 140,
                    },
                    elevation: 0,
                    sx: {
                        left: 'initial !important',
                        top: '50px !important',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                {Object.entries(flags).map(([langKey, flagUrl]) => (
                    <MenuItem
                        sx={{display: 'flex', justifyContent: 'flex-start', padding: '5px 20px'}}
                        onClick={() => setNewLanguage(langKey)}
                        key={langKey}
                    >
                        {globalDictionary.get(`layout_lang_menu_${langKey}`).slice(0, 2)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
});

export default HeaderLanguageMenu;
