import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import {Typography, Box} from "@mui/material";
import globalDictionary from "assets/translations/globalDictionary";

export default function KanbanColumnToolBar({columnName, columnId, leadsCount}) {

    const ColumnHeader = ({name, id, leadsCount}) => {
        const columnColor = {
            'new': '#9DBAD3',
            'in progress': '#88BCB3',
            'processing': '#D6BC5F',
            'agreement': '#7DA04E',
            'closed': '#9180B7',
            'not target': '#989898',
            'failed': '#9A5A59',
        };

        return (
            <Box sx={{
                backgroundColor: columnColor[id] || '#989898',
                padding: '12px',
                borderRadius: '5px',
                width: '100%'
            }}>
                <Typography sx={{
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: '700',
                }}>
                    {name}
                </Typography>
                <Typography sx={{
                    color: 'white',
                    fontSize: '11px',
                }}>
                    {`${leadsCount} ${globalDictionary.get('crm_column_lead_counter')}`}
                </Typography>
            </Box>
        );
    };

    return (
        <>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    padding: '15px 15px 0 15px',
                }}
            >
                <ColumnHeader name={columnName} id={columnId} leadsCount={leadsCount}/>
            </Stack>
        </>
    );
}

KanbanColumnToolBar.propTypes = {
    columnName: PropTypes.string,
    onClearColumn: PropTypes.func,
    onDeleteColumn: PropTypes.func,
    onUpdateColumn: PropTypes.func,
};
