import {getActiveLanguageFromLS} from "utils";
import languageStore from "./languageStore";

const companyRoles = {
    elements: [
        {
            code: 'DevelopmentCompany',
            value: 'DevelopmentCompany',
            labelEn: 'Development Company',
            labelRu: 'Девелоперская компания',
            labelCh: '开发公司',
            labelFr: 'Société de développement',
            labelSp: 'Empresa de desarrollo',
            labelUa: 'Девелоперська компанія',
            labelDe: 'Entwicklungsgesellschaft',
        },
        {
            code: 'ManagementCompany',
            value: 'ManagementCompany',
            labelEn: 'Management Company',
            labelRu: 'Управляющая компания',
            labelCh: '管理公司',
            labelFr: 'Société de gestion',
            labelSp: 'Empresa de gestión',
            labelUa: 'Керуюча компанія',
            labelDe: 'Verwaltungsgesellschaft',
        },
        {
            code: 'CommercialCompany',
            value: 'CommercialCompany',
            labelEn: 'Commercial Company',
            labelRu: 'Коммерческая компания',
            labelCh: '商业公司',
            labelFr: 'Entreprise commerciale',
            labelSp: 'Empresa comercial',
            labelUa: 'Комерційна компанія',
            labelDe: 'Kommerzielles Unternehmen',
        },
    ],
    get: function (key, lang) {
        lang = languageStore.activeLanguage;
        var ar = this.elements;

        if (lang === 'eng') {
            var a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelEn;
            }
        } else if (lang === 'rus') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelRu;
            }
        } else if (lang === 'ukr') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelUa;
            }
        } else if (lang === 'deu') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelDe;
            }
        } else {
            return '';
        }
    },

    getAllValueLabel: function (lang) {
        lang = languageStore.activeLanguage;

        const functor = function (elem, language) {
            switch (language) {
                case 'eng':
                    return {value: elem.value, label: elem.labelEn};
                case 'rus':
                    return {value: elem.value, label: elem.labelRu};
                case 'ukr':
                    return {value: elem.value, label: elem.labelUa};
                case 'deu':
                    return {value: elem.value, label: elem.labelDe};
                default:
                    return {value: elem.value, label: elem.labelEn};
            }
        };

        const roles = this.elements.map((item) => functor(item, lang));
        return roles;
    },
}

export default companyRoles;
