import React from 'react';

import {
    ArticleView,
    ComingSoon,
    CommunityList,
    CommunityView,
    ComplexForm,
    ComplexList,
    ComplexView,
    Contact,
    Error,
    Messenger,
    MyProfile,
    Articles,
    ProfileEdit,
    PropertyList,
    PropertyView,
    PropertyForm,
    TicketAdd,
    TicketItem,
    TicketList,
    FAQ,
    Referrals,
    Smart,
    SmartItem,
    ProjectList,
    ProjectHub,
    ProjectContact,
    ProjectView,
    Wallet,
    WalletDeposit,
    WalletDepositInfo,
    WalletBaseStakingContract,
    WalletBaseStakingDeposit,
    ProjectSelectType,
    WalletWithdraw,
} from '../pages';

import {KanbanBoard} from '../components/shared/Kanban/view';
import EditProfile from "../pages/Profile/MyProfile/components/ProfileEdit/EditProfile";
import EditRole from "../pages/Profile/MyProfile/components/ProfileEdit/EditRole";
import ProjectForm from "../pages/Services/Project/ProjectForm/ProjectForm";

const cabinetRoutes = [
    {
        path: 'profile',
        element: <MyProfile/>,
        protected: true,
        index: true,
    },
    {
        path: 'my/profile/:anchor?',
        element: <MyProfile/>,
        protected: true,
    },
    {
        path: 'my/profile/edit',
        element: <ProfileEdit/>,
        protected: true,
    },
    {
        path: 'my/profile/edit/community',
        element: <EditProfile/>,
        protected: true,
    },
    {
        path: 'my/profile/edit/community/role',
        element: <EditRole/>,
        protected: true,
    },
    {
        path: 'property',
        element: <PropertyList/>,
        protected: true,
    },
    {
        path: 'property/:propertyId/:activeTab?',
        element: <PropertyView/>,
        protected: true,
    },
    {
        path: 'property/form',
        element: <PropertyForm/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: 'property/form/:propertyId',
        element: <PropertyForm/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: 'project',
        element: <ProjectList/>,
        protected: true,
    },
    {
        path: 'project/form',
        element: <ProjectSelectType/>,
        protected: true,
    },
    // {
    //     path: 'project/form/:projectId?',
    //     element: <ProjectForm/>, // TODO Dmytro: change to ProjectForm for add/edit project (type - land, project, unit)
    //     protected: true,
    // },
    {
        path: 'project/form/:type?/:projectId?',
        element: <ProjectForm/>, // TODO Dmytro: change to ProjectForm for add/edit project (type - land, project, unit)
        protected: true,
    },
    {
        path: 'project/hub',
        element: <ProjectHub/>,
        protected: true,
    },
    {
        path: 'project/contact',
        element: <ProjectContact/>,
        protected: true,
    },
    {
        path: 'project/:projectId/:activeTab?',
        element: <ProjectView/>,
        protected: true,
    },
    {
        path: 'complex',
        element: <ComplexList/>,
        protected: true,
    },
    {
        path: 'complex/form',
        element: <ComplexForm/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: 'complex/form/:complexId',
        element: <ComplexForm/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: 'complex/:complexId/:activeTab?',
        element: <ComplexView/>,
        protected: true,
    },
    {
        path: 'community',
        element: <CommunityList/>,
        protected: true,
    },
    {
        path: 'community/:userId',
        element: <CommunityView/>,
        protected: true,
    },
    {
        path: 'contact',
        element: <Contact/>,
        protected: true,
    },
    {
        path: 'faq',
        element: <FAQ/>,
        protected: true,
    },
    {
        path: 'support/tickets',
        element: <TicketList/>,
        protected: true,
    },
    {
        path: 'support/tickets/add',
        element: <TicketAdd/>,
        protected: true,
    },
    {
        path: 'support/tickets/:ticketId',
        element: <TicketItem/>,
        protected: true,
    },

    {
        path: 'articles',
        element: <Articles/>,
        protected: true,
    },
    {
        path: 'articles/:articleId',
        element: <ArticleView/>,
        protected: true,
    },
    {
        path: 'chat/:chatId?',
        element: <Messenger/>,
        protected: true,
    },
    {
        path: 'crm',
        element: <KanbanBoard/>,
        protected: true,
    },
    {
        path: 'referrals',
        element: <Referrals/>,
        protected: true,
    },
    {
        path: 'smart',
        element: <Smart/>,
        protected: true,
    },
    {
        path: 'smart/:smartId?',
        element: <SmartItem/>,
        protected: true,
    },
    {
        path: 'wallet/:activeTab?',
        element: <Wallet/>,
        protected: true,
    },
    {
        path: 'wallet/deposit',
        element: <WalletDeposit/>,
        protected: true,
    },
    {
        path: 'wallet/deposit/:transactionId?',
        element: <WalletDepositInfo/>,
        protected: true,
    },
    {
        path: 'wallet/withdraw',
        element: <WalletWithdraw/>,
        protected: true,
    },
    {
        path: 'wallet/withdraw/:transactionId?',
        element: <WalletWithdraw/>,
        protected: true,
    },
    {
        path: 'wallet/staking/deposit/:transactionId?',
        element: <WalletBaseStakingDeposit/>,
        protected: true,
    },
    {
        path: 'wallet/staking/:sessionId',
        element: <WalletBaseStakingContract/>,
        protected: true,
    },
    {
        path: 'soon',
        element: <ComingSoon/>,
    },
    {
        path: 'error',
        element: <Error/>,
    },
];

export default cabinetRoutes;
