import React, {useState} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {ImageSlider, ModalWindow} from "components";

const ImageSliderModal = ({slides = [], title = '', children}) => {
    const [open, setOpen] = useState(false);
    const toggleModal = () => {
        setOpen(!open);
    }

    if (open) {
        return (
            <ModalWindow
                closeModal={toggleModal}
                modalStyles={{
                    width: '100%',
                    maxWidth: '645px',
                }}
            >
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '25px',
                }}>
                    <Grid container spacing={'25px'} mt={'0px'}>
                        <Grid item xs={12}>
                            <ImageSlider slides={slides}/>
                        </Grid>
                        <Grid item xs={12} sx={{}}>
                            <Typography sx={{
                                color: '#021228',
                                fontSize: '18px',
                                fontWeight: 700,
                                lineHeight: '120%',
                                textAlign: 'center',
                            }}>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </ModalWindow>
        )
    }

    return (
        <Box onClick={toggleModal} sx={{
            cursor: 'pointer',
        }}>
            {children}
        </Box>
    )
};

export default ImageSliderModal;
