import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import globalDictionary from 'assets/translations/globalDictionary';
import { getActiveLanguageFromLS, setActiveLanguageToLS } from 'utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const HeaderLanguageMenu = observer(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [language, setLanguage] = useState(getActiveLanguageFromLS());

  const setNewLanguage = (language) => {
    setActiveLanguageToLS(language);
    setLanguage(language);
    window.location.reload();
  };

  const handleMenuOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen} size="small" sx={{ ml: 2, marginLeft: '5px' }}>
        <Typography sx={{
          fontSize: '16px',
          fontFamily: 'Sofia Sans Condensed',
          textTransform: 'uppercase',
        }}>
          {globalDictionary.get(`layout_lang_menu_${language}`).slice(0, 2)}
        </Typography>
        {!openMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="lang-menu"
        open={openMenu}
        onMouseLeave={handleMenuClose}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        <MenuItem
          sx={{ display: 'flex', justifyContent: 'flex-start' }}
          onClick={() => setNewLanguage('eng')}
        >
          {globalDictionary.get('layout_lang_menu_eng')}
        </MenuItem>
        <MenuItem
          sx={{ display: 'flex', justifyContent: 'flex-start' }}
          onClick={() => setNewLanguage('ukr')}
        >
          {globalDictionary.get('layout_lang_menu_ukr')}
        </MenuItem>
        <MenuItem
          sx={{ display: 'flex', justifyContent: 'flex-start' }}
          onClick={() => setNewLanguage('rus')}
        >
          {globalDictionary.get('layout_lang_menu_rus')}
        </MenuItem>
        <MenuItem
          sx={{ display: 'flex', justifyContent: 'flex-start' }}
          onClick={() => setNewLanguage('deu')}
        >
          {globalDictionary.get('layout_lang_menu_deu')}
        </MenuItem>
      </Menu>
    </>
  );
});

export default HeaderLanguageMenu;
