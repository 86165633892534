import {observer} from "mobx-react-lite";
import {Box, Container, Grid, IconButton, Typography} from "@mui/material";
import TrashButton from "../PhotosLoader/trash_button.svg";
import EditCircle from './edit_circle.svg';
import Image from "mui-image";
import React from "react";
import {ImageChecker} from "../../index";

const BlockList = observer(({
                                list,
                                deleteItem,
                                setData,
                                imageConfigs,
                                getTextField
                            }) => {


    const getMainPhoto = (item) => {
        const main = item[imageConfigs.field].filter((item) => item.isMain)
        return main[0] ? main[0].url : item[imageConfigs.field][0].url;
    }

    const handleChangeItem = (index) => {
        setData(list[index])
        deleteItem(index)
    }

    return (
        <Grid container gridRow>
            {Array.isArray(list) && list.map((item, index) => (
                <Box sx={{
                    backgroundColor: 'white',
                    marginTop: '20px',
                    padding: '20px 20px 15px',
                    marginRight: '20px',
                    borderRadius: ' 0px 0px 10px 10px',
                    maxWidth: '220px',
                    cursor: 'pointer',
                    position: 'relative',
                    '&:hover *': {
                        visibility: 'visible'
                    },
                    '&:hover h3': {
                        color: '#D1B684'
                    }
                }}>
                    <Grid
                        container
                        sx={{
                            borderRadius: '5px',
                            border: '1px solid #DEDEDE',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            color="primary"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                zIndex: 1000,
                                visibility: 'hidden',
                            }}
                            onClick={() => {
                                deleteItem(index)
                            }}
                            size="large"
                        >
                            <img src={TrashButton}/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            sx={{
                                position: 'absolute',
                                top: 40,
                                right: 0,
                                zIndex: 1000,
                                visibility: 'hidden',
                            }}
                            onClick={() => {
                                handleChangeItem(index)
                            }}
                            size="large"
                        >
                            <img src={EditCircle}/>
                        </IconButton>

                        <ImageChecker
                            src={getMainPhoto(item)}
                            style={{
                                objectFit: 'contain',
                                display: 'block',
                            }}
                            height={120}
                            width={180}/>
                    </Grid>
                    <Typography
                        fullWidth
                        textAlign={'left'}
                        sx={{
                            mt: '15px'
                        }}
                    >
                        {getTextField(item)}
                    </Typography>
                </Box>
            ))}
        </Grid>
    )
})

export default BlockList;
