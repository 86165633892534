import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Slider,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import {AIInput} from 'components/index';
import {observer} from "mobx-react-lite";
import LocationBlock from "components/shared/LocationBlock/LocationBlock";
import projectDictionary from "assets/translations/Project/projectDictionary";
import PhotosForm from "./PhotosForm";

const CommonForm = observer(({store}) => {
    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];

    return (
        <>
            <Box sx={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: ' 0px 0px 10px 10px',
                width:'100%'
            }}>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography sx={{
                            fontSize:'16px',
                            fontWeight:'700',
                            marginBottom:'15px'
                        }}>
                            {projectDictionary.get('project_add_common_title')}
                        </Typography>
                    </Grid>

                    <Grid container spacing={2} sx={{marginLeft:'8px'}}>
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_unit_name')}
                            label={projectDictionary.get('project_add_unit_name')}
                            variant="outlined"
                            fullWidth
                            required
                            error={store.fieldsErrors?.name}
                            value={store.fields.name}
                            name={'name'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{marginLeft:'8px'}}>
                    <AIInput
                        placeholder={projectDictionary.get('project_add_unit_description')}
                        label={`${projectDictionary.get('project_add_unit_description')}`}
                        name={'project.description'}
                        value={store.fields.project.description}
                        onChange={store.handleInputChange}
                        maxTextLength={600}
                    />
                    </Grid>

                </Grid>
            </Box>
            <PhotosForm store={store}/>
            <Box
                sx={{backgroundColor: 'white', padding: '20px', borderRadius: '5px', marginTop: '30px', width: '100%'}}>
                <LocationBlock
                    data={store.fields}
                    setData={store.handleInputField}
                    errors={store?.fieldsErrors?.address}
                    field={'address'}
                />
            </Box>
        </>
    );
});

export default CommonForm;
