import {observer} from "mobx-react-lite";
import {FileLoader} from "components";
import projectDictionary from "../../../../../../../assets/translations/Project/projectDictionary";
import {AIInput} from "../../../../../../../components";
import React from "react";
import Box from "@mui/material/Box";
import {Button, Grid, Typography} from "@mui/material";
import {useState} from "react";
import paperClip from "assets/icons/paper-clip.svg";
import commonDictionary from "../../../../../../../assets/translations/commonDictionary";

const ExtraDocumentsBlock = observer(({store})=>{

    const [fileCount,setFileCount] = useState(store?.fields?.project?.documents?.extra?.length ?? 1);
    return(
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px 10px 10px 10px',
            width:'100%'
        }}>
            <Box sx={{paddingLeft:'8px',marginBottom:'30px'}}>
                <Typography sx={{
                    fontSize:'16px',
                    fontStyle:'normal',
                    fontWeight:700,
                    lineHeight:'normal',
                    marginBottom:'10px'
                }}>
                    {projectDictionary.get('project_add_documents_extra_title')}
                </Typography>
                <Box
                sx={{
                    display:'flex',
                    flexDirection:'row'
                }}
                >
                <Typography sx={{
                    fontSize:'16px',
                    fontStyle:'normal',
                    fontWeight:400,
                    lineHeight:'150%',
                    marginRight:'5px'
                }}>
                    {projectDictionary.get('project_add_documents_extra_title_sub')}
                </Typography>

                <Typography  sx={{
                    fontSize:'16px',
                    fontStyle:'normal',
                    color:'#C5A465',
                    fontWeight:400,
                    lineHeight:'150%',
                    textDecoration:'underline'
                }}>
                    {projectDictionary.get('project_add_documents_extra_title_sub_experts')}
                </Typography>
                </Box>
            </Box>
            {[...Array(fileCount)].map((_, index) => {
                return <Grid item xs={12}>
                    <FileLoader
                        _data={store.fields.project.documents.extra}
                        _setData={(file)=>{
                            store.uploadFile(`project.documents.extra.${index}.document`,file.document)
                        }}
                        fileCount={store.fields.project.documents.extra?.[index]?.document?.url ? 1 : 0}
                        documentDropzone={true}
                        configs={{
                            field: `document`,
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            openFilePath: `${index}.document`,
                            //fileName: store.fields.project.documents.extra?.[index]?.document?.url?.name,
                            documentDropzoneTitle:store.fields.project.documents.extra?.[index]?.document?.url?.name ?store.fields.project.documents.extra?.[index]?.document?.url?.name: projectDictionary.get('project_add_documents_extra_dropzone'),
                            confName: 'project.construction.documents.extra',
                            buttonKey: 'documents_load_button_key'
                        }}
                    >
                        <Box/>
                    </FileLoader>

                    <Box sx={{
                        display:'flex',
                        width:'100%',
                        marginTop:'20px',
                    }}>
                        <AIInput
                            placeholder={projectDictionary.get('project_add_documents_extra_comments')}
                            label={`${projectDictionary.get('project_add_documents_extra_comments')}`}
                            name={`project.documents.extra.${index}.description`}
                            value={store.fields.project?.documents?.extra?.[index]?.description}
                            onChange={store.handleInputChange}
                            maxTextLength={600}
                        />
                    </Box>
                </Grid>
            })}
            {fileCount < 10 &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop:'10px'
                    }}>
                        <Button
                            onClick={() => {
                                setFileCount(prevState => prevState + 1)
                                store.handleInputArrayElem('project.documents.extra',{document:{},description:''})
                                //store.setFields('project.documents.extra',{url: '', description:''})
                            }}
                            startIcon={<img src={paperClip}/> }
                        >
                            {commonDictionary.get('add_file')}
                        </Button>
                    </Box>
                </Grid>
            }


        </Box>
    )

})

export default ExtraDocumentsBlock;