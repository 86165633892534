import { GremService } from 'services';
import { getUserToken } from 'utils';
import { server } from '../config';

class ComplexService {
    constructor() {
        this._apiBase = server;
        this.service = new GremService();
    }

    async addComplexProperty(data = {}) {
        const res = await this.service.sendRequest('/complex', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async updateComplexFlags(data = {}) {
        const res = await this.service.sendRequest(
            `/complex/flags/${data._id}`,
            'PATCH',
            data,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async deleteComplexDraft(complexId) {
        const res = await this.service.sendRequest(
            `/complex/${complexId}`,
            'DELETE',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getComplex(data = {}) {
        const res = await this.service.sendRequest(
            `/complex/?${data}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
    async getComplexById(complexId) {
        const res = await this.service.sendRequest(
            `/complex/${complexId}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
}

export default ComplexService;
