import {Box, Typography} from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import styles from "./ProgressBar.module.css";

const CustomProgressBar = ({value, maxValue, valueLabel, maxLabel, type = 'medium'}) => {

    return (
        <Box sx={{
            position: 'relative',
            '& div > div > div': {
                width: `${(value / maxValue) * 100}%`,
            }
        }}
             className={
            type === 'small' ? styles.small : null
        }
        >
            <ProgressBar
                completed={value}
                maxCompleted={maxValue}
                customLabel={valueLabel}
                className={styles.progressBar__wrapper}
                barContainerClassName={styles.progressBar__container}
                completedClassName={styles.progressBar__completed}
                labelClassName={styles.progressBar__label}
            />
            {
                maxLabel && (
                    <Box sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                    className={styles.progressBar__maxLabel}
                    >
                        <Typography sx={{
                            color: '#7A7A7A',
                            fontSize: '12px',
                        }}>
                            {maxLabel}
                        </Typography>
                    </Box>
                )
            }
        </Box>
    );
};

export default CustomProgressBar;
