import React, {Fragment, useContext} from 'react';
import {Box, FormControlLabel, Grid, Switch, Typography} from '@mui/material';
import CompanyMember from './members/company.member';
import SpecialistMember from './members/specialist.member';
import globalDictionary from 'assets/translations/globalDictionary';
import gremLogo from 'assets/icons/grem-logo.svg'
import person from 'assets/icons/person.svg'
import {observer} from "mobx-react-lite";
import Image from "mui-image";
import {useContractStore} from "../ContractStore";

const MemberBlock = observer(() => {
    const formData = useContractStore()
    return (
        <Fragment>
            <Grid container spacing={9} gridRow>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography component={'p'} fontWeight={600}>
                        {globalDictionary.get('contract_add_member_side_1')}
                    </Typography>
                    {formData.userType.some((item) => item.toLowerCase() === 'broker') && (
                    <Box display="flex" alignItems="center">
                        <Typography sx={{marginRight: '20px'}}>
                            {globalDictionary.get('contract_add_member_company')}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.activeTab === 'person'}
                                    sx={{

                                        '.MuiSwitch-switchBase': {
                                            color: '#D1B684',
                                            '&.Mui-disabled': {
                                                color: '#DDDDDD',
                                            },
                                        },
                                        '.MuiSwitch-track': {
                                            backgroundColor: '#D1B684',
                                        }
                                    }}
                                    disabled={formData.isEdit}
                                    onChange={formData.handleChangeTab}/>
                            }
                        />
                        <Typography>
                            {globalDictionary.get('contract_add_member_person')}
                        </Typography>
                    </Box>
                    )}
                    {formData.activeTab === 'company' && <CompanyMember/>}
                    {formData.activeTab === 'person' && <SpecialistMember/>}

                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>

                    <Typography component={'p'} fontWeight={600}>
                        {globalDictionary.get('contract_add_member_side_2')}
                    </Typography>
                    <Grid item xs={12}
                          sx={{
                              backgroundColor: '#F4F4F4',
                              borderRadius: '5px',
                              padding: '20px',
                              marginTop: '10px'
                          }}
                    >
                        <Grid>
                            <Grid item xs={12} sx={{display: 'flex', alignItems: 'center',}}>
                                <Image width={30} height={30} src={gremLogo}/>
                                <Typography
                                    sx={{
                                        paddingLeft: '20px',
                                    }}
                                >
                                    {globalDictionary.get('contract_add_member_company_name')}
                                </Typography>
                            </Grid>
                            <Typography sx={{paddingLeft: '50px'}} variant={'h6'}>
                                {'GREM CAPITAL'}
                            </Typography>
                        </Grid>
                        <Grid sx={{
                            paddingTop: '30px'
                        }}>
                            <Grid item xs={12} sx={{display: 'flex', alignItems: 'center',}}>
                                <Image width={30} height={30} src={person}/>
                                <Typography
                                    sx={{
                                        paddingLeft: '20px',
                                    }}
                                >
                                    {globalDictionary.get('contract_add_member_person_name')}
                                </Typography>
                            </Grid>
                            <Typography sx={{paddingLeft: '50px'}} variant={'h6'}>
                                {'Telman Abbasov'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
});

export default MemberBlock;
