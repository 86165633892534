import {observer} from "mobx-react-lite";
import {Box, Button, Grid, Link, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import projectDictionary from "../../../../../assets/translations/Project/projectDictionary";
import commonDictionary from "../../../../../assets/translations/commonDictionary";
import {CustomAlert} from "components";
import React from "react";

const Moderation = observer(({store,toggleEdit})=>{
    const navigate = useNavigate();
    const myObjects = () => {
        navigate(`/project/${store.fields._id}`)
    }

    const title = store.fields.configs.moderation === 'active'
        ? projectDictionary.get('project_moderation_active_title')
        : store.fields.configs.moderation === 'success'
            ? projectDictionary.get('project_moderation_success_title')
            : projectDictionary.get('project_moderation_failed_title')
    const infoText = store.fields.configs.moderation === 'active'
        ?projectDictionary.get('project_moderation_active_title_info')
        :store.fields.configs.moderation === 'success' ? ''
            : projectDictionary.get('project_moderation_failed_title_info')

    return(
        <Box sx={{backgroundColor: 'white', padding: '20px', borderRadius: ' 0px 0px 10px 10px', }}>
            <Grid container spacing={2} textAlign={'center'} gridColumn gap={'90px'}>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'100%',
                        marginTop:'40px'
                    }}
                >
                <Grid item xs={12} >
                    <Typography sx={{
                        fontSize:'22px',
                        fontStyle:'normal',
                        fontWeight:700,
                        lineHeight:'normal'
                    }}>
                        {title}
                    </Typography>
                </Grid>

                    <Grid container item xs={12}
                          style={{

                              paddingTop:'10px',
                          }}
                    sx={{
                        justifyContent:'center',
                        alignItems:'center',
                        textAlign:'center'
                    }}
                    >
                        <Typography sx={{
                                        fontSize:'16px',
                                        fontStyle:'normal',
                                        fontWeight:400,
                                        lineHeight:'150%',
                            width:'845px',
                                    }}
                        >
                            {infoText}
                        </Typography>
                    </Grid>
                {store.fields.configs.moderation === 'failed' &&
                    <Grid item xs={12}>
                    <CustomAlert severity={'error'} sx={{
                        mt: '10px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '7px'
                        }}>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '150%',
                                color: 'black',
                            }}>
                                {projectDictionary.get('project_moderation_failed_title_reason')}
                            </Typography>
                            <ul style={{
                                marginLeft: '25px',
                                listStyleType: 'disc',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '7px',
                                textAlign:'left'
                            }}>
                                {
                                    store.fields.rejectionReasons && store.fields.rejectionReasons.length > 0 &&
                                    store.fields.rejectionReasons.map((item, index) => (
                                        <li key={`contract-rejected-reason-${index}`}>
                                            <Typography sx={{
                                                fontSize: '16px',
                                                lineHeight: '150%',
                                            }}>
                                                {item}
                                            </Typography>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Box>
                    </CustomAlert>
                    </Grid>
                }
                </Box>
                <Box
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%',
                    marginBottom:'60px'
                }}
                >
                {   store.fields.configs.moderation === 'failed' &&
                    <Grid item xs={12} sx={{marginBottom: '20px'}}>
                        <Button variant={"contained"}
                                onClick={toggleEdit}
                        >
                            {commonDictionary.get('edit')}
                        </Button>
                    </Grid>
                }
                {store.fields.configs.moderation === 'active' &&
                    <Grid item xs={12}>
                        <Button variant={"contained"}
                                onClick={myObjects}
                        >
                            {projectDictionary.get('project_moderation_dashboard')}
                        </Button>
                    </Grid>
                }
                </Box>

            </Grid>
        </Box>
    )

})

export default Moderation;