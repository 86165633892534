import React, { Fragment, useContext } from 'react';
import {
    FormControl,
    Grid, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import globalDictionary from 'assets/translations/globalDictionary';
import {observer} from "mobx-react-lite";
import {useContractStore} from "../ContractStore";

const RewardBlock = observer(() => {
  const formData = useContractStore()
    const wrongSymbols = ['e', 'E', '-', '+'];
  return (
      <Grid container spacing={2} mt={'13px'}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl fullWidth>
                  <InputLabel>{globalDictionary.get('contract_add_reward_currency')} *</InputLabel>
                  <Select
                      required
                      name="currency"
                      label={globalDictionary.get('contract_add_reward_currency')}
                      placeholder={globalDictionary.get('contract_add_reward_currency')}
                      value={formData.contract.currency || 'EURO'}
                      onChange={formData.handleInputChange}
                      error={!!formData.contractErrors.currency}
                  >
                      <MenuItem value="EURO">EURO</MenuItem>
                  </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                  label={globalDictionary.get('contract_add_reward_value')}
                  variant="outlined"
                  fullWidth
                  required
                  onKeyDown={(e) => wrongSymbols.includes(e.key) && e.preventDefault()}
                  InputProps={{
                      inputProps:{
                          min:2
                      },
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  name="sideOneReward.value"
                  type="number"
                  value={formData.contract.sideOneReward.value || ''}
                  onChange={formData.handleInputChange}
                  error={formData.contractErrors?.sideOneReward?.value ?? false}
                  helperText={formData.contractErrors?.sideOneReward?.value ?? undefined}
              />
          </Grid>
          <Grid container spacing={2} item xs={12}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                      label={globalDictionary.get('contract_add_service_payment')}
                      type="number"
                      onKeyDown={(e) => wrongSymbols.includes(e.key) && e.preventDefault()}
                      variant="outlined"
                      fullWidth
                      required
                      name="baseContract.paymentDueDays"
                      value={formData.contract.baseContract.paymentDueDays || ''}
                      onChange={formData.handleInputChange}
                      error={!!formData.contractErrors.baseContract.paymentDueDays}
                      helperText={formData.contractErrors.baseContract.paymentDueDays && formData.contractErrors.baseContract.paymentDueDays}
                  />
                  {!formData.contractErrors.baseContract.paymentDueDays &&
                      < Typography sx={{
                    color:'#A2A2A2',
                          fontSize:'11px',
                          lineHeight:'150%'
                  }}>
                  {globalDictionary.get('contract_add_service_payment_helper')}
                      </Typography>
              }
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                      label={globalDictionary.get('contract_add_service_penalty')}
                      variant="outlined"
                      fullWidth
                      required
                      type='number'
                      onKeyDown={(e) => wrongSymbols.includes(e.key) && e.preventDefault()}
                      name="baseContract.penalty"
                      InputProps={{
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      value={formData.contract.baseContract.penalty || ''}
                      onChange={formData.handleInputChange}
                      error={!!formData.contractErrors.baseContract.penalty}
                      helperText={formData.contractErrors.baseContract.penalty}
                  />
              </Grid>
          </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            label={globalDictionary.get('contract_add_reward_terms')}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="additionalTerms"
            value={formData.contract.additionalTerms || ''}
            onChange={formData.handleInputChange}
            error={!!formData.contractErrors.additionalTerms}
            helperText={formData.contractErrors.additionalTerms}
          />
        </Grid>
      </Grid>
  );
});

export default RewardBlock;
