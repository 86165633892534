import languageStore from "./languageStore";

const roles = {
  elements: [
    {
      code: 'All roles',
      value: '',
      labelEn: 'All roles',
      labelRu: 'Все роли',
      labelCh: '所有角色',
      labelFr: 'Tous les rôles',
      labelSp: 'Todos los roles',
      labelUa: 'Всі ролі',
      labelDe: 'Alle Rollen',
    },
    {
      code: 'Broker',
      value: 'Broker',
      labelEn: 'Broker',
      labelRu: 'Брокер',
      labelCh: '经纪人',
      labelFr: 'Courtier',
      labelSp: 'Corredor',
      labelUa: 'Брокер',
      labelDe: 'Makler',
    },
    {
      code: 'Buyer',
      value: 'Buyer',
      labelEn: 'Buyer',
      labelRu: 'Покупатель',
      labelCh: '买主',
      labelFr: 'Acheteur',
      labelSp: 'Comprador',
      labelUa: 'Покупець',
      labelDe: 'Käufer',
    },
    {
      code: 'Seller',
      value: 'Seller',
      labelEn: 'Seller',
      labelRu: 'Продавец',
      labelCh: '卖主',
      labelFr: 'Vendeur',
      labelSp: 'Vendedor',
      labelUa: 'Продавець',
      labelDe: 'Verkäufer',
    },
    {
      code: 'Developer',
      value: 'Developer',
      labelEn: 'Developer',
      labelRu: 'Девелопер',
      labelCh: '房地产开发商',
      labelFr: 'Developpeur',
      labelSp: 'Desarrollador',
      labelUa: 'Девелопер',
      labelDe: 'Entwickler',
    },
    {
      code: 'Investor',
      value: 'Investor',
      labelEn: 'Investor',
      labelRu: 'Инвестор',
      labelCh: '投资者',
      labelFr: 'Investisseur',
      labelSp: 'Inversionista',
      labelUa: 'Інвестор',
      labelDe: 'Anleger',
    },
    {
      code: 'Appraiser',
      value: 'Appraiser',
      labelEn: 'Appraiser',
      labelRu: 'Оценщик',
      labelCh: '估价员',
      labelFr: 'Expert',
      labelSp: 'Tasador',
      labelUa: 'Оцінювач',
      labelDe: 'Bewerter',
    },
    {
      code: 'Architect',
      value: 'Architect',
      labelEn: 'Architect',
      labelRu: 'Архитектор',
      labelCh: '建筑师',
      labelFr: 'Architecte',
      labelSp: 'Arquitecto',
      labelUa: 'Архітектор',
      labelDe: 'Architekt',
    },
    {
      code: 'Notary',
      value: 'Notary',
      labelEn: 'Notary',
      labelRu: 'Нотариус',
      labelCh: '公证员',
      labelFr: 'Notaire',
      labelSp: 'Notario',
      labelUa: 'Нотаріус',
      labelDe: 'Notar',
    },
    {
      code: 'Expert',
      value: 'Expert',
      labelEn: 'Expert',
      labelRu: 'Эксперт',
      labelCh: '鉴定人',
      labelFr: 'Expert',
      labelSp: 'Experto',
      labelUa: 'Експерт',
      labelDe: 'Experte',
    },
    {
      code: 'ManagementCompany',
      value: 'ManagementCompany',
      labelEn: 'Management Company',
      labelRu: 'Управляющая компания',
      labelCh: '管理公司',
      labelFr: 'Société de gestion',
      labelSp: 'Empresa de gestión',
      labelUa: 'Керуюча компанія',
      labelDe: 'Verwaltungsgesellschaft',
    },
    {
      code: 'DevelopmentCompany',
      value: 'DevelopmentCompany',
      labelEn: 'Development Company',
      labelRu: 'Девелоперская компания',
      labelCh: '开发公司',
      labelFr: 'Société de développement',
      labelSp: 'Empresa de desarrollo',
      labelUa: 'Девелоперська компанія',
      labelDe: 'Entwicklungsgesellschaft',
    },
    {
      code: 'CommercialCompany',
      value: 'CommercialCompany',
      labelEn: 'Commercial Company',
      labelRu: 'Коммерческая компания',
      labelCh: '商业公司',
      labelFr: 'Entreprise commerciale',
      labelSp: 'Empresa comercial',
      labelUa: 'Комерційна компанія',
      labelDe: 'Kommerzielles Unternehmen',
    },
    {
      code: 'PublicOrganization',
      value: 'PublicOrganization',
      labelEn: 'Public Organization',
      labelRu: 'Общественная организация',
      labelCh: '公共组织',
      labelFr: 'Organisation publique',
      labelSp: 'Organización pública',
      labelUa: 'Громадська організація',
      labelDe: 'Öffentliche Organisation',
    },
  ],
  // TODO format this code
  get: function (key, lang) {
    lang = languageStore.activeLanguage;
    var ar = this.elements;

    if (lang === 'eng') {
      var a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelEn;
      }
    } else if (lang === 'rus') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelRu;
      }
    } else if (lang === 'ukr') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelUa;
      }
    } else if (lang === 'deu') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelDe;
      }
    } else {
      return '';
    }
  },

  getAllValueLabel: function (lang) {
    lang = languageStore.activeLanguage;

    const functor = function (elem, language) {
      switch (language) {
        case 'eng':
          return { value: elem.value, label: elem.labelEn };
        case 'rus':
          return { value: elem.value, label: elem.labelRu };
        case 'ukr':
          return { value: elem.value, label: elem.labelUa };
        case 'deu':
          return { value: elem.value, label: elem.labelDe };
        default:
          return { value: elem.value, label: elem.labelEn };
      }
    };

    const roles = this.elements.map((item) => functor(item, lang));
    return roles;
  },
};

export default roles;
