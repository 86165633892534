import React, { useState } from 'react';
import globalDictionary from 'assets/translations/globalDictionary';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getActiveLanguageFromLS } from 'utils';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays } from 'date-fns';

import { snackActions } from 'utils/SnackBarUtils';
const ProjectContract = ({ launch, setLaunch, project }) => {
  const language = getActiveLanguageFromLS();
  const [contractForm, setContractForm] = useState({
    acceptAsset: null,
    pledged: null,
    paymentAsset: null,
    limit: null,
    term: addDays(Date.now(), 1),
  });
  const acceptAssets = ['M2C'];
  const paymentAssets = ['USDT'];
  const pledgedList = ['M2'];

  const handleToggle = () => {
    setLaunch(!launch);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setContractForm(() => ({
      ...contractForm,
      [name]: value,
    }));
  };

  const saveContract = () => {
    snackActions.info(globalDictionary.get('coming_soon', language));
  };

  return (
    <Grid container>
      <Button
        variant={'contained'}
        onClick={handleToggle}
        sx={{
          mt: 1,
        }}
      >
        {globalDictionary.get('contract_launch_new', language)}
      </Button>
      {launch && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id={'pledged'}>
                {globalDictionary.get('contract_pledged', language)}
              </InputLabel>
              <Select
                variant="outlined"
                labelId={'pledged'}
                label={globalDictionary.get('contract_pledged', language)}
                fullWidth
                required
                value={contractForm.pledged}
                name={'type'}
                onChange={(event) => {
                  const selected = event.target.value;
                  setContractForm({
                    ...contractForm,
                    pledged: selected,
                  });
                }}
              >
                {pledgedList.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <TextField
              placeholder={globalDictionary.get('contract_limit', language)}
              label={globalDictionary.get('contract_limit', language)}
              variant="outlined"
              fullWidth
              required
              value={contractForm.limit}
              name={'limit'}
              type={'number'}
              inputProps={{
                min: 0,
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <DatePicker
              label={globalDictionary.get('contract_term', language)}
              variant="outlined"
              fullWidth
              required
              value={contractForm.term}
              name={'term'}
              type={'date'}
              onChange={(date) =>
                setContractForm({
                  ...contractForm,
                  term: date,
                })
              }
              minDate={addDays(Date.now(), 1)}
              maxDate={new Date(+project.tokens.square.primarySaleEndDate)}
              renderInput={(params) => <TextField variant="outlined" fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id={'accept'}>
                {globalDictionary.get('contract_accept_asset', language)}
              </InputLabel>
              <Select
                variant="outlined"
                labelId={'accept'}
                label={globalDictionary.get('contract_accept_asset', language)}
                fullWidth
                required
                value={contractForm.acceptAsset}
                name={'type'}
                onChange={(event) => {
                  const payment = event.target.value;
                  setContractForm({
                    ...contractForm,
                    acceptAsset: payment,
                  });
                }}
              >
                {acceptAssets.map((asset) => (
                  <MenuItem key={asset} value={asset}>
                    {asset}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id={'payment'}>
                {globalDictionary.get('contract_payment_asset', language)}
              </InputLabel>
              <Select
                variant="outlined"
                labelId={'payment'}
                label={globalDictionary.get('contract_payment_asset', language)}
                fullWidth
                required
                value={contractForm.paymentAsset}
                name={'type'}
                onChange={(event) => {
                  const payment = event.target.value;
                  setContractForm({
                    ...contractForm,
                    paymentAsset: payment,
                  });
                }}
              >
                {paymentAssets.map((asset) => (
                  <MenuItem key={asset} value={asset}>
                    {asset}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            lg={2}
            container
            alignContent={'center'}
            justifyContent={'center'}
          >
            <Button variant="contained" onClick={saveContract} fullWidth>
              {globalDictionary.get('contract_create_btn', language)}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectContract;
