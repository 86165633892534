import { makeAutoObservable } from 'mobx';

class MessengerStore {
  chats = [];
  selectedChat = null;

  constructor() {
    makeAutoObservable(this);
  }

  setChats(chats) {
    this.chats = chats;
  }

  setSelectedChat(chat) {
    this.selectedChat = chat;
  }

  addMessageToChat(chatId, newMessage) {
    const chatIndex = this.chats.findIndex((chat) => chat.chat._id === chatId);
    if (chatIndex !== -1) {
      this.chats[chatIndex].lastMessage = newMessage;
    } else {
      const newChat = {
        chat: { _id: chatId, status: 'new' },
        lastMessage: newMessage,
      };
      this.chats.push(newChat);
    }
  }

  get selectedChatInfo() {
    if (!this.selectedChat) return null;
    const chat = this.chats.find((c) => c.chat._id === this.selectedChat._id);
    return chat || { chat: this.selectedChat, lastMessage: {} };
  }
}

export const messenger = new MessengerStore();
