import React, { useContext, useState } from 'react';
import { Button, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import globalDictionary from 'assets/translations/globalDictionary';
import { getActiveLanguageFromLS } from 'utils';
import {useStore} from "Providers";

const HeaderWalletMenu = observer(() => {
  const language = getActiveLanguageFromLS();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { auth } = useStore();
  const commItem = auth && auth.user && auth.user.commItem ? auth.user.commItem : null;

  const handleMenuOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {auth.user && (
        <>
          {/*<Button*/}
          {/*  color="primary"*/}
          {/*  variant="contained"*/}
          {/*  component={Link}*/}
          {/*  to={'/my/wallet/deposit'}*/}
          {/*  sx={{*/}
          {/*    alignSelf: 'center',*/}
          {/*    marginLeft: 2,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography fontSize={'0.9rem'} fontWeight={'500'}>*/}
          {/*    {globalDictionary.get('layout_cabinet_header_wallet_deposit', language)}*/}
          {/*  </Typography>*/}
          {/*</Button>*/}

          <IconButton
            onClick={handleMenuOpen}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openMenu ? 'wallet-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
          >
            <Typography>
              {globalDictionary.get('layout_cabinet_header_wallet', language)}
            </Typography>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            id="wallet-menu"
            open={openMenu}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            left={0}
            PaperProps={{
              style: {
                right: 220,
              },
              elevation: 0,
              sx: {
                left: 'initial !important',
                top: '50px !important',
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 10,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <>
              <MenuItem
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <Link
                  to={'/wallet'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'inherit',
                    width: '100%',
                    padding: '10px',
                    paddingBottom: 0,
                  }}
                >
                  {`${commItem?.wallet?.balance?.toFixed(2) || 0} METRUM`}
                </Link>
              </MenuItem>
              <Divider />
            </>
            <MenuItem
              style={{
                width: '100%',
                padding: 0,
              }}
            >
              <Link
                to={'/wallet'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'inherit',
                  width: '100%',
                  padding: '10px',
                  paddingBottom: 0,
                }}
              >
                {globalDictionary.get('layout_cabinet_header_wallet_overview', language)}
              </Link>
            </MenuItem>
            <MenuItem
              style={{
                width: '100%',
                padding: 0,
              }}
            >
              <Link
                to={'/my/wallet/transaction-history'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'inherit',
                  width: '100%',
                  padding: '10px',
                  paddingBottom: 0,
                }}
              >
                {globalDictionary.get('layout_cabinet_header_wallet_history', language)}
              </Link>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
});

export default HeaderWalletMenu;
