import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import {ImageChecker} from "components";
import {getFormattedLocation, getMainPhoto, numberFormatter} from "utils";
import {Link} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {snackActions} from "../../../utils/SnackBarUtils";
import {LeadService} from "services";
import globalDictionary from "../../../assets/translations/globalDictionary";

const FinalizeLeadModal = ({toggleModal, lead}) => {
    if (!lead) return null;
    const leadService = new LeadService();
    const wrongSymbols = ['e', 'E', '-', '+'];
    const [form, setForm] = useState(
        {
            finalPrice: 0,
            commission: 0,
            finalPriceError: false,
            sending: false,
        }
    );
    const {REACT_APP_FILE_URL} = process.env;
    const {building_id: build = {}, customerName, customerContact, commission, pdfDocumentLink, id: leadId} = lead || {};

    useEffect(() => {
        const commissionValue = form.finalPrice * commission / 100;
        setForm((prev) => ({
            ...prev,
            commission: commissionValue,
        }));
    }, [form.finalPrice]);

    const isFormValid = () => {
        let isValid = true;
        const errors = {
            finalPriceError: false,
        }

        if (!form.finalPrice || form.finalPrice <= 0) {
            isValid = false;
            errors.finalPriceError = true;
        }

        setForm((prev) => ({
            ...prev,
            ...errors,
        }));

        return isValid;
    }

    const submitForm = async () => {
        if (!isFormValid()) {
            return;
        }

        try {
            setForm((prev) => ({
                ...prev,
                sending: true,
            }));

            const payload = {
                summ: form.finalPrice,
                leadId
            }
            const result = await leadService.finalizeLead(payload);
            if(result?.code !== 200) {
                throw new Error('Failed to finalize lead');
            }
            toggleModal(true);
        } catch (e) {
            console.error(e);
            snackActions.error(e.toString() || 'Error while sending form');
        } finally {
            setTimeout(() => {
                setForm((prev) => ({
                    ...prev,
                    sending: false,
                }));
            }, 500);
        }
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: {
                    xs: 'column',
                    sm: 'row'
                },
                gap: '25px',
                flexWrap: 'wrap',
                padding: '20px 0',
                borderBottom: 'solid 1px rgba(0, 0, 0, .1)'
            }}>
                <Box sx={{
                    flex: 1,
                }}>
                    <Typography sx={{
                        color: '#7B7B7B',
                        fontSize: '14px',
                    }}>
                        {globalDictionary.get('crm_finalize_object_info')}
                    </Typography>
                    <Box sx={{
                        backgroundColor: '#F4F6F8',
                        borderRadius: '5px',
                        padding: '10px',
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap',
                        minHeight: '100px'
                    }}>
                        <ImageChecker src={getMainPhoto(build?.photos)?.url} width={80} height={80}/>
                        <Box>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                lineHeight: '150%'
                            }}>
                                {build.name}
                            </Typography>
                            {
                                build?.address && (
                                    <Typography sx={{
                                        fontSize: '14px',
                                        lineHeight: '150%'
                                    }}>
                                        {
                                            getFormattedLocation(build.address)
                                        }
                                    </Typography>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    flex: 1
                }}>
                    <Typography sx={{
                        color: '#7B7B7B',
                        fontSize: '14px',
                    }}>
                        {globalDictionary.get('crm_finalize_info')}
                    </Typography>
                    <Box sx={{
                        backgroundColor: '#F4F6F8',
                        borderRadius: '5px',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '21px',
                        minHeight: '100px'
                    }}>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '150%'
                        }}>
                            {customerName}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'
                        }}>
                            {
                                customerContact.email && (
                                    <Typography
                                        component={'a'}
                                        href={`mailto:${customerContact.email}`}
                                        sx={{
                                            fontSize: '14px',
                                            lineHeight: '150%',
                                            color: '#021228'
                                        }}>
                                        {
                                            customerContact.email
                                        }
                                    </Typography>
                                )
                            }
                            {
                                customerContact.phone && (
                                    <Typography
                                        component={'a'}
                                        href={`tel:${customerContact.phone}`}
                                        sx={{
                                            fontSize: '14px',
                                            lineHeight: '150%',
                                            color: '#021228'
                                        }}>
                                        {
                                            customerContact.phone
                                        }
                                    </Typography>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                padding: '20px 0',
                borderBottom: 'solid 1px rgba(0, 0, 0, .1)'
            }}>
                <Typography sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    textAlign: 'center',
                }}>
                    {globalDictionary.get('crm_finalize_sub_title')}
                </Typography>
                <TextField
                    fullWidth
                    label={globalDictionary.get('crm_finalize_final_summ')}
                    type={'number'}
                    error={form.finalPriceError}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            finalPrice: e.target.value
                        });
                    }}
                    value={form.finalPrice}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }
                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 9999999999.99) {
                            event.target.value = '9999999999.99';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}
                    sx={{
                        marginTop: '15px'
                    }}
                    InputProps={{
                        min: 0,
                        max: 9999999999.99,
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                />
                <Typography sx={{
                    mt: '20px',
                    fontSize: '16px',
                    lineHeight: '140%',
                    textAlign: 'center',
                }}>
                    {`${globalDictionary.get('crm_finalize_text_1')} `}
                    {
                        pdfDocumentLink
                            ? <Typography
                                component={'a'}
                                href={`${REACT_APP_FILE_URL}/${pdfDocumentLink}`}
                                target={'_blank'}
                                sx={{
                                    color: '#C5A465',
                                    textDecoration: 'underline',
                                }}>
                                {globalDictionary.get('crm_finalize_text_2')}
                            </Typography>
                            : <Typography
                                component={'span'}
                                sx={{
                                    color: '#C5A465',
                                    textDecoration: 'underline',
                                }}>
                                {globalDictionary.get('crm_finalize_text_2')}
                            </Typography>
                    } {globalDictionary.get('crm_finalize_text_3')} <Typography component={'span'} sx={{
                    color: '#C5A465'
                }}>
                    {` ${globalDictionary.get('crm_finalize_text_4')} ${commission}%`}
                </Typography>
                </Typography>
            </Box>
            <Box sx={{
                mt: '20px'
            }}>
                <Typography sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '130%',
                    textAlign: 'center',
                }}>
                    {`${globalDictionary.get('crm_finalize_total_title')}: ${numberFormatter(form.commission)}€`}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    },
                    gap: '25px',
                    mt: '20px'
                }}>
                    <Button
                        variant={'outlined'}
                        sx={{
                            flex: 1
                        }}
                        onClick={() => toggleModal()}
                    >
                        {globalDictionary.get('crm_finalize_button_cancel')}
                    </Button>
                    <LoadingButton loading={form.sending} variant={'contained'} onClick={submitForm} sx={{
                        flex: 1
                    }}>
                        {globalDictionary.get('crm_finalize_button_submit')}
                    </LoadingButton>
                </Box>
                <Link to={'/support/tickets'} style={{
                    color: '#C5A465',
                    fontSize: '16px',
                    lineHeight: '150%',
                    textDecoration: 'underline',
                    textAlign: 'center',
                    margin: '20px auto 0',
                    display: 'block',
                }}>
                    {globalDictionary.get('crm_finalize_problem')}
                </Link>
            </Box>
        </Box>
    );
};

export default FinalizeLeadModal;
