import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo, useState} from "react";
import {ProjectService} from "services";
import {useParams} from "react-router-dom";
import saveChangesIcon from "assets/icons/circle-check.svg";
import FormStore, {FormContextProvider, useFormStore} from "components/shared/DraftForm/FormStore";
import {useStore} from "Providers";
import {DraftForm, Page} from "components";
import {LoadingButton} from "@mui/lab";
import {CircularProgress, Grid, Typography} from "@mui/material";
import Moderation from "./components/Moderation";
import {findAndAppendFilesToFormData} from "utils";
import {Error} from "../../../index";
import ImageConfigs from "../../../../assets/configs/image.configs";
import {snackActions} from "../../../../utils/SnackBarUtils";
import ProjectTypesStore from "./stores/ProjectTypesStore";
import Box from "@mui/material/Box";
import commonDictionary from "../../../../assets/translations/commonDictionary";
import projectDictionary from "../../../../assets/translations/Project/projectDictionary";
import {observable} from "mobx";

const ProjectForm = observer(() => {
    const [fields, setFields] = useState({});
    const projectTypeStore = new ProjectTypesStore();
    const [loading, setLoading] = useState(false);
    const service = new ProjectService();
    let {type, projectId} = useParams();

    if (!['construction', 'land', 'unit'].includes(type)) {
        projectId = type;
        type = null;
    }

    const [typeStore, setTypeStore] = useState(type ? projectTypeStore.getTypeStore(type) : null);

    const isEdit = !!projectId;
    const [isDraft, setIsDraft] = useState(true);
    const {auth} = useStore();
    const [error, setError] = useState(false);
    const [store, setStore] = useState(null); // Стейт для стора

    const validatePhotos = (payload, errors) => {
        let photos = true;
        if (ImageConfigs.verifyImages(`project.construction.photos`, payload.photos.length) && payload.photos.length < 1) {
            _.set(errors, 'photos', 'Add at least 1 photo of property');
            photos = false;
        } else {
            _.unset(errors, 'photos');
        }
        return photos;
    };

    const removeUnusedProjectTypeObjects = (data) => {
        ['liveArea', 'officeArea', 'restaurantCafeArea', 'commercialArea', 'storeProductionArea', 'hotelArea'].some(item => {
            if (data?.project?.projectType && !data?.project?.projectType?.includes(item)) {
                delete data.project[item];
            }
        });
        return data;
    };

    useEffect(() => {
        const getProject = async (projectId) => {
            const result = await service.getProject(projectId);
            if (result.code === 200) {
                if (!type) await setTypeStore(projectTypeStore.getTypeStore(result.data.type.toLowerCase()));
                setFields(result.data);
                setIsDraft(result.data.configs.user.isDraft);
            } else {
                setError(true);
            }
            setLoading(false);
        };

        if (projectId) {
            setLoading(true);
            getProject(projectId);
        }
    }, []);

    useEffect(() => {
        if (typeStore) {
            const newStore = new FormStore({
                fields: !!projectId ? _.merge(typeStore?.fields, fields) : typeStore?.fields ?? {},
                steps: typeStore?.steps ?? [],
                async submit(fields, filter) {
                    let payload = { ...fields, ...filter, type: type ? type : fields.type };
                    let isValidate = true;
                    let photos = true;
                    if (!payload.isDraft) {
                        this.isDraft = false;
                        photos = validatePhotos(payload, this.fieldsErrors);
                        let reqFields = [];
                        switch (fields.type) {
                            case 'Construction':
                                reqFields = ['name', 'address.country', 'address.city', 'project.financial.volumeAttracted', 'project.projectType'];
                                break;
                            case 'Land':
                                reqFields = ['name', 'address.country', 'address.city', 'project.areaTotal', 'project.marketValue'];
                                break;
                            case 'Unit':
                                reqFields = ['name', 'address.country', 'address.city'];
                                break;
                            default:
                                reqFields = [];
                                break;
                        }

                        isValidate = this.validateForm(reqFields);

                        if(payload.type.toLowerCase() === 'unit' && payload?.project?.units?.length <1)
                        {
                            isValidate = false;
                            this.fieldsErrors.units = 'Add at less 1 unit'

                        }
                    }
                    if (isValidate && photos) {
                        function processErrors(errors, parentKey = '') {
                            Object.entries(errors).forEach(([key, error]) => {
                                const fullKey = parentKey ? `${parentKey}.${key}` : key;

                                if (typeof error === 'object' && error !== null) {
                                    processErrors(error, fullKey);
                                } else {
                                    snackActions.error(`${fullKey}: ${error}`);
                                }
                            });
                        }
                        processErrors(this.fieldsErrors)
                        this.isDraft = true;
                        return 'Validate Error'
                    }
                        let formData = new FormData();
                        formData = findAndAppendFilesToFormData(payload, formData);
                        if(payload.type.toLowerCase() === 'construction') {
                            payload = removeUnusedProjectTypeObjects(payload);
                        }
                        formData.append('data', JSON.stringify(payload));

                        const result = await service.addProject(formData);
                        if (result?.code !== 200) {
                            return result;
                        }
                            setFields(_.merge(typeStore?.fields, result.data));
                            this.fields = result.data;
                            setIsDraft(result.data.configs.user.isDraft);
                            return result;

                },
                reqFields: typeStore?.reqFields ?? [],
                isEdit,
                customValidation: validatePhotos
            });
            setStore(newStore);
        }
    }, [typeStore, fields]);

    if (error) {
        return (<Error />);
    }

    if (!typeStore || !store) {
        return (
            <Grid container
                  sx={{
                      height: '50vh'
                  }}
                  justifyContent={'center'} alignContent={'center'}>
                <CircularProgress />
            </Grid>
        );
    } else {
        return (
            <FormContextProvider
                store={store}>
                <ProjectPage
                    loading={loading}
                    isDraft={isDraft}
                />
            </FormContextProvider>
        );
    }
});

const ProjectPage = observer(({loading, isDraft}) => {
    const store = useFormStore();
    const {auth} = useStore();
    const [isEdit,setIsEdit] = useState(false)

    const { type: paramType } = useParams();
    const currentType = store?.fields?.type !== '' ? store?.fields?.type : paramType;

    const title = currentType.toLowerCase() === 'construction'
        ? projectDictionary.get('project_add_construction_title')
        : currentType.toLowerCase() === 'land'
            ? projectDictionary.get('project_add_land_title')
            : projectDictionary.get('project_add_unit_title');
    return (
        <>
            {!loading ? (
                    auth.user && store.fields.userId && auth.user._id !== (store.fields?.userId?._id ?store.fields.userId._id : store.fields.userId) ? (<Error/>) :
                        (<Page
                            title={title}
                            showBackButton={true}
                            header={
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    gap:'10px'
                                }}>
                                    {(isEdit||isDraft) ?
                                        <LoadingButton variant={'contained'}
                                                       loading={store.sending}
                                                       disabled={store.sending}
                                                       onClick={!isDraft ?
                                                           store.handleSendForm :
                                                           store.handleSendDraftForm}
                                        >
                                            <img src={saveChangesIcon} alt={'add'}/>
                                            <Typography ml={'10px'}>
                                                {commonDictionary.get('save_changes')}
                                            </Typography>
                                        </LoadingButton>
                                        : <></>}
                                    {isEdit ?
                                        <LoadingButton variant={'contained'}
                                                       loading={store.sending}
                                                       disabled={store.sending}
                                                       onClick={!isDraft ?
                                                           ()=>{
                                                           store.fields.configs.moderation = 'active'
                                                           store.handleSendForm()
                                                               setIsEdit(false)
                                                       } :
                                                           store.handleSendDraftForm}
                                        >
                                            <img src={saveChangesIcon} alt={'add'}/>
                                            <Typography ml={'10px'}>
                                                {commonDictionary.get('send_to_moderation')}
                                            </Typography>
                                        </LoadingButton>
                                        : <></>}
                                </Box>
                            }
                            containerSx={{padding: '0px 0px 0px 0px', backgroundColor: '#F9FAFB'}}
                            containerStyle={{padding: '0px 0px 0px 0px'}}
                        >
                            <>
                                {store.fields?.configs?.moderation && isEdit === false ?
                                    <Moderation store={store} toggleEdit={()=>{setIsEdit(!isEdit)}}/> :
                                    <DraftForm
                                        store={store}
                                        sendButtonText={projectDictionary.get('project_add_send_button')}
                                    />}
                            </>
                        </Page>))
                :
                (<Grid container justifyContent={'center'} alignContent={'center'}>
                    <CircularProgress/>
                </Grid>)
            }
        </>
    )
})
export default ProjectForm;
