import ConstructionStore from "./ConstructionStore";
import {makeAutoObservable} from "mobx";
import LandStore from "./LandStore";
import UnitStore from "./UnitStore";

class ProjectTypesStore {
    // даем наследоваться от текущего класса,
    // при запросе на форму возвращаем обьект формы ребенка с его шагами,
    // при завершении работы ребенка отправляем результат работы через родителя
    // если произошла ошибка с обработкой данных ребенка, берем последние данные итема ребенка
    // и создаем новый обьект ребенка на основе этих данных,
    // что позволяет сохранить данные и обработать вывод ошибок
    //
    fields = {
        type:'',
        name: '',
        photos:[],
        address:{
            location: {
                latitude: 0,
                longitude: 0
            },
            place_id: '',
            city: '',
            country: '',
            street: '',
            streetNumber: ''
        },
        configs:{
            user:{
                isAvailable:true,
                isSold:false
            }
        },
    }
    steps = [];
    reqFields = ['type'];

    constructor() {
        //makeAutoObservable(this)
    }

    getTypeStore = (type) => {
        let typeStore = null;
       switch (type){
           case 'construction': {
               typeStore = new ConstructionStore({
                   fields: this.fields,
                   steps: this.steps,
                   reqFields: this.reqFields
               })
               break;
           }
           case 'land': {
               typeStore = new LandStore({
                   fields: this.fields,
                   steps: this.steps,
                   reqFields: []
               })
               break;
           }
           case 'unit': {
               typeStore = new UnitStore({
                   fields: this.fields,
                   steps: this.steps,
                   reqFields: []
               })
               break;
           }
           default:{
               break;
           }
       }
       return typeStore
    }

}

export default ProjectTypesStore;