import {
    Box,
    Button,
    Divider,
    FormControl, FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import React, {Fragment, useContext} from 'react';
import {FullAddressBlock} from 'components/index';
import {observer} from 'mobx-react-lite';
import globalDictionary from 'assets/translations/globalDictionary';
import clipboardText from 'assets/icons/clipboard-text.svg'
import {useContractStore} from "../ContractStore";
import {FileUploader} from "react-drag-drop-files";
import DocumentView from "components/shared/DocumentView/DocumentView";
import {CustomAlert} from "components";
import ClipboardText from 'assets/icons/clipboard-text.svg'


const ObjectBlock = observer(() => {
    const formData = useContractStore()
    const wrongSymbols = ['e', 'E', '-', '+'];

    return (
        <Grid container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        {globalDictionary.get('contract_object_info')}
                    </Typography>
                </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                {/*<Typography pb={1} fontWeight={600}>*/}
                {/*    {globalDictionary.get('contract_add_object_name')}*/}
                {/*</Typography>*/}
                <TextField
                    label={globalDictionary.get('contract_add_object_name')}
                    variant="outlined"
                    fullWidth
                    required
                    name="build.buildName"
                    value={formData.contract.build.buildName || ''}
                    onChange={formData.handleInputChange}
                    error={!!formData.contractErrors.build.buildName}
                    helperText={formData.contractErrors.build.buildName}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
                {/*<Typography pb={1} fontWeight={600}>*/}
                {/*    {globalDictionary.get('contract_add_object_start_price_label')}*/}
                {/*</Typography>*/}

                <TextField
                    label={globalDictionary.get('contract_add_object_start_price')}
                    variant="outlined"
                    fullWidth
                    required
                    type={'number'}
                    inputProps={{min: 0, max: 9999999999.99}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 9999999999.99) {
                            event.target.value = '9999999999.99';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}
                    name="startingSalePrice"
                    value={formData.contract.startingSalePrice || ''}
                    onChange={formData.handleInputChange}
                    error={!!formData.contractErrors.startingSalePrice}
                    helperText={formData.contractErrors.startingSalePrice}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id={'object-status'}>
                        {globalDictionary.get('contract_add_object_status')}
                    </InputLabel>
                <Select
                    value={formData.contract.build.buildStatus || ''}
                    fullWidth
                    labelId={'object-status'}
                    label={globalDictionary.get('contract_add_object_status')}
                    required
                    name={'build.buildStatus'}
                    onChange={formData.handleInputChange}
                    error={_.get(formData.contractErrors,'build.buildStatus')}
                >
                    <MenuItem value="underConstruction">
                        {globalDictionary.get('contract_add_object_status_under')}
                    </MenuItem>
                    <MenuItem value="enteredIntoOperation">
                        {globalDictionary.get('contract_add_object_status_entered')}
                    </MenuItem>
                </Select>
                    <FormHelperText error={_.get(formData.contractErrors,'build.buildStatus')}>{_.get(formData.contractErrors,'build.buildStatus')}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                {/*<Typography pb={1} fontWeight={600}>*/}
                {/*    {globalDictionary.get('contract_add_object_floors')}*/}
                {/*</Typography>*/}
                <TextField
                    label={globalDictionary.get('contract_add_object__count_floors')}
                    variant="outlined"
                    fullWidth
                    required
                    name="build.floors"
                    onKeyDown={(e) => wrongSymbols.includes(e.key) && e.preventDefault()}
                    type={'number'}
                    value={formData.contract.build.floors || ''}
                    onChange={formData.handleInputChange}
                    error={!!formData.contractErrors.build.floors}
                    helperText={formData.contractErrors.build.floors}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                {/*<Typography pb={1} fontWeight={600}>*/}
                {/*    {globalDictionary.get('contract_add_object_total_area')}*/}
                {/*</Typography>*/}
                <TextField
                    label={globalDictionary.get('contract_add_object_total_area')}
                    variant="outlined"
                    fullWidth
                    required
                    inputProps={{min: 0, max: 1000000.00}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 1000000.00) {
                            event.target.value = '1000000.00';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}

                    type={'number'}
                    name="build.buildSquare"
                    value={formData.contract.build.buildSquare || ''}
                    onChange={formData.handleInputChange}
                    error={!!formData.contractErrors.build.buildSquare}
                    helperText={formData.contractErrors.build.buildSquare}
                />
            </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={8} sx={{
                marginTop:'40px',
            }}>
                <Typography variant={'h6'}
                sx={{
                    marginBottom:'20px'
                }}>
                    {globalDictionary.get('contract_object_location')}
                </Typography>
                <FullAddressBlock
                    data={formData.contract.build.buildAddress}
                    parentField={'build.buildAddress'}
                    dataError={formData.contractErrors}
                    handleInputChange={formData.handleInputChange}
                    countryTranslateKey={'contract_add_object_country'}
                />
            </Grid>
            {formData.userType.some((item) => item.toLowerCase() === 'seller') && formData.contractObjectType === 'property' && (
                <Grid item xs={12} sm={12} md={12} lg={8}>
                    <CustomAlert severity={'error'} sx={{
                        mt: '10px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}>
                            <Typography sx={{
                                fontSize: '14px',
                                lineHeight: '150%',
                                color: 'black',
                            }}>
                                {globalDictionary.get('contract_object_seller')}
                            </Typography>
                            <ul style={{
                                listStyleType: 'disc',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                textAlign:'left',
                                justifyContent:'left'
                            }}>
                                {!!formData.contract?.pdfDocuments?.passport ?
                                    <DocumentView
                                        image={formData.contract?.pdfDocuments?.passport}
                                        name={globalDictionary.get('contract_object_seller_passport_scan')}
                                        handleDelete={()=>{
                                            formData.contract.pdfDocuments.passport = ''}
                                        }
                                    />
                                    :
                                    <FileUploader
                                        handleChange={(file) => {
                                            formData.uploadFile('pdfDocuments.passport', file)
                                        }}
                                        children={
                                            <Button
                                                variant={'contained'}
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '300px',
                                                    textAlign: {
                                                        xs: 'center',
                                                        sm: 'left',
                                                    },
                                                    justifyContent: 'left',
                                                    flexDirection: {
                                                        xs: 'column',
                                                        sm: 'row',
                                                    }
                                                }}
                                                startIcon={<img src={ClipboardText} alt={'clipboard'}/>}
                                            >
                                                <Typography>
                                                    {globalDictionary.get('contract_object_seller_passport')}
                                                </Typography>
                                            </Button>
                                        }
                                        name="pdfDocuments.passport"
                                        types={['JPG', 'JPEG', 'PNG', 'PDF', 'WEBP']}
                                    />
                                }
                                {!!formData.contract?.pdfDocuments?.owner?
                                    <DocumentView
                                        image={formData.contract?.pdfDocuments?.owner}
                                        name={globalDictionary.get('contract_object_seller_owner_scan')}
                                        handleDelete={() => {
                                            formData.contract.pdfDocuments.owner = ''
                                        }
                                        }
                                    />
                                    :
                                    <FileUploader
                                        handleChange={(file) => {
                                            formData.uploadFile('pdfDocuments.owner', file)
                                        }}
                                        children={

                                            <Button variant={'contained'} sx={{
                                                width: '100%',
                                                maxWidth: '300px',
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'left',
                                                },
                                                justifyContent: 'left',
                                                flexDirection: {
                                                    xs: 'column',
                                                    sm: 'row',
                                                }
                                            }}
                                                    startIcon={<img src={ClipboardText} alt={'clipboard'}/>}
                                            >
                                                <Typography>
                                                    {globalDictionary.get('contract_object_seller_owner')}
                                                </Typography>
                                            </Button>
                                        }
                                        name="pdfDocuments.owner"
                                        types={['JPG', 'JPEG', 'PNG', 'PDF', 'WEBP']}
                                    />
                                }

                            </ul>
                        </Box>
                    </CustomAlert>
                </Grid>
            )}
            {(formData.userType.some((item) => item.toLowerCase() === 'broker') && formData.contractObjectType === 'complex') && (
                <Grid item xs={12} sm={12} md={12} lg={8} sx={{
                    backgroundColor: '#F4F4F4',
                    borderRadius: '5px',
                    margin: '20px 20px 20px 0px',
                    padding: '20px',
                    display: 'flex',
                }}>
                    <Divider orientation="vertical" color="#E5494D" sx={{
                        marginRight: '18px',
                        width: '2px',
                        borderRight: 'none',
                    }}/>
                    <Box sx={{flexGrow: 1}}>

                        <Typography>
                            {globalDictionary.get('contract_agency_info')}
                        </Typography>
                        {!!formData.contract?.pdfDocuments?.agency ?
                            <DocumentView
                                image={formData.contract?.pdfDocuments?.agency}
                                name={globalDictionary.get('contract_agency_file')}
                                handleDelete={() => {
                                    formData.contract.pdfDocuments.agency = ''
                                }
                                }
                            />
                            :
                            <FileUploader
                                handleChange={(file) => {
                                    formData.uploadFile('pdfDocuments.agency', file)
                                }}
                                children={

                                    <Button variant={'contained'}
                                            startIcon={<img src={clipboardText} alt={'clipboard'}/>}
                                    >
                                        <Typography>
                                            {globalDictionary.get('contract_agency_upload')}
                                        </Typography>
                                    </Button>
                                }
                                name="pdfDocuments.agency"
                                types={['JPG', 'JPEG', 'PNG', 'PDF', 'WEBP']}
                            />
                        }
                    </Box>
                </Grid>

            )
            }
        </Grid>
    );
});

export default ObjectBlock;
