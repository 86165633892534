import {makeAutoObservable} from "mobx";
import React, {createContext, useContext} from "react";
import {observer} from "mobx-react-lite";
import {snackActions} from "utils/SnackBarUtils";
import globalDictionary from "assets/translations/globalDictionary";
import {isEmpty, isNumber, isString} from "lodash";

class FormStore {
    static lastInstance = null;
    fields = {};
    fieldsErrors = {};
    regFields = [];
    sending = false;
    isDraft = true;
    isEdit = false;
    finish = false;
    submit = () => {
    };
    customValidation = ()=>{ return false}

    constructor(props) {
        makeAutoObservable(this);
        this.fields = props?.fields || {};
        this.steps = props?.steps || [{label: '', content: <div/>}]
        this.errors = {};
        this.regFields = props.reqFields ?? [];
        this.isEdit = props?.isEdit ?? false;
        this.isDraft = props?.fields?.configs?.user?.isDraft ?? true;
        this.submit = props?.submit
        this.customValidation = props?.customValidation
        this.props = props

        FormStore.lastInstance = this;
    }

    static getLastInstance() {
        return FormStore.lastInstance;
    }
    toggleFinish = () => {
        this.finish = !this.finish
    }
    toggleIsEdit = () => {
        this.isEdit = !this.isEdit
    }

    handleSendDraftForm = async () => {
            this.sending = true
            const result = await this.submit(this.fields, {isDraft: true})

            if (result) {
                snackActions.success(globalDictionary.get('draft_stepper_saved'));
                this.fields = _.merge(this.fields,result.data)
                this.sending = false
            } else {
                snackActions.error(globalDictionary.get('request_failed'));
                this.sending = false
            }
            if(this.validateForm())return false


        // } else {
        //     // Object.entries(this.fieldsErrors).forEach(([key, error]) => {
        //     //     if (typeof error === 'object') {
        //     //         Object.values(error).forEach(subError => {
        //     //             snackActions.warning(`${key}: ${subError}`);
        //     //         });
        //     //     } else {
        //     //         snackActions.warning(`${key}: ${error}`);
        //     //     }
        //     // });
        //     this.sending = false
        //     return true
        // }
    }


    handleSendForm = async () => {
        this.sending = true;
        if (!this.validateForm(this.regFields)) {
            Object.entries(this.fieldsErrors).forEach(([key, error]) => {
                if (typeof error === 'object') {
                    Object.values(error).forEach(subError => {
                        snackActions.error(`${key}: ${subError}`);
                    });
                } else {
                    snackActions.error(`${key}: ${error}`);
                }
            });
            this.sending = false
            return false
        }
        const result = await this.submit(this.fields, {isDraft: false});
        console.log(result)
        if (result?.code !==200) {
            snackActions.error(globalDictionary.get('request_failed'));
            return result
        }
            snackActions.success(globalDictionary.get('draft_stepper_sent'));
            this.sending = false
            return result;


    }
    handleInputField = (field, value) => {
        const parts = field.split(".");
        if (parts.length > 0) {
            _.set(this.fields, field, value)
        } else {
            this.fields[field] = value
        }
        this.validate(field)
        if (this.regFields.includes(field) && this.customValidation) this.customValidation(this.fields,this.fieldsErrors)
    }
    handleInputRadio = (event) => {
        const {name, value} = event.target;
        _.set(this.fields,name,value === 'true')
        //this.fields[name] = value === 'true';
    }

    handleInputCheckBox = (event) => {
        const {name, checked} = event.target;
        const parts = name.split(".");

        if (checked) {
            _.concat(this.fields[parts[0]], parts[1]);
        } else {
            _.remove(this.fields[parts[0]], (item) => item === parts[1]);
        }
    }

    handleToggleCheckBox = (event) => {
        const {name, checked} = event.target;
        console.log(checked)
        _.set(this.fields,name,checked);
    }

    handleAddRemoveArrayElem = (field, value) => {
        const currentValue = _.get(this.fields, field, []);
        if (currentValue.includes(value)) {
            _.set(this.fields, field, currentValue.filter(item => item !== value));
        } else {
            _.set(this.fields, field, _.concat(currentValue, value));
        }
    }
    handleInputSwitch = (event, value) => {
        const {name} = event.target;
        _.set(this.fields,name,value)
    }

    validateInput = (pattern, value) => {
        switch (pattern) {
            case 'positive': {
                const wrongSymbols = ['e', 'E', '-', '+'];
                if (wrongSymbols.includes(value)) {
                    return '';
                } else {
                    return value;
                }
            }
            default: {
                return value
            }
        }
    }

    handleInputChange = (event) => {
        const {name, value} = event.target;

        _.set(this.fields, name, value);

        this.validate(name);
    }

    handleInputDate = (event) => {
        const {name, value} = event.target;
        const parts = name.split(".");
        if (parts.length > 1) {
            const nestedField = parts[1];
            const topLevelField = parts[0];

            this.fields[topLevelField] = {
                ...this.fields[topLevelField],
                [nestedField]: Date.parse(value),
            };
        } else {
            this.fields[name] = Date.parse(value);
        }
        this.validate(name)
    }
    handleInputArrayElem = (field, elem) => {
        const parts = field.split(".");
        let current = this.fields;

        for (let i = 0; i < parts.length - 1; i++) {
            if (!current[parts[i]]) {
                current[parts[i]] = {};
            }
            current = current[parts[i]];
        }

        const lastPart = parts[parts.length - 1];

        if (!Array.isArray(current[lastPart])) {
            current[lastPart] = [];
        }

        current[lastPart].push(elem);

        this.validate(field);
    }

    handleDeleteArrayElem = (field, index) => {
        const array = _.get(this.fields, field);

        if (Array.isArray(array) && index >= 0 && index < array.length) {

            const updatedArray = array.filter((_, i) => i !== index);

            _.set(this.fields, field, updatedArray);
        }
    }
    uploadFile = (name, file) => {
        _.set(this.fields, name, file);
    }

    setFields = (setData) => {
        const newData = setData(this.fields);
        this.fields = newData;
        if (this.customValidation) this.customValidation(this.fields,this.fieldsErrors)
    }

    validate = (key) => {
        console.log(this.fields,key)
        if (isString(_.get(this.fields, key)) && isEmpty(_.get(this.fields, key))) {
            _.set(this.fieldsErrors, key, globalDictionary.get('validate_empty'))
        } else if(_.get(this.fields, key) === 0|| !_.get(this.fields, key)|| _.get(this.fields, key) ===''){
            _.set(this.fieldsErrors, key, globalDictionary.get('validate_empty'))
        }else {
            _.unset(this.fieldsErrors, key)
        }
        console.log(this.fieldsErrors)
    }

    validateForm = (reqFields = this.regFields) => {
        this.fieldsErrors = []
        reqFields.forEach((key) => {
            this.validate(key);
        });

        if(this.customValidation) this.customValidation(this.fields,this.fieldsErrors)

        const errorsArray = [];
        for (const key in this.fieldsErrors) {
            if (typeof this.fieldsErrors[key] === 'object') {
                for (const nestedKey in this.fieldsErrors[key]) {
                    errorsArray.push(this.fieldsErrors[key][nestedKey]);
                }
            } else {
                errorsArray.push(this.fieldsErrors[key]);
            }
        }

        return errorsArray.length === 0;
    }
    //remove files, and don`t understand when user set empty field
    removeEmptyFields = (obj) => {
        if (_.isArray(obj)) {
            return obj
                .map(item => this.removeEmptyFields(item))
                .filter(item => !_.isNil(item) && (typeof item !== 'object' || !_.isEmpty(item)));
        } else if (_.isObject(obj)) {
            return _.transform(obj, (result, value, key) => {
                const cleanedValue = this.removeEmptyFields(value);

                // Условие для удаления пустых строк, null, undefined и пустых объектов/массивов, но оставить false, 0 и пустые массивы
                if (!_.isNil(cleanedValue) && (typeof cleanedValue !== 'object' || !_.isEmpty(cleanedValue) || _.isBoolean(cleanedValue) || _.isNumber(cleanedValue))) {
                    result[key] = cleanedValue;
                }
            }, {});
        } else {
            return !_.isNil(obj) && obj !== '' ? obj : undefined;
        }
    }

    updateFields = (data) => {
        this.fields = data
    }

}

export default FormStore;


export const FormContext = createContext();

export const useFormStore = () => {
    return useContext(FormContext)
}

export const FormContextProvider = observer(({children, store}) => {
    return <FormContext.Provider value={store}>
        {children}
    </FormContext.Provider>
})
