import {useCallback} from 'react';
import {DragDropContext, Droppable} from '@hello-pangea/dnd';

import {Stack, Box} from '@mui/material';
import EmptyContent from 'components/v2/empty-content';

import KanbanColumn from '../kanban-column';
import {KanbanColumnSkeleton} from '../kanban-skeleton';
import {createContext} from 'react';
import globalDictionary from 'assets/translations/globalDictionary';
import {snackActions} from "utils/SnackBarUtils";

export const BoardContext = createContext();
export default function KanbanView({
                                       board,
                                       boardLoading,
                                       boardEmpty,
                                       setBoard,
                                       updateContractLead,
                                       isTaskMove,
                                   }) {
    const moveTask = async ({destination, source, draggableId, type, board}) => {
        let updateColumns = null;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const sourceColumn = {...board.columns[source.droppableId]};
        const destinationColumn = {...board.columns[destination.droppableId]};

        // Moving task to same list
        if (sourceColumn.id === destinationColumn.id) {
            const newTaskIds = [...sourceColumn.taskIds];
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);
            updateColumns = {
                ...board?.columns,
                [sourceColumn.id]: {
                    ...sourceColumn,
                    taskIds: newTaskIds,
                },
            };
        } else {
            const sourceTaskIds = [...sourceColumn.taskIds];
            const destinationTaskIds = [...destinationColumn.taskIds];
            const result = await updateContractLead(draggableId, destination.droppableId);
            if (!result) return;

            sourceTaskIds.splice(source.index, 1);
            destinationTaskIds.splice(destination.index, 0, draggableId);
            updateColumns = {
                ...board?.columns,
                [sourceColumn.id]: {
                    ...sourceColumn,
                    taskIds: sourceTaskIds,
                },
                [destinationColumn.id]: {
                    ...destinationColumn,
                    taskIds: destinationTaskIds,
                },
            };
        }

        const updatedBoard = {
            ...board,
            columns: updateColumns,
        };

        setBoard(updatedBoard);
    };

    const onDragEnd = useCallback(
        async (data) => {
            try {
                await moveTask({...data, board});
            } catch (error) {
                console.error(error);
                snackActions.error('Failed to update lead');
            }
        },
        [board, board?.columns, board?.ordered]
    );

    const renderSkeleton = (
        <Stack direction="row" alignItems="flex-start" spacing={3}>
            {[...Array(4)].map((_, index) => (
                <KanbanColumnSkeleton key={index} index={index}/>
            ))}
        </Stack>
    );

    if (boardLoading) return renderSkeleton;
    if (boardEmpty)
        return (
            <EmptyContent
                filled
                title={globalDictionary.get('no_data')}
                sx={{
                    py: 10,
                    maxHeight: {md: 480},
                }}
            />
        );

    return (
        <BoardContext.Provider value={{moveTask: moveTask, board: board}}>
            <>
                {!!board?.ordered.length && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="board"
                            type="COLUMN"
                            direction="horizontal"
                            isDropDisabled={true}
                        >
                            {(provided) => (
                                <Box
                                    sx={{
                                        height: '100%',
                                        overflowX: 'scroll',
                                    }}
                                >
                                    <Stack
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        spacing={'20px'}
                                        direction="row"
                                        alignItems="flex-start"
                                        height={'auto'}
                                        pb={'25px'}
                                    >
                                        {board?.ordered.map((columnId, index) => (
                                            <KanbanColumn
                                                index={index}
                                                key={columnId}
                                                column={board?.columns[columnId]}
                                                tasks={board?.tasks}
                                                isTaskMove={isTaskMove}
                                            />
                                        ))}

                                        {provided.placeholder}
                                    </Stack>
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </>
        </BoardContext.Provider>
    );
}
