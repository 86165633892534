import React from 'react';
import Flag from 'react-world-flags';
import {Link} from 'react-router-dom';
import countries from "assets/translations/countries";
import {ImageChecker} from "components";
import {Box, Button, Typography} from "@mui/material";
import roles from "../../../assets/translations/roles";
import globalDictionary from 'assets/translations/globalDictionary';
import {getProfileData} from "utils";
import {useStore} from "../../../Providers";

const CommunityListItem = ({
                               community: {
                                   _id: commId,
                                   country,
                                   type,
                                   user,
                                   name: companyName,
                                   contact = {}
                               }
                           }) => {
    const profileName = getProfileData('name', {...user, contact, companyName});
    const {auth} = useStore();
    return (
        <Link to={`/community/${user.seoName}`} style={{
            borderRadius: '5px',
            overflow: 'hidden',
        }}>
            <Box sx={{
                maxWidth: '285px',
                height: '330px',
                position: 'relative',
            }}>
                <ImageChecker src={user.avatar} alt={profileName}/>
                {
                    user?.address?.country && (
                        <Box sx={{
                            position: 'absolute',
                            bottom: '13px',
                            left: '13px',
                            borderRadius: '5px',
                            backgroundColor: 'rgba(255, 255, 255, .7)',
                            border: 'solid 1px white',
                            padding: '5px 20px 5px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            '& img': {
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }
                        }}>
                            <Flag code={user.address.country}/>
                            <Typography sx={{
                                color: '#021228',
                                fontFamily: 'Sofia Sans Condensed',
                                fontSize: '12px',
                                textTransform: 'uppercase'
                            }}>
                                {countries.get(user.address.country)}
                            </Typography>
                        </Box>
                    )
                }
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                mt: '20px'
            }}>
                <Typography sx={{
                    fontFamily: 'Sofia Sans Condensed',
                    fontSize: '20px',
                    textTransform: 'uppercase',
                    color: '#021228',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    {profileName}
                </Typography>
                <Typography sx={{
                    fontSize: '12px',
                    color: '#939393',
                    lineHeight: '18px',
                    height: '36px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical'
                }}>
                    {type.filter((role) => !['Admin', 'Support'].includes(role)).map((role) => roles.get(role)).join(' ⬥ ')}
                </Typography>
            </Box>
            <Box sx={{
                mt: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}>
                <Button variant={'contained'} color={'secondary'} fullWidth>
                    {globalDictionary.get('community_list_item_more')}
                </Button>
                <Button variant={'text'}
                        color={'secondary'}
                        fullWidth
                        component={Link}
                        to={auth.user.flags.confirm ? auth.user.flags.isVerified ? `/chat/${user._id}` : '/my/profile/verification' : '/my/profile/info'}>
                    {globalDictionary.get('community_send_message')}
                </Button>
            </Box>
        </Link>
    );
}
export default CommunityListItem;
