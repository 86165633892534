import {GremService} from 'services';
import {getUserToken} from 'utils';
import {server} from '../config';

class ReferralService {
    constructor() {
        this._apiBase = server;
        this.service = new GremService();
    }

    async getLinks() {
        const res = await this.service.sendRequest(
            `/referrals`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
    async getList() {
        const res = await this.service.sendRequest(
            `/referrals/list`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
    async incrementView(refId,short) {
        const res = await this.service.sendRequest(
            `/referrals/view/${refId}?utm_short=${short}`,
            'PATCH',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
    async createLink(
        {
            name,
            refId,
        }
    ) {
        const res = await this.service.sendRequest(
            `/referrals/link/${refId}?name=${name}`,
            'PUT',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
    async sendInvite(email) {
        const res = await this.service.sendRequest(
            `/referrals/invite`,
            'POST',
            {email},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    resetLink = async (refId, short) => {
        const res = await this.service.sendRequest(`/referrals/link/${refId}?utm_short=${short}`,
            'PATCH',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    deleteLink = async (refId, short) => {
        const res = await this.service.sendRequest(`/referrals/link/${refId}?utm_short=${short}`,
            'DELETE',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }
}

export default ReferralService;
