import {MapWithASearchBox} from "../../index";
import React, {useEffect, useState} from "react";
import {CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import globalDictionary from "assets/translations/globalDictionary";
import countries from "../../../assets/translations/countries";
import languageStore from "../../../assets/translations/languageStore";

const LocationBlock = observer(({data, setData, errors, field}) => {
    const [loading, setLoading] = useState(false)
    const setLocation = (location) => {
        setData(field, {
            place_id: location.place_id ?? data[field].place_id,
            country: location.country ?? data[field].country,
            street: location.street ?? data[field].street,
            streetNumber: location.streetNumber ?? data[field].streetNumber,
            city: location.city ?? data[field].city,
            location: {
                longitude: location.lng,
                latitude: location.lat,
            },
        })
    }

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setData(field, {
            ...data[field],
            [name]: value
        })
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {

            setLoading(false)
        }, 500)
    }, [languageStore.activeLanguage])

    return (
        <Grid container spacing={2} sx={{backgroundColor: 'white',}}>
            <Grid item xs={12} sm={6}>
                <Typography variant={'subtitle2'}>
                    {globalDictionary.get('complex_location_block_title')}
                </Typography>
            </Grid>
            <Grid container item spacing={2}>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        display: 'flex',
                        justifyContent: loading ? 'center' : 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {loading ? <CircularProgress/> : <MapWithASearchBox
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&v=3.exp&libraries=places&language=en`}
                        lat={data[field].location.latitude}
                        lng={data[field].location.longitude}
                        location={data[field]}
                        funcSetLocation={setLocation}
                    />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="select-country">
                                    {globalDictionary.get('location_country')}
                                </InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-country"
                                    id="select-type"
                                    label='country'
                                    required
                                    error={errors?.country ?? false}
                                    value={data[field].country}
                                    name={'country'}
                                    onChange={handleInputChange}
                                >
                                    {countries.getAllValueLabel().map((type) => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={globalDictionary.get('location_city')}
                                fullWidth
                                name={'city'}
                                error={errors?.city ?? false}
                                value={data[field].city}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={globalDictionary.get('location_street')}
                                fullWidth
                                name={'street'}
                                value={data[field].street}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={globalDictionary.get('location_street_num')}
                                fullWidth
                                name={'streetNumber'}
                                value={data[field].streetNumber}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )

})

export default LocationBlock;
