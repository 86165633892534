import {getUserToken} from 'utils/index';
import {validateResponseData} from 'utils/ProcessResponseUtils';
import {server} from 'config';
import {Error} from "../pages";

class GremService {
    constructor(apiBase = server) {
        this._apiBase = apiBase;
        this.defaultHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }

    async sendRequest(route, method = 'GET', data = {}, additionalHeaders = {}, isBlob = false) {
        const headers = {...this.defaultHeaders, ...additionalHeaders};
        if (getUserToken()) {
            headers['x-www-access'] = getUserToken();
        }

        const payload =
            method === 'GET' ? {method, headers} : {method, body: JSON.stringify(data), headers};

        try {
            const response = await fetch(`${this._apiBase}${route}`, payload);
            const parsedResponseData = isBlob ? await response.blob() : await response.json();
            const validateResponse = validateResponseData(parsedResponseData);
            return validateResponse
        } catch (error) {
            return this.handleRequestError(error);
        }
    }

    async sendFormData(route, method = 'GET', data = {}, additionalHeaders = {}, isBlob = false) {
        const headers = {...this.defaultHeaders, ...additionalHeaders};
        if (getUserToken()) {
            headers['x-www-access'] = getUserToken();
        }

        const payload = {method, body: data, headers};

        try {
            const response = await fetch(`${this._apiBase}${route}`, {
                method: method,
                body: data,
                headers: {'x-www-access': getUserToken()}
            });
            const parsedResponseData = isBlob ? await response.blob() : await response.json();
            return validateResponseData(parsedResponseData);
        } catch (error) {
            return this.handleRequestError(error);
        }
    }

    handleRequestError(error) {
        console.error(error);

        return {
            code: 404,
            message: 'Errors',
            errors: [{errorCode: 409172, errorMessage: 'Internal Server Error'}],
        };
    }

    async updateUserAvatar(data = {}) {
        const res = await this.sendFormData(
            '/users/avatar',
            'PATCH',
            data,
            {
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async doLogin({email = '', pass = ''}) {
        const res = await this.sendRequest(
            '/users/login',
            'POST',
            {
                email,
                pass,
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest',
            }
        );
        return res;
    }

    async doRegister({
                         email = '',
                         pass = '',
                         token = '',
                         lang = 'en',
                         refId = '',
                         short = '',
                     }) {
        const res = await this.sendRequest(
            '/users/register',
            'POST',
            {
                email,
                pass,
                token,
                lang,
                refId,
                short
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest',
            }
        );
        return res;
    }

    async resendConfirmMail() {
        const res = await this.sendRequest(
            '/users/resend-confirm-email',
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async doConfirmMail({userId = '', registrationToken = ''}) {
        const res = await this.sendRequest(
            `/users/confirm-email/${userId}/${registrationToken}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest',
            }
        );
        return res;
    }

    async confirmUser(data) {
        const res = await this.sendFormData(
            `/users/confirm-user`,
            'POST',
            data,
            {
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async updateUser(data) {
        const res = await this.sendFormData(
            `/users/update`,
            'POST',
            data,
            {
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async sendResetPasswordRequest(email = '') {
        const res = await this.sendRequest(
            '/users/reset-password',
            'POST',
            {
                email,
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guyest',
            }
        );
        return res;
    }

    async sendNewPassword({recoveryToken = '', password: newpass = ''}) {
        const res = await this.sendRequest(
            `/users/reset-password-confirm/${recoveryToken}`,
            'POST',
            {
                newpass,
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guyest',
            }
        );
        return res;
    }

    async authGoogle(data = {}) {
        const res = await this.sendRequest('/users/googleAuth', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': 'guest',
        });
        return res;
    }

    async authFacebook(data = {}) {
        const res = await this.sendRequest('/users/facebookAuth', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': 'guest',
        });
        return res;
    }

    async authLinkedin(code = '') {
        const res = await this.sendRequest(
            '/users/linkedinAuth',
            'POST',
            {
                code,
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest',
            }
        );
        return res;
    }

    async doLogout() {
        const res = await this.sendRequest(
            '/users/logout',
            'POST',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getUserSessions() {
        const res = await this.sendRequest(
            '/users/session',
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async deleteUserSession(token = '') {
        const res = await this.sendRequest(
            '/users/session',
            'DELETE',
            {
                token,
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getCryptoPrices(data = {}) {
        const res = await this.sendRequest('/serviceRequest/cryptoPrices', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': 'guest',
        });
        return res;
    }

    async getUserInfo(userId = '') {
        const res = await this.sendRequest(
            '/users/item',
            'POST',
            {userId},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getUser() {
        const res = await this.sendRequest(
            '/users/item',
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async sendPartnerInviteByEmail(email = '') {
        const res = await this.sendRequest(
            '/users/invite',
            'POST',
            {email},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async sendForm(data = {}) {
        const res = await this.sendRequest('/common/grem/request', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async sendContactForm(data = {}) {
        const res = await this.sendRequest('/common/grem/feedback', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': 'guest',
        });
        return res;
    }

    async sendUserUnavailabilityDates(data = {}) {
        const res = await this.sendRequest('/community/setUnavailability', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async getProperties(data = {}) {
        const res = await this.sendRequest('/property/list', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async getComplex(data = {}) {
        const res = await this.sendRequest(
            `/complex/?${data}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getComplexById(complexId) {
        const res = await this.sendRequest(
            `/complex/${complexId}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getAIDescription(data = {text: ''}) {
        const res = await this.sendRequest('/openai/gpt/description', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async getCommunity(data = '') {
        const res = await this.sendRequest(
            `/community?${data}`,
            'GET',
            data,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async getUserCommunity() {
        const res = await this.sendRequest(
            '/community/user',
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async getCommunityItem(id) {
        const res = await this.sendRequest(
            `/community/${id}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async getReferralParent(referralCode = '') {
        const res = await this.sendRequest(
            '/referrals/parent',
            'POST',
            {referralCode},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest',
            }
        );
        return res;
    }

    async getPropertiesMapMarkers() {
        const res = await this.sendRequest(
            '/property/markers',
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest',
            }
        );
        return res;
    }

    async getProjectsMapMarkers() {
        const res = await this.sendRequest(
            '/projects/markers',
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async doSecondaryAuth(data) {
        const res = await this.sendFormData(
            '/community/add',
            'POST',
            data,
            {
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async getArticles(data = {}) {
        const res = await this.sendRequest(
            `/articles?${data}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async changePassword(data = {}) {
        const res = await this.sendRequest('/users/direct-reset-password', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async getNewsArticle(newsId) {
        const res = await this.sendRequest(
            `/articles/${newsId}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getProperty(propId) {
        const res = await this.sendRequest(
            '/property/item',
            'POST',
            {propId},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getRequests(data = {}) {
        const res = await this.sendRequest('/serviceRequest/list', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async addRequestProperty(data = {}) {
        const res = await this.sendRequest('/serviceRequest/addProperty', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async addRequestSpecialist(data = {}) {
        const res = await this.sendRequest('/serviceRequest/addSpecialist', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async deleteRequest(requestId) {
        const res = await this.sendRequest(
            `/serviceRequest/${requestId}`,
            'DELETE',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getRequest(requestId) {
        const res = await this.sendRequest(
            '/serviceRequest/item',
            'POST',
            {requestId},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async updateCommunity(data = {}) {
        const res = await this.sendFormData(
            '/community/update',
            'PUT',
            data,
            {
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async addProperty(data = {}) {
        const res = await this.sendRequest('/property/add', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async addComplexProperty(data = {}) {
        const res = await this.sendRequest('/complex', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async getProject(projId) {
        const res = await this.sendRequest(
            '/projects/item',
            'POST',
            {projId},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async updateProperty(data = {}) {
        const res = await this.sendRequest('/property/update', 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async deleteComplexDraft(complexId) {
        const res = await this.sendRequest(
            `/complex/${complexId}`,
            'DELETE',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getUserNotifications(userId = '', page = 1, limit = 10) {
        const res = await this.sendRequest(
            `/notifications/${userId}/${page}/${limit}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async setAllUserNotificationsRead(userId = '') {
        const res = await this.sendRequest(
            `/notifications/${userId}`,
            'PUT',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getChats() {
        const res = await this.sendRequest(
            `/chat`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async createNewChat(data) {
        const res = await this.sendRequest(`/chat`, 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async createBrokerCommissionContract(data) {
        const res = await this.sendRequest(
            `/contract`,
            'POST',
            data,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async createSplitComissionContract(data) {
        const res = await this.sendFormData(`/contract/division`, 'POST', data, {
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async updateSplitComissionContract(id, data) {
        const res = await this.sendFormData(`/contract/division/${id}`, 'PUT', data, {
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async getContract(contractId) {
        const res = await this.sendRequest(
            `/contract/division/${contractId}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async uploadUserScan(contractId, data) {
        const res = await this.sendFormData(
            `/contract/division/${contractId}`,
            'PATCH',
            data,
            {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getContractLeads(contractId) {
        const res = await this.sendRequest(
            `/contract/division/${contractId}/lead`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getLeads() {
        const res = await this.sendRequest(
            `/contract/division/lead`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async updateContractLead(contractId, leadId, data) {
        const res = await this.sendRequest(
            `/contract/division/${contractId}/lead/${leadId}`,
            'PUT',
            {
                status: data.status,
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async createTargetLanding(data) {
        const res = await this.sendRequest(`/landing`, 'POST', data, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-www-access': getUserToken(),
        });
        return res;
    }

    async integratePartner(partner) {
        const res = await this.sendRequest(
            `/user/integrate/${partner}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getUserObjects(type, page, limit, userId) {
        const res = await this.sendRequest(
            `/objects?type=${type}&page=${page}&limit=${limit}&userId=${userId}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
}

export default GremService;
