import {GremService} from "services";
import React, {useEffect, useState} from "react";
import FormStore, {FormContextProvider, useFormStore} from "../../../../../components/shared/DraftForm/FormStore";
import {AIInput, Page} from "../../../../../components";
import {Box, Checkbox, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import CommunityStep from "../VerificationSteps/CommunityStep";
import SelectRole from "../VerificationSteps/SelectRole";
import globalDictionary from "assets/translations/globalDictionary";
import specializationRoles from "../../../../../assets/translations/specializationRoles";
import Image from "mui-image";
import {observer} from "mobx-react-lite";
import {snackActions} from "../../../../../utils/SnackBarUtils";
import {useStore} from "../../../../../Providers";
import {specialistIcons} from "../VerificationSteps/icons/specialistsIcons";
import { History } from 'utils/NavigationHistoryUtils';

const EditPage = observer(()=>{
    const communityStore = useFormStore();
    const getIcon = (label) => {
        return specialistIcons[label.toLowerCase()]
    }

    return(
        <>
        <Grid item xs={12}>
            <Typography sx={{
                fontSize:'16px',
                fontWeight:'700',
                marginBottom: '10px'}}>
                {globalDictionary.get('profile_main_add_role')}
            </Typography>
            <Grid container spacing={3} gridRow>
                {specializationRoles.getAllValueLabel().map((item) => {
                    return (
                        <Grid item>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '170px',
                                    borderRadius: '5px',
                                    backgroundColor: communityStore.fields.type.includes(item.value)?'#F0E8D9':'#F4F4F4',
                                    padding: '8px 32px',
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    textAlign: "center",
                                }}
                                onClick={()=>communityStore.handleAddRemoveArrayElem(`type`,item.value)}

                            >
                                <Checkbox
                                    name={`type.${item.value}`}
                                    value={item.value}
                                    checked={communityStore.fields.type.includes(item.value)}
                                    onChange={communityStore.handleInputCheckBox}
                                />
                                <Image src={getIcon(item.value)}
                                       width={'70px'}
                                       height={'70px'}/>
                                <Typography sx={{
                                    fontWeight: communityStore.fields.type.includes(item.value) ? 700 : 400
                                }}>
                                    {item.label}
                                </Typography>
                            </Box>
                        </Grid>)
                })}
            </Grid>
            {communityStore.fields.type.length !== 0 && communityStore.fields.type.every((type) => ['Buyer', 'Seller'].includes(type))&&
                <Grid item xs={12} marginTop={'20px'}>
                    <Box
                        display={'flex'}
                        flexDirection={"column"}
                        gap={'10px'}
                        justifyContent={"space-between"}
                    >
                        <Typography variant={'h6'}>
                            {globalDictionary.get('profile_verification_community_specialist_field_description')}
                        </Typography>
                        <Typography>
                            {globalDictionary.get('profile_verification_community_specialist_field_description_sub')}
                        </Typography>
                        <AIInput
                            label={globalDictionary.get('profile_verification_community_specialist_field_description')}
                            name={'description'}
                            value={communityStore.fields.description}
                            onChange={communityStore.handleInputChange}
                            maxTextLength={600}
                        />
                    </Box>
                </Grid>
            }
        </Grid>

            { communityStore.fields.type.length !== 0 && !communityStore.fields.type.every((type) => ['Buyer', 'Seller'].includes(type)) &&
                <CommunityStep/>
            }
        </>
    )
})

const EditRole = observer(() => {
    const {auth} = useStore();
    const gremService = new GremService();
    const [loading,setLoading] = useState(false);
    const [communityFields,setCommunityFields] = useState({
        subjectOfLaw: '',//Company
        type: [],
        description: '',
        specialization: '',
        businessCountry: [],
        experience: '',
        license: [],
        education: [],
        associations: [],
        portfolio: [],
        maxDeal: '',
        deals: '',
        investmentCountries: [],
        desiredInvestmentPeriod: '',
        desiredProfitability: '',
        qualifiedSpecialist: false,
        taxResidentUSA: false,
        name: '',
        numberOfCompletedProjects: '',
        foundationYear: '',
        registrationNumber: '',
        country: '',
        city: '',
        biggestProjects: '',
        totalNumberOfConstructedSquareMeters: '',
        managementObjectsValue: '',
        managementObjectsQuantity: '',
        ownTenantBase: false,
        membersNumber: '',
        branchesNumber: '',
        services: '',
        employees: '',
        implementedProjects: '',
        logo: [],
        certificateScan: [],
        clients: '',
        contact: {
            phone: '',
            fname: '',
            lname: '',
            position: '',
            email: '',
        },
    })

    const getCommunityUser = async () => {
        setLoading(true)
        const result = await gremService.getUserCommunity()
        if(result.code === 200){
            setCommunityFields(result.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        getCommunityUser()
    },[])

    const communityStore = new FormStore({
        fields: communityFields,
    })


    function findAndAppendFilesToFormData(data, formData, currentPath = '') {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                const path = currentPath ? `${currentPath}.${key}` : key;
                if (value instanceof File) {
                    formData.append(path, value);
                } else if (typeof value === 'object') {
                    findAndAppendFilesToFormData(value, formData, path);
                }
            }
        }
    }

    const sendRequest = async () => {
        const formData = new FormData();
        findAndAppendFilesToFormData(communityStore.fields, formData)
        formData.append('data', JSON.stringify(communityStore.fields))
        const result = await gremService.updateCommunity(formData)
        if(result.code === 200){
            auth.user.commItem = {...auth.user.commItem,...result.data}
            snackActions.success(globalDictionary.get('profile_update_success'))
            History.navigate('/my/profile');
        } else {
            snackActions.error(result.message ? result.message : globalDictionary.get('error_title'))
        }
    }

    return (
        <>
        <Page showBackButton={true} title={globalDictionary.get('profile_main_add_role')}>
            {loading ?
                <Box
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    height:'200px',
                    width:'100%'
                }}
                >
                <CircularProgress/>
                </Box>
                :
                <FormContextProvider store={communityStore}>
                        <EditPage/>
                </FormContextProvider>
            }
        </Page>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    marginTop:'10px'
                }}
            >
                <LoadingButton
                    loading={communityStore.sending}
                    disabled={communityStore.sending}
                    variant={'contained'}
                    sx={{
                        width: '225px'
                    }}
                    onClick={sendRequest}
                >
                    Send
                </LoadingButton>
            </Box>
        </>
    )

})

export default EditRole;
