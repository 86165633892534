import {observer} from "mobx-react-lite";
import {Box, Button, Checkbox, Grid, Radio, Typography} from "@mui/material";
import {useFormStore} from "components/shared/DraftForm/FormStore";
import Image from "mui-image";
import Company from "./icons/company-flag.png";
import Specialist from "./icons/specialist.png";
import OrganizationFlag from "./icons/organizationFlag.png";
import specializationRoles from "assets/translations/specializationRoles";
import {specialistIcons} from "./icons/specialistsIcons";
import companyRoles from "assets/translations/companyRoles";
import companyIcon from './icons/company-roles.svg';
import React, {useEffect, useRef} from "react";
import globalDictionary from "assets/translations/globalDictionary";
import {AIInput} from "components";
import circleCheckIcon from "assets/icons/circle-check.svg";

const SelectRole = observer(() => {
    const communityFields = {
        description: '',
        specialization: '',
        businessCountry: [],
        experience: '',
        license: [],
        education: [],
        associations: [],
        portfolio: [],
        maxDeal: '',
        deals: '',
        investmentCountries: [],
        desiredInvestmentPeriod: '',
        desiredProfitability: '',
        qualifiedSpecialist: false,
        taxResidentUSA: false,
        name: '',
        numberOfCompletedProjects: '',
        foundationYear: '',
        registrationNumber: '',
        country: '',
        city: '',
        biggestProjects: '',
        totalNumberOfConstructedSquareMeters: '',
        managementObjectsValue: '',
        managementObjectsQuantity: '',
        ownTenantBase: false,
        membersNumber: '',
        branchesNumber: '',
        services: '',
        employees: '',
        implementedProjects: '',
        logo: [],
        certificateScan: [],
        investmentAreas: '',
        clients: '',
        contact: {
            phone: '',
            fname: '',
            lname: '',
            position: '',
            email: '',
        },
    }
    const communityStore = useFormStore();
    const personBlockRef = useRef(null);
    const companyBlockRef = useRef(null);

    useEffect(() => {
        if (communityStore.fields.subjectOfLaw === 'person' && personBlockRef.current) {
            personBlockRef.current.scrollIntoView({behavior: 'smooth'});
        } else if (communityStore.fields.subjectOfLaw === 'company' && companyBlockRef.current) {
            companyBlockRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [communityStore.fields.subjectOfLaw]);

    const getIcon = (label) => {
        return specialistIcons[label.toLowerCase()]
    }

    const selectRole = (role) => {
        communityStore.handleInputField('subjectOfLaw', role)
        communityStore.fields = {...communityStore.fields, ...communityFields}

        if (role === 'organization') {
            communityStore.handleInputField('type', ['PublicOrganization'])
        } else {
            communityStore.handleInputField('type', [])
        }
    }

    return (
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px'
        }}>
            <Typography marginBottom={'20px'}>
                {globalDictionary.get('profile_verification_role_tab_info')}
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '5px',
                            backgroundColor: '#F4F4F4',
                            padding: '25px',
                            alignItems: "center",
                            gap: '10px',
                            textAlign: "center",
                        }}
                    >
                        <Image
                            src={Specialist}
                            style={{objectFit: 'contain'}}
                            width={'166px'}
                            height={'208px'}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",
                                flexDirection: 'column',
                                height: '100%'
                            }}
                        >
                            <Typography variant="h6">
                                {globalDictionary.get('profile_verification_community_specialist')}
                            </Typography>
                            <Typography>
                                {globalDictionary.get('profile_verification_community_specialist_description')}
                            </Typography>
                            <Button
                                fullWidth
                                sx={{marginTop: '10px', color: '#021228'}}
                                startIcon={communityStore.fields.subjectOfLaw === 'person' &&
                                    <img src={circleCheckIcon} alt={'check'} width={20} height={20}/>}
                                variant={communityStore.fields.subjectOfLaw === 'person' ? 'contained' : 'outlined'}
                                onClick={() => selectRole('person')}
                            >
                                {communityStore.fields.subjectOfLaw === 'person' ?
                                    globalDictionary.get('profile_verification_role_selected')
                                    :
                                    globalDictionary.get('profile_verification_role_select')
                                }
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '5px',
                            backgroundColor: '#F4F4F4',
                            padding: '25px',
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: '10px',
                            textAlign: "center",
                        }}
                    >
                        <Image
                            src={Company}
                            style={{objectFit: 'contain'}}
                            width={'130px'}
                            height={'208px'}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",
                                flexDirection: 'column',
                                height: '100%'
                            }}
                        >
                            <Typography variant="h6">
                                {globalDictionary.get('profile_verification_community_company')}
                            </Typography>
                            <Typography>
                                {globalDictionary.get('profile_verification_community_company_description')}
                            </Typography>
                            <Button
                                fullWidth
                                sx={{marginTop: '10px', color: '#021228'}}
                                startIcon={communityStore.fields.subjectOfLaw === 'company' &&
                                    <img src={circleCheckIcon} alt={'check'} width={20} height={20}/>}
                                variant={communityStore.fields.subjectOfLaw === 'company' ? 'contained' : 'outlined'}
                                onClick={() => selectRole('company')}
                            >
                                {communityStore.fields.subjectOfLaw === 'company' ? (
                                        globalDictionary.get('profile_verification_role_selected')
                                    )
                                    :
                                    globalDictionary.get('profile_verification_role_select')
                                }
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '5px',
                            backgroundColor: '#F4F4F4',
                            padding: '25px',
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: '10px',
                            textAlign: "center",
                        }}
                    >
                        <Image
                            src={OrganizationFlag}
                            style={{objectFit: 'contain'}}
                            width={'130px'}
                            height={'208px'}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",
                                flexDirection: 'column',
                                height: '100%'
                            }}
                        >
                            <Typography variant="h6">
                                {globalDictionary.get('profile_verification_community_organization')}
                            </Typography>
                            <Typography>
                                {globalDictionary.get('profile_verification_community_organization_description')}
                            </Typography>
                            <Button
                                fullWidth
                                sx={{marginTop: '10px', color: '#021228'}}
                                startIcon={communityStore.fields.subjectOfLaw === 'organization' &&
                                    <img src={circleCheckIcon} alt={'check'} width={20} height={20}/>}
                                variant={communityStore.fields.subjectOfLaw === 'organization' ? 'contained' : 'outlined'}
                                onClick={() => selectRole('organization')}
                            >
                                {communityStore.fields.subjectOfLaw === 'organization' ?
                                    globalDictionary.get('profile_verification_role_selected')
                                    :
                                    globalDictionary.get('profile_verification_role_select')
                                }
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {communityStore.fields.subjectOfLaw === 'person' &&
                    <Grid item xs={12} ref={personBlockRef}>
                        <Typography sx={{marginBottom: '10px'}}>
                            {globalDictionary.get('profile_verification_community_specialist_continue_1')}
                            <Typography component={'span'} sx={{
                                color: '#C5A465'
                            }}>
                                {` ${globalDictionary.get('profile_verification_community_specialist_continue_2')}`}
                            </Typography>
                            {globalDictionary.get('profile_verification_community_specialist_continue_3')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: {
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(4, 1fr)',
                                    md: 'repeat(4, 1fr)',
                                    lg: 'repeat(8, 1fr)',
                                },
                                gap: '5px',
                                rowGap: '20px',
                                justifyItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            {specializationRoles.getAllValueLabel().map((item) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRadius: '5px',
                                            height: '170px',
                                            minWidth: '125px',
                                            width: '100%',
                                            backgroundColor: communityStore.fields.type.includes(item.value) ? '#F0E8D9' : '#F4F4F4',
                                            padding: '8px 32px',
                                            alignItems: "center",
                                            gap: '15px',
                                        }}
                                        onClick={() => communityStore.handleAddRemoveArrayElem(`type`, item.value)}
                                    >
                                        <Checkbox
                                            name={`type.${item.value}`}
                                            value={item.value}
                                            checked={communityStore.fields.type.includes(item.value)}
                                            onChange={communityStore.handleInputCheckBox}
                                        />
                                        <Image src={getIcon(item.value)}
                                               width={'70px'}
                                               height={'70px'}/>
                                        <Typography sx={{
                                            fontWeight: communityStore.fields.type.includes(item.value) ? 700 : 400,
                                        }}>
                                            {item.label}
                                        </Typography>
                                    </Box>)
                            })}
                        </Box>
                        {communityStore.fields.type.length !== 0 && communityStore.fields.type.every((type) => ['Buyer', 'Seller'].includes(type)) &&
                            <Box
                                display={'flex'}
                                flexDirection={"column"}
                                gap={'10px'}
                                justifyContent={"space-between"}
                            >
                                <Typography sx={{
                                    mt: '30px',
                                    mb: '-5px',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                }}>
                                    {globalDictionary.get('profile_verification_community_specialist_field_description')}
                                </Typography>
                                <Typography>
                                    {globalDictionary.get('profile_verification_community_specialist_field_description_sub')}
                                </Typography>
                                <AIInput
                                    label={globalDictionary.get('profile_verification_community_specialist_field_description')}
                                    name={'description'}
                                    value={communityStore.fields.description}
                                    onChange={communityStore.handleInputChange}
                                    maxTextLength={600}
                                />
                            </Box>
                        }
                    </Grid>
                }
                {communityStore.fields.subjectOfLaw === 'company' &&
                    <Grid item xs={12} ref={companyBlockRef}>
                        <Typography marginBottom={'20px'}>
                            {globalDictionary.get('profile_verification_community_company_sub')}
                        </Typography>
                        <Grid container spacing={3} gridRow>
                            {companyRoles.getAllValueLabel().map((item) => {
                                return (
                                    <Grid item xs={12} md={4}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                minHeight: '125px',
                                                borderRadius: '5px',
                                                backgroundColor: communityStore.fields.type.includes(item.value) ? '#F0E8D9' : '#F4F4F4',
                                                padding: '8px 32px',
                                                alignItems: "center",
                                                textAlign: "center",
                                                gap: '15px'
                                            }}
                                            onClick={() => communityStore.handleInputField(`type`, [item.value])}
                                        >
                                            <Image src={companyIcon}
                                                   width={'70px'}
                                                   height={'70px'}/>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: {
                                                    xs: 'column-reverse',
                                                    sm: 'row',
                                                },
                                                gap: '15px'
                                            }}>
                                                <Radio
                                                    value={item.value}
                                                    name={item.value}
                                                    checked={communityStore.fields.type.includes(item.value)}
                                                    onChange={(event) => {
                                                        const {value} = event.target;
                                                        communityStore.handleInputField("type", [value]);
                                                    }}/>
                                                <Typography sx={{
                                                    fontWeight: communityStore.fields.type.includes(item.value) ? 700 : 400,
                                                }}>
                                                    {item.label}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>)
                            })}
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Box>
    );
});

export default SelectRole;
