import {observer} from "mobx-react-lite";
import {Page} from "components";
import React from "react";
import ObjectBlock from "./components/ObjectBlock";
import globalDictionary from "../../../assets/translations/globalDictionary";
import MemberBlock from "./components/MemberBlock";
import RewardBlock from "./components/RewardBlock";

const ContractPage = observer(()=>{

    return(
        <>
        <Page title={globalDictionary.get('complex_list_tab_contract_object')}>
            <ObjectBlock/>
        </Page>
    <Page title={globalDictionary.get('complex_list_tab_contract_members')}>
        <MemberBlock/>
    </Page>
    <Page title={globalDictionary.get('complex_list_tab_contract_reward')}>
        <RewardBlock/>
    </Page>
        </>
    )
})

export default ContractPage;