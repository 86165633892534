import { GremService } from 'services';
import { getUserToken } from 'utils';
import { server } from '../config';

class FactoringService {
  constructor() {
    this._apiBase = server;
    this.service = new GremService();
  }

  async sendCode(code) {
    const res = await this.service.sendRequest(
      `/community/google/${code}`,
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }
  async getGoogleCode() {
    const res = await this.service.sendRequest(
      `/factoring/send/google`,
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }
}

export default FactoringService;
