import React from 'react';
import style from './ErrorFallback.module.css';
import {getActiveLanguageFromLS} from '../../utils/index';
import globalDictionary from 'assets/translations/globalDictionary';

function ErrorFallback({error, resetErrorBoundary}) {
    const language = getActiveLanguageFromLS();

    return (
        <div role={'alert'} className={style.errorBlock}>
            <div className={style.errorWrapper}>
                <p>{globalDictionary.get('layout_error_title', language)}</p>
                <button onClick={resetErrorBoundary}>
                    {globalDictionary.get('layout_error_reset_button', language)}
                </button>
            </div>
        </div>
    );
}

export default ErrorFallback;
