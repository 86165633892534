import {observer} from "mobx-react-lite";
import {useFormStore} from "components/shared/DraftForm/FormStore";
import {
    Box,
    Button,
    Chip,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import countries from "assets/translations/countries";
import {AIInput, FileLoader} from "components";
import {AddCircleOutline, AddCircleOutlineOutlined} from "@mui/icons-material";
import globalDictionary from "assets/translations/globalDictionary";
import DocumentView from "../../../../../components/shared/DocumentView/DocumentView";
import {isEmpty} from "lodash";


const CompanyInfoBlock = observer(() => {
    const communityStore = useFormStore();
    const communityType = communityStore.fields.type;
    return (
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        {globalDictionary.get('profile_verification_community_company_title')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_name')}
                        fullWidth
                        name={'name'}
                        type={'text'}
                        value={communityStore.fields.name}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_foundationYear')}
                        fullWidth
                        name={'foundationYear'}
                        type={'text'}
                        value={communityStore.fields.foundationYear}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
                {communityStore.fields.subjectOfLaw !== 'organization' &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_registrationNumber')}
                            fullWidth
                            name={'registrationNumber'}
                            type={'text'}
                            value={communityStore.fields.registrationNumber}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityStore.fields.subjectOfLaw === 'organization' &&
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="select-industrialType-label">
                                {globalDictionary.get('profile_verification_community_company_businessCountry')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                labelId="select-industrialType-label"
                                id="select-industrialType"
                                label={globalDictionary.get('profile_verification_community_company_businessCountry')}
                                required
                                multiple
                                value={communityStore.fields.businessCountry}
                                onChange={communityStore.handleInputChange}
                                name={'businessCountry'}
                            >
                                {countries.getAllValueLabel().map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-industrialType-label">
                            {globalDictionary.get('location_country')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-industrialType-label"
                            id="select-industrialType"
                            label={globalDictionary.get('location_country')}
                            required
                            value={communityStore.fields.country}
                            onChange={communityStore.handleInputChange}
                            name={'country'}
                        >
                            {countries.getAllValueLabel().map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('location_city')}
                        fullWidth
                        name={'city'}
                        type={'text'}
                        value={communityStore.fields.city}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>

                {communityStore.fields.subjectOfLaw !== 'organization' &&
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="select-industrialType-label">
                                {globalDictionary.get('profile_verification_community_company_businessCountry')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                labelId="select-industrialType-label"
                                id="select-industrialType"
                                label={globalDictionary.get('profile_verification_community_company_businessCountry')}
                                required
                                multiple
                                value={communityStore.fields.businessCountry}
                                onChange={communityStore.handleInputChange}
                                name={'businessCountry'}
                            >
                                {countries.getAllValueLabel().map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12}>
                    <AIInput
                        label={globalDictionary.get('profile_verification_community_company_field_description')}
                        name={'description'}
                        value={communityStore.fields.description}
                        onChange={communityStore.handleInputChange}
                        maxTextLength={600}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        {globalDictionary.get('profile_verification_community_company_profecional_title')}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_specialization')}
                        fullWidth
                        name={'specialization'}
                        type={'text'}
                        value={communityStore.fields.specialization}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
                {communityType.some(type => ['DevelopmentCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_numberOfCompletedProjects')}
                            fullWidth
                            name={'numberOfCompletedProjects'}
                            type={'text'}
                            value={communityStore.fields.numberOfCompletedProjects}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['DevelopmentCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_totalNumberOfConstructedSquareMeters')}
                            fullWidth
                            name={'totalNumberOfConstructedSquareMeters'}
                            type={'text'}
                            value={communityStore.fields.totalNumberOfConstructedSquareMeters}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['DevelopmentCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_biggestProjects')}
                            fullWidth
                            name={'biggestProjects'}
                            type={'text'}
                            value={communityStore.fields.biggestProjects}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['ManagementCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_managementObjectsValue')}
                            fullWidth
                            name={'managementObjectsValue'}
                            type={'number'}
                            value={communityStore.fields.managementObjectsValue}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['ManagementCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_managementObjectsQuantity')}
                            fullWidth
                            name={'managementObjectsQuantity'}
                            type={'number'}
                            value={communityStore.fields.managementObjectsQuantity}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['ManagementCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_employees')}
                            fullWidth
                            name={'employees'}
                            type={'number'}
                            value={communityStore.fields.employees}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['ManagementCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_implementedProjects')}
                            fullWidth
                            name={'implementedProjects'}
                            type={'text'}
                            value={communityStore.fields.implementedProjects}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['ManagementCompany'].includes(type)) &&
                    <Grid item xs={12}>
                        <Typography color={'#A2A2A2'}>
                            {globalDictionary.get('profile_verification_community_company_ownTenantBase')}
                        </Typography>
                        <RadioGroup
                            name={'ownTenantBase'}
                            value={communityStore.fields.ownTenantBase}
                            onChange={communityStore.handleInputRadio}
                            row>
                            <Typography alignItems={'center'}>
                                <Radio
                                    sx={{width: '20px', mr: '10px'}}
                                    value={true}
                                    checked={communityStore.fields.ownTenantBase === true}
                                />
                                {globalDictionary.get('yes')}
                            </Typography>
                            <Typography ml={5} alignItems={'center'}>
                                <Radio
                                    value={false}
                                    checked={communityStore.fields.ownTenantBase === false}
                                />
                                {globalDictionary.get('no')}
                            </Typography>
                        </RadioGroup>
                    </Grid>
                }


                {(communityStore.fields.subjectOfLaw === 'organization' || communityType.some(type => ['CommercialCompany'].includes(type))) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_membersNumber')}
                            fullWidth
                            name={'membersNumber'}
                            type={'number'}
                            value={communityStore.fields.membersNumber}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {(communityStore.fields.subjectOfLaw === 'organization' || communityType.some(type => ['CommercialCompany'].includes(type))) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_branchesNumber')}
                            fullWidth
                            name={'branchesNumber'}
                            type={'number'}
                            value={communityStore.fields.branchesNumber}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }

                {communityType.some(type => ['CommercialCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_services')}
                            fullWidth
                            name={'services'}
                            type={'text'}
                            value={communityStore.fields.services}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['CommercialCompany'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_clients')}
                            fullWidth
                            name={'clients'}
                            type={'text'}
                            value={communityStore.fields.clients}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }


            </Grid>

        </Box>
    )
})

const CompanyLogoBlock = observer(() => {
    const communityStore = useFormStore();

    return (
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px'
        }}>
            <FileLoader
                _data={communityStore.fields}
                _setData={communityStore.setFields}
                dropzone={false}
                configs={{
                    field: 'logo',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    titleKey: 'profile_verification_community_organization_logo_title',
                    titleSubKey: 'profile_verification_community_organization_logo_title_sub',
                    buttonIcon: <AddCircleOutlineOutlined/>,
                    buttonKey: 'profile_verification_community_organization_logo_button',
                    confName: 'communityConfigs',
                }}
            />
        </Box>

    )
})

const CompanyCertificateBlock = observer(() => {
    const communityStore = useFormStore();

    return (

        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px'
        }}>
            <FileLoader
                _data={communityStore.fields}
                _setData={communityStore.setFields}
                dropzone={false}
                configs={{
                    field: 'certificateScan',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    titleKey: 'profile_verification_community_company_certificateScan_title',
                    titleSubKey: 'profile_verification_community_company_certificateScan_title_sub',
                    buttonIcon: <AddCircleOutlineOutlined/>,
                    buttonKey: 'profile_verification_community_company_certificateScan_button',
                    confName: 'communityConfigs',
                }}
            />
        </Box>
    )
})

export const ContactBlock = observer(() => {
    const communityStore = useFormStore();

    return (
        <Box sx={{backgroundColor: 'white', padding: '20px', borderRadius: '10px'}}>
            <Typography variant={'h6'}>
                {globalDictionary.get('profile_verification_community_company_contact_title')}
            </Typography>
            <Grid container spacing={2} sx={{
                marginTop:'10px'
            }}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_contact_fname')}
                        fullWidth
                        name={'contact.fname'}
                        type={'text'}
                        value={communityStore.fields.contact.fname}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_contact_lname')}
                        fullWidth
                        name={'contact.lname'}
                        type={'text'}
                        value={communityStore.fields.contact.lname}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_contact_position')}
                        fullWidth
                        name={'contact.position'}
                        type={'text'}
                        value={communityStore.fields.contact.position}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_contact_phone')}
                        fullWidth
                        name={'contact.phone'}
                        type={'text'}
                        value={communityStore.fields.contact.phone}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={globalDictionary.get('profile_verification_community_company_contact_email')}
                        fullWidth
                        name={'contact.email'}
                        type={'text'}
                        value={communityStore.fields.contact.email}
                        onChange={communityStore.handleInputChange}
                    />
                </Grid>
            </Grid>
        </Box>
    )

})

const ProfessionalBlock = observer(() => {
    const communityStore = useFormStore();
    const communityType = communityStore.fields.type;

    return (
        <Box sx={{backgroundColor: 'white', padding: '20px', borderRadius: ' 0px 0px 10px 10px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        {globalDictionary.get('profile_verification_community_specialist_professional_title')}
                    </Typography>
                </Grid>
                {communityType.some(type => ['Appraiser', 'Broker', 'Developer', 'Investor', 'Notary'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_specialist_field_specialization')}
                            fullWidth
                            name={'specialization'}
                            type={'text'}
                            value={communityStore.fields.specialization}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['Appraiser', 'Broker', 'Developer', 'Notary'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="select-industrialType-label">
                                {globalDictionary.get('profile_verification_community_specialist_field_businessCountry')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                labelId="select-industrialType-label"
                                id="select-industrialType"
                                label={globalDictionary.get('profile_verification_community_specialist_field_businessCountry')}
                                required
                                multiple
                                value={communityStore.fields.businessCountry}
                                onChange={communityStore.handleInputChange}
                                name={'businessCountry'}
                            >
                                {countries.getAllValueLabel().map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {communityType.some(type => ['Investor'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="select-industrialType-label">
                                {globalDictionary.get('profile_verification_community_specialist_field_investmentCountries')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                labelId="select-industrialType-label"
                                id="select-industrialType"
                                label={globalDictionary.get('profile_verification_community_specialist_field_investmentCountries')}
                                required
                                multiple
                                value={communityStore.fields.investmentCountries}
                                onChange={communityStore.handleInputChange}
                                name={'investmentCountries'}
                            >
                                {countries.getAllValueLabel().map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {communityType.some(type => ['Investor'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_investmentAreas')}
                            fullWidth
                            name={'investmentAreas'}
                            type={'string'}
                            value={communityStore.fields.investmentAreas}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['Appraiser', 'Broker', 'Developer', 'Investor', 'Notary'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_specialist_field_experience')}
                            fullWidth
                            name={'experience'}
                            type={'number'}
                            value={communityStore.fields.experience}
                            onChange={communityStore.handleInputChange}
                        />

                    </Grid>
                }
                {communityType.some(type => ['Developer'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_company_implementedProjects')}
                            fullWidth
                            name={'implementedProjects'}
                            type={'text'}
                            value={communityStore.fields.implementedProjects}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['Broker'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_specialist_field_maxDeal')}
                            fullWidth
                            name={'maxDeal'}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            type={'number'}
                            value={communityStore.fields.maxDeal}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['Broker'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_specialist_field_deals')}
                            fullWidth
                            name={'deals'}
                            type={'number'}
                            value={communityStore.fields.deals}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {communityType.some(type => ['Investor'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_specialist_field_desiredInvestmentPeriod')}
                            fullWidth
                            name={'desiredInvestmentPeriod'}
                            type={'number'}
                            value={communityStore.fields.desiredInvestmentPeriod}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }

                {communityType.some(type => ['Investor'].includes(type)) &&
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={globalDictionary.get('profile_verification_community_specialist_field_desiredProfitability')}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            name={'desiredProfitability'}
                            type={'number'}
                            value={communityStore.fields.desiredProfitability}
                            onChange={communityStore.handleInputChange}
                        />
                    </Grid>
                }
                {(communityType.some(type => ['Appraiser', 'Investor', 'Notary', 'Developer', 'Broker', 'Architect'].includes(type))
                        || (communityStore.isEdit && communityType.some(type => ['Buyer','Seller'].includes(type)))) &&
                    <Grid item xs={12}>
                        <AIInput
                            label={globalDictionary.get('profile_verification_community_specialist_field_description')}
                            name={'description'}
                            value={communityStore.fields.description}
                            onChange={communityStore.handleInputChange}
                            maxTextLength={600}
                        />
                    </Grid>
                }

                {communityType.some(type => ['Investor'].includes(type)) &&
                    <Grid item xs={12}>
                        <Typography color={'#A2A2A2'}>
                            {globalDictionary.get('profile_verification_community_specialist_field_qualifiedSpecialist')}
                        </Typography>
                        <RadioGroup
                            name={'qualifiedSpecialist'}
                            value={communityStore.fields.qualifiedSpecialist}
                            onChange={communityStore.handleInputRadio}
                            row>
                            <Typography alignItems={'center'}>
                                <Radio
                                    sx={{width: '20px', mr: '10px'}}
                                    value={true}
                                    checked={communityStore.fields.qualifiedSpecialist === true}
                                />
                                {globalDictionary.get('yes')}
                            </Typography>
                            <Typography ml={5} alignItems={'center'}>
                                <Radio
                                    value={false}
                                    checked={communityStore.fields.qualifiedSpecialist === false}
                                />
                                {globalDictionary.get('no')}
                            </Typography>
                        </RadioGroup>
                    </Grid>
                }

                {communityType.some(type => ['Investor'].includes(type)) &&
                    <Grid item xs={12}>
                        <Typography color={'#A2A2A2'}>
                            {globalDictionary.get('profile_verification_community_specialist_field_taxResidentUSA')}
                        </Typography>
                        <RadioGroup
                            name={'taxResidentUSA'}
                            value={communityStore.fields.taxResidentUSA}
                            onChange={communityStore.handleInputRadio}
                            row>
                            <Typography alignItems={'center'}>
                                <Radio
                                    sx={{width: '20px', mr: '10px'}}
                                    value={true}
                                    checked={communityStore.fields.taxResidentUSA === true}
                                />
                                {globalDictionary.get('yes')}
                            </Typography>
                            <Typography ml={5} alignItems={'center'}>
                                <Radio
                                    value={false}
                                    checked={communityStore.fields.taxResidentUSA === false}
                                />
                                {globalDictionary.get('no')}
                            </Typography>
                        </RadioGroup>
                    </Grid>
                }
            </Grid>
        </Box>
    )

})

const LicenseBlock = observer(() => {
    const communityStore = useFormStore();
    const empty = {
        object: '',
        name:'',
        scan: [],
    }
    const [isAdd, setIsAdd] = useState(true);

    const toggleAddBlock = () => {
        setIsAdd(!isAdd)
    }
    const [license, setLicense] = useState(empty)
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setLicense({...license, [name]: value});
    }
    const addItem = () => {
        communityStore.handleInputArrayElem('license', license)
        setLicense(empty)
    }
    const deleteItem = (index) => {
        communityStore.handleDeleteArrayElem('license', index)
    }
    const editItem = (index) => {
        setLicense(communityStore.fields.license[index])
        deleteItem(index)
    }

    return (
        <>
            <Box sx={{
                backgroundColor: 'white',
                padding: '20px',
                marginTop: '20px',
                borderRadius: '10px'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            {globalDictionary.get('profile_verification_community_specialist_license_title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>
                            {globalDictionary.get('profile_verification_community_specialist_license_title_sub')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {communityStore.fields.license.length > 0 &&
                            communityStore.fields.license.map((item, index) => {
                                return (
                                    <DocumentView
                                        name={item.name}
                                        subFields={
                                            <Box
                                                sx={{
                                                    display: 'inline-flex',
                                                    padding: '0px 15px 0px 15px',
                                                    borderRadius: '30px',
                                                    border: '1px solid #DFDFDF',
                                                    backgroundColor: '#FFF'
                                                }}
                                            >
                                                <Typography>
                                                    {item.object}
                                                </Typography>
                                            </Box>
                                        }
                                        image={item.scan[0]?.url ?? ''}
                                        handleEdit={() => {
                                            editItem(index)
                                        }}
                                        handleDelete={() => {
                                            deleteItem(index)
                                        }}
                                    />
                                )
                            })

                        }
                        {communityStore.fields.license.length !== 5 &&
                            <FileLoader
                                _data={license}
                                _setData={setLicense}
                                documentDropzone={true}
                                fileCount={license.scan.length}
                                configs={{
                                    field: 'scan',
                                    confName: 'communityConfigs.license',
                                    documentDropzoneTitle: globalDictionary.get('community_license')
                                }}
                            >
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            label={globalDictionary.get('community_license_name')}
                                            name={'name'}
                                            value={license.name}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            label={globalDictionary.get('profile_verification_community_specialist_license_field_object')}
                                            name={'object'}
                                            value={license.object}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </FileLoader>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                display: 'flex',
                marginTop: '20px',
            }}>
                {
                    (license?.object !== '' && license?.name !== '' && license?.scan?.length !== 0) && (
                        <Button
                            variant={'contained'}
                            onClick={addItem}
                            sx={{
                                marginBottom: '20px',
                            }}
                        >
                            <AddCircleOutline/>
                            <Typography ml={'10px'}>
                                {globalDictionary.get('profile_verification_community_specialist_license_add')}
                            </Typography>
                        </Button>
                    )
                }
            </Box>


        </>

    )

})

const EducationBlock = observer(() => {
    const communityStore = useFormStore();
    const empty = {
        scan: [],
        name:''
    }
    const [isAdd, setIsAdd] = useState(false);

    const toggleAddBlock = () => {
        setIsAdd(!isAdd)
    }
    const [education, setEducation] = useState(empty)
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEducation({...education, [name]: value});
    }
    const addItem = () => {
        communityStore.handleInputArrayElem('education', education)
        setEducation(empty)
    }

    const deleteItem = (index) => {
        communityStore.handleDeleteArrayElem('education', index)
    }
    const editItem = (index) => {
        setEducation(communityStore.fields.education[index])
        deleteItem(index)
    }
    return (
        <>
            <Box sx={{
                backgroundColor: 'white',
                padding: '20px',
                marginTop: '20px',
                borderRadius: '10px'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            {globalDictionary.get('profile_verification_community_specialist_education_title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>
                            {globalDictionary.get('profile_verification_community_specialist_education_description')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {communityStore.fields.education.length > 0 &&
                            communityStore.fields.education.map((item, index) => {
                                return (
                                    <DocumentView
                                        name={item.name}
                                        image={item.scan[0]?.url ?? ''}
                                        handleEdit={() => {
                                            editItem(index)
                                        }}
                                        handleDelete={() => {
                                            deleteItem(index)
                                        }}
                                    />
                                )
                            })

                        }
                        {communityStore.fields.education.length !== 5 &&
                            //<FileLoader
                            //                                     _data={education}
                            //                                     _setData={setEducation}
                            //                                     configs={{
                            //                                         field: 'scan',
                            //                                         minKey: 'complex_progress_photo_min',
                            //                                         maxKey: 'complex_progress_photo_min',
                            //                                         titleKey: 'profile_verification_community_specialist_education_title',
                            //                                         buttonKey: 'profile_verification_community_specialist_education_button',
                            //                                         confName: 'communityConfigs.education',
                            //                                     }}
                            //                                 />
                            <FileLoader
                                _data={education}
                                _setData={setEducation}
                                documentDropzone={true}
                                fileCount={education.scan.length}
                                configs={{
                                    field: 'scan',
                                    confName: 'communityConfigs.education',
                                    documentDropzoneTitle: globalDictionary.get('community_education')
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            label={globalDictionary.get('community_education_name')}
                                            name={'name'}
                                            value={education.name}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </FileLoader>
                        }
                    </Grid>
                </Grid>
            </Box>
            {
                education.name &&education.scan.length !== 0 && (
                    <Box sx={{
                        display: 'flex',
                        marginTop: '20px',
                    }}>
                        <Button
                            variant={'contained'}
                            onClick={addItem}
                        >
                            <AddCircleOutline/>
                            <Typography ml={'10px'}>
                                {globalDictionary.get('profile_verification_community_specialist_education_add')}
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        </>

    )
})

const AssociationBlock = observer(() => {

    const communityStore = useFormStore();
    const [association, setAssociation] = useState('');
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            gap: '10px',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            justifyContent: 'space-between'
        }}>
            <Typography variant={'h6'}>
                {globalDictionary.get('profile_verification_community_specialist_association_title')}
            </Typography>
            <Typography>
                {globalDictionary.get('profile_verification_community_specialist_association_title_sub')}
            </Typography>
            {
                communityStore.fields.associations.length > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            flexWrap: 'wrap'
                        }}
                    >
                        {communityStore.fields.associations.map((item, index) => {
                            return (<Chip
                                sx={{
                                    backgroundColor: '#E0E0E0',
                                    borderRadius: '30px',
                                    border: 'none'
                                }}
                                key={item}
                                label={item}
                                variant="outlined"
                                onDelete={(e) => {
                                    communityStore.handleDeleteArrayElem('associations', index)
                                }}
                            />)
                        })}
                    </Box>
                )
            }
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                flexWrap: 'wrap',
            }}>
                <TextField
                    label={globalDictionary.get('profile_main_association')}
                    name={'association'}
                    type={'text'}
                    value={association}
                    sx={{
                        width: {
                            xs: '100%',
                            sm: '40%'
                        },
                    }}
                    onChange={(event) => {
                        const {value} = event.target;
                        setAssociation(value)
                    }}
                />
                <Button
                    sx={{
                        p: '0 50px',
                        width: {
                            xs: '100%',
                            sm: 'auto'
                        }
                    }}
                    variant={'outlined'}
                    onClick={() => {
                        communityStore.handleInputArrayElem('associations', association)
                        setAssociation('')
                    }}
                >
                    {globalDictionary.get('add_translate')}
                </Button>
            </Box>

        </Box>

    )

})

const PortfolioBlock = observer(() => {

    const communityStore = useFormStore();

    return (

        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px'
        }}>
            <FileLoader
                _data={communityStore.fields}
                _setData={communityStore.setFields}
                configs={{
                    field: 'portfolio',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    titleKey: 'profile_verification_community_specialist_portfolio_title',
                    titleSubKey: 'profile_verification_community_specialist_portfolio_subTitle',
                    confName: 'communityConfigs',
                }}
            />
        </Box>
    )

})

const CommunityStep = observer(() => {
    const communityStore = useFormStore();
    const communityType = communityStore.fields.type;

    return (
        <>
            {communityStore.fields.subjectOfLaw === 'person' &&
                <>
                    <ProfessionalBlock/>

                    {communityType.some(type => ['Architect'].includes(type)) &&
                        <PortfolioBlock/>
                    }
                    {communityType.some(type => ['Appraiser', 'Architect', 'Broker', 'Notary'].includes(type)) &&
                        <LicenseBlock/>
                    }

                    {communityType.some(type => ['Appraiser', 'Architect', 'Broker', 'Notary'].includes(type)) &&
                        <EducationBlock/>
                    }
                    {communityType.some(type => ['Appraiser', 'Developer', 'Broker', 'Notary'].includes(type)) &&
                        <AssociationBlock/>
                    }
                </>
            }
            {
                communityStore.fields.subjectOfLaw !== "person" &&
                <>

                    <CompanyInfoBlock/>

                    <CompanyLogoBlock/>

                    {communityStore.fields.subjectOfLaw !== 'organization' &&
                        <CompanyCertificateBlock/>
                    }
                    {communityStore.fields.subjectOfLaw !== 'organization' &&
                        <LicenseBlock/>
                    }
                </>
            }
        </>
    )

})

export default CommunityStep;
