import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import {FreeMode, Thumbs, Navigation} from 'swiper/modules';
import styles from './GallerySlider.module.css';
import {ImageChecker} from "../../index";
import sliderArrLeft from "assets/icons/slider_arr_left.svg";
import sliderArrRight from "assets/icons/slider_arr_right.svg";


const GallerySlider = (
    {
        slides = [],
        navigation = true
    }
) => {
    const photos = JSON.parse(JSON.stringify(slides)).sort((a, b) => b.isMain - a.isMain);
    const [activeSlide, setActiveSlide] = useState(0);
    const [thumbsDirection, setThumbsDirection] = useState('vertical');
    const mainSwiperRef = useRef(null);
    const thumbsSwiperRef = useRef(null);
    const containerRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    useEffect(() => {
        if(photos?.length > 0) {
            changeThumbsDirection();

            if (mainSwiperRef.current && thumbsSwiperRef.current) {
                mainSwiperRef.current.slideTo(activeSlide);
                thumbsSwiperRef.current.slideTo(activeSlide);
            }

            window.addEventListener('resize', () => {
                changeThumbsDirection();
            });
        }
    }, [activeSlide]);

    const changeThumbsDirection = () => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
        const windowWidth = window.innerWidth;
        const orientation = (windowWidth > 380 && windowWidth < 1280 || containerWidth > 380 && containerWidth < 820) ? 'horizontal' : 'vertical';
        setThumbsDirection(orientation);
    }

    const updateActiveSlide = (index) => {
        setActiveSlide(index);
    };

    return (
        <div className={styles.gallery__container} ref={containerRef}>
            <div className={styles.gallery__wrapper}>
                {
                    navigation && (
                        <>
                            <button ref={prevRef}
                                    className={`${styles.gallery__navigation} ${styles.gallery__navigation_prev}`}>
                                <img src={sliderArrLeft} alt={'slide button left'}/>
                            </button>
                            <button ref={nextRef}
                                    className={`${styles.gallery__navigation} ${styles.gallery__navigation_next}`}>
                                <img src={sliderArrRight} alt={'slide button right'}/>
                            </button>
                        </>
                    )
                }
                <Swiper
                    onSwiper={(swiper) => {
                        mainSwiperRef.current = swiper;
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    spaceBetween={10}
                    navigation={true}
                    loop={true}
                    modules={[FreeMode, Thumbs, Navigation]}
                    className={styles.gallery}
                    onSlideChange={(swiper) => updateActiveSlide(swiper.activeIndex)}
                >
                    {
                        photos?.length > 0 ?
                        photos.map((slide, index) => (
                            <SwiperSlide key={`slide-main-${index}`} className={styles.gallery__slide}>
                                <ImageChecker sx={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                              src={slide?.url ? slide.url : slide ? slide : ''} alt={'slide'}/>
                            </SwiperSlide>
                        ))
                            :
                            <SwiperSlide key={`slide-main-none`} className={styles.gallery__slide}>
                                <ImageChecker sx={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                              src={''} alt={'slide'}/>
                            </SwiperSlide>

                    }
                </Swiper>
            </div>
            {photos?.length > 0 &&
                <div className={`${styles.gallery__thumbs} ${photos.length < 4 ? styles.gallery__thumbs_small : null}`}>
                    <Swiper
                        onSwiper={(swiper) => thumbsSwiperRef.current = swiper}
                        onClick={(swiper, e) => updateActiveSlide(swiper.clickedIndex)}
                        slidesPerView={4}
                        loop={true}
                        spaceBetween={'10px'}
                        freeMode={true}
                        direction={thumbsDirection}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Thumbs, Navigation]}
                        className={styles.thumbsSwiper}
                    >
                        {
                            photos.map((slide, index) => (
                                <SwiperSlide key={`slide-sub-${index}`}
                                             className={styles.gallery__sub_slide}>
                                    <ImageChecker sx={{
                                        width: '100%',
                                        height: '90px'
                                    }} src={slide?.url || slide} alt={'slide'}/>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>}
        </div>
    );
}
export default GallerySlider;
