import React from 'react';
import {
    Box, Checkbox,
    FormControl, FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import utilitySystemsDictionary from 'assets/translations/utilitySystems';
import {observer} from "mobx-react-lite";
import {AIInput, FileLoader} from "components";
import {DatePicker} from "@mui/x-date-pickers";
import projectDictionary from "assets/translations/Project/projectDictionary";
import projectTypesDictionary from "../../../../../../assets/translations/Project/projectTypesDictionary";
import apartsType from "../../../../../../assets/translations/apartsType";
import LiveBlock from "./technicalBlocks/LiveBlock";
import OfficeBlock from "./technicalBlocks/OfficeBlock";
import StoreProductionBlock from "./technicalBlocks/StoreProductionBlock";
import ParkingBlock from "./technicalBlocks/ParkingBlock";
import HotelBlock from "./technicalBlocks/HotelBlock";
import RestaurantCafeBlock from "./technicalBlocks/RestaurantCafeBlock";
import {useFormStore} from "components/shared/DraftForm/FormStore";

const TechnicalForm = observer(() => {
  const store = useFormStore()
  const wrongSymbols = ['e', 'E', '-', '+', '.', ','];
  return (
      <>
    <Box sx={{
      display:'flex',
      flexDirection:'column',
      gap:'10px',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: ' 0px 0px 10px 10px',
      width:'100%'
    }}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography sx={{
          fontSize:'16px',
          fontWeight:'700'
        }}>
          {projectDictionary.get('project_add_technical_title')}
        </Typography>
      </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-type-label">
                      {projectDictionary.get('project_add_projectType')}
                  </InputLabel>
                  <Select
                      variant="outlined"
                      labelId="select-type-label"
                      id="select-type"
                      label={projectDictionary.get('project_add_projectType')}
                      multiple
                      value={store.fields.project.projectType}
                      name={'project.projectType'}
                      onChange={(event) => {
                          const value = event.target.value;
                          store.handleInputField('project.projectType',value)
                      }}
                  >
                      {projectTypesDictionary.getAllValueLabel().map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                              {item.label}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                  placeholder={projectDictionary.get(
                      'project_add_floors'
                  )}
                  label={projectDictionary.get('project_add_floors')}
                  variant="outlined"
                  fullWidth
                  value={store.fields.project.floors}
                  name={'project.floors'}
                  type={'number'}
                  inputProps={{min: 0, max: 1000000}}
                  onKeyDown={(e) => {
                      if(wrongSymbols.includes(e.key)) {
                          e.preventDefault()
                      }

                  }}
                  onInput={(event)=>{
                      const value = event.target.value;
                      if (parseFloat(value) > 1000000) {
                          event.target.value = '1000000';
                      }
                      const regex = /^\d*\.?\d{0}$/;
                      if (!regex.test(value)) {
                          event.target.value = value.slice(0, -1);
                      }
                      if (value.length > 1 && parseFloat(value) === 0) {
                          event.target.value = value.slice(0, -1);
                      }
                  }}

                  onChange={store.handleInputChange}
              />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
              <DatePicker
                  placeholder={projectDictionary.get(
                      'project_add_finalDate'
                  )}
                  sx={{
                      width:'100%'
                  }}
                  label={projectDictionary.get('project_add_finalDate')}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  yearsPerRow={3}
                  value={store.fields.project.finalDate ? parseInt(store.fields.project.finalDate) : Date.now()}
                  name={'project.finalDate'}
                  type={'date'}
                  onKeyDown={(e) => wrongSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(value)=> {
                      store.handleInputField('project.finalDate',value.getTime())
                  }}
              />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                  placeholder={projectDictionary.get(
                      'project_add_area_total'
                  )}
                  label={projectDictionary.get('project_add_area_total')}
                  variant="outlined"
                  fullWidth
                  value={store.fields.project.area.total}
                  name={'project.area.total'}
                  type={'number'}
                  inputProps={{min: 0, max: 1000000.00}}
                  onKeyDown={(e) => {
                      if(wrongSymbols.includes(e.key)) {
                          e.preventDefault()
                      }

                  }}
                  onInput={(event)=>{
                      const value = event.target.value;
                      if (parseFloat(value) > 1000000.00) {
                          event.target.value = '1000000.00';
                      }
                      const regex = /^\d*\.?\d{0,2}$/;
                      if (!regex.test(value)) {
                          event.target.value = value.slice(0, -1);
                      }
                      if (value.length > 1 && parseFloat(value) === 0) {
                          event.target.value = value.slice(0, -1);
                      }
                  }}

                  onChange={store.handleInputChange}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                  placeholder={projectDictionary.get(
                      'project_add_area_building'
                  )}
                  label={projectDictionary.get('project_add_area_building')}
                  variant="outlined"
                  fullWidth
                  value={store.fields.project.area.building}
                  name={'project.area.building'}
                  type={'number'}
                  inputProps={{min: 0, max: 1000000.00}}
                  onKeyDown={(e) => {
                      if(wrongSymbols.includes(e.key)) {
                          e.preventDefault()
                      }

                  }}
                  onInput={(event)=>{
                      const value = event.target.value;
                      if (parseFloat(value) > 1000000.00) {
                          event.target.value = '1000000.00';
                      }
                      const regex = /^\d*\.?\d{0,2}$/;
                      if (!regex.test(value)) {
                          event.target.value = value.slice(0, -1);
                      }
                      if (value.length > 1 && parseFloat(value) === 0) {
                          event.target.value = value.slice(0, -1);
                      }
                  }}

                  onChange={store.handleInputChange}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                  placeholder={projectDictionary.get(
                      'project_add_area_effective'
                  )}
                  label={projectDictionary.get('project_add_area_effective')}
                  variant="outlined"
                  fullWidth
                  value={store.fields.project.area.effective}
                  name={'project.area.effective'}
                  type={'number'}
                  inputProps={{min: 0, max: 1000000.00}}
                  onKeyDown={(e) => {
                      if(wrongSymbols.includes(e.key)) {
                          e.preventDefault()
                      }

                  }}
                  onInput={(event)=>{
                      const value = event.target.value;
                      if (parseFloat(value) > 1000000.00) {
                          event.target.value = '1000000.00';
                      }
                      const regex = /^\d*\.?\d{0,2}$/;
                      if (!regex.test(value)) {
                          event.target.value = value.slice(0, -1);
                      }
                      if (value.length > 1 && parseFloat(value) === 0) {
                          event.target.value = value.slice(0, -1);
                      }
                  }}

                  onChange={store.handleInputChange}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                  placeholder={projectDictionary.get(
                      'project_add_area_land'
                  )}
                  label={projectDictionary.get('project_add_area_land')}
                  variant="outlined"
                  fullWidth
                  value={store.fields.project.area.land}
                  name={'project.area.land'}
                  type={'number'}
                  inputProps={{min: 0, max: 1000000.00}}
                  onKeyDown={(e) => {
                      if(wrongSymbols.includes(e.key)) {
                          e.preventDefault()
                      }

                  }}
                  onInput={(event)=>{
                      const value = event.target.value;
                      if (parseFloat(value) > 1000000.00) {
                          event.target.value = '1000000.00';
                      }
                      const regex = /^\d*\.?\d{0,2}$/;
                      if (!regex.test(value)) {
                          event.target.value = value.slice(0, -1);
                      }
                      if (value.length > 1 && parseFloat(value) === 0) {
                          event.target.value = value.slice(0, -1);
                      }
                  }}

                  onChange={store.handleInputChange}
              />
          </Grid>
      </Grid>


    </Box>
          { store.fields.project.projectType.some(item=>item==='liveArea') &&
              <LiveBlock store={store}/>
          }

          {store.fields.project.projectType.some(item => item === 'officeArea') &&
              <OfficeBlock store={store}/>
          }

          {store.fields.project.projectType.some(item => item === 'restaurantCafeArea') &&
              <RestaurantCafeBlock store={store}/>
          }

          {store.fields.project.projectType.some(item => item === 'storeProductionArea') &&
              <StoreProductionBlock store={store}/>
          }
          {store.fields.project.projectType.some(item => item === 'hotelArea') &&
              <HotelBlock store={store}/>
          }

        <ParkingBlock store={store}/>

      </>
  );
});

export default TechnicalForm;
