import {observer} from "mobx-react-lite";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import commonDictionary from "../../../../../../../assets/translations/commonDictionary";
import utilitySystems from "../../../../../../../assets/translations/utilitySystems";
import React from "react";
import projectDictionary from "../../../../../../../assets/translations/Project/projectDictionary";

const RestaurantCafeBlock = observer(({store})=>{

    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];
    return(
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width:'100%'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography sx={{
                        fontSize:'16px',
                        fontWeight:'700',
                    }}>
                        {projectDictionary.get('project_add_restaurant_cafe_title')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_total'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_total')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.total}
                        name={`project.restaurantCafeArea.total`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_effective'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_effective')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.effective}
                        name={`project.restaurantCafeArea.effective`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_seats'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_seats')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.seats}
                        name={`project.restaurantCafeArea.seats`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000) {
                                event.target.value = '1000000';
                            }
                            const regex = /^\d*\.?\d{0}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_kitchen'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_kitchen')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.kitchen}
                        name={`project.restaurantCafeArea.kitchen`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_store'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_store')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.store}
                        name={`project.restaurantCafeArea.store`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_storeNumber'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_storeNumber')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.storeNumber}
                        name={`project.restaurantCafeArea.storeNumber`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000) {
                                event.target.value = '1000000';
                            }
                            const regex = /^\d*\.?\d{0}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_cabinets'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_cabinets')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.cabinets}
                        name={`project.restaurantCafeArea.cabinets`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_cabinetsNumbers'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_cabinetsNumbers')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.cabinetsNumbers}
                        name={`project.restaurantCafeArea.cabinetsNumbers`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000) {
                                event.target.value = '1000000';
                            }
                            const regex = /^\d*\.?\d{0}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_restaurant_cafe_floor'
                        )}
                        label={projectDictionary.get('project_add_restaurant_cafe_floor')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.floor}
                        name={`project.restaurantCafeArea.floor`}
                        type={'text'}
                        onChange={store.handleInputChange}
                    />
                </Grid>


                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_storeys'
                        )}
                        label={projectDictionary.get('project_add_storeys')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.storeys}
                        name={`project.restaurantCafeArea.storeys`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000) {
                                event.target.value = '1000000';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_floorHeight'
                        )}
                        label={projectDictionary.get('project_add_floorHeight')}
                        variant="outlined"
                        fullWidth
                        value={store.fields.project.restaurantCafeArea.floorHeight}
                        name={`project.restaurantCafeArea.floorHeight`}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">
                            {projectDictionary.get('project_add_lift')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            id="select-type"
                            label={projectDictionary.get('project_add_lift')}
                            value={store.fields?.project?.restaurantCafeArea?.lift?.toString()?? 'false'}
                            name={'project.restaurantCafeArea.lift'}
                            onChange={(event) => {
                                const value = event.target.value;
                                store.handleInputField('project.restaurantCafeArea.lift',value==='true')
                            }}
                        >
                            <MenuItem key={'yes'} value={'true'}>
                                {commonDictionary.get('yes')}
                            </MenuItem>
                            <MenuItem key={'no'} value={'false'}>
                                {commonDictionary.get('no')}
                            </MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">
                            {projectDictionary.get('project_add_utility')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            id="select-type"
                            label={projectDictionary.get('project_add_utility')}
                            multiple
                            value={store.fields.project.restaurantCafeArea.utilitySystem}
                            name={'project.restaurantCafeArea.utilitySystem'}
                            onChange={(event) => {
                                const value = event.target.value;
                                store.handleInputField('project.restaurantCafeArea.utilitySystem',value)
                            }}
                        >
                            {utilitySystems.getAllValueLabel().map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        </Box>
    )

})

export default RestaurantCafeBlock;