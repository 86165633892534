import React from 'react';
import style from './Header.module.css';
import { Link, useLocation } from 'react-router-dom';
import { getActiveLanguageFromLS, setActiveLanguageToLS } from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';
import HeaderLanguageMenu from './HeaderLanguageMenu';
import gremLogo from 'assets/images/logo.svg';

function Header() {
  const location = useLocation();
  const language = getActiveLanguageFromLS();

  const setNewLanguage = (language) => {
    setActiveLanguageToLS(language);
  };

  return (
    <header className={style.header}>
      <nav>
        <div className={style.header__logo}>
          <Link to={'/'}>
            <img
              src={gremLogo}
              height={60}
              alt={globalDictionary.get('layout_cabinet_header_logo', language)}
            />
          </Link>
        </div>
        <div className={style.headerNavigation}>
          <HeaderLanguageMenu />
          <ul className={style.authList}>
            {location.pathname.indexOf('register') !== -1 ? (
              <li>
                <Link to={'/auth/login'}>
                  {globalDictionary.get('layout_auth_header_signin', language)}
                </Link>
              </li>
            ) : (
              <li>
                <Link to={'/auth/register'}>
                  {globalDictionary.get('layout_auth_header_signup', language)}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
