import React, {useState} from 'react';
import pdfIcon from 'assets/icons/pdf1.svg';
import {Typography, Box} from "@mui/material";
import {ModalWindow} from "components";
import ImageSlider from "../ImageSlider/ImageSlider";

const DocumentItem = ({document = [], title = ''}) => {
    const [open, setOpen] = useState(false);
    const slides = document.map((item) => item.url);

    const toggleModal = () => {
        setOpen(!open);
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
            }}
                 onClick={toggleModal}
            >
                <img src={pdfIcon} alt={'pdf'}/>
                <Typography sx={{
                    color: 'black',
                    fontSize: '15px',
                    fontWeight: 300,
                    paddingTop: '2px'
                }}>
                    {title}
                </Typography>
            </Box>
            {
                open && <ModalWindow
                    closeModal={toggleModal}
                    title={title}
                    modalStyles={{
                        width: {xs: '70%', sm: '70%', md: '70%'},
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '50vh',
                        maxHeight: '90vh',
                        '& > div': {minHeight: '50vh'},
                        '& > div > div': {maxWidth: '100%'},
                    }}
                >
                    <ImageSlider slides={slides} navigation={false}/>
                </ModalWindow>
            }
        </Box>
    );
};

export default DocumentItem;
