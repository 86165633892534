import React, {useState} from 'react';
import gremLogo from 'assets/images/logo.svg';
import {Box, IconButton, styled, Toolbar, useMediaQuery, useTheme} from '@mui/material';
import {useLocation} from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import {observer} from 'mobx-react-lite';
import {getActiveLanguageFromLS} from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';
import HeaderNotificationMenu from './components/HeaderNotificationMenu/HeaderNotificationMenu';
import HeaderProfileMenu from './components/HeaderProfileMenu/HeaderProfileMenu';
import HeaderLanguageMenu from './components/HeaderLanguageMenu/HeaderLanguageMenu';
import HeaderWalletMenu from './components/HeaderWalletMenu/HeaderWalletMenu';
import {Link} from 'react-router-dom';

const Header = observer(({handleDrawerOpen, open = false}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const isSpecialPage = [].includes(location.pathname);
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1, transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
        }), ...(open && !isMobile && {
            width: `100%`, transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
            }),
        }), ...(!open && isMobile && {
            justifyContent: 'flex-start', width: '100%', marginLeft: '0', ...(isSpecialPage && {marginRight: '22px'}),
        }),

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center', marginLeft: '0', width: '100%',
        },
    }));

    return (<AppBar
        position="fixed"
        open={open}
        sx={{
            backgroundColor: 'white', boxShadow: 'none', maxWidth: '100vw', overflowX: 'hidden',
        }}
    >
        <Toolbar sx={{justifyContent: 'space-between', width: '100%'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    ...(isMobile && {justifyContent: 'flex-start'}),
                }}
            >
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={() => {
                        handleDrawerOpen();
                    }}
                    edge="start"
                    sx={{
                        marginRight: 5, ...(open && {display: 'none'}),
                        border: 'solid 1px #C5A465',
                        width: '45px',
                        height: '45px',
                    }}
                >
                    <MenuIcon/>
                </IconButton>

                <Box component={Link} to={'/'} sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    }
                }}>
                    <img
                        src={gremLogo}
                        height={60}
                        alt={globalDictionary.get('layout_cabinet_header_logo')}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: isMobile ? 'center' : 'flex-end',
                    width: isMobile ? 'auto' : 'inherit',
                }}
            >
                {/*<Box sx={{*/}
                {/*    display: {*/}
                {/*        xs: 'none',*/}
                {/*        sm: 'none',*/}
                {/*        md: 'block'*/}
                {/*    }*/}
                {/*}}>*/}
                {/*    <HeaderWalletMenu/>*/}
                {/*</Box>*/}
                <HeaderLanguageMenu/>
                <HeaderNotificationMenu/>
                <HeaderProfileMenu/>
            </Box>
        </Toolbar>
    </AppBar>);
});

export default Header;
