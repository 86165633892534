import {createContext, useContext} from "react";
import {observer} from "mobx-react-lite";
import React from "react";
import {makeAutoObservable} from "mobx";
import {ProjectService} from "services";

class ProjectStore {
    service
    user
    isOwner
    object = {}
    projectType = '';
    project = {
        loading: false,
        data: null,
    }
    projects = {
        loading: false,
        data: null,
        currentPage: 1,
        limit: 12,
        totalPages: 0,
    }
    projectMarkers = {
        loading: false,
        data: null,
    }
    myProjects = {
        loading: false,
        data: null,
        currentPage: 1,
        limit: 12,
        totalPages: 0,
    }
    initDocForm = {
        loading: false,
        data: null
    }
    projectWallet = {
        loading: false,
        data: null
    }
    lastHistory = {
        loading: false,
        data: null,
        currentPage: 1,
        limit: 5,
        totalPages: 0,
    }


    constructor(props) {
        const {user, projectId} = props;
        makeAutoObservable(this)
        this.user = user;
        this.service = new ProjectService();
        this.getInitDocForm();

        if(projectId) {
            this.getProject(projectId).then(r=>{
                this.isOwner = this.project.data.userId === this.user._id
                this.projectType = this.project.data.type;
            })
        }
    }

    getProjects = async (page = 1, limit = this.projects.limit, filters = {}, isMyProjects = false) => {
        const target = isMyProjects ? this.myProjects : this.projects;
        target.loading = true;

        try {
            const payload = new URLSearchParams({page: page.toString(), limit, ...filters});
            if (isMyProjects) payload.append('user', 'true');

            const result = await this.service.getProjects(payload);
            const {data, paginate} = result?.data || {};

            target.data = data;
            target.totalPages = paginate.totalPages;
            target.currentPage = paginate.currentPage;

            return data;
        } catch (e) {
            console.error(e);
            target.loading = false;
        } finally {
            setTimeout(() => {
                target.loading = false;
            }, 500);
        }
    }

    handleHistoryPageChange = (newPage, filters = {}, isMyProjects = false) => {
        const target = isMyProjects ? this.myProjects : this.projects;
        target.currentPage = newPage;
        this.getProjects(newPage, target.limit, filters, isMyProjects);
    }

    getInitDocForm = async () => {
        this.initDocForm.loading = true;

        try {
            const result = await this.service.getInitDocForm();
            this.initDocForm.data = result?.data;
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                this.initDocForm.loading = false;
            }, 500);
        }
    }

    sendInitDocForm = async (form) => {
        this.initDocForm.loading = true;

        try {
            const formData = new FormData();
            let formCopy = {...form};
            delete formCopy.file;
            delete formCopy.nda;

            formData.append('nda', form.nda[0]?.url);
            formData.append('presentation', form.file);
            formData.append('data', JSON.stringify(formCopy));

            const result = await this.service.sendInitDocForm(formData);
            this.initDocForm.data = result?.data;

            return result;
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                this.initDocForm.loading = false;
            }, 500);
        }
    }

    getProjectMarkers = async () => {
        this.projectMarkers.loading = true;

        try {
            const result = await this.service.getProjectMarkers();
            this.projectMarkers.data = result?.data;
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                this.projectMarkers.loading = false;
            }, 500);
        }
    }

    deleteDraft = async (projectId) => {
        try {
            const result = await this.service.deleteDraft(projectId);
            return result;
        } catch (e) {
            console.error(e);
        }
    }

    updateProject = async (data) => {
        try {
            const result = await this.service.updateProject(data);
            return result;
        } catch (e) {
            console.error(e);
        }
    }

    getProject = async (projectId) => {
        this.project.loading = true;

        try {
            const result = await this.service.getProject(projectId);
            this.project.data = result?.data;
            this.object = result?.data;
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                this.project.loading = false;
            }, 500);
        }
    }

    getProjectWallet = async (projectId) => {
        this.projectWallet.loading = true;
        try {
            const result = await this.service.getProjectWallet(projectId);
            this.projectWallet.data = result?.data;
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                this.projectWallet.loading = false;
            }, 500);
        }
    }
    getProjectWalletHistory = async (projectId,page,limit) => {
        this.lastHistory.loading = true;
        try {

            const payload ={
                page: page ? page.toString() : this.lastHistory.currentPage,
                limit:limit ? limit : this.lastHistory.limit};

            const result = await this.service.getProjectWalletHistory(projectId,payload);

            const {data, paginate} = result?.data || {};
            this.lastHistory.data = data;
            this.lastHistory.totalPages = paginate.totalPages;
            this.lastHistory.currentPage = paginate.currentPage;

        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                this.lastHistory.loading = false;
            }, 500);
        }
    }

    handleProjectWalletHistoryPageChange = (newPage, filters = {}) => {
        this.lastHistory.currentPage = newPage;
        this.getProjectWalletHistory(newPage, target.limit, filters,);
    }
}

export default ProjectStore;

export const ProjectContext = createContext();

export const useProjectStore = () => {
    return useContext(ProjectContext);
};

export const ProjectContextProvider = observer(({children, store}) => {
    return (
        <ProjectContext.Provider value={store}>
            {children}
        </ProjectContext.Provider>
    );
});
