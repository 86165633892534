import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {numberFormatter} from "utils";
import globalDictionary from "assets/translations/globalDictionary";
import {ImageSlider} from "components";

const ComplexApartInfoModal = ({apart}) => {
    const photos = apart.apartPhotos.map((photo) => photo.url);

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
        }}>
            <Grid container spacing={'25px'} mt={'0px'}>
                <Grid item xs={12} md={8}>
                    <ImageSlider slides={photos}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '25px',
                    }}>
                        <Box>
                            <Typography sx={{
                                fontFamily: "Sofia Sans Condensed",
                                fontSize: '11px',
                                color: '#A2A2A2',
                                textTransform: 'uppercase',
                            }}>
                                {globalDictionary.get('complex_item_plans_price')}
                            </Typography>
                            <Typography sx={{
                                mt: '8px',
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '120%',
                            }}>
                                {`€ ${numberFormatter(apart.price)}`}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{
                                fontFamily: "Sofia Sans Condensed",
                                fontSize: '11px',
                                color: '#A2A2A2',
                                textTransform: 'uppercase',
                            }}>
                                {globalDictionary.get('complex_item_plans_area')}
                            </Typography>
                            <Typography sx={{
                                mt: '8px',
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '120%',
                            }}>
                                {`${apart.area} ${globalDictionary.get('complex_item_plans_area_sub')}`}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '150%',
                    }}>
                        {globalDictionary.get('complex_apart_field_description')}
                    </Typography>
                    <Typography sx={{
                        mt: '6px',
                        fontSize: '16px',
                        lineHeight: '150%',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                    }}>
                        {apart.description}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ComplexApartInfoModal;
