import React from 'react';
import languageStore from "./languageStore";

const commonDictionary = {
    dictionary: {
            yes: {
                eng: 'Yes',
                rus: 'Да',
                ukr: 'Так',
                deu: 'Ja',
            },
            no:{
                eng: 'No',
                rus: 'Нет',
                ukr: 'Ні',
                deu: 'Nein',
            },
        next: {
            eng: 'Next',
            rus: 'Далее',
            ukr: 'Далі',
            deu: 'Weiter',
        },
        send: {
            eng: 'Send',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        back: {
            eng: 'Previous step',
            rus: 'Предыдущий шаг',
            ukr: 'Попередній крок',
            deu: 'Vorheriger Schritt',
        },
        add_file: {
            eng: 'Attach file',
            rus: 'Прикрепить файл',
            ukr: 'Прикріпити файл',
            deu: 'Datei anhängen',
        },
        edit: {
            eng: 'Edit',
            rus: 'Редактировать',
            ukr: 'Редагувати',
            deu: 'Bearbeiten',
        },
        save_changes: {
            eng: 'Save Changes',
            rus: 'Сохранить изменения',
            ukr: 'Зберегти зміни',
            deu: 'Änderungen speichern'
        },
        send_to_moderation: {
            eng: 'Send for moderation',
            rus: 'Отправить на модерацию',
            ukr: 'Відправити на модерацію',
            deu: 'Zur Moderation senden'
        },
        withdraw: {
            eng: 'Withdraw',
            rus: 'Вывод',
            ukr: 'Вивід',
            deu: 'Abheben',
        },
        transaction_history: {
            eng: 'Transaction History',
            rus: 'История транзакций',
            ukr: 'Історія транзакцій',
            deu: 'Transaktionsverlauf',
        },
        transaction_date: {
            eng: 'Transaction date',
            rus: 'Дата транзакции',
            ukr: 'Дата транзакції',
            deu: 'Transaktionsdatum',
        },
        transaction_type: {
            eng: 'Transaction type',
            rus: 'Тип транзакции',
            ukr: 'Тип транзакції',
            deu: 'Transaktionstyp',
        },
        count: {
            eng: 'Quantity',
            rus: 'Количество',
            ukr: 'Кількість',
            deu: 'Menge',
        },
        contract_id: {
            eng: 'Contract ID',
            rus: 'ID контракта',
            ukr: 'ID контракту',
            deu: 'Vertrags-ID',
        },
        amount_m2c: {
            eng: 'Amount, M2C',
            rus: 'Сумма, М2С',
            ukr: 'Сума, М2С',
            deu: 'Betrag, M2C',
        },
        no_data: {
            eng: 'No data',
            rus: 'Нет данных',
            ukr: 'Немає даних',
            deu: 'Keine Daten',
        },
        watch: {
            eng: 'View',
            rus: 'Просмотр',
            ukr: 'Перегляд',
            deu: 'Ansehen',
        },
        docs_empty: {
            eng: "No Documents",
            rus: "Документы отсутствуют",
            ukr: "Документи відсутні",
            deu: "Keine Dokumente"
        },
        public_documents: {
            eng: "Public Documents",
            rus: "Общедоступные документы",
            ukr: "Загальнодоступні документи",
            deu: "Öffentliche Dokumente"
        },
        modal_copy_success: {
            eng: 'Copied successfully',
            rus: 'Скопировано успешно',
            ukr: 'Скопійовано успішно',
            deu: 'Erfolgreich kopiert',
        },
        architect: {
            eng: 'Architect',
            rus: 'Архитектор',
            ukr: 'Архітектор',
            deu: 'Architekt',
        },
        owner: {
            eng: 'Owner',
            rus: 'Собственник',
            ukr: 'Власник',
            deu: 'Eigentümer',
        },
        technical_specifications: {
            eng: 'Technical specifications',
            rus: 'Технические характеристики',
            ukr: 'Технічні характеристики',
            deu: 'Technische Daten',
        },
        utility: {
            eng: 'Utility system',
            rus: 'Коммуникации',
            ukr: 'Комунальні системи',
            deu: 'Versorgungssystem',
        },
        storeys: {
            eng: 'Number of storeys',
            rus: 'Этажность',
            ukr: 'Поверховість',
            deu: 'Anzahl der Stockwerke',
        },
        floor_height: {
            eng: 'Floor Height',
            rus: 'Высота этажа',
            ukr: 'Висота поверху',
            deu: 'Geschosshöhe',
        },
        lift: {
            eng: 'Elevator',
            rus: 'Лифт',
            ukr: 'Ліфт',
            deu: 'Aufzug',
        },
        floor: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Etage',
        },
        edit_information: {
            eng: 'Edit Information',
            rus: 'Редактировать информацию',
            ukr: 'Редагувати інформацію',
            deu: 'Informationen bearbeiten',
        },
        contact: {
            eng: "Contact",
            rus: "Связаться",
            ukr: "Зв'язатися",
            deu: "Kontakt"
        },
        construction_progress: {
            eng: 'Construction Progress',
            rus: 'Ход Строительства',
            ukr: 'Хід Будівництва',
            deu: 'Baufortschritt',
        },
        construction_news: {
            eng: 'Construction News',
            rus: 'Новости Строительства',
            ukr: 'Новини Будівництва',
            deu: 'Bau Nachrichten',
        },
        news: {
            eng: 'News',
            rus: 'Новости',
            ukr: 'Новини',
            deu: 'Nachrichten',
        },
        learn_more: {
            eng: "Learn More",
            rus: "Подробнее",
            ukr: "Детальніше",
            deu: "Mehr erfahren"
        },
        review_title: {
            eng: 'Expert Reviews',
            rus: 'Рецензии экспертов',
            ukr: 'Рецензії експертів',
            deu: 'Expertenbewertungen',
        },
        review: {
            eng: 'Review',
            rus: 'Рецензия',
            ukr: 'Рецензія',
            deu: 'Rezension',
        },
        characteristics: {
            eng: 'Characteristics',
            rus: 'Характеристики',
            ukr: 'Характеристики',
            deu: 'Merkmale',
        },
        from: {
            eng: 'from',
            rus: 'из',
            ukr: 'з',
            deu: 'aus',
        },
        count_from: {
            eng: 'from',
            rus: 'от',
            ukr: 'від',
            deu: 'von',
        },
        count_to: {
            eng: 'to',
            rus: 'до',
            ukr: 'до',
            deu: 'zu',
        },
        sq_m: {
            eng: 'sq.m',
            rus: 'кв.м',
            ukr: 'кв.м',
            deu: 'qm',
        },
        floor_number: {
            eng: 'Floor Number',
            rus: 'Номер этажа',
            ukr: 'Номер поверху',
            deu: 'Stockwerksnummer',
        },
        area: {
            eng: 'Area',
            rus: 'Площадь',
            ukr: 'Площа',
            deu: 'Fläche',
        },
        add: {
            eng: 'Add',
            rus: 'Добавить',
            ukr: 'Додати',
            deu: 'Hinzufügen',
        },
        attached_file: {
            eng: 'Attached file',
            rus: 'Прикрепленный файл',
            ukr: 'Прикріплений файл',
            deu: 'Angehängte Datei',
        },
        description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        ceiling_height_m: {
            eng: 'Ceiling height (m)',
            rus: 'Высота потолков (м)',
            ukr: 'Висота стель (м)',
            deu: 'Deckenhöhe (m)',
        },
        view_description: {
            eng: 'View description',
            rus: 'Просмотр описания',
            ukr: 'Перегляд опису',
            deu: 'Beschreibung anzeigen',
        },
        Key_not_founded: {
            eng: 'Error: Text not found',
            rus: 'Ошибка: Текст не найден',
            ukr: 'Помилка: Текст не знайдено',
            deu: 'Fehler: Text nicht gefunden',
        },
    },
        get: function (key, lang) {
            lang = languageStore.activeLanguage;
            let dictionary = this.dictionary[key]
                ? this.dictionary[key]
                : this.dictionary['Key_not_founded'];
            let dictionaryItem = dictionary[lang]
                ? dictionary[lang]
                : this.dictionary['Key_not_founded']['eng'];

            return dictionaryItem;
        },
}

export default commonDictionary;