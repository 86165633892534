const { REACT_APP_NODE_ENV: env } = process.env;
const baseProdServer = 'api.grem.capital';
const baseDevServer = 'devapi.grem.capital';
const baseLocalServer = '127.0.0.1:5000';

const config = {
  development: {
    apiServer: `https://${baseDevServer}/v1`,
    wsServer: `https://devapi.grem.capital`,
  },
  production: {
    apiServer: `https://${baseProdServer}/v1`,
    wsServer: `https://api.grem.capital`,
  },
  local: {
    apiServer: `http://${baseLocalServer}/v1`,
    wsServer: `ws://127.0.0.1:4000`,
  },
};

const getEnvironmentConfig = (env) => {
  return config[env] || config.local;
};

const { apiServer: server, wsServer } = getEnvironmentConfig(env);

export { server, wsServer, env };
