import { redirect, useNavigate } from 'react-router-dom';

export const History = {
  navigate: null,
  push: (page, ...rest) => History.navigate(page, ...rest),
};

export const NavigateSetter = () => {
  History.navigate = useNavigate();

  return null;
};
