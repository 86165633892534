import React, { Fragment, useEffect, useState } from 'react';
import QrCode from 'qrcode.react';
import { FactoringService } from '../../services';
import { RequestsValidator } from '../index';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import globalDictionary from 'assets/translations/globalDictionary';
import { getActiveLanguageFromLS } from 'utils';
import { isEmpty } from 'lodash';

const googleQrCode = ({ setMethod, setError }) => {
  const language = getActiveLanguageFromLS();
  const service = new FactoringService();
  const [googleCode, setGoogleCode] = useState({});

  useEffect(() => {
    async function fetch() {
      const result = await RequestsValidator(await service.getGoogleCode());
      if (result.code === 200) {
        setGoogleCode(result.data);
      } else {
        setError(result.error);
        setMethod('select');
      }
    }
    fetch();
  }, []);

  return (
    <Grid container justifyContent={'center'}>
      {!isEmpty(googleCode) ? (
        <Fragment>
          <Grid
            container
            spacing={2}
            pt={2}
            alignItems={'center'}
            justifyContent={'center'}
            textAlign={'center'}
          >
            <Grid item xs={12}>
              <QrCode value={googleCode?.secret} />
            </Grid>
            <Grid item xs={12}>
              {globalDictionary.get('factoring_qr_text', language)}
            </Grid>
          </Grid>

          <Button
            variant={'contained'}
            style={{ marginTop: 10 }}
            onClick={() => {
              setMethod('googleVerify');
            }}
            fullWidth
          >
            <Typography variant={'body1'}>
              {globalDictionary.get('factoring_next', language)}
            </Typography>
          </Button>
        </Fragment>
      ) : (
        <CircularProgress color={'secondary'} />
      )}
    </Grid>
  );
};

export default googleQrCode;
