import {observer} from "mobx-react-lite";
import {Box, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import apartsType from "assets/translations/apartsType";
import React from "react";
import projectDictionary from "assets/translations/Project/projectDictionary";

const LiveBlock = observer(({store}) => {

    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];
    return(
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width:'100%'
        }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography sx={{
                    fontSize:'16px',
                    fontWeight:'700',
                    marginBottom:'20px'
                }}>
                    {projectDictionary.get('project_add_liveArea_title')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get(
                        'project_add_liveArea_total'
                    )}
                    label={projectDictionary.get('project_add_liveArea_total')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.liveArea.total}
                    name={'project.liveArea.total'}
                    type={'number'}
                    inputProps={{min: 0, max: 1000000.00}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 1000000.00) {
                            event.target.value = '1000000.00';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}

                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sx={{marginTop:'40px'}}>
                <Grid item xs={12} container spacing={2} style={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(3, auto)',
                    gridAutoFlow: 'column',
                    gridAutoColumns: 'auto',
                }}>
                    {apartsType.getAllValueLabel().map((item)=>{
                        return <Grid
                            container
                            sx={{
                                position:'relative',
                                left: '28px',
                                //justifyContent:'space-between',
                                padding:'10px'
                            }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={`project.liveArea.${item.langValue ? item.langValue :item.value}`}
                                        value={store.fields.project.liveArea[item.langValue ? item.langValue :item.value]}
                                        checked={store.fields.project.liveArea[item.langValue ? item.langValue :item.value]}
                                        onChange={store.handleToggleCheckBox}
                                    />
                                } label={item.label}/>
                            {store.fields.project.liveArea[item.langValue ? item.langValue :item.value]?
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        placeholder={projectDictionary.get(
                                            'project_add_liveArea_count'
                                        )}
                                        label={projectDictionary.get('project_add_liveArea_count')}
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            marginLeft:'40px',
                                            marginRight:'40px',
                                        }}
                                        value={store.fields.project.liveArea.count[item.langValue ? item.langValue :item.value]}
                                        name={`project.liveArea.count.${item.langValue ? item.langValue :item.value}`}
                                        type={'number'}
                                        inputProps={{min: 0, max: 1000000.00}}
                                        onKeyDown={(e) => {
                                            if(wrongSymbols.includes(e.key)) {
                                                e.preventDefault()
                                            }

                                        }}
                                        onInput={(event)=>{
                                            const value = event.target.value;
                                            if (parseFloat(value) > 1000000.00) {
                                                event.target.value = '1000000.00';
                                            }
                                            const regex = /^\d*\.?\d{0,2}$/;
                                            if (!regex.test(value)) {
                                                event.target.value = value.slice(0, -1);
                                            }
                                            if (value.length > 1 && parseFloat(value) === 0) {
                                                event.target.value = value.slice(0, -1);
                                            }
                                        }}

                                        onChange={store.handleInputChange}
                                    />
                                </Grid>
                                : ''
                            }

                        </Grid>
                    })}
                </Grid>
            </Grid>
        </Box>
    )

})

export default LiveBlock;