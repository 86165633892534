import {server} from 'config';
import {getUserToken} from 'utils/index';
import {GremService} from 'services';

class ProjectService {
    constructor() {
        this._apiBase = server;
        this.service = new GremService();
    }

    async sendConsultRequest(form) {
        const res = await this.service.sendFormData(`/project/consulting`, 'POST', form, {
            'x-www-access': getUserToken(),
        });

        return res;
    }

    async getInitDocForm() {
        const res = await this.service.sendRequest(`/project/application`, 'GET', {}, {
            'x-www-access': getUserToken(),
        });

        return res;
    }

    async sendInitDocForm(form) {
        const res = await this.service.sendFormData(`/project/application`, 'POST', form, {
            'x-www-access': getUserToken(),
        });

        return res;
    }

    async getProject(projectId) {
        const res = await this.service.sendRequest(`/project/${projectId}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getProjects(data = {}) {
        const res = await this.service.sendRequest(`/project/?${data}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getProjectMarkers(data = {}) {
        const res = await this.service.sendRequest(`/project/markers/?`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }
    async addProject(data){
        const res = await this.service.sendFormData(
            `/project`,
            'POST',
            data,
            {
                'x-www-access': getUserToken(),
        });

        return res;
    }

    async deleteDraft(projectId) {
        const res = await this.service.sendRequest(
            `/project/${projectId}`,
            'DELETE',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async updateProject(data = {}) {
        const res = await this.service.sendFormData(
            `/project/${data.get('_id')}`,
            'PUT',
            data,
            {
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getProjectWallet(projectId) {
        const res = await this.service.sendRequest(`/wallet/project/${projectId}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }
    async getProjectWalletHistory(projectId,payload) {
        const res = await this.service.sendRequest(`/wallet/project/transaction/${projectId}?page=${payload.page}&limit=${payload.limit}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

}

export default ProjectService;
