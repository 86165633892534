import io from 'socket.io-client';
import { wsServer } from '../config';
import {makeAutoObservable} from "mobx";

class NotificationSocketService{
  socket= null
  notifications= null
  counter= null
  smart = null
  chat = null
  support = null
  constructor() {
    makeAutoObservable(this)
  }

  newItemShowed = (pageName) => {
    this.socket.emit('newItemsShowed', {pageName})
  }
  newItemShowedBadge = (pageName) => {
    this.socket.emit('newItemsShowedBadge', {pageName})
  }
  readMessages = ({id, pageName,count}) => {
    this.socket.emit('messagesRead', {id,pageName,count})
  }

  async init(token) {
    return new Promise((resolve, reject) => {
      if (token) {
        this.socket = io(`${wsServer}/notification`, {
          transports: ['websocket'],
          query: {
            token,
          },
        });

        this.socket.on('connect', () => {
          resolve(this);
        });
        this.socket.on('counters', (data) => {
          this.counter = data;
          this.smart = data.smart;
          this.chat = data.chat;
          this.support = data.support;
        });

        this.socket.on('error', (error) => {
          console.error(error);
          reject(error);
        });

        this.socket.on('connect_error', (error) => {
          console.error(error);
          reject(error);
        });
      }
    });
  }

};

export default new NotificationSocketService();
