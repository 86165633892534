import {observer} from "mobx-react-lite";
import {useState} from "react";
import {Box} from "@mui/system";
import projectDictionary from "../../../../../../assets/translations/Project/projectDictionary";
import {FileLoader} from "components";
import React from "react";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import formErrorDictionary from "../../../../../../assets/translations/errors/formErrorDictionary";
import {snackActions} from "../../../../../../utils/SnackBarUtils";
import utilitySystems from "../../../../../../assets/translations/utilitySystems";
import {AIInput} from "../../../../../../components";
import circlePlusBlackIcon from 'assets/icons/circle-plus-black.svg';
import UnitsListBlock from "./tokenizationBlocks/UnitsListBlock";
import commonDictionary from "../../../../../../assets/translations/commonDictionary";

const TokenizationForm = observer(({store})=>{

    const emptyUnit = {
        section:'',
        number:'',
        floor:'',
        rooms: '',
        numberWindows: '',
        numberOfBathrooms: '',
        utilitySystem: [],
        spaceAll: '',
        spaceLiving: '',
        spaceKitchen: '',
        ceilingHeight: '',
        balcony:'',
        terrace:'',
        description:'',
        windowView: '',
        plans:[],
        photos:[],
    }

    const [unit,setUnit] = useState(emptyUnit)
    const [unitErrors,setUnitErrors] = useState({
        number:'',
        floor:'',
        spaceAll:''
    })
    const addUnit = () => {
        const reqFields = ['number','floor','spaceAll'];
        let newErrors = {};

        reqFields.forEach((field) => {
            if(unit[field] === ''){
                newErrors[field] = formErrorDictionary.get('empty_field');
            } else {
                newErrors[field] = '';
            }
        });

        setUnitErrors(newErrors);

        const hasError = Object.values(newErrors).some(item => item !== '');

        if (!hasError) {
            store.handleInputArrayElem('project.units', unit)
            setUnit(emptyUnit)
        } else {
            snackActions.error(formErrorDictionary.get('form'))
        }
    }


    const editUnit = (index) => {
        setUnit(store.fields.project.units[index])
        removeUnit(index)
    }

    const removeUnit = (index) => {
        store.fields.project.units.splice(index,1)
    }

    const validate = (name) => {
            if(unit[name] === ''){
                setUnitErrors({...unitErrors,[name]:formErrorDictionary.get('empty_field')})
            } else {
                setUnitErrors({...unitErrors,[name]:''})
            }
    }

    const handleInputChange = (e) => {
        const {name,value} = e.target;
        setUnit({...unit,[name]:value})
        validate(name)
    }

    const handleInputField = (field, value) => {
        const newState = { ...unit };
        newState[field] = value;
        setUnit(newState);
    };

    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];

    return (
        <>
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '0px 0px 10px 10px',
            width:'100%'
        }}>
            <Typography sx={{
                fontSize:'16px',
                fontWeight:'700',
            }}>
                {projectDictionary.get('project_add_unit_item_title')}
            </Typography>
            <Typography sx={{
                fontSize:'16px',
                fontWeight:'400',
                lineHeight:'150%'
            }}>
                {projectDictionary.get('project_add_unit_item_title_sub')}
            </Typography>

            <UnitsListBlock edit={editUnit} remove={removeUnit}/>
            <Box sx={{
                display:'flex',
                marginTop:'30px',
                marginBottom:'20px',
            }}>
                <Typography sx={{
                    borderRadius: '30px',
                    background: '#E7E7E7',
                    padding:'10px 20px',
                    justifyContent:'center',
                    alignItems:'center',
                    textWrap:'noWrap',
                    fontSize:'16px',
                    fontWeight:'700',
                }}>
                    {`№ ${store?.fields?.project?.units?.length +1 ?? 1}`}
                </Typography>
            </Box>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get('project_add_unit_item_section')}
                        label={projectDictionary.get('project_add_unit_item_section')}
                        variant="outlined"
                        fullWidth
                        value={unit.section}
                        name={'section'}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get('project_add_unit_item_number')}
                        label={projectDictionary.get('project_add_unit_item_number')}
                        variant="outlined"
                        fullWidth
                        required
                        value={unit.number}
                        name={'number'}
                        error={unitErrors?.number !== ''}
                        helperText={unitErrors.number}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={commonDictionary.get('floor')}
                        label={commonDictionary.get('floor')}
                        variant="outlined"
                        fullWidth
                        required
                        value={unit.floor}
                        name={'floor'}
                        error={unitErrors?.floor !== ''}
                        helperText={unitErrors.floor}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_rooms'
                        )}
                        label={projectDictionary.get('project_add_unit_item_rooms')}
                        variant="outlined"
                        fullWidth
                        value={unit.rooms}
                        name={'rooms'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_windows'
                        )}
                        label={projectDictionary.get('project_add_unit_item_windows')}
                        variant="outlined"
                        fullWidth
                        value={unit.numberWindows}
                        name={'numberWindows'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_numberOfBathrooms'
                        )}
                        label={projectDictionary.get('project_add_unit_item_numberOfBathrooms')}
                        variant="outlined"
                        fullWidth
                        value={unit.numberOfBathrooms}
                        name={'numberOfBathrooms'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">
                            {projectDictionary.get('project_add_utility')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            id="select-type"
                            label={projectDictionary.get('project_add_utility')}
                            multiple
                            value={unit.utilitySystem}
                            name={'utilitySystem'}
                            onChange={(event) => {
                                const value = event.target.value;
                                handleInputField('utilitySystem',value)
                            }}
                        >
                            {utilitySystems.getAllValueLabel().map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_spaceAll'
                        )}
                        label={projectDictionary.get('project_add_unit_item_spaceAll')}
                        variant="outlined"
                        fullWidth
                        required
                        value={unit.spaceAll}
                        name={'spaceAll'}
                        type={'number'}
                        error={unitErrors?.spaceAll !== ''}
                        helperText={unitErrors.spaceAll}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_spaceLiving'
                        )}
                        label={projectDictionary.get('project_add_unit_item_spaceLiving')}
                        variant="outlined"
                        fullWidth
                        value={unit.spaceLiving}
                        name={'spaceLiving'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_kitchen'
                        )}
                        label={projectDictionary.get('project_add_unit_item_kitchen')}
                        variant="outlined"
                        fullWidth
                        value={unit.spaceKitchen}
                        name={'spaceKitchen'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_balcony'
                        )}
                        label={projectDictionary.get('project_add_unit_item_balcony')}
                        variant="outlined"
                        fullWidth
                        value={unit.balcony}
                        name={'balcony'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_item_terrace'
                        )}
                        label={projectDictionary.get('project_add_unit_item_terrace')}
                        variant="outlined"
                        fullWidth
                        value={unit.terrace}
                        name={'terrace'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={commonDictionary.get(
                            'floor_height'
                        )}
                        label={commonDictionary.get('floor_height')}
                        variant="outlined"
                        fullWidth
                        value={unit.ceilingHeight}
                        name={'ceilingHeight'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000.00}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000.00) {
                                event.target.value = '1000000.00';
                            }
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AIInput
                        placeholder={projectDictionary.get('project_add_unit_item_description')}
                        label={`${projectDictionary.get('project_add_unit_item_description')}`}
                        name={'description'}
                        value={unit.description}
                        onChange={handleInputChange}
                        maxTextLength={600}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AIInput
                        placeholder={projectDictionary.get('project_add_unit_item_windows_view')}
                        label={`${projectDictionary.get('project_add_unit_item_windows_view')}`}
                        name={'windowView'}
                        value={unit.windowView}
                        onChange={handleInputChange}
                        maxTextLength={600}
                    />
                </Grid>

            </Grid>

        </Box>
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px 10px 10px 10px',
            width:'100%',
            marginTop:'30px'
        }}>
            <FileLoader
                _data={unit}
                _setData={setUnit}
                configs={{
                    field: 'photos',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    title: projectDictionary.get('project_add_unit_item_photos'),
                    confName: 'project.unit',
                }}
            />

        </Box>
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px 10px 10px 10px',
            width:'100%',
            marginTop:'30px'
        }}>

            <FileLoader
                _data={unit}
                _setData={setUnit}
                configs={{
                    field: 'plans',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    title: projectDictionary.get('project_add_unit_item_plan'),
                    buttonText: projectDictionary.get('project_add_unit_item_plan_button'),
                    confName: 'project.unit',
                }}
            />

        </Box>
        <Box sx={{
            backgroundColor: 'none',
            padding: '0px',
            borderRadius: '0px',
            width:'100%',
            marginTop:'30px'
        }}>

            <Button
                onClick={addUnit}
                fullWidth
                startIcon={
                    <img src={circlePlusBlackIcon} alt={'add unit'}/>
                }
                sx={{
                    height:'50px',
                borderRadius:'5px',
                border:'1px solid #D1B684',
            }}>
                <Typography sx={{
                    color: '#021228',
                    fontFamily: 'Gilroy',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                }}>
                    {projectDictionary.get('project_add_unit_item_button')}
                </Typography>
            </Button>

        </Box>
        </>
    )

})

export default TokenizationForm;