import languageStore from "../languageStore";

const projectTypesDictionary = {
    elements: [
        {
            code: 'live',
            value: 'liveArea',
            labelEn: 'Residential real estate',
            labelRu: 'Жилая недвижимость',
            labelUa: 'Житлова нерухомість',
            labelDe: 'Wohnimmobilien',
        },
        {
            code: 'office',
            value: 'officeArea',
            labelEn: 'Office real estate',
            labelRu: 'Офисная недвижимость',
            labelUa: 'Офісна нерухомість',
            labelDe: 'Büroimmobilien',
        },
        // {
        //     code: 'commercial',
        //     value: 'commercialArea',
        //     labelEn: 'Commercial real estate',
        //     labelRu: 'Коммерческая недвижимость',
        //     labelUa: 'Комерційна нерухомість',
        //     labelDe: 'Gewerbeimmobilien',
        // },
        {
            code: 'restaurantCafe',
            value: 'restaurantCafeArea',
            labelEn: 'Restaurants and Cafes',
            labelRu: 'Рестораны и кафе',
            labelUa: 'Ресторани та кафе',
            labelDe: 'Restaurants und Cafés',
        },
        {
            code: 'storeProduction',
            value: 'storeProductionArea',
            labelEn: 'Warehouse and production real estate',
            labelRu: 'Складская и производственная недвижимость',
            labelUa: 'Складська та виробнича нерухомість',
            labelDe: 'Lager- und Produktionsimmobilien',
        },
        {
            code: 'hotel',
            value: 'hotelArea',
            labelEn: 'Hotel real estate',
            labelRu: 'Гостиничная недвижимость',
            labelUa: 'Готельна нерухомість',
            labelDe: 'Hotelimmobilien',
        },

    ],
    // TODO format this code
    get: function (key, lang) {
        lang = languageStore.activeLanguage;
        var ar = this.elements;

        if (lang === 'eng') {
            var a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelEn;
            }
        } else if (lang === 'rus') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelRu;
            }
        } else if (lang === 'ukr') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelUa;
            }
        } else if (lang === 'deu') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelDe;
            }
        } else {
            return '';
        }
    },
    getByValue: function (key, lang) {
        lang = languageStore.activeLanguage;
        var ar = this.elements;

        if (lang === 'eng') {
            var a = ar.find((x) => {
                return x.value === key || x.labelEn === key;
            });
            if (a) {
                return a.labelEn;
            }
        } else if (lang === 'rus') {
            a = ar.find((x) => {
                return x.value === key || x.labelEn === key;
            });
            if (a) {
                return a.labelRu;
            }
        } else if (lang === 'ukr') {
            a = ar.find((x) => {
                return x.value === key || x.labelEn === key;
            });
            if (a) {
                return a.labelUa;
            }
        } else if (lang === 'deu') {
            a = ar.find((x) => {
                return x.value === key || x.labelEn === key;
            });
            if (a) {
                return a.labelDe;
            }
        } else {
            return '';
        }
    },

    getAllValueLabel: function (lang) {
        lang = languageStore.activeLanguage;

        const functor = function (elem, language) {
            switch (language) {
                case 'eng':
                    return { value: elem.value, label: elem.labelEn };
                case 'rus':
                    return { value: elem.value, label: elem.labelRu };
                case 'uah':
                    return { value: elem.value, label: elem.labelUa };
                case 'deu':
                    return { value: elem.value, label: elem.labelDe };
                default:
                    return { value: elem.value, label: elem.labelEn };
            }
        };

        const roles = this.elements.map((item) => functor(item, lang));
        return roles;
    },
};

export default projectTypesDictionary;
