import {observer} from "mobx-react-lite";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import React from "react";
import projectDictionary from "assets/translations/Project/projectDictionary";
import projectTypesDictionary from "../../../../../../../assets/translations/Project/projectTypesDictionary";
import utilitySystems from "../../../../../../../assets/translations/utilitySystems";
import commonDictionary from "../../../../../../../assets/translations/commonDictionary";

const OfficeBlock = observer(({store})=>{

    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];
    return(
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width:'100%'
        }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography sx={{
                    fontSize:'16px',
                    fontWeight:'700',
                }}>
                    {projectDictionary.get('project_add_technical_office_title')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get(
                        'project_add_office_total'
                    )}
                    label={projectDictionary.get('project_add_office_total')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.officeArea.total}
                    name={`project.officeArea.total`}
                    type={'number'}
                    inputProps={{min: 0, max: 1000000.00}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 1000000.00) {
                            event.target.value = '1000000.00';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}

                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get(
                        'project_add_office_effective'
                    )}
                    label={projectDictionary.get('project_add_office_effective')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.officeArea.effective}
                    name={`project.officeArea.effective`}
                    type={'number'}
                    inputProps={{min: 0, max: 1000000.00}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 1000000.00) {
                            event.target.value = '1000000.00';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}

                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get(
                        'project_add_office_rooms'
                    )}
                    label={projectDictionary.get('project_add_office_rooms')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.officeArea.rooms}
                    name={`project.officeArea.rooms`}
                    type={'number'}
                    inputProps={{min: 0, max: 1000000}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 1000000) {
                            event.target.value = '1000000';
                        }
                        const regex = /^\d*\.?\d{0}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}

                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get(
                        'project_add_storeys'
                    )}
                    label={projectDictionary.get('project_add_storeys')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.officeArea.storeys}
                    name={`project.officeArea.storeys`}
                    type={'number'}
                    inputProps={{min: 0, max: 1000000}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 1000000) {
                            event.target.value = '1000000';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}

                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get(
                        'project_add_floorHeight'
                    )}
                    label={projectDictionary.get('project_add_floorHeight')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.officeArea.floorHeight}
                    name={`project.officeArea.floorHeight`}
                    type={'number'}
                    inputProps={{min: 0, max: 1000000.00}}
                    onKeyDown={(e) => {
                        if(wrongSymbols.includes(e.key)) {
                            e.preventDefault()
                        }

                    }}
                    onInput={(event)=>{
                        const value = event.target.value;
                        if (parseFloat(value) > 1000000.00) {
                            event.target.value = '1000000.00';
                        }
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (!regex.test(value)) {
                            event.target.value = value.slice(0, -1);
                        }
                        if (value.length > 1 && parseFloat(value) === 0) {
                            event.target.value = value.slice(0, -1);
                        }
                    }}

                    onChange={store.handleInputChange}
                />
            </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">
                            {projectDictionary.get('project_add_lift')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            id="select-type"
                            label={projectDictionary.get('project_add_lift')}
                            value={store.fields.project.officeArea.lift.toString()}
                            name={'project.officeArea.lift'}
                            onChange={(event) => {
                                const value = event.target.value;
                                store.handleInputField('project.officeArea.lift',value==='true')
                            }}
                        >
                                <MenuItem key={'yes'} value={'true'}>
                                    {commonDictionary.get('yes')}
                                </MenuItem>
                                <MenuItem key={'no'} value={'false'}>
                                    {commonDictionary.get('no')}
                                </MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">
                            {projectDictionary.get('project_add_utility')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            id="select-type"
                            label={projectDictionary.get('project_add_utility')}
                            multiple
                            value={store.fields.project.officeArea.utilitySystem}
                            name={'project.officeArea.utilitySystem'}
                            onChange={(event) => {
                                const value = event.target.value;
                                store.handleInputField('project.officeArea.utilitySystem',value)
                            }}
                        >
                            {utilitySystems.getAllValueLabel().map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        </Box>
    )
})

export default OfficeBlock;