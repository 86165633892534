import {Grid, TextField, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {FullAddressBlock} from 'components';
import globalDictionary from 'assets/translations/globalDictionary';
import {observer} from "mobx-react-lite";
import {useContractStore} from "../../ContractStore";
import {DatePicker} from "@mui/x-date-pickers";
import CalendarIcon from 'assets/icons/calendar.svg'

const SpecialistMember = observer(() => {
    const formData = useContractStore()
    return (
        <Grid container spacing={'20px'} marginTop={'20px'}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        label={globalDictionary.get('contract_add_member_person_fname')}
                        variant="outlined"
                        fullWidth
                        required
                        name="person.fname"
                        value={formData.contract.person.fname || ''}
                        onChange={formData.handleInputChange}
                        error={!!formData.contractErrors.fname}
                        helperText={formData.contractErrors.fname}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>

                    <TextField
                        label={globalDictionary.get('contract_add_member_person_lname')}
                        variant="outlined"
                        fullWidth
                        required
                        name="person.lname"
                        value={formData.contract.person.lname || ''}
                        onChange={formData.handleInputChange}
                        error={!!formData.contractErrors.lname}
                        helperText={formData.contractErrors.lname}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        label={globalDictionary.get('contract_add_member_person_passport')}
                        variant="outlined"
                        fullWidth
                        required
                        name="person.personPassport"
                        value={formData.contract.person.personPassport || ''}
                        onChange={formData.handleInputChange}
                        error={!!formData.contractErrors.personPassport}
                        helperText={formData.contractErrors.personPassport}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        label={globalDictionary.get('contract_add_member_person_passport_issued')}
                        variant="outlined"
                        fullWidth
                        required
                        name="person.personPassportIssuedBy"
                        value={formData.contract.person.personPassportIssuedBy || ''}
                        onChange={formData.handleInputChange}
                        error={!!formData.contractErrors.personPassportIssuedBy}
                        helperText={formData.contractErrors.personPassportIssuedBy}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <DatePicker
                        label={globalDictionary.get('contract_add_member_person_passport_date')+' *'}
                        variant="outlined"
                        required
                        slots={{
                            openPickerIcon: () => <img src={CalendarIcon} alt="calendar" />,
                        }}
                        sx={{
                            '& .MuiSvgIcon-root':{
                                color:'#7A7A7A',
                            },
                            '& .MuiButtonBase-root':{
                               marginRight:'10px'

                            },
                            width:'100%',
                        }}
                        value={formData.contract.person.passportDate}
                        onChange={(value)=>{formData.handleInputDate(value,'person.passportDate')}}
                        error={!!formData.contractErrors.passportDate}
                        helperText={formData.contractErrors.passportDate}
                    />
                </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography fontWeight={600} mb={'15px'}>
                    {globalDictionary.get('contract_add_member_person_address')}:
                </Typography>
                <FullAddressBlock
                    data={formData.contract.person.personLivingAddress}
                    parentField={'person.personLivingAddress'}
                    dataError={formData.contractErrors}
                    handleInputChange={formData.handleInputChange}
                />
            </Grid>


        </Grid>
    );
});

export default SpecialistMember;
