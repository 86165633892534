import {getActiveLanguageFromLS} from "utils/index";
import languageStore from "./languageStore";

const apartsType = {
    elements: [
        {
            code: '1',
            value: '1',
            langValue:'one',
            labelEn: '1 bedroom',
            labelRu: '1 спальня',
            labelUa: '1 спальня',
            labelDe: '1 Schlafzimmer',
        },
        {
            code: '2',
            value: '2',
            langValue:'two',
            labelEn: '2 bedrooms',
            labelRu: '2 спальни',
            labelUa: '2 спальні',
            labelDe: '2 Schlafzimmer',
        },
        {
            code: '3',
            value: '3',
            langValue:'three',
            labelEn: '3 bedrooms',
            labelRu: '3 спальни',
            labelUa: '3 спальні',
            labelDe: '3 Schlafzimmer',
        },
        {
            code: '4',
            value: '4',
            langValue:'four',
            labelEn: '4 bedrooms',
            labelRu: '4 спальни',
            labelUa: '4 спальні',
            labelDe: '4 Schlafzimmer',
        },
        {
            code: '5',
            value: '5',
            langValue:'five',
            labelEn: '5 bedrooms',
            labelRu: '5 спален',
            labelUa: '5 спалень',
            labelDe: '5 Schlafzimmer',
        },
        {
            code: 'penthouse',
            value: 'penthouse',
            labelEn: 'Penthouse',
            labelRu: 'Пентхаус',
            labelUa: 'Пентхаус',
            labelDe: 'Penthouse',
        },
        {
            code: 'villa',
            value: 'villa',
            labelEn: 'Villa',
            labelRu: 'Вилла',
            labelUa: 'Вілла',
            labelDe: 'Villa',
        },
        {
            code: 'studio',
            value: 'studio',
            labelEn: 'Studio apartment',
            labelRu: 'Квартира-студия',
            labelUa: 'Квартира-студія',
            labelDe: 'Studio-Apartment',
        },

    ],
    // TODO format this code
    get: function (key, lang) {
        lang = languageStore.activeLanguage;
        var ar = this.elements;

        if (lang === 'eng') {
            var a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelEn;
            }
        } else if (lang === 'rus') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelRu;
            }
        } else if (lang === 'ukr') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelUa;
            }
        } else if (lang === 'deu') {
            a = ar.find((x) => {
                return x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelDe;
            }
        } else {
            return '';
        }
    },
    getByLangValue: function (key, lang) {
        lang = languageStore.activeLanguage;
        var ar = this.elements;

        if (lang === 'eng') {
            var a = ar.find((x) => {
                return  x?.langValue === key || x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelEn;
            }
        } else if (lang === 'rus') {
            a = ar.find((x) => {
                return x?.langValue === key || x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelRu;
            }
        } else if (lang === 'ukr') {
            a = ar.find((x) => {
                return x?.langValue === key || x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelUa;
            }
        } else if (lang === 'deu') {
            a = ar.find((x) => {
                return x?.langValue === key || x.code === key || x.labelEn === key;
            });
            if (a) {
                return a.labelDe;
            }
        } else {
            return '';
        }
    },

    getAllValueLabel: function (lang) {
        lang = languageStore.activeLanguage;

        const functor = function (elem, language) {
            switch (language) {
                case 'eng':
                    return { value: elem.value, langValue:elem.langValue, label: elem.labelEn };
                case 'rus':
                    return { value: elem.value, langValue:elem.langValue,label: elem.labelRu };
                case 'ukr':
                    return { value: elem.value, langValue:elem.langValue,label: elem.labelUa };
                case 'deu':
                    return { value: elem.value, langValue:elem.langValue,label: elem.labelDe };
                default:
                    return { value: elem.value, langValue:elem.langValue,label: elem.labelEn };
            }
        };

        const roles = this.elements.map((item) => functor(item, lang));
        return roles;
    },
};

export default apartsType;
