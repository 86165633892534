import React, { useMemo } from 'react';
import {Box} from '@mui/system';
import {IconButton, Modal, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GlobalDictionary from 'assets/translations/globalDictionary';
import {getActiveLanguageFromLS} from 'utils/index';
import {observer} from 'mobx-react-lite';

const ModalWindow = observer(({children, closeModal, title, dictionaryKey, modalStyles}) => {
    const globalDictionary = GlobalDictionary;
    const language = getActiveLanguageFromLS();

    const styles = useMemo(() => ({
        outline: 'none',
        boxShadow: 3,
        maxHeight: '80%',
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...modalStyles,
        width: {xs: '90%', sm: '75%', md: '60%'},
    }), [modalStyles]);

    return (
        <Modal
            open={true}
            onClose={() => closeModal()}
            style={{
                backdropFilter: 'blur(2px)',
                backgroundColor: 'rgba(255, 255, 255, 0.35)',
                outline: 'none',
            }}
        >
            <Box sx={styles}>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        width: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {(title || dictionaryKey) &&
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: 700,
                                textAlign: 'center',
                                lineHeight: '130%',
                                p: '30px',
                                pb: 0,
                            }}
                        >
                            {title || globalDictionary.get(dictionaryKey, language)}
                        </Typography>
                    }
                    <Box sx={{
                        overflowY: 'auto',
                        p: '30px',
                        pt: 0,
                        flex: 1
                    }}>
                        {children}
                    </Box>
                </Box>
                <IconButton
                    onClick={() => closeModal()}
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: '-60px',
                            sm: '0px',
                        },
                        right: {
                            xs: '0px',
                            sm: '-60px'
                        },
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        color: 'white',
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
        </Modal>
    );
});

export default ModalWindow;
