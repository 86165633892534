import { server } from 'config';
import { getUserToken } from 'utils/index';
import { GremService } from 'services';

class LeadService {
  constructor() {
    this._apiBase = server;
    this.service = new GremService();
  }

  async getObjects({type}) {
    const res = await this.service.sendRequest(
      `/contract/division/lead/available?objectType=${type}`,
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }
  async getContractLeads(id,period) {
    const res = await this.service.sendRequest(
      `/contract/division/${id}/lead?period=${period}`,
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }
  async createLead(contractId, data) {
    const res = await this.service.sendRequest(
      `/contract/division/${contractId}/lead`,
      'POST',
      data,
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

    async sendMessage(leadId, data) {
        const res = await this.service.sendRequest(
            `/contract/division/${leadId}/message`,
            'POST',
            data,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getMessages(leadId) {
        const res = await this.service.sendRequest(
            `/contract/division/${leadId}/message`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async finalizeLead({leadId, summ}) {
        const res = await this.service.sendRequest(
            `/contract/final/${leadId}`,
            'POST',
            {summ},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
}

export default LeadService;
