import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import {FreeMode, Thumbs, Navigation} from 'swiper/modules';
import styles from './ImageSlider.module.css';
import sliderArrLeft from "assets/icons/slider_arr_left.svg";
import sliderArrRight from "assets/icons/slider_arr_right.svg";
import {ImageChecker} from "../../index";


const ImageSlider = (
    {
        slides = [],
        navigation = true,
    }
) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    slides = [...slides, ...slides]
    return (
        <div className={styles.gallery__container}>
            <div className={styles.gallery__wrapper}>
                {
                    navigation && (
                        <>
                            <button ref={prevRef}
                                    className={`${styles.gallery__navigation} ${styles.gallery__navigation_prev}`}>
                                <img src={sliderArrLeft} alt={'slide button left'}/>
                            </button>
                            <button ref={nextRef}
                                    className={`${styles.gallery__navigation} ${styles.gallery__navigation_next}`}>
                                <img src={sliderArrRight} alt={'slide button right'}/>
                            </button>
                        </>
                    )
                }
                <Swiper
                    spaceBetween={10}
                    loop={true}
                    modules={[FreeMode, Thumbs, Navigation]}
                    className={styles.gallery}
                    navigation={true}
                    onSwiper={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                >
                    {
                        slides.map((slide, index) => (
                            <SwiperSlide key={`slide-main-${index}`} className={styles.gallery__slide}>
                                <ImageChecker src={slide} alt={'slide'} sx={{
                                    '& img': {
                                        objectFit: 'contain',
                                    }
                                }}/>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
}
export default ImageSlider;
