import {DraftStepper} from "../../index";
import React from "react";
import {observer} from "mobx-react-lite";

/**
 *
 * @param steps: [{ label: String ,content:{JSX.Element}}]
 * @param sendForm: Function
 * @returns {JSX.Element}
 * @constructor
 */

const DraftForm = observer((props) => {
    return (
        <DraftStepper
            store={props.store}
            {...props}
        />
    )
});

export default DraftForm;
