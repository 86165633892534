import React, {Fragment, useEffect, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {Box, Button, Grid, IconButton, Typography} from '@mui/material';
import {getBase64FromFile} from 'utils';
import {getActiveLanguageFromLS} from 'utils/index';
import TrashButton from './trash_button.svg';
import ImageCircle from './image_circle.svg';
import Image from 'mui-image';
import ImageConfigs from "assets/configs/image.configs";
import globalDictionary from "assets/translations/globalDictionary";
import {observer} from "mobx-react-lite";
import {ImageChecker} from "../../index";

/**
 * @param data :{images:[],isMain:Boolean}
 * <br />
 * @param _setData :()=>{_setData(field,value)}
 * <br />
 * @param isError : boolean
 * <br />
 * @param config : { <br />
 *    field: 'fieldName', <br />
 *    titleKey: 'title for form', <br />
 *    confName: 'parent config name from imageConfigs' <br />
 * } <br />
 * <br />
 * @returns {JSX.Element}
 * @constructor
 */

const PhotosLoader = observer(({_data, _setData, isError = false, config}) => {
    const imageConfigs = ImageConfigs.get(config.confName, config.field);
    const configs = config ? {...imageConfigs, ...config} : ImageConfigs.defaultConfigs;
    const language = getActiveLanguageFromLS();
    const calcFileCount = Array.isArray(_data[config.field])
        ? _data[config.field].length
        : _data[config.field] !== ''
            ? 1
            : 0;
    const [uploadedFileCount, setUploadedFileCount] = useState(calcFileCount);
    const [error, setError] = useState(isError ? isError : false);

    // useEffect(() => {
    //     setError(isError);
    // }, [isError]);

    useEffect(() => {
        if (configs.minCount > 0) {
            uploadedFileCount < configs.minCount ? setError(true) : setError(false);
        }
    }, [uploadedFileCount]);

    const handleChange = async (file) => {
        let currentData = _data[configs.field];

        if (configs.maxCount > 1) {
            const files = Array.from(file).slice(0, configs.maxCount - uploadedFileCount);
            const images = await Promise.all(files.map(async (photo) => {
                return {
                    url: await getBase64FromFile(photo),
                    isMain: false
                }

            }));
            currentData = [...currentData, ...images];
            if (currentData.filter(item => item.isMain === true).length === 0) {
                currentData[0].isMain = true
            }
            setUploadedFileCount(uploadedFileCount + files.length);
        } else if (configs.maxCount === 1) {
            const photo = await getBase64FromFile(file);
            currentData[configs.field].url = photo;
            currentData[configs.field].isMain = true;
            setUploadedFileCount(1);
        }

        _setData(`${configs.field}`, currentData);
    };

    const setMain = (index) => {
        _setData(configs.field,
            _data[configs.field].map((image, i) => {
                if (i === index) {
                    return {
                        ...image,
                        isMain: true
                    };
                } else {
                    return {
                        ...image,
                        isMain: false
                    };
                }
            })
        );
    };


    const showImages = () => {
        let images = _data[configs.field]
        if (Array.isArray(images) && images.length > 0) {
            return images.map((photo, index) => (
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        '&:hover > *': {
                            visibility: 'visible'
                        }
                    }}
                    gridRow
                >
                    <IconButton
                        color="primary"
                        sx={{
                            top: 5,
                            left: 150,
                            zIndex: 1000,
                            visibility: 'hidden',
                        }}
                        onClick={() => {
                            let currentData = _data;

                            const newPhotos = currentData[configs.field].filter((item) => item !== photo)
                            _setData(configs.field, newPhotos);

                            setUploadedFileCount((prevCount) => prevCount - 1);
                        }}
                        size="large"
                    >
                        <img style={{position: 'absolute'}} src={TrashButton}/>
                    </IconButton>
                    <ImageChecker src={photo.url} style={{objectFit: 'contain', display: 'block'}} height={120}
                                  width={180}/>

                    {photo.isMain ? (
                        <Box
                            sx={{
                                backgroundColor: 'rgba(2, 18, 40, 0.15)',
                                borderColor: 'black',
                                borderRadius: '5px',
                                textAlign: 'center',
                                justifyItems: 'center',
                                display: 'grid',
                                color: 'black',
                                minHeight: '20px',
                                height: '20px',
                                mt: '5px',
                            }}
                        >
                            <Typography variant={'caption'}>
                                {globalDictionary.get('photos_loader_general')}
                            </Typography>
                        </Box>
                    ) : (
                        <Button
                            sx={{
                                visibility: 'hidden',
                                bgcolor: 'white',
                                borderColor: 'black',
                                color: 'black',
                                minHeight: '20px',
                                height: '20px',
                                mt: '5px',
                                "&:hover": {
                                    borderColor: 'black',
                                    bgcolor: 'white',
                                    color: 'black'
                                },


                            }}
                            fullWidth
                            variant={'outlined'}
                            textAlign={'center'}
                            onClick={() => {
                                setMain(index)
                            }}
                        >
                            <Typography variant={'caption'}>
                                {globalDictionary.get('photos_loader_set_general')}
                            </Typography>
                        </Button>
                    )}
                </Grid>
            ));
        }
    };
    return (
        <Box sx={{
            color: isError ? 'red' :'black',
            border:isError ? '1px solid red' :'none',
            padding:'5px',
            borderRadius:'5px',
            width:'100%'
        }}>
            <Grid container>
                <Grid item xs={12}>
                    {configs.titleKey && (<Typography variant={"subtitle2"}>
                        {globalDictionary.get(`${configs.titleKey}`)}
                    </Typography>)
                    }
                </Grid>
                <Grid item xs={12} sx={{marginTop: '15px'}}>
                    {configs.titleSubKey &&
                        (<Typography variant={'body2'}>
                            {globalDictionary.get(`${configs.titleSubKey}`)}
                        </Typography>)
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {showImages()}
            </Grid>
            {uploadedFileCount < configs.maxCount &&
                <Grid container mt={'40px'}>
                    <FileUploader
                        disabled={uploadedFileCount >= configs.maxCount}
                        onClick={handleChange}
                        children={
                            <Button
                                variant={'contained'}
                            >

                                <img src={ImageCircle}/>
                                <Typography
                                    ml={'10px'}
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                >
                                    {configs.buttonText ? configs.buttonText : globalDictionary.get('photos_loader_upload')}
                                </Typography>
                            </Button>
                        }

                        maxSize={configs.size}
                        multiple={configs.maxCount > 1}
                        handleChange={handleChange}
                        name="file"
                        types={['JPG', 'JPEG', 'PNG', 'WEBP']}
                    />

                </Grid>
            }
            {uploadedFileCount < configs.maxCount &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FileUploader
                        disabled={uploadedFileCount >= configs.maxCount}
                        children={
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: '50px',
                                    minHeight: '50px',
                                    padding: '20px 16px 8px 8px',
                                    cursor: 'pointer',
                                    flexWrap: 'wrap',
                                    color: '#939393'
                                }}
                            >
                                <Fragment>
                                    <Typography sx={{
                                        color: '#939393',
                                        fontFamily: 'Gilroy',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '150%'
                                    }}>
                                        {globalDictionary.get('photo_loader_drop', language)}
                                    </Typography>
                                    <Grid container gridRow>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '150%'
                                            }}
                                        >
                                            {configs.minCount > 0 && (`${globalDictionary.get('photo_loader_file_count_min', language)} ${
                                                configs.minCount
                                            } ${globalDictionary.get(configs.minKey)}. `)}
                                            {`${globalDictionary.get('photo_loader_file_count', language)} ${
                                                configs.maxCount
                                            } ${globalDictionary.get(configs.maxKey)}. `}
                                            {`${globalDictionary.get('photo_loader_file_size', language)} - ${
                                                configs.size
                                            }Mb. `}
                                            {globalDictionary.get('photo_loader_formats', language)}
                                        </Typography>
                                    </Grid>
                                </Fragment>

                            </Box>
                        }
                        maxSize={configs.size}
                        multiple={configs.maxCount > 1}
                        handleChange={handleChange}
                        name="file"
                        types={['JPG', 'JPEG', 'PNG', 'WEBP']}
                    />
                </Grid>
            }
        </Box>
    );
})

export default PhotosLoader;
