import React from 'react';
import {Box, Typography} from '@mui/material';

const ProfileListItem = ({title, value}) => (
    <Box>
        <Typography sx={{
            color: '#7A7A7A',
            fontSize: '12px',
            lineHeight: '150%',
        }}>
            {title}
        </Typography>
        {
            React.isValidElement(value) ? value : (
                <Typography sx={{
                    color: '#021228',
                    fontSize: '20px',
                    lineHeight: '150%',
                }}>
                    {value && Array.isArray(value) ? value.replace(/,/g, ', ') : value.replace(/,/g, ', ')}
                </Typography>
            )
        }
    </Box>
);

export default ProfileListItem;
