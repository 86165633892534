import { GremService } from 'services';
import { getUserToken } from 'utils';
import { server } from '../config';

class SupportService {
  constructor() {
    this._apiBase = server;
    this.service = new GremService();
  }

  async getTickets({ status = '', title = '', page = 1 } = {}) {
    const res = await this.service.sendRequest(
      `/support/ticket?status=${status}&title=${title}&page=${page}`,
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async getTicket(ticketId) {
    const res = await this.service.sendRequest(
      `/support/ticket/${ticketId}`,
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async getTicketMessage(ticketId) {
    const res = await this.service.sendRequest(
      `/support/ticket/${ticketId}/message`,
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async sendMessageToTicket(ticketId, data) {
    const res = await this.service.sendFormData(
      `/support/ticket/${ticketId}/message`,
      'POST',
      data,
      {
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async updateTicketStatus(ticketId, status) {
    const res = await this.service.sendRequest(
      `/support/ticket/${ticketId}`,
      'PUT',
      { status },
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async createNewTicket(data) {
    const res = await this.service.sendFormData(
        '/support/ticket',
        'POST',
        data,
        {
      'x-www-access': getUserToken(),
    });
    return res;
  }
}

export default SupportService;
