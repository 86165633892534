import {observer} from "mobx-react-lite";
import {Box, Typography} from "@mui/material";
import {useFormStore} from "../../../../../../../components/shared/DraftForm/FormStore";
import projectDictionary from "../../../../../../../assets/translations/Project/projectDictionary";
import commonDictionary from "../../../../../../../assets/translations/commonDictionary";

import PenClipElipse from "assets/icons/pen-clip-elipse.svg"
import TrashBlankElipse from "assets/icons/trash-blank-alt-elipse.svg"

const UnitsListBlock = observer(({edit,remove}) => {

    const store = useFormStore();

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            gap:'10px',
            marginTop:'30px',
        }}>
            {store?.fields?.project?.units?.length > 0 &&
            store?.fields?.project?.units?.map((unit,index) => {
                return <Box sx={{
                    display:'flex',
                    flexDirection:'row',
                    borderRadius: '10px',
                    background: '#F2F3F4',
                    width: '100%',
                    padding: '17px 20px',
                    justifyContent:'space-between'
                }}>
                    <Box sx={{

                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'space-between',
                        justifyItems:'center',
                        alignItems:'center',
                        gap:'20px'
                    }}>
                    <Typography sx={{
                        borderRadius: '30px',
                        border: '1px solid #021228',
                        color:'#021228',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        padding:'10px 20px'
                    }}>
                        {`№ ${index+1}`}
                    </Typography>
                    <Typography sx={{
                        color:'#021228',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal'
                    }}>
                        {`${projectDictionary.get('project_add_unit_item_number')}: ${unit.number}`}
                    </Typography>
                    <Typography sx={{
                        color:'#021228',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal'
                    }}>
                        {`${commonDictionary.get('floor')}: ${unit.floor}`}
                    </Typography>
                    <Typography sx={{
                        color:'#021228',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal'
                    }}>
                        {`${commonDictionary.get('area')}: ${unit.spaceAll}`}
                    </Typography>
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'space-between',
                        justifyItems:'center',
                        alignItems:'center',
                        gap:'20px'
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '20px',
                                alignItems: 'center',
                                mt: {
                                    xs: '10px',
                                    sm: '0',
                                },
                            }}
                        >

                                <img src={PenClipElipse}
                                     onClick={()=>{edit(index)}}
                                     style={{
                                         cursor:'pointer'}}
                                />


                                <img src={TrashBlankElipse}
                                     onClick={()=>{remove(index)}}
                                     style={{
                                         cursor:'pointer'}}
                                />

                        </Box>
                    </Box>
                </Box>
            })

            }
        </Box>
    )

})

export default UnitsListBlock;