import { server } from 'config';
import { getUserToken } from 'utils/index';
import { GremService } from 'services';
import { random } from 'lodash';

class ConvertService {
  constructor() {
    this._apiBase = server;
    this.service = new GremService();
  }

  async getPairs() {
    const res = await this.service.sendRequest(
      '/convert/pairs',
      'GET',
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async getOrders(page, limit) {
    const res = await this.service.sendRequest(
      '/convert/orders/all',
      'POST',
      { page, limit },
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async getExchangeRate(convertPairId) {
    const res = await this.service.sendRequest(
      `/price/exchange`,
      'POST',
      { convertPairId },
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-www-access': getUserToken(),
      }
    );
    return res;
  }

  async createOrder(data) {
    const res = await this.service.sendRequest('/convert/orders/create', 'POST', data, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-www-access': getUserToken(),
    });
    return res;
  }
}

export default ConvertService;
