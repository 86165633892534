import {makeAutoObservable} from "mobx";

class LanguageStore {
    activeLanguage = localStorage.getItem('language') ?? 'eng';

    constructor() {
        makeAutoObservable(this);
    }

    setActiveLanguage(lang) {
        this.activeLanguage = lang;
        localStorage.setItem('language', lang); // Сохраняем выбранный язык в localStorage
    }
}

const languageStore = new LanguageStore();
export default languageStore;