import React, {lazy, Suspense} from 'react';

const LazyLoadComponent = (Component) => (props) =>
    (
        <Suspense fallback={<></>}>
            <Component {...props} />
        </Suspense>
    );

const Login = LazyLoadComponent(lazy(() => import('./Auth/Login/Login')));
const Register = LazyLoadComponent(lazy(() => import('./Auth/Register/Register')));
const RegisterSuccess = LazyLoadComponent(
    lazy(() => import('./Auth/RegisterSuccess/RegisterSuccess'))
);
const RegisterConfirm = LazyLoadComponent(
    lazy(() => import('./Auth/RegisterConfirm/RegisterConfirm'))
);
const Forgot = LazyLoadComponent(lazy(() => import('./Auth/Forgot/Forgot')));
const ForgotMessage = LazyLoadComponent(lazy(() => import('./Auth/ForgotMessage/ForgotMessage')));
const ForgotChangePassword = LazyLoadComponent(
    lazy(() => import('./Auth/ForgotChangePassword/ForgotChangePassword'))
);
const Logout = LazyLoadComponent(lazy(() => import('./Auth/Logout/Logout')));
const AuthSocial = LazyLoadComponent(lazy(() => import('./Auth/AuthSocial/AuthSocial')));

const MyProfile = LazyLoadComponent(lazy(() => import('./Profile/MyProfile/MyProfile')));

const ProfileEdit = LazyLoadComponent(
    lazy(() => import('./Profile/MyProfile/components/ProfileEdit/ProfileEdit'))
);

const Referrals = LazyLoadComponent(lazy(() => import('./Services/Referrals/Referrals/Referrals')));

const PropertyList = LazyLoadComponent(lazy(() => import('./Services/ Property/PropertyList/PropertyList')));

const PropertyView = LazyLoadComponent(lazy(() => import('./Services/ Property/PropertyView')));

const PropertyForm = LazyLoadComponent(lazy(() => import('./Services/ Property/PropertyForm/PropertyForm')));

const ComplexForm = LazyLoadComponent(
    lazy(() => import('./Services/Complex/ComplexForm/ComplexForm'))
);
const ComplexList = LazyLoadComponent(lazy(() => import('./Services/Complex/ComplexList/ComplexList')));
const ComplexView = LazyLoadComponent(
    lazy(() => import('./Services/Complex/ComplexView/ComplexView'))
);

const CommunityList = LazyLoadComponent(lazy(() => import('./Services/Community/CommunityList/CommunityList')));
const CommunityView = LazyLoadComponent(
    lazy(() => import('./Services/Community/CommunityView/CommunityView'))
);

const Messenger = LazyLoadComponent(lazy(() => import('./Services/Messenger/Messenger')));

const TicketList = LazyLoadComponent(
    lazy(() => import('./Services/Support/TicketList/TicketList'))
);
const TicketItem = LazyLoadComponent(
    lazy(() => import('./Services/Support/TicketItem/TicketItem'))
);
const TicketAdd = LazyLoadComponent(lazy(() => import('./Services/Support/TicketAdd/TicketAdd')));

const SplitCommissionAdd = LazyLoadComponent(
    lazy(() => import('./Services/Contracts/SplitCommission/SplitCommissionAdd'))
);

const Articles = LazyLoadComponent(lazy(() => import('./Articles/Articles/Articles')));
const ArticleView = LazyLoadComponent(
    lazy(() => import('./Articles/ArticleView/ArticleView'))
);

const Contact = LazyLoadComponent(lazy(() => import('./Contact/Contact')));

const FAQ = LazyLoadComponent(lazy(() => import('./FAQ/FAQ')));

const Error = LazyLoadComponent(lazy(() => import('./SecondaryPages/Error/Error')));
const ComingSoon = LazyLoadComponent(lazy(() => import('./SecondaryPages/ComingSoon/ComingSoon')));
const Smart = LazyLoadComponent(lazy(()=> import('./Services/SmartRequests/Smart')));
const SmartItem = LazyLoadComponent(lazy(()=> import('./Services/SmartRequests/Item/SmartItem')));

const ProjectHub = LazyLoadComponent(lazy(() => import('./Services/Project/ProjectHub/ProjectHub')));

const ProjectList = LazyLoadComponent(lazy(() => import('./Services/Project/ProjectList/ProjectList')));

const ProjectContact = LazyLoadComponent(lazy(() => import('./Services/Project/ProjectContact/ProjectContact')));

const ProjectView = LazyLoadComponent(lazy(() => import('./Services/Project/ProjectView/ProjectView')));

const Wallet = LazyLoadComponent(lazy(() => import('./Services/Wallet/Wallet/Wallet')));

const WalletDeposit = LazyLoadComponent(lazy(() => import('./Services/Wallet/WalletDeposit/WalletDeposit')));

const WalletDepositInfo = LazyLoadComponent(lazy(() => import('./Services/Wallet/WalletDeposit/WalletDepositInfo')));

const WalletBaseStakingContract = LazyLoadComponent(lazy(() => import('./Services/Wallet/WalletBaseStaking/WalletBaseStakingContract')));

const WalletBaseStakingDeposit = LazyLoadComponent(lazy(() => import('./Services/Wallet/WalletBaseStaking/WalletBaseStakingDeposit/WalletBaseStakingDeposit')));

const WalletWithdraw = LazyLoadComponent(lazy(() => import('./Services/Wallet/WalletWithdraw/WalletWithdraw')));

const ProjectSelectType = LazyLoadComponent(lazy(() => import('./Services/Project/ProjectSelectType/ProjectSelectType')));

export {
    LazyLoadComponent,
    Login,
    Register,
    RegisterSuccess,
    RegisterConfirm,
    Forgot,
    ForgotMessage,
    ForgotChangePassword,
    Logout,
    AuthSocial,
    MyProfile,
    ProfileEdit,
    PropertyList,
    PropertyView,
    PropertyForm,
    ComplexForm,
    ComplexList,
    ComplexView,
    CommunityList,
    CommunityView,
    Messenger,
    TicketList,
    TicketItem,
    TicketAdd,
    SplitCommissionAdd,
    Articles,
    ArticleView,
    Contact,
    Error,
    ComingSoon,
    FAQ,
    Referrals,
    Smart,
    SmartItem,
    ProjectList,
    ProjectHub,
    ProjectContact,
    ProjectView,
    ProjectSelectType,
    Wallet,
    WalletDeposit,
    WalletDepositInfo,
    WalletBaseStakingContract,
    WalletBaseStakingDeposit,
    WalletWithdraw,
};
