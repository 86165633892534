import React, {useState} from 'react';
import {Box, Button, Checkbox, Grid, Stack, TextField, Typography} from '@mui/material';
import {AddData, PhoneInput} from "components";
import {GremService} from "services";
import {snackActions} from "utils/SnackBarUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import globalDictionary from "assets/translations/globalDictionary";

const PlatformServicesModal = ({toggleModal, services: initServices}) => {
    const [serviceForm, setServiceForm] = useState({
        name: '',
        phone: '',
        description: '',
        services: initServices?.length > 0 ? initServices : [
            {
                title: globalDictionary.get('complex_platform_services_modal_request_serviced_3d'),
                isChecked: false,
            },
            {
                title: globalDictionary.get('complex_platform_services_modal_request_serviced_promotion'),
                isChecked: false,
            },
            {
                title: globalDictionary.get('complex_platform_services_modal_request_serviced_landing'),
                isChecked: false,
            }
        ],
        isSuccess: null,
        isSending: false,
    });
    const [serviceFormErrors, setServiceFormErrors] = useState({
        name: false,
        phone: false,
        description: false,
    });
    const gremService = new GremService();
    const form = new AddData(serviceForm, setServiceForm, serviceFormErrors, setServiceFormErrors);

    const isValidForm = () => {
        let isValid = true;
        let errors = serviceFormErrors;
        Object.keys(errors).forEach(key => errors[key] = false);

        if (!serviceForm.name) {
            errors.name = true;
            isValid = false;
        }

        if (!serviceForm.phone) {
            errors.phone = true;
            isValid = false;
        }

        if (!serviceForm.description) {
            errors.description = true;
            isValid = false;
        }

        setServiceFormErrors(errors);
        return isValid;
    }

    const sendServiceForm = async () => {
        try {
            if (!isValidForm()) throw new Error(globalDictionary.get('complex_platform_services_modal_request'));

            setServiceForm({...serviceForm, isSending: true});

            const payload = {
                name: serviceForm.name,
                phone: serviceForm.phone,
                description: serviceForm.description,
                services: serviceForm.services.filter(service => service.isChecked).map(service => service.title).join(', '),
            }

            const result = await gremService.sendForm(payload);
            if(result.code !== 200) throw new Error(globalDictionary.get('complex_platform_services_modal_request_error'));

            setTimeout(() => {
                setServiceForm({...serviceForm, isSuccess: true, isSending: false});
            }, 1000);
        } catch (e) {
            snackActions.error(e.message || globalDictionary.get('complex_platform_services_modal_request_error'));
            setTimeout(() => {
                setServiceForm({...serviceForm, isSending: false});
            }, 1000);
        }
    }

    const SuccessMessage = () => {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '50px',
                mt: '20px'
            }}>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '130%',
                    color: 'black',
                    textAlign: 'center'
                }}>
                    {globalDictionary.get('complex_platform_services_modal_request_success')}
                </Typography>
                <Button onClick={toggleModal} variant={'contained'} fullWidth>
                    {globalDictionary.get('close')}
                </Button>
            </Box>
        );
    }

    if (serviceForm.isSuccess) return <SuccessMessage/>;

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
        }}>
            <Grid container sx={{
                mt: '5px'
            }} spacing={'15px'}>
                <Grid item xs={12}>
                    <TextField
                        placeholder={globalDictionary.get('complex_platform_services_modal_request_field_name')}
                        label={globalDictionary.get('complex_platform_services_modal_request_field_name')}
                        variant="outlined"
                        fullWidth
                        type={'name'}
                        value={form.data.name}
                        name={'name'}
                        onChange={form.handleChangeInput}
                        error={serviceFormErrors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PhoneInput
                        placeholder={globalDictionary.get('complex_platform_services_modal_request_field_phone')}
                        label={globalDictionary.get('complex_platform_services_modal_request_field_phone')}
                        variant="outlined"
                        fullWidth
                        type={'phone'}
                        value={form.data.phone}
                        name={'phone'}
                        onChange={form.handleChangeInput}
                        error={serviceFormErrors.phone}
                        onChange={(phone) => setServiceForm({...serviceForm, phone})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        placeholder={globalDictionary.get('complex_platform_services_modal_request_field_description')}
                        label={globalDictionary.get('complex_platform_services_modal_request_field_description')}
                        variant="outlined"
                        fullWidth
                        type={'description'}
                        value={form.data.description}
                        name={'description'}
                        onChange={form.handleChangeInput}
                        error={serviceFormErrors.description}
                    />
                </Grid>
                <>
                    {
                        form.data.services.map((service, index) => (
                            <Grid item xs={12}>
                                <Stack
                                    key={`platform-services-item-${index}`}
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    onClick={() => {
                                        form.data.services[index].isChecked = !service.isChecked;
                                        setServiceForm({...form.data});
                                    }}
                                >
                                    <Checkbox checked={service.isChecked}/>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        lineHeight: '150%',
                                        color: service.isChecked ? '#021228' : '#848484',
                                    }}>
                                        {service.title}
                                    </Typography>
                                </Stack>
                            </Grid>
                        ))
                    }
                </>
                <Grid item xs={12} sx={{
                    mt: '5px'
                }}>
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        onClick={sendServiceForm}
                        loading={serviceForm.isSending}
                    >
                        {globalDictionary.get('send')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PlatformServicesModal;
