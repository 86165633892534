import React from 'react';
import {Box} from "@mui/material";

function CustomAlert({severity = 'error', children, sx}) {
    const lineColor = severity === 'error' ? '#E5494D' : '#00FF00';

    return (
        <Box sx={{
            width: '100%',
            padding: '20px',
            borderRadius: '5px',
            backgroundColor: '#F2F3F4',
            display: 'flex',
            gap: '20px',
            ...sx,
        }}>
            <Box sx={{
                minWidth: '2px',
                height: 'auto',
                backgroundColor: lineColor
            }}></Box>
            {children}
        </Box>
    );
}

export default CustomAlert;
