import {observer} from "mobx-react-lite";
import ImageConfigs from "../../../assets/configs/image.configs";
import React, {Fragment, useEffect, useState} from "react";
import {Box, Button, FormHelperText, Grid, IconButton, Typography} from "@mui/material";
import globalDictionary from "assets/translations/globalDictionary";
import {FileUploader} from "react-drag-drop-files";
import ImageCircle from "../PhotosLoader/image_circle.svg";
import TrashButton from "../PhotosLoader/trash_button.svg";
import {ImageChecker} from "../../index";
import DocumentDropzone from "./document.dropzone";
import ImageDropzone from "./image.dropzone";

const {REACT_APP_FILE_URL} = process.env

const FileLoader = observer((
    {
        _data,
        _setData,
        error = false,
        helperText = '',
        dropzone = true,
        documentDropzone = false,
        fileCount = null,
        customButton,
        onDelete,
        configs,
        children,
        customHandleChange
    }) => {

    const imageConfigs = ImageConfigs.get(configs.confName, configs.field)
    const config = configs ? {...imageConfigs, ...configs} : ImageConfigs.defaultConfigs;
    const calcFileCount = () => {
        const fieldValue = _.get(_data, config.field);

        if (Array.isArray(fieldValue)) {
            return fieldValue.length;
        } else if (fieldValue && typeof fieldValue === 'object' && fieldValue.url && fieldValue.url !=='') {
            return 1;
        } else if (typeof fieldValue === 'string' && fieldValue !== '') {
            return 1;
        } else {
            return 0;
        }
    };

    const [uploadedFileCount, setUploadedFileCount] = useState(fileCount ? fileCount : calcFileCount());

    useEffect(() => {
        if (fileCount || fileCount ===0) {
            setUploadedFileCount(fileCount)
        } else {
            setUploadedFileCount(calcFileCount())
        }
    }, [_data])

    const handleChange = (file) => {
        if (config.maxCount > 1) {
            let currentData = _.get(_data, config.field);
            const files = Array.from(file).slice(0, config.maxCount - uploadedFileCount);
            const listFiles = files.map((file) => {
                return {
                    url: file,
                    isMain: false
                };
            });

            const updatedFiles = _.concat(currentData || [], listFiles);

            setUploadedFileCount((prevCount) => prevCount + files.length);

            if (updatedFiles.filter(item => item.isMain === true).length === 0) {
                _.set(updatedFiles, `[0].isMain`, true);
            }

            if (customHandleChange) {
                customHandleChange({ ..._data, [config.field]: updatedFiles });
            } else {
                _.set(_data, config.field, updatedFiles);
            }

        } else if (config.maxCount === 1) {
            let updatedFile = { url: file, isMain: true };

            if (Array.isArray(_data[config.field])) {
                const updatedFiles = _.concat(_data[config.field], updatedFile);

                if (customHandleChange) {
                    customHandleChange({ ..._data, [config.field]: updatedFiles });
                } else {
                    _.set(_data, config.field, updatedFiles);
                }
            } else {
                if (customHandleChange) {
                    customHandleChange({ ..._data, [config.field]: updatedFile });
                } else {
                    _.set(_data, config.field, updatedFile);
                }
            }

            setUploadedFileCount(prevCount => prevCount + 1);
        }
    };

    const handleChangeDropzone = (file) => {

        if (config.maxCount > 1) {
            let currentData = _.get(_data, config.field);
            const files = Array.from(file).slice(0, config.maxCount - uploadedFileCount);
            const listFiles = files.map((file) => {
                return {
                    url: file,
                    isMain: false
                };
            });
            setUploadedFileCount((prevCount) => prevCount + files.length);
            _setData(prevData => {
                const newData = _.set(_.cloneDeep(prevData), config.field, _.concat(currentData || [], listFiles));

                console.log(currentData,newData)
                if (_.get(newData, config.field).filter(item => item.isMain === true).length === 0) {
                    newData[config.field][0].isMain = true;
                }
                return newData;
            });
        }
        console.log(config,_data)
            if (config.maxCount === 1) {
                if(Array.isArray(_data[config.field])){
                    _setData(prevData => {
                        const newData = _.cloneDeep(prevData);
                        if (Array.isArray(newData[config.field])) {
                            newData[config.field] = [{url: file, isMain: true}];
                        }
                        return newData;
                    })
                }else {
                     _setData({[config.field]: {url: file, isMain: true}});
                    setUploadedFileCount((prevCount) => prevCount + 1);
                }
            }
    }

    const deleteFile = (removeIndex) => {
        if (Array.isArray(_.get(_data,config.field))) {
            //_.remove(fruits, (fruit, index) => index === removeIndex);
            _.set(_data,config.field, _.filter(_.get(_data,config.field),(item,index) => index !== removeIndex))
            // _setData(prevData => ({
            //     ...prevData,
            //     [config.field]: prevData[config.field].filter((item, index) => index !== removeIndex),
            // }));
        } else {
            _.set(_data, removeIndex, '')
        }
        setUploadedFileCount((prevCount) => prevCount - 1);
    }

    const deleteDropzoneFile = (removeIndex) => {
        _setData(prevData => {
            if (Array.isArray(_.get(prevData, config.field))) {
                const filteredFiles = _.filter(_.get(prevData, config.field), (item, index) => index !== removeIndex);
                return _.set(_.cloneDeep(prevData), config.field, filteredFiles);
            } else {
                return _.set(prevData, config.field, '');
            }
        });
        setUploadedFileCount(prevCount => prevCount - 1);
        if (onDelete) {
            onDelete()
        }
    };

    const downloadFile = () => {

        const fileUrl = _.get(_data, config.openFilePath ? config.openFilePath :config.field);
        console.log(fileUrl,config.openFilePath,_data)
        if (Array.isArray(fileUrl) && typeof fileUrl[0].url === 'object') {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(fileUrl[0].url);
            link.download = fileUrl[0].url.name;
            link.click();
        } else if (typeof fileUrl === 'object' && typeof fileUrl?.url === 'object') {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(fileUrl?.url ? fileUrl.url : fileUrl);
            link.download = fileUrl?.url?.name ? fileUrl.url.name : fileUrl.name;
            link.click();
        } else if (typeof fileUrl === 'object' && typeof fileUrl?.url === 'string'){
            window.open(`${REACT_APP_FILE_URL}${fileUrl.url}`, '_blank');
        } else{
            window.open(`${REACT_APP_FILE_URL}${fileUrl[0].url}`, '_blank');
        }
    };

    const setMain = (index) => {
        const newData = _.get(_data, config.field).map((image, i) => {
            if (i === index) {
                return {
                    ...image,
                    isMain: true
                };
            } else {
                return {
                    ...image,
                    isMain: false
                };
            }
        })
        _.set(_data, config.field, newData)
        // _setData(prevData =>({
        //     ...prevData,
        //     [config.field]: newData
        // }))
    }
    const setDropzoneMain = (index) => {
        _setData(prevData => {
            const newData = _.get(prevData, config.field).map((image, i) => {
                if (i === index) {
                    return {...image, isMain: true};
                } else {
                    return {...image, isMain: false};
                }
            });
            return _.set(_.cloneDeep(prevData), config.field, newData);
        });
    };

    const showImages = () => {
        let images = _.get(_data, config.field)
        if (Array.isArray(images) && images.length > 0) {
            return images.map((photo, index) => (
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        '&:hover > *': {
                            visibility: 'visible'
                        }
                    }}
                    gridRow
                >
                    <IconButton
                        color="primary"
                        sx={{
                            top: 10,
                            left: 165,
                            zIndex: 1000,
                            visibility: 'hidden',
                            cursor: 'pointer'
                        }}
                        onClick={() => deleteFile(index)}
                        size="large"
                    >
                        <img style={{position: 'absolute',}} src={TrashButton}/>
                    </IconButton>
                    <ImageChecker src={photo.url} style={{objectFit: 'contain', display: 'block'}} height={120}
                                  width={180}/>

                    {photo.isMain ? (
                        <Box
                            sx={{
                                backgroundColor: 'rgba(2, 18, 40, 0.15)',
                                borderColor: 'black',
                                borderRadius: '5px',
                                textAlign: 'center',
                                justifyItems: 'center',
                                display: 'grid',
                                color: 'black',
                                minHeight: '20px',
                                height: '20px',
                                mt: '5px',
                            }}
                        >
                            <Typography variant={'caption'}>
                                {globalDictionary.get('photos_loader_general')}
                            </Typography>
                        </Box>
                    ) : (
                        <Button
                            sx={{
                                visibility: 'hidden',
                                bgcolor: 'white',
                                borderColor: 'black',
                                color: 'black',
                                minHeight: '20px',
                                height: '20px',
                                mt: '5px',
                                "&:hover": {
                                    borderColor: 'black',
                                    bgcolor: 'white',
                                    color: 'black'
                                },


                            }}
                            fullWidth
                            variant={'outlined'}
                            textAlign={'center'}
                            onClick={() => {
                                setMain(index)
                            }}
                        >
                            <Typography variant={'caption'}>
                                {globalDictionary.get('photos_loader_set_general')}
                            </Typography>
                        </Button>
                    )}
                </Grid>
            ));
        }
    };
    return (
        <Box sx={{
            color: error ? 'red' : 'black',
            border: error ? '1px solid red' : 'none',
            padding: '5px',
            borderRadius: '5px'
        }}>
            {(config.titleKey || config.titleSubKey || config.title || config.titleSub) &&
                <Grid container>
                    <Grid item xs={12}>
                        {(config.titleKey || config.title) && (<Typography sx={{
                            fontSize: '16px',
                            fontWeight: '700'
                        }}>
                            {config.titleKey ? globalDictionary.get(`${config.titleKey}`) : config.title}
                        </Typography>)
                        }
                    </Grid>
                    {(config.titleSubKey||config.titleSub) &&
                        <Grid item xs={12} sx={{marginTop: '5px'}}>
                            {(config.titleSubKey || config.titleSub) &&
                                (<Typography sx={{
                                    fontSize: '14px',
                                    fontWeight: '400'
                                }}>
                                    {config.titleSubKey ? globalDictionary.get(`${config.titleSubKey}`) : config.titleSub}
                                </Typography>)
                            }
                        </Grid>
                    }
                </Grid>
            }
            {!documentDropzone && config.maxCount !== uploadedFileCount &&
                <Grid container sx={{marginTop: '20px'}}>
                    <FileUploader
                        disabled={uploadedFileCount >= config.maxCount}
                        onClick={handleChange}
                        maxSize={config.size}
                        multiple={config.maxCount > 1}
                        handleChange={handleChange}
                        name="file"
                        types={['JPG', 'JPEG', 'PNG', 'WEBP']}
                    >
                        {!!customButton ?
                            customButton
                            :
                            <Button variant={'contained'}>
                                {config.buttonIcon ? config.buttonIcon :
                                    <img src={ImageCircle}/>
                                }
                                <Typography
                                    ml={'10px'}
                                    sx={{
                                        fontSize: '14px'
                                    }}
                                >
                                    {config.buttonText ? config.buttonText : globalDictionary.get(config.buttonKey ? config.buttonKey : 'photos_loader_upload')}
                                </Typography>
                            </Button>
                        }
                    </FileUploader>
                </Grid>
            }
            {!documentDropzone &&
                <Grid container columnGap={2}>
                    {showImages()}
                </Grid>
            }
            {children &&
                <Grid container marginBottom={'20px'}>
                    {children}
                </Grid>
            }
            <Grid container>
                {dropzone && !documentDropzone && (
                    <ImageDropzone
                        config={config}
                        uploadedFileCount={uploadedFileCount}
                        handleChange={handleChange}
                    />
                )}
                {dropzone && documentDropzone && (
                    <DocumentDropzone
                        config={config}
                        uploadedFileCount={uploadedFileCount}
                        handleChange={handleChangeDropzone}
                        deleteFile={deleteDropzoneFile}
                        downloadFile={downloadFile}
                    />
                )}
            </Grid>
        </Box>
    )

})

export default FileLoader;
