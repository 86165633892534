export const languages = [
    { name: "Spanish", nativeName: "Español" },
    { name: "English", nativeName: "English" },
    { name: "Russian", nativeName: "Русский" },
    { name: "Chinese", nativeName: "中文" },
    { name: "French", nativeName: "Français" },
    { name: "Portuguese", nativeName: "Português" },
    { name: "Arabic", nativeName: "العربية" },


    // { name: "Hindi", nativeName: "हिन्दी" },
    // { name: "Bengali", nativeName: "বাংলা" },
    // { name: "Japanese", nativeName: "日本語" },
    // { name: "Western Punjabi", nativeName: "پنجابی" },
    // { name: "Marathi", nativeName: "मराठी" },
    // { name: "Telugu", nativeName: "తెలుగు" },
    // { name: "Turkish", nativeName: "Türkçe" },
    // { name: "Korean", nativeName: "한국어" },
    // { name: "German", nativeName: "Deutsch" },
    // { name: "Vietnamese", nativeName: "Tiếng Việt" },
    // { name: "Tamil", nativeName: "தமிழ்" },
    // { name: "Urdu", nativeName: "اُردُو" },
    // { name: "Italian", nativeName: "Italiano" },
    // { name: "Indonesian", nativeName: "Bahasa Indonesia" },
    // { name: "Polish", nativeName: "Polski" },
    // { name: "Ukrainian", nativeName: "Українська" },
    // { name: "Greek", nativeName: "Ελληνικά" },
    // { name: "Czech", nativeName: "Čeština" },
    // { name: "Swedish", nativeName: "Svenska" },
    // { name: "Danish", nativeName: "Dansk" },
    // { name: "Finnish", nativeName: "Suomi" },
    // { name: "Norwegian", nativeName: "Norsk" },
    // { name: "Hungarian", nativeName: "Magyar" },
    // { name: "Haitian Creole", nativeName: "Kreyòl Ayisyen" },
    // { name: "Swahili", nativeName: "Kiswahili" },
    // { name: "Filipino", nativeName: "Filipino" },
    // { name: "Romanian", nativeName: "Română" },
    // { name: "Dutch", nativeName: "Nederlands" },
    // { name: "Kazakh", nativeName: "Қазақша" },
    // { name: "Swahili", nativeName: "Kiswahili" },
    // { name: "Swahili", nativeName: "Kiswahili" },
    // { name: "Catalan", nativeName: "Català" },
    // { name: "Serbian", nativeName: "Српски" },
    // { name: "Slovak", nativeName: "Slovenčina" },
    // { name: "Lithuanian", nativeName: "Lietuvių" },
    // { name: "Bulgarian", nativeName: "Български" },
    // { name: "Georgian", nativeName: "ქართული" },
    // { name: "Latvian", nativeName: "Latviešu" },
    // { name: "Armenian", nativeName: "Հայերեն" },
    // { name: "Albanian", nativeName: "Shqip" },
    // { name: "Mongolian", nativeName: "Монгол хэл" },
    // { name: "Finnish", nativeName: "Suomi" },
    // { name: "Tajik", nativeName: "тоҷикӣ" },
    // { name: "Sinhala", nativeName: "සිංහල" },
];

