import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getUserToken } from './index';
import { useContext } from 'react';
import {StoreContext, useStore} from '../Providers';
import { History } from './NavigationHistoryUtils';
import {observer} from "mobx-react-lite";
import {Box, CircularProgress} from "@mui/material";

export const ProtectedRoute = observer(({children}) => {
    const {auth} = useStore();
    if (auth.loading) {
        return(
        <Box
        sx={{
          display:'flex',
          width:'100vw',
          height:'100vh',
          justifyContent:'center',
          alignItems:'center'
        }}
        >
        <CircularProgress/>
        </Box>
          )
    } else {
        const userToken = getUserToken();
        if (!userToken) return <Navigate to="/auth/login" replace/>;
        return children;
    }
});

export const CommunityProtectedRoute = observer(({children}) => {
    const navigate = useNavigate();
    const {auth} = useStore();
    if (auth.loading) {
        return(
        <Box
            sx={{
              display:'flex',
              width:'100vw',
              height:'100vh',
              justifyContent:'center',
              alignItems:'center'
            }}
        >
          <CircularProgress/>
        </Box>
    )
    } else {
        const {isCommunity, confirm} = auth.user?.flags;
        if (!isCommunity) {
            History.navigate('/my/profile/verification');
        }else if(!confirm) {
            History.navigate('/my/profile');
        }
        return children;
    }
});

export const NoProtectedRoute = observer(({children}) => {
    const {auth} = useStore();
    if (auth.loading) {
        return (
        <Box
            sx={{
              display:'flex',
              width:'100vw',
              height:'100vh',
              justifyContent:'center',
              alignItems:'center'
            }}
        >
          <CircularProgress/>
        </Box>
    )
    } else {
        if (auth?.user?.flags?.confirm) {
            return <Navigate to="/my/profile" replace/>;
        } else {
            return children;
        }
    }
});
