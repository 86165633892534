
import circleInformationBlackIcon from "assets/icons/circle-information-black.svg";
import circleInformationIcon from "assets/icons/circle-information.svg";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {tooltipClasses, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import Tooltip from "@mui/material/Tooltip";

const InfoIcon = observer(({infoText,color}) => {

    let src = '';

    switch (color) {
        case 'primary':{
            src = circleInformationIcon
            break
        }
        default: {
            src = circleInformationBlackIcon
        }
    }

    return(

    <Tooltip
        title={
            <Typography
                component={'p'}
                sx={{
                    borderRadius: '5px',
                    // backgroundColor: '#F2EEE5', // Фон тултипа
                    // color: '#000000', // Цвет текста
                    padding: '10px 20px',
                    minWidth: '130px',
                    maxWidth: '300px',
                    fontSize: '12px',
                    fontWeight: 400,
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    textAlign: 'center',
                    lineHeight: '150%',
                }}
            >
                {infoText}
            </Typography>
        }
        placement="top"
        arrow
        componentsProps={{
            tooltip: {
                sx: {
                    color: "#000000",
                    // Override MUI defaults
                    backgroundColor: '#F2EEE5',
                    [`& .${tooltipClasses.arrow}`]: {
                        color: "#F2EEE5",
                    },
                },
            },
        }}
    >
        <IconButton>
            <img src={src} alt="info" />
        </IconButton>
    </Tooltip>

)
})

export default InfoIcon;