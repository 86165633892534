import React from 'react';
import style from './AuthLayout.module.css';
import {Outlet} from 'react-router-dom';
import Header from './components/Header/Header';
import imageBG from 'assets/images/auth/layout_bg.png';
import Footer from './components/Footer/Footer';
import {observer} from "mobx-react-lite";

const AuthLayout = observer(() => {

    const backgroundStyle = {
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '130px center',
        backgroundImage: `url(${imageBG})`,
    };

    return (
        <div className={style.layout} style={backgroundStyle}>
            <Header/>
            <div className={style.content}>
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
});

export default AuthLayout;
