import React, {useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem, Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {AddData, CustomAlert, ImageChecker, PhoneInput} from "components";
import telegramIcon from 'assets/icons/telegram.svg';
import whatsappIcon from 'assets/icons/whatsapp.svg';
import viberIcon from 'assets/icons/viber.svg';
import {snackActions} from "utils/SnackBarUtils";
import LeadService from "services/LeadService";
import countries from "assets/translations/countries";
import {getActiveLanguageFromLS, getFormattedLocation, getMainPhoto} from "utils";
import {LoadingButton} from "@mui/lab";
import Image from "mui-image";
import globalDictionary from "assets/translations/globalDictionary";
import {History} from "../../../utils/NavigationHistoryUtils";

const messengers = [
    {
        label: 'Telegram',
        value: 'telegram',
        img: telegramIcon
    },
    {
        label: 'Whatsapp',
        value: 'whatsapp',
        img: whatsappIcon
    },
    {
        label: 'Viber',
        value: 'viber',
        img: viberIcon
    }
];

const SendLeadModal = ({contractId, realEstateObject, toggleModal}) => {
    const [avaliableObjects, setAvaliableObjects] = useState([]);
    const [filers, setFilters] = useState({
        type: 'complex'
    });
    const [selectedObject, setSelectedObject] = useState(realEstateObject);
    const [leadForm, setLeadForm] = useState({
        complexName: selectedObject?.name,
        clientName: '',
        clientPhone: '',
        clientEmail: '',
        clientBudget: '',
        clientCountry: '',
        clientDescription: '',
        clientMessenger: '',
        isSuccess: null,
        isSending: false,
    });
    const [leadFormErrors, setLeadFormErrors] = useState({
        clientName: false,
        clientPhone: false,
        clientBudget: false,
    });
    const language = getActiveLanguageFromLS();
    const leadService = new LeadService();
    const form = new AddData(leadForm, setLeadForm, leadFormErrors, setLeadFormErrors);

    useEffect(() => {
        if (!contractId) getObjects();
    }, [filers]);

    async function getObjects() {
        const result = await leadService.getObjects(filers);
        setAvaliableObjects(result.data);
    }

    const isValidForm = () => {
        let isValid = true;
        let errors = leadFormErrors;
        Object.keys(errors).forEach(key => errors[key] = false);

        if (!leadForm.clientName) {
            errors.clientName = true;
            isValid = false;
        }

        if (!leadForm.clientPhone) {
            errors.clientPhone = true;
            isValid = false;
        }

        if (!leadForm.clientBudget) {
            errors.clientBudget = true;
            isValid = false;
        }

        if (!contractId && !selectedObject) {
            snackActions.error('Select real estate object');
            isValid = false;
        }

        setLeadFormErrors(errors);
        return isValid;
    }

    const sendLeadForm = async () => {
        try {
            if (!isValidForm()) return snackActions.error('Fill in all required fields');

            setLeadForm({...leadForm, isSending: true});

            const payload = {
                building_id: selectedObject?.targetId,
                customerName: leadForm.clientName || '',
                customerContact: {
                    phone: leadForm.clientPhone,
                    email: leadForm.clientEmail,
                },
                customerBudget: leadForm.clientBudget,
                customerCountry: leadForm.clientCountry,
                customerMessenger: leadForm.clientMessenger,
                brokerComments: leadForm.clientDescription,
            }

            const objectContractId = selectedObject.contractId || contractId;
            const result = await leadService.createLead(objectContractId, payload);

            if (result.code !== 200) {
                if (result?.errors && result?.errors[0].code === 409999) {
                    History.navigate('/my/profile/commission-agreement');
                    return;
                }

                throw new Error('Failed to send lead');
            }

            snackActions.success('Lead sent successfully');
            toggleModal();
        } catch (e) {
            console.error('Failed to send lead: ', e);
            snackActions.error('Failed to send lead');
        } finally {
            setTimeout(() => setLeadForm({...leadForm, isSending: false}), 1000);
        }
    }

    const toggleType = (e) => {
        setFilters({...filers, type: e.target.value});
    }

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
        }}>
            <Grid
                container
                mt={'5px'}
                spacing={'15px'}
            >
                {
                    !contractId && (
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-labelledby="subjectOfLaw"
                                name="subjectOfLaw"
                                value={filers.type}
                                onChange={toggleType}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    gap: '20px'
                                }}
                            >
                                <FormControlLabel
                                    value="complex"
                                    control={<Radio/>}
                                    label={globalDictionary.get('lead_form_type_complex')}
                                />
                                <FormControlLabel
                                    value="property"
                                    control={<Radio/>}
                                    label={globalDictionary.get('lead_form_type_property')}
                                />
                            </RadioGroup>
                        </Grid>
                    )
                }
                {
                    !contractId && (
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id={'real-estate-objects'}>
                                    {globalDictionary.get('lead_form_input_object')}
                                </InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId={'real-estate-objects'}
                                    id="real-estate-objects-select"
                                    label={globalDictionary.get('lead_form_input_object')}
                                    disabled={!!contractId}
                                    fullWidth
                                    onChange={(e) => {
                                        setSelectedObject(avaliableObjects.find(obj => obj.targetId === e.target.value))


                                    }}
                                >
                                    {
                                        avaliableObjects.length > 0 ? (
                                            avaliableObjects.map((object) => (
                                                <MenuItem key={`target-${object.targetId}`} value={object.targetId}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '16px'
                                                    }}>
                                                        <ImageChecker
                                                            src={getMainPhoto(object.photo)?.url}
                                                            alt={object.name}
                                                            width={'35px'}
                                                            height={'35px'}
                                                            sx={{
                                                                borderRadius: '5px'
                                                            }}
                                                        />
                                                        <Box>
                                                            <Typography sx={{
                                                                lineHeight: '150%',
                                                                fontWeight: '700'
                                                            }}>
                                                                {object.name}
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontSize: '10px',
                                                                lineHeight: '150%'
                                                            }}>
                                                                {getFormattedLocation(object.address)}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled={true}>
                                                <Typography>
                                                    {globalDictionary.get('requests_list_not_found')}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    )
                }
                <Grid item xs={12} sm={6}>
                    <TextField
                        //placeholder={globalDictionary.get('lead_form_input_user')}
                        label={globalDictionary.get('lead_form_input_user')}
                        variant="outlined"
                        fullWidth
                        type={'string'}
                        value={form.data.clientName}
                        name={'clientName'}
                        onChange={form.handleChangeInput}
                        required
                        error={leadFormErrors.clientName}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PhoneInput
                        //placeholder={globalDictionary.get('lead_form_input_phone')}
                        label={globalDictionary.get('lead_form_input_phone')}
                        variant="outlined"
                        fullWidth
                        type={'phone'}
                        value={form.data.clientPhone}
                        name={'clientPhone'}
                        onChange={(phone) => setLeadForm({...leadForm, clientPhone: phone})}
                        required
                        error={leadFormErrors.clientPhone}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                       // placeholder={globalDictionary.get('lead_form_input_email')}
                        label={globalDictionary.get('lead_form_input_email')}
                        variant="outlined"
                        fullWidth
                        type={'email'}
                        value={form.data.clientEmail}
                        name={'clientEmail'}
                        onChange={form.handleChangeInput}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        //placeholder={globalDictionary.get('lead_form_input_budget')}
                        label={globalDictionary.get('lead_form_input_budget')}
                        variant="outlined"
                        fullWidth
                        type={'number'}
                        value={form.data.clientBudget}
                        name={'clientBudget'}
                        onChange={form.handleChangeInput}
                        required
                        error={leadFormErrors.clientBudget}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-country-label">
                            {globalDictionary.get('lead_form_input_country')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label={globalDictionary.get('lead_form_input_country')}
                            labelId="select-country-label"
                            value={form.data.clientCountry}
                            name={'clientCountry'}
                            onChange={form.handleChangeInput}
                        >
                            {countries.getAllValueLabel(language).map((country, index) => (
                                <MenuItem key={`${country.value}-${index}`} value={country.value}>
                                    {country.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="client-messenger">
                            {globalDictionary.get('lead_form_input_messenger')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label={globalDictionary.get('lead_form_input_messenger')}
                            labelId="client-messenger"
                            value={form.data.clientMessenger}
                            name={'clientMessenger'}
                            onChange={form.handleChangeInput}
                        >
                            {
                                messengers.map((messenger) => (
                                    <MenuItem value={messenger.value} key={messenger.value}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}>
                                            <img src={messenger.img}/>
                                            {messenger.label}
                                        </Box>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        //placeholder={globalDictionary.get('lead_form_input_notes')}
                        label={globalDictionary.get('lead_form_input_notes')}
                        variant="outlined"
                        fullWidth
                        type={'string'}
                        value={form.data.clientDescription}
                        name={'clientDescription'}
                        onChange={form.handleChangeInput}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        loading={leadForm.isSending}
                        variant="contained"
                        fullWidth
                        onClick={sendLeadForm}
                        disabled={avaliableObjects.length === 0 && !contractId}
                    >
                        {globalDictionary.get('lead_form_send_lead')}
                    </LoadingButton>
                </Grid>
                <Grid item xs={12}>
                    <CustomAlert severity={'error'} sx={{
                        mt: '10px'
                    }}>
                        <Typography sx={{
                            fontSize: '12px',
                            color: 'black',
                            lineHeight: '130%'
                        }}>
                            {globalDictionary.get('lead_form_send_lead_info_1')}<Typography component={'span'} sx={{
                            fontSize: '12px',
                            color: '#C5A465',
                            lineHeight: '130%'
                        }}>{globalDictionary.get('lead_form_send_lead_info_2')} </Typography>
                            {globalDictionary.get('lead_form_send_lead_info_3')}
                        </Typography>
                    </CustomAlert>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SendLeadModal;
