import React, {useEffect, useState} from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AiIcon from 'assets/images/openai.webp';
import AiIconGrey from 'assets/images/openaigrey.png';
import { GremService } from 'services';
import {observer} from "mobx-react-lite";

const AIInput = observer(({
  placeholder,
  label,
  value: initialValue,
  name,
  onChange,
  error,
    disabled=false,
  helperText,
  maxTextLength,
}) => {
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const gremService = new GremService();
  const isButtonDisabled = value?.length < 10;
    useEffect(()=>{
        setValue(initialValue)
    },[initialValue])
  const handleAIButtonClick = async () => {
    setIsLoading(true);
    try {
      const response = await gremService.getAIDescription({ text: value });
      if (response && response.code === 200 && response.data) {
        const aiGeneratedText = response.data;
        setValue(aiGeneratedText);
        if (onChange) {
          onChange({ target: { name: 'aiInput', value: aiGeneratedText } });
        }
      } else {
        console.error('Ошибка API:', response.message || 'Отсутствует сообщение об ошибке');
      }
    } catch (error) {
      console.error('Ошибка при генерации текста AI:', error);
    }
    setIsLoading(false);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (!onChange) return;

    if (value.length <= maxTextLength) {
      setValue(value);
      onChange(event);
    } else {
      setValue(value.slice(0, maxTextLength));
      onChange({ target: { name, value: value.slice(0, maxTextLength) } });
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <TextField
        placeholder={placeholder}
        label={label}
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        value={value}
        name={name}
        disabled={disabled}
        onChange={handleInputChange}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  padding: '1px 10px',
                }}
              >
                {isLoading ? (
                  <CircularProgress size="24px" sx={{ marginRight: '10px' }} />
                ) : (
                  <Tooltip
                    title="Generate unique text with AI based on your input data"
                    placement="left"
                  >
                    <IconButton
                      sx={{
                        color: isButtonDisabled ? 'grey' : 'inherit',
                        '.Mui-disabled': {
                          color: 'grey',
                        },
                      }}
                      disabled={isButtonDisabled}
                      onClick={handleAIButtonClick}
                    >
                      <img
                        src={isButtonDisabled ? AiIconGrey : AiIcon}
                        alt="Ai Icon"
                        style={{ width: '24px', height: '24px' }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {maxTextLength && (
                  <Typography variant="caption" color="textSecondary">
                    {value?.length || 0} / {maxTextLength}
                  </Typography>
                )}
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
});

export default AIInput;
