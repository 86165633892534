import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from 'components/index';
import {Container, Grid, Typography, Box} from '@mui/material';
import backLeftIcon from 'assets/icons/back-left.svg';
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

function Page({
                  children,
                  title,
                  header,
                  headerSx = {
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                  },
                  underHeader,
                  containerStyle,
                  containerSx,
                  showBackButton = false,
              }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isFirstPage = window.history.length <= 2;

    const goBack = () => {
        if (isFirstPage) navigate('/profile/info');
        window.history.back();
    }

    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {title && <Grid
                    container
                    sx={{
                        maxWidth: '1250px',
                        margin: '30px auto 0',
                    }}
                >
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '20px',
                    }}>
                        {
                            showBackButton && (
                                <Box sx={{
                                    width: '30px',
                                    height: '30px',
                                    cursor: 'pointer',
                                    transition: 'opacity 0.1s',
                                    '&:hover': {
                                        opacity: 0.7,
                                    }
                                }}
                                     onClick={() => goBack()}
                                >
                                    <img src={backLeftIcon} alt={'back'}/>
                                </Box>
                            )
                        }
                        <Typography variant="h5" component="h1" sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        sx={{
                            ...headerSx,
                            [theme.breakpoints.down('sm')]: {
                                mt: 1,
                                '& > *': {
                                    flex: 1,
                                }
                            }
                        }}
                    >
                        {header}
                    </Grid>
                    {underHeader &&
                        <Grid container>
                            {underHeader}
                        </Grid>
                    }
                </Grid>
                }
                <Container style={{height: '100%', ...containerStyle}} sx={{...containerSx}}>{children}</Container>
            </ErrorBoundary>
        </>
    );
}

export default Page;
