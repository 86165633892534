import {GremService} from "services";
import CommunityStep, {ContactBlock} from "../VerificationSteps/CommunityStep";
import FormStore, {FormContextProvider} from "../../../../../components/shared/DraftForm/FormStore";
import {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {Page} from "../../../../../components";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {snackActions} from "../../../../../utils/SnackBarUtils";
import {useStore} from "../../../../../Providers";
import {observer} from "mobx-react-lite";
import globalDictionary from "assets/translations/globalDictionary";
import { History } from 'utils/NavigationHistoryUtils';
import UserStep from "../VerificationSteps/UserStep";
import VerificationModal from "../VerificationSteps/VerificationModal";

const EditProfile = observer(()=>{

    const {auth} = useStore()
    const gremService = new GremService();
    const [loading,setLoading] = useState(false);

    const userStore = new FormStore({
        fields: {
            fname: auth?.user?.fname || '',
            lname: auth?.user?.lname || '',
            phone: auth?.user?.phone || '',
            email: auth?.user?.email || '',
            contactLanguage: auth?.user?.contactLanguage || [],
            address: {
                country: auth?.user?.address?.country || '',
                city: auth?.user?.address?.city || '',
            },
            avatar: auth?.user?.avatar || '',
        },
        isEdit:true,
    });


    const [communityFields,setCommunityFields] = useState({
        subjectOfLaw: '',//Company
        type: [],
        description: '',
        specialization: '',
        businessCountry: [],
        experience: '',
        license: [],
        education: [],
        associations: [],
        portfolio: [],
        maxDeal: '',
        deals: '',
        investmentCountries: [],
        desiredInvestmentPeriod: '',
        desiredProfitability: '',
        qualifiedSpecialist: false,
        taxResidentUSA: false,
        name: '',
        numberOfCompletedProjects: '',
        foundationYear: '',
        registrationNumber: '',
        country: '',
        city: '',
        biggestProjects: '',
        totalNumberOfConstructedSquareMeters: '',
        managementObjectsValue: '',
        managementObjectsQuantity: '',
        ownTenantBase: false,
        membersNumber: '',
        branchesNumber: '',
        services: '',
        employees: '',
        implementedProjects: '',
        logo: [],
        certificateScan: [],
        clients: '',
        contact: {
            phone: '',
            fname: '',
            lname: '',
            position: '',
            email: '',
        },
    })

    const getCommunityUser = async () => {
        setLoading(true)
        const result = await gremService.getUserCommunity()
        if(result.code === 200){
            setCommunityFields(result.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        if(auth.user.flags.isCommunity) {
            getCommunityUser()
        }
    },[])

    const communityStore = new FormStore({
        fields: communityFields,
        isEdit: true,
    })

    function findAndAppendFilesToFormData(data, formData, currentPath = '') {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                const path = currentPath ? `${currentPath}.${key}` : key;
                if (value instanceof File) {
                    formData.append(path, value);
                } else if (typeof value === 'object') {
                    findAndAppendFilesToFormData(value, formData, path);
                }
            }
        }
    }

    const sendRequest = async () => {
        const formData = new FormData();
        findAndAppendFilesToFormData(communityStore.fields, formData)
        formData.append('data', JSON.stringify(communityStore.fields))
        formData.append('userData', JSON.stringify(userStore.fields))
        formData.append('avatar',userStore.fields.avatar)
        const result = await gremService.updateUser(formData)
        //const result = await gremService.updateCommunity(formData)

        if(result.code === 200){
            auth.user = {...auth.user,...result.data}
            snackActions.success(globalDictionary.get('profile_update_success'))

            History.navigate('/my/profile');
        } else {
            snackActions.error(result.message ? result.message : globalDictionary.get('error_title'))
        }
    }
    return(
        <>
        <Page
            showBackButton={true}
            title={globalDictionary.get('profile_title')}
            containerStyle={{
                backgroundColor:'transparent',
                padding:'0px',
            }}
        >
        {loading ?
            <Box
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                width:'100%',
                height:'300px'
            }}
            >
            <CircularProgress/>
            </Box>
            :
            <FormContextProvider store={communityStore}>
                <FormContextProvider store={userStore}>
                    <Box
                    sx={{
                        backgroundColor:'white',
                        marginTop:'20px',
                        marginBottom:'20px',
                        paddingBottom:'20px',
                        paddingLeft:'20px',
                        borderRadius:'10px'
                    }}>
                <UserStep/>
                    </Box>
                </FormContextProvider>
                {auth.user.flags.isCommunity &&
                    <CommunityStep/>
                }
                {auth.user.flags.isCommunity && communityStore.fields?.subjectOfLaw !== 'person' &&

                    <ContactBlock/>
                }
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'flex-end',
                        marginTop:'10px'
                    }}
                >
                    <LoadingButton
                        loading={communityStore.sending}
                        disabled={communityStore.sending}
                        variant={'contained'}
                        sx={{
                            width: '225px'
                        }}
                        onClick={sendRequest}
                    >
                        {globalDictionary.get('send')}
                    </LoadingButton>
                </Box>
            </FormContextProvider>

        }
        </Page>

            </>
    )

})

export default EditProfile;
