class PairData {
  confirmAmount = {
    baseCurrency: 0,
    quoteCurrency: 0,
  };
  constructor(pairsList, getExchangeRate, baseCode, quoteCode) {
    this.fetchExchangeRate = getExchangeRate;
    this.pairsList = pairsList.map((pair) => ({
      _id: pair._id,
      min: pair.min,
      max: pair.max,
      baseAsset: pair.base_currency.assetCode,
      quoteAsset: pair.quote_currency.assetCode,
      status: pair.status,
    }));
    this.baseCurrencies = {};
    this.quoteCurrencies = {};
    this.currentPair = null;
    this.exchangeRate = {};
    this.exchangeAmount = {};
    this.calculatedCurrencies = '';
    this.digit = {};

    pairsList.forEach((pair) => {
      this.baseCurrencies[pair.base_currency.assetCode] = pair.base_currency;
      this.quoteCurrencies[pair.quote_currency.assetCode] = pair.quote_currency;
    });

    this._filterAssets(baseCode, quoteCode);

    this._setDigit();

    this.amount = {
      baseCurrency: NaN,
      quoteCurrency: NaN,
    };
  }

  getAmount = (formName) => this.amount[formName];

  getExchangeRate = (formName) => this.exchangeRate[formName];

  getAsset = (formName) => this[formName];

  getBaseAsset = () => this.baseCurrency;

  getQuoteAsset = () => this.quoteCurrency;

  calcResult = () => this.setAmount(this.calculatedCurrencies);

  getConfirmAmount = () => this.confirmAmount;

  getAssetsId = () => {
    return { baseId: this.getBaseAsset()._id, quoteId: this.getQuoteAsset()._id };
  };

  _filterAssets = (base, quote) => {
    const availablePairs = Object.keys(this.quoteCurrencies);

    if (this.baseCurrencies[base] && this.quoteCurrencies[quote] && base !== quote) {
      this.baseCurrency = this.baseCurrencies[base];
      this.quoteCurrency = this.quoteCurrencies[quote];
    } else if (base === quote && this.baseCurrencies[base]) {
      const currentIndex = availablePairs.indexOf(quote);
      if (currentIndex !== -1) {
        const nextIndex = (currentIndex + 1) % availablePairs.length;
        this.baseCurrency = this.baseCurrencies[base];
        this.quoteCurrency = this.quoteCurrencies[availablePairs[nextIndex]];
      } else {
        this.baseCurrency = this.baseCurrencies[base];
        this.quoteCurrency = this.quoteCurrencies[availablePairs[0]];
      }
    } else if (this.baseCurrencies[quote] && this.quoteCurrencies[base] && base !== quote) {
      this.baseCurrency = this.baseCurrencies[quote];
      this.quoteCurrency = this.quoteCurrencies[base];
    } else if (this.baseCurrencies['BTC'] && this.quoteCurrencies['M2C']) {
      this.baseCurrency = this.baseCurrencies['BTC'];
      this.quoteCurrency = this.quoteCurrencies['M2C'];
    } else {
      this.baseCurrency = this.baseCurrencies[this.pairsList[0].baseAsset];
      this.quoteCurrency = this.quoteCurrencies[this.pairsList[0].quoteAsset];
    }
  };

  fetchRate = async () => {
    const pair = await this.getCurrentPair();

    const rate = await this.fetchExchangeRate(pair._id);

    this.setExchangeRate(rate.baseExchange, rate.quoteExchange);
    this.calcResult();
    this.formConfirmData();
  };

  getCurrentPair = () => {
    this.currentPair = this.pairsList.find(
      (pair) =>
        pair.baseAsset === this.getBaseAsset().assetCode &&
        pair.quoteAsset === this.getQuoteAsset().assetCode
    );

    return this.currentPair;
  };

  formConfirmData = () => {
    if ('baseCurrency' === this.calculatedCurrencies) {
      this.confirmAmount.baseCurrency = this.amount.baseCurrency;
      this.confirmAmount.quoteCurrency = this.amount.baseCurrency * this.exchangeRate.baseCurrency;
    }
    if ('quoteCurrency' === this.calculatedCurrencies) {
      this.confirmAmount.quoteCurrency = this.amount.quoteCurrency;
      this.confirmAmount.baseCurrency = this.amount.quoteCurrency / this.exchangeRate.baseCurrency;
    }
  };

  _setDigit = () => {
    this.digit.baseCurrency = this.baseCurrency.digit;
    this.digit.quoteCurrency = this.quoteCurrency.digit;
  };

  resetAmount = () => {
    this.amount.baseCurrency = NaN;
    this.amount.quoteCurrency = NaN;
  };

  setExchangeRate = (baseCurrency, quoteCurrency) => {
    this.exchangeRate.baseCurrency = baseCurrency;
    this.exchangeRate.quoteCurrency = quoteCurrency;
  };

  swapAssets = () => {
    if (this.baseCurrencies[this.quoteCurrency] && this.quoteCurrencies[this.baseCurrencies]) {
      [this.baseCurrency, this.quoteCurrency] = [this.quoteCurrency, this.baseCurrency];
      this.fetchRate();
      this.resetAmount();
    } else {
      this.fetchRate();
      this.resetAmount();
    }
  };

  setBaseCurrency = async (code) => {
    this._filterAssets(code, this.quoteCurrency.assetCode);
    await this.fetchRate();
    this._setDigit();
  };

  setQuoteCurrency = async (code) => {
    this._filterAssets(this.baseCurrency.assetCode, code);
    await this.fetchRate();
    this._setDigit();
  };

  handleInputChange = (event, formName) => {
    const { name, value } = event.target;
    const numericValue = parseFloat(value);
    this.calculatedCurrencies = formName;
    this.amount[formName] = numericValue;
  };

  setAmount = (formName) => {
    if (formName === 'baseCurrency') {
      this.amount.quoteCurrency = NaN;
      this.exchangeAmount.quoteCurrency = 0;
    }
    if (formName === 'quoteCurrency') {
      this.amount.baseCurrency = NaN;
      this.exchangeAmount.baseCurrency = 0;
    }
  };

  getExchange = (formName) => {
    if (!formName) {
      formName = this.calculatedCurrencies;
    }
    if (isNaN(this.amount[formName])) {
      return 0;
    } else {
      this.exchangeAmount.baseCurrency =
        (!isNaN(this.amount.baseCurrency) ? this.amount.baseCurrency : 1) *
        this.exchangeRate.baseCurrency;
      this.exchangeAmount.quoteCurrency =
        (!isNaN(this.amount.quoteCurrency) ? this.amount.quoteCurrency : 1) *
        this.exchangeRate.quoteCurrency;
      this.exchangeAmount.baseCurrency = this.exchangeAmount.baseCurrency.toFixed(
        this.digit.quoteCurrency
      );
      this.exchangeAmount.quoteCurrency = this.exchangeAmount.quoteCurrency.toFixed(
        this.digit.baseCurrency
      );
    }

    return this.exchangeAmount[formName];
  };

  checkIsValid = () => {
    const { amount, currentPair } = this;

    if (amount.baseCurrency > 0) {
      if (currentPair.min.baseCurrency === 0 && currentPair.max.baseCurrency === 0) {
        if (this.getExchange('baseCurrency')) {
          currentPair.min.baseCurrency = (
            currentPair.min.quoteCurrency / this.getExchange('baseCurrency')
          ).toFixed(this.digit.baseCurrency);
        }
      }
      if (
        (currentPair.min.baseCurrency === 0 ||
          amount.baseCurrency >= currentPair.min.baseCurrency) &&
        (currentPair.max.baseCurrency === 0 || amount.baseCurrency <= currentPair.max.baseCurrency)
      ) {
        return true;
      }
    }

    if (amount.quoteCurrency > 0) {
      if (currentPair.min.quoteCurrency === 0 && currentPair.max.quoteCurrency === 0) {
        return true;
      } else if (
        (currentPair.min.quoteCurrency === 0 ||
          amount.quoteCurrency >= currentPair.min.quoteCurrency) &&
        (currentPair.max.quoteCurrency === 0 ||
          amount.quoteCurrency <= currentPair.max.quoteCurrency)
      ) {
        return true;
      }
    }

    return false;
  };
}

export default PairData;
