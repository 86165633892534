import {GremService} from 'services';
import {getUserToken} from 'utils';
import {server} from '../config';

class WalletService {
    constructor() {
        this._apiBase = server;
        this.service = new GremService();
    }

    async getWallet() {
        const res = await this.service.sendRequest('/wallet', 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getHistory({
                         page = 1, limit = 10, filters = {
            walletType: '', status: '', transactionTypeId: '',
        }
                     }) {
        const res = await this.service.sendRequest(`/wallet/transaction?walletType=${filters.walletType}&page=${page}&limit=${limit}&transactionType=${filters.transactionTypeId}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getMethods({
                         typeId = ''
                     }) {
        const res = await this.service.sendRequest(`/wallet/method?type=${typeId}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async createInvoice(data) {
        const res = await this.service.sendRequest(`/wallet/transaction`, 'POST', data, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getInvoice(transactionId) {
        const res = await this.service.sendRequest(`/wallet/invoice/${transactionId}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async confirmInvoice(transactionId) {
        const res = await this.service.sendRequest(`/wallet/transaction/confirm/${transactionId}`, 'PUT', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getStakingContracts({
                                  page = 1, limit = 10,
                              }) {
        const res = await this.service.sendRequest(`/staking/contracts?page=${page}&limit=${limit}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getStakingSessions({
                                 page = 1, limit = 10,
                             }) {
        const res = await this.service.sendRequest(
            `/staking/sessions?page=${page}&limit=${limit}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );

        return res;
    }

    async getStakingSession(sessionId) {
        const res = await this.service.sendRequest(
            `/staking/sessions/${sessionId}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );

        return res;
    }

    async getStakingTransactions({
                                     page = 1, limit = 10, filters = {}
                                 }) {
        const res = await this.service.sendRequest(`/staking/transactions?page=${page}&limit=${limit}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async createBaseStakingInvoice(data) {
        const res = await this.service.sendRequest(`/staking/sessions`, 'POST', data, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async getBaseStakingInvoice(transactionId) {
        const res = await this.service.sendRequest(`/wallet/invoice/${transactionId}`, 'GET', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async confirmBaseStakingInvoice(transactionId) {
        const res = await this.service.sendRequest(`/wallet/transaction/confirm/${transactionId}`, 'PUT', {}, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }

    async generateItezWidgetSignature(data) {
        const res = await this.service.sendRequest('/wallet/signature', 'POST', data, {
            Accept: 'application/json', 'Content-Type': 'application/json', 'x-www-access': getUserToken(),
        });

        return res;
    }
}

export default WalletService;
