import languageStore from "./languageStore";

const utilitySystems = {
  elements: [
    {
      code: 'Electricity',
      value: 'Electricity',
      labelEn: 'Electricity',
      labelRu: 'Электричество',
      labelUa: 'Електрика',
    },
    {
      code: 'Gas',
      value: 'Gas',
      labelEn: 'Gas',
      labelRu: 'Газ',
      labelUa: 'Газ',
    },
    {
      code: 'Water',
      value: 'Water',
      labelEn: 'Water',
      labelRu: 'Вода',
      labelUa: 'Вода',
    },
    {
      code: 'Heating',
      value: 'Heating',
      labelEn: 'Heating',
      labelRu: 'Отопление',
      labelUa: 'Опалення',
    },
    {
      code: 'Internet connection',
      value: 'Internet connection',
      labelEn: 'Internet connection',
      labelRu: 'Интернет-соединение',
      labelUa: "Інтернет-з'єднання",
    },
    {
      code: 'Telephony',
      value: 'Telephony',
      labelEn: 'Telephony',
      labelRu: 'Телефония',
      labelUa: 'Телефонія',
    },
  ],
  // TODO format this code
  get: function (key, lang) {
    lang = languageStore.activeLanguage;
    var ar = this.elements;

    if (lang === 'eng') {
      var a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelEn;
      }
    } else if (lang === 'rus') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelRu;
      }
    } else if (lang === 'ukr') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelUa;
      }
    } else {
      return '';
    }
  },

  getAllValueLabel: function (lang) {
    lang = languageStore.activeLanguage;

    const functor = function (elem, language) {
      switch (language) {
        case 'eng':
          return { value: elem.value, label: elem.labelEn };
        case 'rus':
          return { value: elem.value, label: elem.labelRu };
        case 'uah':
          return { value: elem.value, label: elem.labelUa };
        default:
          return { value: elem.value, label: elem.labelEn };
      }
    };

    const roles = this.elements.map((item) => functor(item, lang));
    return roles;
  },
};

export default utilitySystems;
