import {makeAutoObservable} from "mobx";
import gremService from "services/GremService";
import globalDictionary from "assets/translations/globalDictionary";
import {snackActions} from "utils/SnackBarUtils";
import {createContext, useContext} from "react";
import {observer} from "mobx-react-lite";

class ContractStore {
    emptyPerson = {
        fname:'',
        lname:'',
        personPassport: '', //  write in contract form
        personPassportIssuedBy: '', //  write in contract form
        passportDate: Date.now(),
        personLivingAddress: {
            country: '',
            city: '',
            street: '',
            streetNumber: '',
        }, //  write in contract form
    }
    emptyCompany = {
        companyName: '', // get from community (if not person data)
        companyFullAddress: {
            country: '',
            city: '',
            street: '',
            streetNumber: '',
        }, // get from community ( if not person data)
        registrationNumber: '', // get from community ( if not person data)
        contactFullName: '', //(contactFname,contactLname) // get from community or user
        additionalTerms: '',
        companyActingBased: '', // write in contract form
    }
    contract = {
        startingSalePrice: '',
        baseContract: {
            paymentDueDays: '',
            penalty: '',
        },
        build: {
            buildSquare: '',
            buildStatus: '',
            buildName: '',
            buildAddress: {
                country: '',
                city: '',
                street: '',
                streetNumber: ''
            },
            floors: '',
        },
        company: {
            companyName: '', // get from community (if not person data)
            companyFullAddress: {
                country: '',
                city: '',
                street: '',
                streetNumber: '',
            }, // get from community ( if not person data)
            registrationNumber: '', // get from community ( if not person data)
            contactFullName: '', //(contactFname,contactLname) // get from community or user
            additionalTerms: '',
            companyActingBased: '', // write in contract form
        },
        person: {
            fname:'',
            lname:'',
            personPassport: '', //  write in contract form
            personPassportIssuedBy: '', //  write in contract form
            passportDate: Date.now(),
            personLivingAddress: {
                country: '',
                city: '',
                street: '',
                streetNumber: '',
            }, //  write in contract form
        },
        pdfDocuments: {
            agency: '',
            owner:'',
            passport:'',
        },
        sideOneReward: {
            value: '',
            type: 'percentage'
        },
    }
    contractErrors = {
        startingSalePrice: '',
        baseContract: {
            paymentDueDays: '',
            penalty: '',
        },
        build: {
            buildSquare: '',
            buildStatus: '',
            buildName: '',
            buildAddress: {
                country: '',
                city: '',
                street: '',
                streetNumber: ''
            },
            floors: '',
        },
        company: {
            companyName: '', // get from community (if not person data)
            companyFullAddress: {
                country: '',
                city: '',
                street: '',
                streetNumber: '',
            }, // get from community ( if not person data)
            registrationNumber: '', // get from community ( if not person data)
            contactFullName: '', //(contactFname,contactLname) // get from community or user
            additionalTerms: '',
            companyActingBased: '', // write in contract form
        },
        person: {
            fname:'',
            lname:'',
            personPassport: '', //  write in contract form
            personPassportIssuedBy: '', //  write in contract form
            passportDate: Date.now(),
            personLivingAddress: {
                country: '',
                city: '',
                street: '',
                streetNumber: '',
            }, //  write in contract form
        },
        pdfDocuments: {
            agency: '',
            owner:'',
            passport:'',
        }
    }
    status = ''
    contractId = null
    sending = false
    loading = false
    isEdit = false
    isOwner = false
    userType = ''
    activeTab = 'company'
    contractObjectType = ''

    constructor(props) {
        makeAutoObservable(this)
        this.contractId = props.contractId
        this.service = new gremService();
        this.userType = props.userType
        this.contractObjectType = props.contractObjectType
        this.userType ? this.userType.some((item) => item.toLowerCase() === 'seller') ? this.activeTab = 'person' : this.activeTab = 'company' : this.activeTab = 'company'
    }

    getContract = async () => {
        this.loading = true
        const result = await this.service.getContract(this.contractId);

        this.contract = {...this.contract,...result.data}
        this.contract.baseContract.penalty = result?.data?.baseContract?.penalty?.$numberDecimal

        this.loading = false
    }

    sendUserScan = async (id, file) => {
        const result = await this.service.uploadUserScan(id, file);
        return result;
    }

    uploadFile = (name, file) => {
        _.set(this.contract, name, file)
    }

    changeTab = (event, newTabIndex) => {
        this.activeTab = newTabIndex
    };
    handleChangeTab = (event) => {
        this.activeTab = event.target.checked ? 'person' : 'company'
    };

    getError = (field) => {
        return this.contractErrors[field]
    }

    isLatin = (value) => {
        const pattern = /^[a-zA-Z0-9.,-@/\s!#$%^&*()_+=|<>?{}[\]\\/]+$/;
        return pattern.test(value);
    };

    handleInputChange = (e) => {
        let {name, value, checked, type} = e.target;

        _.set(this.contract, name, type === 'checkbox' ? checked : value)

        this.validate(name, value);
    };
    handleInputDate = (value,name) => {

        _.set(this.contract,name,Date.parse(value))
    }
    validate = (name, value) => {
        if (typeof value === 'object' && value !== null) {
            for (const nestedKey in value) {
                this.validate(`${name}.${nestedKey}`, value[nestedKey]);
            }
        } else {
            const error = this.checkField(value, name);

            if (error) {
                _.set(this.contractErrors, name, error)

                return error;
            } else {
                _.set(this.contractErrors, name, '')
                return '';
            }
        }
    };

    checkField = (value, name = '') => {
        if (typeof value === 'string') value = value.trim();

        if(name ==='company.additionalTerms'){return false}
        if(name ==='person.additionalTerms'){return false}
        if(name.includes('pdfDocuments.agency.')){return false}
        if(name.includes('pdfDocuments.owner.')){return false}
        if(name.includes('pdfDocuments.passport.')){return false}
        if(name.includes('place_id')){return false}
        if(name.includes('district')){return false}
        if(name.includes('location')){return false}

        if (!value) {
            return globalDictionary.get('validate_empty');
        } else if (value instanceof Date && value.getTime() === 0) {
            return globalDictionary.get('validate_empty_date');
        } else if (typeof value === 'string' && !this.isLatin(value)) {
            if(name === 'developerEmail') return false
            return globalDictionary.get('validate_wrong_language');
        }else if(name==='sideOneReward.value' && value <2) {
            return 'Minimum commission 2%'
        }else{
            return false;
        }
    };
    isFormValid = (data) => {
        const errors = {};

        if(this.userType.some(item=> item ==='Broker')&& this.contractObjectType==='complex') {
            delete data.pdfDocuments.passport
            delete this.contractErrors.pdfDocuments.passport
            delete data.pdfDocuments.owner
            delete this.contractErrors.pdfDocuments.owner
        } else if(this.userType.some(item=> item === 'Seller') && this.contractObjectType === 'property') {
            delete data.pdfDocuments.agency
            delete this.contractErrors.pdfDocuments.agency
        } else {
            delete data.pdfDocuments
            delete this.contractErrors.pdfDocuments
        }
        Object.entries(data).forEach(([key, value]) => {
            const error = this.validate(key, value);
            if (error) errors[key] = error;
        });
        const isEmptyObject = (obj) => {
            for (const key in obj) {
                if (typeof obj[key] === 'object') {
                    if (!isEmptyObject(obj[key])) {
                        return false;
                    }
                } else if (obj[key] !== '') {
                    snackActions.error(`${key}:${obj[key]}`)
                    return false;
                }
            }
            return true;
        };

        return isEmptyObject(this.contractErrors);
    };
    handleSubmit = async (targetType, targetId) => {
        this.loading = true
        if (this.activeTab === 'company') {
            delete this.contract.person
            delete this.contractErrors.person
            // delete this.contract.pdfDocuments
            // delete this.contractErrors.pdfDocuments
        } else {
            delete this.contract.company
            delete this.contractErrors.company
        }
        if (this.isFormValid(this.contract)) {
            const payload = {
                ...this.contract,
                targetId: targetId,
                targetType: targetType,
            }
            const formData = new FormData();
            if(this.userType.some(item=> item ==='Broker')&& this.contractObjectType==='complex') {
                formData.append('agency', payload.pdfDocuments.agency)
            }
            if(this.userType.some(item=> item === 'Seller') && this.contractObjectType === 'property') {
                formData.append('passport', payload.pdfDocuments.passport)
                formData.append('owner', payload.pdfDocuments.owner)
            }
            formData.append('data', JSON.stringify(payload))
            let response = {}
            if (this.isEdit) {
                response = await this.service.updateSplitComissionContract(this.contractId, formData);
            } else {
                response = await this.service.createSplitComissionContract(formData);
            }
            if (response.code !== 200) {
                snackActions.error('Error creating contract');
                this.contract.person = {...this.emptyPerson,...this.contract.person}
                this.contract.company = {...this.emptyCompany,...this.contract.company}
                response.errors.map(error=>snackActions.error(error.message))
                this.loading=false
                return response
            } else if (response.code === 200) {
                this.loading=false
                snackActions.success('Contract created successfully');
                return response;
                //navigate(`/${targetType}/${targetId}`);
            }
        } else {

            this.loading=false
            snackActions.error('Form data is wrong! Check fields');
        }
    };

}

export default ContractStore;

export const ContractContext = createContext();

export const useContractStore = () => {
    return useContext(ContractContext)
}

export const ContractProvider = observer(({children, store}) => {

    return <ContractContext.Provider value={store}>
        {children}
    </ContractContext.Provider>

})



