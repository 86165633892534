import React, {useRef, useState} from "react";
import GoogleMapReact from "google-map-react";
import {IconButton} from "@mui/material";
import markerImage from 'assets/images/marker.png';

const Marker = ({children}) => children;

/***
 * LocationMap component
 * @param props - props
 * @param props.lat - latitude
 * @param props.lng - longitude
 * @param props.isMarkerShown - is marker shown
 * @returns {JSX.Element}
 * @constructor
 */
const LocationMap = (props) => {
    const mapRef = useRef();
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(3);
    const {lat = 0, lng = 0, isMarkerShown = false, ...otherProps} = props || {};

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            }}
            defaultCenter={{
                lat,
                lng,
            }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({map}) => {
                mapRef.current = map;
            }}
            onChange={({zoom, bounds}) => {
                setZoom(zoom);
                setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
            }}
            {...otherProps}
        >
            {isMarkerShown && (

                <Marker
                    key={`marker`}
                    lat={lat}
                    lng={lng}
                >
                    <IconButton
                        sx={{
                            background: 'none !important',
                            border: 'none',
                        }}
                        size="large"
                    >
                        <img
                            src={markerImage}
                            alt={'Marker'}
                            style={{
                                width: '50px',
                            }}
                        />
                    </IconButton>
                </Marker>
            )}
        </GoogleMapReact>
    );
}

export default LocationMap;
