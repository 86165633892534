import React from 'react';
import {Box, Button, Chip, Grid, TextField, Typography} from '@mui/material';
import {FileLoader} from 'components';
import {observer} from "mobx-react-lite";
import {useStore} from "Providers";
import ClipboardTextIcon from 'assets/icons/clipboard-text.svg'
import projectDictionary from "../../../../../../assets/translations/Project/projectDictionary";
import commonDictionary from "../../../../../../assets/translations/commonDictionary";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {AIInput} from "../../../../../../components";
import ExtraDocumentsBlock from "./documentsBlocks/ExtraDocumentsBlock";
import {useState} from "react";
import paperClip from 'assets/icons/paper-clip.svg'
const DocumentsForm = observer(({store}) => {

    const [company, setCompany] = useState('');
    const [projectFileCount,setProjectFileCount] = useState(1);
    const [companyFileCount,setCompanyFileCount] = useState(1);
    const {auth} = useStore();
    return(
        <Box sx={{
            display:'flex',
            gap:'30px',
            flexDirection:'column'
        }}>
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px 10px 10px 10px',
            width:'100%'
        }}>
            <Box sx={{paddingLeft:'8px',marginBottom:'30px'}}>
            <Typography sx={{
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:700,
                lineHeight:'normal',
                marginBottom:'10px'
            }}>
                {projectDictionary.get('project_add_documents_title')}
            </Typography>
            <Typography sx={{
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:400,
                lineHeight:'150%'
            }}>
                {projectDictionary.get('project_add_documents_title_sub')}
            </Typography>
            </Box>


            <FileLoader
                _data={store.fields.project.documents}
                _setData={(file)=>{
                    store.uploadFile('project.documents.ownerDocument',file.ownerDocument)
                }}
                documentDropzone={true}
                configs={{
                    field: 'ownerDocument',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    title: projectDictionary.get('project_add_documents_owner_title'),
                    titleSub: projectDictionary.get('project_add_documents_owner_title_sub'),
                    documentDropzoneTitle:projectDictionary.get('project_add_documents_owner_dropzone'),
                    confName: 'project.unit.documents',
                    buttonKey:'documents_load_button_key',
                    documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                }}
            >
                <Box marginTop={'20px'}>

                    <RadioGroup
                        name={'project.documents.owner'}
                        value={store.fields.project.documents?.owner.toString()}
                        onChange={store.handleInputRadio}
                        row>
                        <Typography alignItems={'center'}>
                            <Radio
                                sx={{width: '20px', mr: '10px'}}
                                value={'true'}
                                checked={store.fields.project.documents.owner  === true}
                            />
                            {commonDictionary.get('yes')}
                        </Typography>
                        <Typography ml={5} alignItems={'center'}>
                            <Radio
                                value={'false'}
                                checked={store.fields.project.documents.owner === false}
                            />
                            {commonDictionary.get('no')}
                        </Typography>
                    </RadioGroup>
                </Box>
            </FileLoader>
            <FileLoader
                _data={store.fields.project.documents}
                _setData={(file)=>{
                    store.uploadFile('project.documents.agreementDocument',file.agreementDocument)
                }}
                dropzone={store.fields.project.documents.owner === false}
                documentDropzone={true}
                configs={{
                    field: 'agreementDocument',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    //title: projectDictionary.get('project_add_documents_owner_title'),
                    //titleSub: projectDictionary.get('project_add_documents_owner_title_sub'),
                    documentDropzoneTitle:projectDictionary.get('project_add_documents_agreement_dropzone'),
                    confName: 'project.unit.documents',
                    buttonKey:'documents_load_button_key',
                    documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                }}
            >
                <Box>
                </Box>
            </FileLoader>
        </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={(file)=>{
                            store.uploadFile('project.documents.experts',file.experts)
                        }}
                        documentDropzone={true}
                        configs={{
                            field: 'experts',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            title: projectDictionary.get('project_add_documents_experts_title'),
                            titleSub: projectDictionary.get('project_add_documents_experts_title_sub'),
                            documentDropzoneTitle:projectDictionary.get('project_add_documents_experts_dropzone'),
                            confName: 'project.unit.documents',
                            buttonKey:'documents_load_button_key',
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}
                    >
                        <Box>

                        </Box>
                    </FileLoader>
                </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
            <Typography sx={{
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:700,
                lineHeight:'normal',
                marginBottom:'10px'
            }}>
                {projectDictionary.get('project_add_unit_item_project_documentation_title')}
            </Typography>
            {[...Array(projectFileCount)].map((_, index) => {
                return <Grid item xs={12}>
                    <FileLoader
                        _data={store.fields.project.projectDocumentation}
                        _setData={
                            (file)=>{
                                console.log(file)
                                store.uploadFile(`project.projectDocumentation.[${index}]`,file[index])
                            }
                    }
                        onDelete={()=>{
                            setProjectFileCount(prevState => prevState - 1)
                            store.handleDeleteArrayElem('project.projectDocumentation',index)
                        }}
                        fileCount={store.fields.project.projectDocumentation[index]?.url ? 1 : 0}
                        documentDropzone={true}
                        configs={{
                            field: `${index}`,
                            confName: 'project.unit.projectDocumentation',
                            //documentDropzoneTitle: commonDictionary.get('attached_file')
                            //title: projectDictionary.get('project_add_unit_item_project_documentation_title'),
                                documentDropzoneTitle:projectDictionary.get('project_add_unit_item_project_documentation_dropzone'),

                                buttonKey:'documents_load_button_key',
                                documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}
                    />
                </Grid>
            })}

            {companyFileCount < 10 &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop:'10px'
                    }}>
                        <Button
                            onClick={() => {
                                setProjectFileCount(prevState => prevState + 1)
                                store.fields.project.projectDocumentation.push({})
                            }}
                            startIcon={<img src={paperClip}/> }
                        >
                            {commonDictionary.get('add_file')}
                        </Button>
                    </Box>
                </Grid>
            }
            
                </Box>

        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
            <Typography sx={{
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:700,
                lineHeight:'normal',
                marginBottom:'10px'
            }}>
                {projectDictionary.get('project_add_unit_item_companys')}
            </Typography>
            <Box sx={{
                display:'flex',
                width:'100%',
                marginTop:'20px',
                flexDirection:'column',
                gap:'10px'
            }}>

                {
                    store?.fields?.project?.developmentCompanys?.participants?.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                flexWrap: 'wrap'
                            }}
                        >
                            {store?.fields?.project?.developmentCompanys?.participants.map((item, index) => {
                               console.log(item)
                                return (<Chip
                                    sx={{
                                        backgroundColor: '#E0E0E0',
                                        borderRadius: '30px',
                                        border: 'none'
                                    }}
                                    key={item}
                                    label={item}
                                    variant="outlined"
                                    onDelete={(e) => {
                                        store.handleDeleteArrayElem('project.developmentCompanys.participants', index)
                                    }}
                                />)
                            })}
                        </Box>
                    )
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    flexWrap: 'wrap',
                    marginBottom:'10px'
                }}>
                    <TextField
                        label={projectDictionary.get('project_add_unit_item_companys')}
                        name={'company'}
                        type={'text'}
                        value={company}
                        onChange={(event) => {
                            const {value} = event.target;
                            setCompany(value)
                        }}
                    />
                    <Button
                        sx={{
                            p: '0 50px',
                            width: {
                                xs: '100%',
                                sm: 'auto'
                            }
                        }}
                        variant={'outlined'}
                        onClick={() => {
                            store.handleInputArrayElem('project.developmentCompanys.participants', company)
                            setCompany('')
                        }}
                    >
                        {commonDictionary.get('add')}
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={1}>
            {[...Array(companyFileCount)].map((_, index) => {
                return <Grid item xs={12}>
                    <FileLoader
                        _data={store.fields.project.developmentCompanys.documents}
                        _setData={
                            (file)=>{
                                console.log(file)
                                store.uploadFile(`project.developmentCompanys.documents.[${index}]`,file[index])
                            }
                        }
                        onDelete={()=>{
                            setCompanyFileCount(prevState => prevState - 1)
                            store.handleDeleteArrayElem('project.developmentCompanys.documents',index)
                        }}
                        fileCount={store.fields.project.developmentCompanys.documents[index]?.url ? 1 : 0}
                        documentDropzone={true}
                        configs={{
                            field: `${index}`,
                            confName: 'project.unit.developmentCompanys.documents',
                            //documentDropzoneTitle: commonDictionary.get('attached_file')
                            //title: projectDictionary.get('project_add_unit_item_project_documentation_title'),
                            documentDropzoneTitle:projectDictionary.get('project_add_unit_item_license'),

                            buttonKey:'documents_load_button_key',
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}
                    />
                </Grid>
            })}
            </Grid>
            {companyFileCount < 10 &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop:'10px'
                    }}>
                        <Button
                            onClick={() => {
                                setCompanyFileCount(prevState => prevState + 1)
                                store.fields.project.developmentCompanys.documents.push({})
                            }}
                            startIcon={<img src={paperClip}/> }
                        >
                            {commonDictionary.get('add_file')}
                        </Button>
                    </Box>
                </Grid>
            }
                    {/*<FileLoader*/}
                    {/*    _data={store.fields.project.developmentCompanys}*/}
                    {/*    _setData={(file)=>{*/}
                    {/*        store.uploadFile('project.developmentCompanys',file.documents)*/}
                    {/*    }}*/}
                    {/*    documentDropzone={true}*/}
                    {/*    configs={{*/}
                    {/*        field: 'documents',*/}
                    {/*        minKey: 'complex_progress_photo_min',*/}
                    {/*        maxKey: 'complex_progress_photo_min',*/}
                    {/*        //title: projectDictionary.get('project_add_unit_item_companys'),*/}
                    {/*        documentDropzoneTitle:projectDictionary.get('project_add_unit_item_license'),*/}
                    {/*        confName: 'project.unit.developmentCompanys',*/}
                    {/*        buttonKey:'documents_load_button_key'*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    */}
                    {/*</FileLoader>*/}
                </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={(file)=>{
                            store.uploadFile('project.documents.pledgeDocument',file.pledgeDocument)
                        }}
                        documentDropzone={true}
                        configs={{
                            field: 'pledgeDocument',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            title: projectDictionary.get('project_add_documents_pledge_title'),
                            titleSub: projectDictionary.get('project_add_documents_pledge_title_sub'),
                            documentDropzoneTitle:projectDictionary.get('project_add_documents_pledge_dropzone'),
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title'),
                            confName: 'project.unit.documents',
                            buttonKey:'documents_load_button_key'
                        }}
                    >
                        <Box sx={{
                            display:'flex',
                            width:'100%',
                            marginTop:'20px',
                        }}>
                            <AIInput
                                placeholder={projectDictionary.get('project_add_documents_pledge_description')}
                                label={`${projectDictionary.get('project_add_documents_pledge_description')}`}
                                name={'project.documents.pledge'}
                                value={store?.fields?.project?.documents?.pledge ?? ''}
                                onChange={store.handleInputChange}
                                maxTextLength={600}
                            />
                        </Box>
                    </FileLoader>
                </Box>

            <ExtraDocumentsBlock store={store}/>

        </Box>
    )
});

export default DocumentsForm;
