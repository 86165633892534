import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid, Step, StepLabel, Stepper, Typography,} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {observer} from "mobx-react-lite";
import globalDictionary from "assets/translations/globalDictionary";

/**
 * CustomStepper is a horizontal stepper component with the ability to navigate between steps.
 */
const DraftStepper = observer((props) => {

    const [activeStep, setActiveStep] = useState(0)
    const [sending, setSending] = useState(false)
    const store = props.store;

    const getStepContent = () => {
        const Step = store.steps[activeStep].content;
        return <Step store={store}/>
    };

    const getStepper = () => {
        return (<Stepper nonLinear activeStep={activeStep}>
            {store.steps.map((step, index) => (
                <Step key={typeof step.label === 'string' ? step.label : step.label()}>
                    <StepLabel
                        onClick={!store.finish && handleStep(index)}
                        sx={{cursor: 'pointer'}}
                        disabled={store.sending}
                    >
                        <Typography>
                            {typeof step.label === 'string' ? step.label : step.label()}
                        </Typography>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>)
    }

    const handleNext = async () => {
        setSending(true)
        if(store.isDraft){
            await store.handleSendDraftForm()
                setActiveStep(activeStep +1);

        }else {
            await store.handleSendForm()
            setActiveStep(activeStep +1);
        }
        setSending(false)
    };

    const handleBack = async () => {
        setSending(true)
        if(store.isDraft){
           await store.handleSendDraftForm()
                setActiveStep(activeStep -1);

        }else {
            await store.handleSendForm()
            setActiveStep(activeStep -1);
        }
        setSending(false)
    };

    const handleStep = (step) => async () => {
        setSending(true)
        let result
        if(store.isDraft){
            await store.handleSendDraftForm()
                setActiveStep(step);

        }else {
            await store.handleSendForm()
            setActiveStep(step);
        }

        setSending(false)
    };

    const handleSendForm = async () => {
        setSending(true)
        await store.handleSendForm()
        setSending(false)
    }


    const CustomStepButton = () => {
        return (
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    {activeStep > 0 && (
                        <Button
                            color="primary"
                            variant={'outlined'}
                            disabled={activeStep === 0 || sending}
                            onClick={handleBack}
                            sx={{
                                mr: 1,
                                color: 'black'
                            }}
                        >
                            {globalDictionary.get('stepper_back')}
                        </Button>
                    )}

                    <Box sx={{flex: '1 1 auto'}}/>
                    {activeStep === store.steps.length - 1 ? (

                        <LoadingButton
                            onClick={handleSendForm}
                            sx={{
                                mr: 1,
                                display:store.isDraft === false? 'none' : 'block'
                        }}
                            loading={sending}
                            variant="contained"
                            disabled={sending}
                        >
                            {props.sendButtonText ? props.sendButtonText :globalDictionary.get('stepper_send')}
                        </LoadingButton>

                    ) : (
                        <Button
                            onClick={handleNext}
                            sx={{mr: 1}}
                            variant="contained"
                            disabled={activeStep === store.steps.length - 1 || sending}
                        >
                            {globalDictionary.get('stepper_next')}
                        </Button>
                    )}
                </Box>
            </React.Fragment>
        );
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            {store.finish ? store.finishStep :
                <>
                    <Box sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        padding: '20px',
                        borderRadius: '5px 5px 0px 0px'
                    }}>
                        {getStepper()}

                    </Box>
                    <Grid container sx={{width: '100%'}}>
                        {sending || store.sending ? (
                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                <CircularProgress/>
                            </Grid>
                        ) : (
                            getStepContent()
                        )}
                    </Grid>
                    {!store.finish && <CustomStepButton/>}
                </>
            }
        </Grid>
    );
})
export default DraftStepper;
