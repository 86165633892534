import React from 'react';
import {FileLoader} from 'components';
import {observer} from "mobx-react-lite";
import {Box} from "@mui/material";
import projectDictionary from "../../../../../../assets/translations/Project/projectDictionary";

const PhotosForm = observer(({store}) => {

    //const store = useFormStore();

    return(
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px 10px 10px 10px',
            width:'100%',
            marginTop:'30px'
        }}>
            <FileLoader
                _data={store.fields}
                _setData={store.setFields}
                error={store.isDraft ? false :store.fieldsErrors.photos}
                configs={{
                    field: 'photos',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    title: projectDictionary.get('project_add_photos_title'),
                    titleSubKey: 'property_add_photos_title_sub',
                    confName: 'project.unit',
                }}
            />
        </Box>
    )
});

export default PhotosForm;
