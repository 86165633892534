import React, { createContext, useContext, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getActiveLanguageFromLS, setActiveLanguageToLS } from './utils';
import ThemeProvider from './theme';
import { Translator } from './utils/react-auto-translate';
import auth from './store/auth';
import { messenger } from './store/messenger';
import websocket from './store/websocket';
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils';
import Websocket from "./store/websocket";

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;
const queryClient = new QueryClient();
export const StoreContext = createContext();
export const WebsocketContext = createContext();

const userLanguage = getActiveLanguageFromLS();
if (!userLanguage) setActiveLanguageToLS('eng');

export function useStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within StoreContext.Provider');
  }

  return context;
}
export function useWebsocketStore() {
  const context = useContext(WebsocketContext);
  if (context === undefined) {
    throw new Error('useWebsocketStore must be used within StoreContext.Provider');
  }

  return context;
}

const Providers = ({ children }) => {
  const store = {
    auth,
    messenger,
  }
  const websocketStore = new Websocket({auth:store.auth});
  return (
      <StoreContext.Provider value={store}>
        <WebsocketContext.Provider value={websocketStore}>
        <ThemeProvider>
          <Translator
              from=""
              to={userLanguage.substr(0, 2)}
              googleApiKey={process.env.REACT_APP_GOOGLE_TRANSLATOR_API_KEY}
          >
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider maxSnack={3}>
                <SnackbarUtilsConfigurator />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}
                                           onErrored={(error) => {
                                             console.log('ReCaptcha Error:', error);
                                           }}
                  >
                    {children}
                  </GoogleReCaptchaProvider>
                </LocalizationProvider>
              </SnackbarProvider>
            </QueryClientProvider>
          </Translator>
        </ThemeProvider>
        </WebsocketContext.Provider>
      </StoreContext.Provider>
  );
};

export default React.memo(Providers);
