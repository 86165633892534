import React from 'react';
import languageStore from "../languageStore";

const formErrorDictionary = {
    dictionary: {
        empty_field: {
            eng: 'Field is empty',
            rus: 'Поле пусто',
            ukr: 'Поле порожнє',
            deu: 'Feld ist leer',
        },
        email: {
            eng: 'Invalid email',
            rus: 'Не валидный E-mail',
            ukr: 'Неправильний електронний адрес',
            deu: 'Ungültige E-Mail',
        },
        phone: {
            eng: 'Invalid phone number',
            rus: 'Неверный номер телефона',
            ukr: 'Неправильний номер телефону',
            deu: 'Ungültige Telefonnummer',
        },
        wrong_language: {
            eng: 'Enter data in English',
            rus: 'Введите данные на Английском',
            ukr: 'Введіть дані Англійською',
            deu: 'Geben Sie Daten in Englisch ein',
        },
        form: {
            eng: 'Form validation error',
            rus: 'Ошибка валидации формы',
            ukr: 'Помилка валідації форми',
            deu: 'Formularvalidierungsfehler',
        },
        Key_not_founded: {
            eng: 'Error: Text not found',
            rus: 'Ошибка: Текст не найден',
            ukr: 'Помилка: Текст не знайдено',
            deu: 'Fehler: Text nicht gefunden',
        },
    },
    get: function (key, lang) {
        lang = languageStore.activeLanguage;
        let dictionary = this.dictionary[key]
            ? this.dictionary[key]
            : this.dictionary['Key_not_founded'];
        let dictionaryItem = dictionary[lang]
            ? dictionary[lang]
            : this.dictionary['Key_not_founded']['eng'];

        return dictionaryItem;
    },
}

export default formErrorDictionary;