import { makeAutoObservable, reaction } from 'mobx';
import { ChatSocketService, NotificationSocketService } from 'services';

class Websocket {
  chat = null;
  notification = null;
  reconnectInterval = 5000;
  auth = null;
  manualClose = false;

  constructor(props) {
    makeAutoObservable(this);

    this.auth = props.auth;
    reaction(
        () => this.auth.user,
        (user) => {
          const { userToken: token } = user;
          if (token) {
            this.manualClose = false;
            this.init(token).then(() => {});
          } else {
            this.stopAll();
          }
        }
    );
  }

  async init(token) {
    this.stopAll();

    this.chat = await ChatSocketService.init(token);
    this.notification = await NotificationSocketService.init(token);

    this.addReconnectHandler(this.chat, token, ChatSocketService);
    this.addReconnectHandler(this.notification, token, NotificationSocketService);

    this.addDataHandlers();
  }

  addReconnectHandler(service, token, ServiceClass) {
    service.socket.onclose = (reason) => {
      if (!this.manualClose) {
        console.log('Connection lost, attempting to reconnect...' + reason);
        this.reconnect(service, token, ServiceClass);
      } else {
        console.log('Manual close, no reconnection attempt.');
      }
    };
  }

  addDataHandlers() {
    if (this.notification) {
      this.notification.socket.on('notifications', (data) => {
        this.notification.notifications = data;
        console.log('Received notifications:', this.notification.notifications);
      });

      this.notification.socket.on('counters', (data) => {
        this.notification.counter = data;
        console.log('Received counters:', this.notification.counter);
      });
    }
  }

  async reconnect(service, token, ServiceClass) {
    if (this.manualClose) {
      console.log('Manual close detected, cancelling reconnection.');
      return;
    }

    service.socket = null;

    setTimeout(async () => {
      console.log('Reconnecting...');
      try {
        const newService = await ServiceClass.init(token);
        if (ServiceClass === ChatSocketService) {
          this.chat = newService;
        } else if (ServiceClass === NotificationSocketService) {
          this.notification = newService;
        }
        this.addReconnectHandler(newService, token, ServiceClass);
        this.addDataHandlers();
        console.log('Reconnected successfully.');
      } catch (error) {
        console.error('Reconnect failed, trying again...', error);
        await this.reconnect(service, token, ServiceClass);
      }
    }, this.reconnectInterval);
  }

  stopAll() {
    this.manualClose = true;

    if (this.chat) {
      this.chat.socket.close();
    }
    if (this.notification) {
      this.notification.socket.close();
    }

    this.chat = null;
    this.notification = null;
  }
}

export default Websocket;
