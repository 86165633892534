import React, { useState, useEffect, useRef } from 'react';
import {GoogleMap, LoadScript, Marker, StandaloneSearchBox, useLoadScript} from "@react-google-maps/api";
import { observer } from "mobx-react-lite";
import countries from "../../../assets/translations/countries";
import languageStore from "../../../assets/translations/languageStore";

const libraries = ['places'];
const MapWithASearchBox = observer((props) => {
    const [center, setCenter] = useState({ lat: parseFloat(props.lat), lng: parseFloat(props.lng) });
    const [markers, setMarkers] = useState([]);
    const mapRef = useRef(null);
    const searchBoxRef = useRef(null);
    const script = useLoadScript({
        id:'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        libraries,
        language: languageStore.activeLanguage === 'eng' ? 'en' : languageStore.activeLanguage === 'rus' ? 'ru' : languageStore.activeLanguage === 'ukr' ? 'uk': languageStore.activeLanguage === 'deu' ? 'de' : 'en'
    })
    useEffect(() => {
        const onBoundsChanged = () => {
            // Your logic here if needed
        };
        const map = mapRef.current;
        map?.addListener("bounds_changed", onBoundsChanged);
        return () => map?.removeListener("bounds_changed", onBoundsChanged);
    }, []);
    useEffect(() => {

                geocodeNewLocation(props.location);

    }, [props.location]);

    const handleAddressComponent = (addressComponents) => {
        const locationObject = { country: '', city: '', district: '', street: '', streetNumber: '', postalCode: '', countryCode: '' };
        addressComponents.forEach(component => {
                const type = component.types[0];
                switch (type) {
                    case 'country':
                        if(component.short_name) {
                            locationObject.country = component.short_name;
                        } else if(component.long_name) {
                            locationObject.country = component.long_name;
                        }
                        break;
                    case 'route':
                        if(component.long_name) {
                            locationObject.street = component.long_name;
                        }
                        break;
                    case 'locality':
                        if(component.long_name) {
                            locationObject.city = component.long_name;
                        }
                        break;
                    case 'postal_town':
                        if(component.long_name) {
                            locationObject.city = component.long_name;
                        }
                        break;
                    case 'administrative_area_level_1':
                        if (!locationObject.city && component.long_name) {
                            locationObject.city = component.long_name;
                        } else if (!locationObject.district) {
                            locationObject.district = component.long_name;
                        }
                        break;
                    case 'street_number':
                        if(component.long_name) {
                            locationObject.streetNumber = component.long_name;
                        }
                        break;
                    default:
                        break;
                }
            });
        return locationObject;
    };

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places && places.length) {
            const markers = places.map(place => ({ position: place.geometry.location }));
            setMarkers(markers);
            setCenter(markers[0].position);
            if (places[0].address_components) {
                const locationObject = handleAddressComponent(places[0].address_components);
                props.funcSetLocation({...locationObject, place_id: places[0].place_id});
            }
        }
    };
    const geocodeNewLocation = (location) => {
        if (window.google && window.google.maps) {
            const geocoder = new window.google.maps.Geocoder();
            const address = `${location.streetNumber}, ${location.street}, ${location.city}, ${countries.get(location.country)}`;

            geocoder.geocode({ address: address }, (results, status) => {
                if (status === 'OK') {
                    const geometry = results[0].geometry.location;
                    const lat = geometry.lat();
                    const lng = geometry.lng();
                    if(parseFloat(lat) !== parseFloat(center.lat) || parseFloat(lng) !== parseFloat(center.lng)) {
                    const nextCenter = { lat, lng };
                    const nextMarkers = [{ position: geometry }];

                    setCenter(nextCenter);
                    setMarkers(nextMarkers);

                    let locationObject = {
                        lat: nextCenter.lat,
                        lng: nextCenter.lng,
                        place_id: results[0].place_id,
                    };
                    // if (location.length > 0 && location[0].hasOwnProperty('address_components')) {
                    //     locationObject = handleAddressComponent(location[0].address_components);
                    // }
                        props.funcSetLocation(locationObject);
                    }
                } else {
                    console.error('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
    };

    return script.isLoaded ?(
        <GoogleMap
            ref={mapRef}
            onLoad={() => console.log('Map loaded')}
            mapContainerStyle={{
                width:'100%',
                maxWidth: '585px',
                height: '320px',
                padding:'20px'
            }}
            options={{
                mapTypeControl:false,
                streetViewControl:false,
                fullscreenControl:false
        }}
            center={center}
            zoom={15}
        >
            <StandaloneSearchBox
                onLoad={(ref) => {
                    searchBoxRef.current = ref;
                }}
                onPlacesChanged={onPlacesChanged}
            >
                <input
                    type="text"
                    placeholder={'Please select an address in this field'}
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `100%`,
                        height: `45px`,
                        padding: `0 12px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipsis`,
                        position: 'relative',
                        top: '0px',
                    }}
                />
            </StandaloneSearchBox>
            {markers.map((marker, index) => (
                <Marker key={index} position={marker.position} />
            ))}
        </GoogleMap>
    ):<></>
});

export default MapWithASearchBox;
