import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Box,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const findLastOrder = (parentId, linkedRows) => {
  const linkedOrders = linkedRows.filter((row) => row.parentId === parentId);

  return linkedOrders.reduce(
    (max, order) =>
      new Date(order.updatedAt).toLocaleDateString() < new Date(max.updatedAt).toLocaleDateString()
        ? order
        : max,
    linkedOrders[0]
  );
};

function DataTable({ columns, data, error, collapsedRows, toggleRowCollapse }) {
  const mainDataArray = data.filter((row) => !row.parentId);
  const mainData = mainDataArray.map((row) => {
    const lastOrder = findLastOrder(row._id, data);
    if (lastOrder) {
      return {
        ...row,
        completedAt: lastOrder.completedAt,
        status: lastOrder.status,
      };
    } else {
      return { ...row };
    }
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          {columns.map((column) => (
            <TableCell key={column.id}>{column.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              {error}
            </TableCell>
          </TableRow>
        ) : (
          mainData.map((row) => (
            <React.Fragment key={row._id}>
              {data.filter((childRow) => childRow.parentId === row._id).length > 0 ? (
                <TableRow
                  onClick={() => {
                    toggleRowCollapse(row._id);
                  }}
                >
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        toggleRowCollapse(row._id);
                      }}
                    >
                      {collapsedRows.filter((id) => id === row._id).length > 0 ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>

                  {columns.map((column) => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell />
                  {columns.map((column) => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              )}
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
                  <Collapse in={collapsedRows.includes(row._id)}>
                    <Box>
                      <Table >
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell key={column.id}>{column.label}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data
                            .filter(
                              (childRow) =>
                                childRow.parentId === row._id || childRow._id === row._id
                            )
                            .map((childRow) => (
                              <TableRow key={childRow._id}>
                                {columns.map((column) => (
                                  <TableCell align={'center'} key={column.id}>
                                    {childRow[column.id]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))
        )}
      </TableBody>
    </Table>
  );
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  error: PropTypes.string,
  collapsedRows: PropTypes.array.isRequired,
  toggleRowCollapse: PropTypes.func.isRequired,
};

export default DataTable;
