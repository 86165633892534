import React, {useEffect, useRef} from 'react';
import CryptoJS from 'crypto-js';
import {observer} from "mobx-react-lite";
import {useStore} from "../../../Providers";
import gremService from "../../../services/GremService";
import {WalletService} from "services";

const {REACT_APP_ITEZ_TOKEN, REACT_APP_ITEZ_FIAT_USDT_TRC_ADDRESS} = process.env;

const ItezWidget = observer((
    {
        amount = 0,
        paymentId
    }
) => {
    console.log('PaymentId', paymentId);
    const {auth} = useStore();
    const walletService = new WalletService();
    const widgetContainerRef = useRef(null);

    useEffect(() => {
        const loadScript = (src, onload) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = onload;
            document.body.appendChild(script);
        };

        const initializeWidget = async () => {
            try {


                if (window.ItezWidget) {
                    const widgetData = {
                        partner_token: REACT_APP_ITEZ_TOKEN,
                        target_element: 'widget-container',
                        timestamp: new Date().getTime(),
                        to_account: REACT_APP_ITEZ_FIAT_USDT_TRC_ADDRESS,
                        from_currency: 'EUR',
                        from_amount: amount,
                        to_currency: 'TRC20USDT',
                        user_login: 'telman.a@grem.capital', //auth.user.email,
                        partner_operation_id: paymentId,
                        //partner_css: './ItezWidget.css',
                        onError: (error) => {
                            //console.log('ITEZ onError', error);
                        },
                        onLoaded: (data) => {
                            //console.log('ITEZ onLoaded', data);
                        }
                    };

                    const signature = await walletService.generateItezWidgetSignature(widgetData);
                    widgetData.signature = signature.data;

                    window.ItezWidget.run(widgetData, 'POST');
                }
            } catch (e) {
                console.error(e);
            }
        };

        loadScript('https://pay.itez.com/static/main/share/merchant.js', initializeWidget);
    }, []);

    return <div id="widget-container" ref={widgetContainerRef}></div>;
});

export default ItezWidget;
