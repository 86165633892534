import {GremService} from 'services';
import {getUserToken} from 'utils';
import {server} from '../config';

class PropertyService {
    constructor() {
        this._apiBase = server;
        this.service = new GremService();
    }

    async addProperty(data = {}) {
        const res = await this.service.sendFormData(
            '/property',
            'POST',
            data,
            {
                'x-www-access': getUserToken(),
            });
        return res;
    }

    async updateProperty(data = {}) {
        const res = await this.service.sendFormData(
            `/property/${data.get('_id')}`,
            'PUT',
            data,
            {
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getPropertyList(data = {}) {
        const res = await this.service.sendRequest(
            `/property?${data}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getPropertyItem(id) {
        const res = await this.service.sendRequest(
            `/property/${id}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async deletePropertyDraft(propertyId) {
        const res = await this.service.sendRequest(
            `/property/${propertyId}`,
            'DELETE',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
}

export default PropertyService;
