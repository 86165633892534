import {GremService} from '../services/index';
import {setUserInfoToLocalStorage} from '../utils/index';
import {makeAutoObservable} from 'mobx';
import {getUserInfoFromLocalStorage} from "utils/index";
import {Error} from "../pages";
import {History} from 'utils/NavigationHistoryUtils';

class Auth {
    user = null;
    errors = [];
    gremService = new GremService();
    loading = false;
    sending = false;
    isAvailable = false
    isCommunity = false
    isVerified = false
    activeTab = 'info'
    isLogout = false

    constructor() {
        makeAutoObservable(this);
        const userLS = getUserInfoFromLocalStorage();
        if (!this.user && userLS) {
            this.getUser();
        }
    }

    doLogin = async (loginForm) => {
        const result = await this.gremService.doLogin(loginForm);
        const userData = result.data;

        if (userData) {
            setUserInfoToLocalStorage(userData);
            this.user = userData;
            this.errors = [];
            await this.getUser();
        } else {
            this.errors = result.errors;
        }

    }

    async doLogout() {
        if (this.user) {
            this.isLogout = true
            const result = await this.gremService.doLogout();
        }
        this.clearAll();
        localStorage.clear();
        this.isLogout = false
    }

    getUser = async () => {
        this.loading = true;
        const result = await this.gremService.getUser();
        if (result.code === 200) {
            this.user = {...this.user, ...result.data};
            this.isAvailable = this.user.flags.isAvailable;
            this.isCommunity = this.user.flags.isCommunity;
            this.isVerified = this.user.flags.isVerified;
        } else {
            History.navigate('error');
        }
        this.loading = false;
    }

    setUser(user) {
        this.user = user;
    }

    clearAll() {
        this.user = null;
        this.errors = [];
    }

    sendConfirm = async () => {
        this.sending = true
        const result = await this.gremService.resendConfirmMail();
        this.sending = false
        return result;
    }

    confirmUser = async (data) => {
        this.sending = true
        const formData = new FormData();
        formData.append('userData', JSON.stringify(data))
        formData.append('avatar', data.avatar)
        const result = await this.gremService.confirmUser(formData)
        if (result.code === 200) {
            this.user = result.data;
            this.isAvailable = this.user.flags.isAvailable;
            this.isCommunity = this.user.flags.isCommunity;
            this.isVerified = this.user.flags.isVerified;
        }
        this.sending = false
        return result;
    }
}

export default new Auth();
