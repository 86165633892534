import {observer} from "mobx-react-lite";
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import projectTypesDictionary from "../../../../../../assets/translations/Project/projectTypesDictionary";
import {DatePicker} from "@mui/x-date-pickers";
import React from "react";
import projectDictionary from "../../../../../../assets/translations/Project/projectDictionary";
import {useFormStore} from "../../../../../../components/shared/DraftForm/FormStore";
import commonDictionary from "../../../../../../assets/translations/commonDictionary";
import globalDictionary from "../../../../../../assets/translations/globalDictionary";
import {validateAreaRange} from "../../../../../../utils/validate-number";
import apartsType from "../../../../../../assets/translations/apartsType";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const TechnicalForm = observer(()=>{

    const store = useFormStore()

    const wrongSymbols = ['e', 'E', '-', '+', '.', ','];
    return (
        <>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                gap:'10px',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: ' 0px 0px 10px 10px',
                width:'100%'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography sx={{
                            fontSize:'16px',
                            fontWeight:'700'
                        }}>
                            {commonDictionary.get('characteristics')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <DatePicker
                            placeholder={projectDictionary.get(
                                'project_add_unit_finalDate'
                            )}
                            sx={{
                                width:'100%'
                            }}
                            label={projectDictionary.get('project_add_unit_finalDate')}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            yearsPerRow={3}
                            value={store.fields.project.finalDate ? parseInt(store.fields.project.finalDate) : Date.now()}
                            name={'project.finalDate'}
                            type={'date'}
                            onKeyDown={(e) => wrongSymbols.includes(e.key) && e.preventDefault()}
                            onChange={(value)=> {
                                store.handleInputField('project.finalDate',value.getTime())
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_unit_houseClass')}
                            label={projectDictionary.get('project_add_unit_houseClass')}
                            variant="outlined"
                            fullWidth
                            
                            value={store.fields.project.houseClass}
                            name={'project.houseClass'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_unit_sections')}
                            label={projectDictionary.get('project_add_unit_sections')}
                            variant="outlined"
                            fullWidth
                            
                            value={store.fields.project.sections}
                            name={'project.sections'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get(
                                'project_add_unit_floors'
                            )}
                            label={projectDictionary.get('project_add_unit_floors')}
                            variant="outlined"
                            fullWidth
                            value={store.fields.project.floors}
                            name={'project.floors'}
                            type={'number'}
                            inputProps={{min: 0, max: 1000000}}
                            onKeyDown={(e) => {
                                if(wrongSymbols.includes(e.key)) {
                                    e.preventDefault()
                                }

                            }}
                            onInput={(event)=>{
                                const value = event.target.value;
                                if (parseFloat(value) > 1000000) {
                                    event.target.value = '1000000';
                                }
                                const regex = /^\d*\.?\d{0}$/;
                                if (!regex.test(value)) {
                                    event.target.value = value.slice(0, -1);
                                }
                                if (value.length > 1 && parseFloat(value) === 0) {
                                    event.target.value = value.slice(0, -1);
                                }
                            }}

                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get(
                                'project_totalAparts'
                            )}
                            label={projectDictionary.get('project_totalAparts')}
                            variant="outlined"
                            fullWidth
                            value={store?.fields?.project?.apartments?.count}
                            name={'project.apartments.count'}
                            type={'number'}
                            inputProps={{min: 0, max: 1000000}}
                            onKeyDown={(e) => {
                                if(wrongSymbols.includes(e.key)) {
                                    e.preventDefault()
                                }

                            }}
                            onInput={(event)=>{
                                const value = event.target.value;
                                if (parseFloat(value) > 1000000) {
                                    event.target.value = '1000000';
                                }
                                const regex = /^\d*\.?\d{0}$/;
                                if (!regex.test(value)) {
                                    event.target.value = value.slice(0, -1);
                                }
                                if (value.length > 1 && parseFloat(value) === 0) {
                                    event.target.value = value.slice(0, -1);
                                }
                            }}

                            onChange={store.handleInputChange}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_unit_condition')}
                            label={projectDictionary.get('project_add_unit_condition')}
                            variant="outlined"
                            fullWidth
                            
                            value={store.fields.project.apartments.condition}
                            name={'project.apartments.condition'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputLabel>
                            <Typography variant={'body2'} color={'#A2A2A2'}>
                                {projectDictionary.get('project_add_unit_area')}
                            </Typography>
                        </InputLabel>
                        <Grid container gridRow spacing={2} alignItems={'center'}>
                            <Grid item xs={5.5}>
                                <TextField
                                    label={commonDictionary.get('count_from')}
                                    fullWidth
                                    type={'number'}
                                    name={'project.apartments.area.from'}
                                    value={store?.fields?.project?.apartments?.area?.from}
                                    inputProps={{min: 0, max: parseFloat(store?.fields?.project?.apartments?.area?.to) ?? 1000000.00}}
                                    onKeyDown={(e) => {
                                        if(wrongSymbols.includes(e.key)) {
                                            e.preventDefault()
                                        }

                                    }}
                                    onInput={(event)=>{
                                        const value = event.target.value;
                                        const regex = /^\d*\.?\d{0,2}$/;
                                        if (!regex.test(value)) {
                                            event.target.value = value.slice(0, -1);
                                        }
                                        if (value.length > 1 && parseFloat(value) === 0) {
                                            event.target.value = value.slice(0, -1);
                                        }
                                    }}
                                    error={!!validateAreaRange(store?.fields?.project?.apartments?.area?.from, store?.fields?.project?.apartments?.area?.to)}
                                    onChange={store.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography textAlign={'center'}>-</Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                                <TextField
                                    label={commonDictionary.get('count_to')}
                                    fullWidth
                                    type={'number'}
                                    name={'project.apartments.area.to'}
                                    value={store?.fields?.project?.apartments?.area?.to}
                                    inputProps={{min: parseFloat(store?.fields?.project?.apartments?.area?.from) ??0, max: 1000000.00}}
                                    onKeyDown={(e) => {
                                        if(wrongSymbols.includes(e.key)) {
                                            e.preventDefault()
                                        }

                                    }}
                                    onInput={(event)=>{
                                        const value = event.target.value;
                                        const regex = /^\d*\.?\d{0,2}$/;
                                        if (!regex.test(value)) {
                                            event.target.value = value.slice(0, -1);
                                        }
                                        if (value.length > 1 && parseFloat(value) === 0) {
                                            event.target.value = value.slice(0, -1);
                                        }

                                    }}
                                    error={!!validateAreaRange(store?.fields?.project?.apartments?.area?.from, store?.fields?.project?.apartments?.area?.to)}
                                    onChange={store.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  style={{paddingTop:'0px'}}
                            >
                                <FormHelperText
                                    error={!!validateAreaRange(store?.fields?.project?.apartments?.area?.from, store?.fields?.project?.apartments?.area?.to)}
                                >
                                    {validateAreaRange(store.fields?.project?.apartments?.area?.from,store.fields?.project?.apartments?.area?.to)}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1} sx={{marginTop:'40px'}}>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} color={'#A2A2A2'}>
                                {projectDictionary.get('project_add_unit_type')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={2} sx={{
                            maxWidth: '700px !important',
                        }}>
                            {apartsType.getAllValueLabel().map((item)=>{
                                return <Grid sx={{
                                    position:'relative',
                                    left: '12px'
                                }} item xs={6} sm={4} md={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={`project.apartments.apartType.${item.value}`}
                                                value={store.fields.project.apartments.apartType}
                                                checked={store.fields.project.apartments.apartType.includes(item.value)}
                                                onChange={()=>{store.handleAddRemoveArrayElem(`project.apartments.apartType`,item.value)}}
                                            />
                                        } label={item.label}/>

                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{marginTop:'40px'}}>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} color={'#A2A2A2'}>
                                {projectDictionary.get('project_add_unit_furniture')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                name={'project.apartments.furniture'}
                                value={store.fields.project.apartments.furniture}
                                onChange={store.handleInputRadio}
                                row>
                                <Typography alignItems={'center'}>
                                    <Radio
                                        sx={{width:'20px',mr:'10px'}}
                                        value={true}
                                        checked={store.fields.project.apartments.furniture === true}
                                    />
                                    {projectDictionary.get('project_add_unit_furniture_true')}
                                </Typography>
                                <Typography ml={5} alignItems={'center'}>
                                    <Radio
                                        value={false}
                                        checked={store.fields.project.apartments.furniture === false}
                                    />
                                    {projectDictionary.get('project_add_unit_furniture_false')}
                                </Typography>
                            </RadioGroup>
                        </Grid>
                    </Grid>

                </Grid>


            </Box>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                gap:'15px',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: ' 10px 10px 10px 10px',
                width:'100%',
                marginTop:'30px'
            }}>

                <Typography sx={{
                    fontSize:'16px',
                    fontWeight:'700',
                }}>
                    {projectDictionary.get('project_add_unit_parking_title')}
                </Typography>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_unit_parking_total'
                        )}
                        label={projectDictionary.get('project_add_unit_parking_total')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.parking?.total}
                        name={'project.parking.total'}
                        type={'number'}
                        inputProps={{min: 0, max: 1000000}}
                        onKeyDown={(e) => {
                            if(wrongSymbols.includes(e.key)) {
                                e.preventDefault()
                            }

                        }}
                        onInput={(event)=>{
                            const value = event.target.value;
                            if (parseFloat(value) > 1000000) {
                                event.target.value = '1000000';
                            }
                            const regex = /^\d*\.?\d{0}$/;
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        }}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Box sx={{
                    display:'flex',
                    flexDirection:'row',
                    gap:'70px',
                    marginTop:'13px'
                }}>
                    <FormControlLabel
                        style={{
                            marginLeft:'0px'
                        }}
                        control={
                            <Checkbox
                                name={`project.parking.undergroundParking`}
                                title={projectDictionary.get('project_add_parking_underground')}
                                value={store.fields.project.parking.undergroundParking}
                                checked={store.fields.project.parking.undergroundParking === true}
                                onChange={store.handleToggleCheckBox}
                            />
                        } label={projectDictionary.get('project_add_parking_underground')}/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={`project.parking.groundParking`}
                                value={store.fields.project.parking.groundParking}
                                checked={store.fields.project.parking.groundParking === true}
                                onChange={store.handleToggleCheckBox}
                            />
                        } label={projectDictionary.get('project_add_parking_ground')}/>
                </Box>
            </Box>
        </>
    );


})

export default TechnicalForm;