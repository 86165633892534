import React from 'react';
import style from './Footer.module.css';
import {getActiveLanguageFromLS} from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';
import {useNavigate} from 'react-router-dom';

function Footer() {
    const currentYear = new Date().getFullYear();
    const language = getActiveLanguageFromLS();
    const navigate = useNavigate();
    const handleNavigate = (url) => {
        navigate(url);
    };
    return (
        <footer className={style.footer}>
            <div className={style.footerWrapper}>
                <a
                    href={'https://grem.capital/'}
                    target={'_blank'}
                    rel="noreferrer"
                >
                    GREM CAPITAL LTD
                </a>
                <nav className={style.footerNavigation}>
                    <ul>
                        <li>
                            <a href={'https://grem.capital/terms'} target={'_blank'} rel="noreferrer">
                                {globalDictionary.get('layout_cabinet_footer_sub_terms', language)}
                            </a>
                        </li>
                        <li>
                            <a href={'https://grem.capital/privacy'} target={'_blank'} rel="noreferrer">
                                {globalDictionary.get('layout_cabinet_footer_sub_privacy', language)}
                            </a>
                        </li>
                        <li>
                            <a href={'https://grem.capital/anla'} target={'_blank'} rel="noreferrer">
                                {globalDictionary.get('layout_cabinet_footer_sub_anti_money', language)}
                            </a>
                        </li>
                        <li>
                            <a href={'https://grem.capital/gdpr'} target={'_blank'} rel="noreferrer">
                                {'GDPR'}
                            </a>
                        </li>
                    </ul>
                </nav>
                <a href={'https://find-and-update.company-information.service.gov.uk/company/14177574'}
                   target={'_blank'}
                   rel="noreferrer">
                <span>
                  {`${globalDictionary.get(
                      'layout_cabinet_footer_copyright',
                      language
                  )} ${currentYear}`}
                </span>
                </a>
            </div>
        </footer>
    );
}

export default Footer;
