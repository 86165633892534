import { getActiveLanguageFromLS } from 'utils/index';
import languageStore from "./languageStore";

const leadStatuses = {
  elements: [
    {
      code: 'new',
      value: 'new',
      labelEn: 'New',
      labelRu: 'Открыт',
      labelUa: 'Відкрито',
      labelDe: 'Offen',
    },
    {
      code: 'in progress',
      value: 'in progress',
      labelEn: 'In progress',
      labelRu: 'В процессе',
      labelUa: 'В процесі',
      labelDe: 'In Bearbeitung',
    },
    {
      code: 'processing',
      value: 'processing',
      labelEn: 'Processing',
      labelRu: 'Обработка',
      labelUa: 'Обробка',
      labelDe: 'Verarbeitung',
    },
    {
      code: 'agreement',
      value: 'agreement',
      labelEn: 'Agreement',
      labelRu: 'Согласование',
      labelUa: 'Узгодження',
      labelDe: 'Vereinbarung',
    },
    {
      code: 'closed',
      value: 'closed',
      labelEn: 'Success',
      labelRu: 'Успешно',
      labelUa: 'Успішно',
      labelDe: 'Erfolg',
    },
    // {
    //   code: 'closed',
    //   value: 'closed',
    //   labelEn: 'Closed',
    //   labelRu: 'Закрыто',
    //   labelUa: 'Закрито',
    //   labelDe: 'Geschlossen',
    // },
    {
      code: 'not target',
      value: 'not target',
      labelEn: 'Not target',
      labelRu: 'Не целевой',
      labelUa: 'Не цільовий',
      labelDe: 'Nicht das Ziel',
    },
    {
      code: 'failed',
      value: 'failed',
      labelEn: 'Failed',
      labelRu: 'Неудача',
      labelUa: 'Невдача',
      labelDe: 'Fehlgeschlagen',
    },
  ],
  get: function (key, lang) {
    lang = languageStore.activeLanguage;
    key = key.toLowerCase();
    var ar = this.elements;

    if (lang === 'eng') {
      var a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelEn;
      }
    } else if (lang === 'rus') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelRu;
      }
    } else if (lang === 'ukr') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelUa;
      }
    } else if (lang === 'deu') {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelDe;
      }
    } else {
      return '';
    }
  },

  getAllValueLabel: function (lang) {
    lang = languageStore.activeLanguage;

    const functor = function (elem, language) {
      switch (language) {
        case 'eng':
          return { value: elem.value, label: elem.labelEn };
        case 'rus':
          return { value: elem.value, label: elem.labelRu };
        case 'ukr':
          return { value: elem.value, label: elem.labelUa };
        case 'deu':
          return { value: elem.value, label: elem.labelDe };
        default:
          return { value: elem.value, label: elem.labelEn };
      }
    };

    const roles = this.elements.map((item) => functor(item, lang));
    return roles;
  },
};
export default leadStatuses;
