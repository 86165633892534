import React, {useContext, useState} from 'react';
import {
    Avatar, Box,
    CircularProgress,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Tooltip, Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import globalDictionary from 'assets/translations/globalDictionary';
import {getActiveLanguageFromLS, isCommunity} from 'utils';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import {ImageChecker} from "components";
import {useStore} from "Providers";

const HeaderProfileMenu = observer(() => {
    const language = getActiveLanguageFromLS();
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const {auth} = useStore();
    const avatar = auth?.user?.avatar;
    const usetTag = auth?.user?.email;

    const handleMenuOpen = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title={usetTag}>
                <IconButton
                    onClick={handleMenuOpen}
                    size="small"
                    sx={{ml: 2}}
                    aria-controls={openMenu ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                >
                    {!auth?.user ? (
                        <CircularProgress/>
                    ) : (
                        <ImageChecker
                            src={avatar}
                            sx={{
                                width: 32,
                                height: 32,
                                borderRadius: '50%',
                                '& img': {
                                    objectFit: 'contain',
                                },
                            }}/>
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                left={0}
                PaperProps={{
                    style: {
                        right: 26,
                    },
                    elevation: 0,
                    sx: {
                        left: 'initial !important',
                        top: '50px !important',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <MenuItem
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px',
                        minHeight: '32px',
                        width: '100%',
                    }}
                >
                    <Link
                        to={''}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'inherit',
                            width: '100%',
                            justifyContent: 'space-around',
                            padding: '5px',
                        }}
                    >
                        {globalDictionary.get('layout_cabinet_header_profile_menu_my', language)}
                    </Link>
                </MenuItem>
                <Divider/>
                <MenuItem component={Link} to="/auth/logout" sx={{ minHeight: '32px', width: '100%' }}>
                    <Box display="flex" alignItems="center">
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2">{globalDictionary.get('layout_cabinet_header_profile_menu_exit', language)}</Typography>
                    </Box>
                </MenuItem>

            </Menu>
        </>
    )
        ;
});

export default HeaderProfileMenu;
