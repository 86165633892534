import PropTypes from 'prop-types';
import {Draggable} from '@hello-pangea/dnd';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useBoolean} from 'hooks/use-boolean';
import {bgBlur} from 'theme/css';
import Iconify from 'components/v2/iconify';
import KanbanDetails from './kanban-details';
import Image from 'mui-image';
import leadInIcon from '@/../assets/icons/lead-in.svg';
import leadOutIcon from '@/../assets/icons/lead-out.svg';
import React from "react";
import {useStore, useWebsocketStore} from "../../../Providers";

export default function KanbanTaskItem({task, index, isTaskMove, sx, ...other}) {
    if (!task) return;
    const websocket = useWebsocketStore();
    const theme = useTheme();
    const openDetails = useBoolean();
    const taskCreatedAt = new Date(task.createdAt).toLocaleDateString();
    const taskUpdatedAt = new Date(task.updatedAt).toLocaleDateString();
    return (<>
        <Draggable draggableId={task.id} index={index} isDragDisabled={isTaskMove(task)}>
            {(provided, snapshot) => (
                <Paper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={openDetails.onTrue}
                    sx={{
                        borderRadius: '8px',
                        padding: '0px 15px 10px',
                        position: 'relative',
                        backgroundColor: 'white',
                        boxShadow: theme.customShadows.z1,
                        '&:hover': {
                            boxShadow: theme.customShadows.z20,
                        }, ...(openDetails.value && {
                            boxShadow: theme.customShadows.z20,
                        }), ...(snapshot.isDragging && {
                            boxShadow: theme.customShadows.z20, ...bgBlur({
                                opacity: 0.48, color: theme.palette.background.default,
                            }),
                        }),
                        ...sx,
                    }}
                    {...other}
                >
                    {websocket?.notification?.crm?.items?.[task.id]?.newItem === true &&
                        <Box sx={{
                            width: '26px',
                            height: '26px',
                            borderRadius: '50%',
                            background: '#e7a66d',
                            position: 'absolute',
                            right: '-5px',
                            top: '-5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Typography
                                sx={{fontSize: '11px', transform: 'rotate(15deg)', color: 'black'}}
                            >new</Typography>
                        </Box>
                    }
                    <Stack
                        spacing={'12px'}
                        sx={{
                            padding: '12px 5px 0px',
                            position: 'relative',
                            borderTop: `solid 2px ${task.isOutcome ? '#F2994A' : '#219653'}`,
                        }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography sx={{
                                fontSize: '15px', fontWeight: '700', textTransform: 'capitalize',
                            }}>{task.customerName}</Typography>
                            {task.isOutcome ? (
                                <Image src={leadOutIcon} alt={'lead out'} width={'20px'}/>) : (
                                <Image src={leadInIcon} alt={'lead in'} width={18} height={18}/>)}
                        </Stack>
                        {
                            task.building_id && (
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography sx={{
                                        fontSize: '15px', textTransform: 'capitalize',
                                    }}>{task.building_id.name}</Typography>
                                </Stack>
                            )
                        }
                        <Stack direction={'row'}
                               alignItems={'center'}
                               gap={'30px'}
                        >
                            <Box sx={{
                                display: 'flex', alignItems: 'center', gap: '7px',
                            }}>
                                <Iconify width={20} icon="eva:calendar-outline" color={'#929292'}/>
                                <Typography
                                    component="span"
                                    sx={{
                                        color: '#929292', fontSize: '12px', textTransform: 'capitalize'
                                    }}>
                                    {taskCreatedAt}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex', alignItems: 'center', gap: '7px',
                            }}>
                                <Iconify width={20} icon="material-symbols:update" color={'#929292'}/>
                                <Typography
                                    component="span"
                                    sx={{
                                        color: '#929292', fontSize: '12px', textTransform: 'capitalize'
                                    }}>
                                    {taskUpdatedAt}
                                </Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Paper>
            )}
        </Draggable>

        <KanbanDetails
            task={task}
            change={isTaskMove}
            openDetails={openDetails.value}
            onCloseDetails={openDetails.onFalse}
        />
    </>);
}

KanbanTaskItem.propTypes = {
    index: PropTypes.number, sx: PropTypes.object, task: PropTypes.object, isTaskMove: PropTypes.bool,
};
