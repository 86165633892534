import React from 'react';
import { ComingSoon, Error } from '../pages';

const commonRoutes = [
  {
    path: 'soon',
    element: <ComingSoon />,
  },
  {
    path: 'error',
    element: <Error />,
  },
];

export default commonRoutes;
