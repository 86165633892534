import {observer} from "mobx-react-lite";
import {Box, Typography} from "@mui/material";
import {ImageChecker} from "../../index";
import PenClipElipse from "assets/icons/pen-clip-elipse.svg"
import TrashBlankElipse from "assets/icons/trash-blank-alt-elipse.svg"

const DocumentView = observer(({image, name, subFields, handleEdit, handleDelete}) => {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {
                xs: 'column',
                sm: 'row',
            },
            alignItems: 'center',
            borderRadius: '10px',
            border: '1px solid #F2F3F4',
            background: '#F2F3F4',
            justifyContent: 'space-between',
            padding: '10px',
            marginBottom: '20px'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: {
                        xs: '5px',
                        sm: '20px',
                    },
                    alignItems: 'center',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                }}>
                <ImageChecker
                    src={image}
                    width={'50px'}
                    height={'50px'}
                />
                <Typography>
                    {name}
                </Typography>
                {subFields && subFields}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                    mt: {
                        xs: '10px',
                        sm: '0',
                    },
                }}
            >
                {!!handleEdit &&
                    <img src={PenClipElipse}
                         onClick={handleEdit}
                         style={{
                             cursor:'pointer'}}
                    />
                }
                {!!handleDelete &&
                    <img src={TrashBlankElipse}
                         onClick={handleDelete}
                         style={{
                             cursor:'pointer'}}
                    />
                }
            </Box>
        </Box>
    )

})

export default DocumentView;
