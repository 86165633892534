import React, {useState} from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

function PhoneInput(props) {
    const [value, setValue] = useState('');

    const handleChange = (phone) => {
        setValue(phone);
        if (props.onChange) {
            props.onChange(phone);
        }
    };

    return (
        <MuiPhoneNumber
            defaultCountry={'us'}
            value={value}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            {...props}
        />
    );
}

export default PhoneInput;
