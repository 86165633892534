import { useSnackbar } from 'notistack';
import React from 'react';
import { Button, IconButton } from "@mui/material";

const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

export const snackActions = {
  success(msg) {
    this.toast(msg, 'success');
  },
  warning(msg) {
    this.toast(msg, 'warning');
  },
  info(msg) {
    this.toast(msg, 'info');
  },
  error(msg) {
    this.toast(msg, 'error');
  },
  action() {
    return (
        <IconButton
            onClick={() => useSnackbarRef.closeSnackbar()}
            style={{
              fontSize: '12px',
              padding: '4px',
            }}
        >
          <span style={{ color: 'inherit' }}>X</span>
        </IconButton>
    );
  },
  toast(msg, variant = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      style: {
        marginRight: '40px',
      },
      disableWindowBlurListener: true,
      autoHideDuration: 6000,
      preventDuplicate: true,
      action: snackActions.action(),
    });
  },
};
