import { Box } from '@mui/system';
import { Button, CircularProgress, Typography } from '@mui/material';
import copy from 'clipboard-copy';
import { snackActions } from '../../../utils/SnackBarUtils';
import globalDictionary from 'assets/translations/globalDictionary';
import { getActiveLanguageFromLS } from 'utils';
import { GremService } from 'services';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../Providers';

const LandingSubBlock = observer(({ landing: landingItem, contract, type, targetId }) => {
  const [landing, setLanding] = useState(landingItem);
  const [loading, setLoading] = useState(false);
  const { auth } = useStore();
  const gremService = new GremService();
  const language = getActiveLanguageFromLS();

  const copyLandingLink = () => {
    const landingLink = `https://${process.env.REACT_APP_UNIVERSAL_LANDING_URL}/${landing?._id}`;
    copy(landingLink);
    snackActions.success(globalDictionary.get('profile_partners_copy_success', language));
  };

  const createLanding = async () => {
    setLoading(true);
    const payload = {
      targetId,
      type,
    };

    const response = await gremService.createTargetLanding(payload);
    setLoading(false);
    if (response.code !== 200) {
      snackActions.error('Something went wrong. Please try again later.');
      return;
    }

    setLanding(response.data);
    snackActions.success('Landing created successfully');
  };

  if (loading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <>
      {landing && contract.status === 'approved' && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography>{`${globalDictionary.get('views', language)} ${
            landing?.analytics?.views || 0
          }`}</Typography>
          <Button size="large" variant="text" color="secondary" onClick={copyLandingLink}>
            {globalDictionary.get('copy_landing_link', language)}
          </Button>
        </Box>
      )}
      {!landing && contract.status === 'approved' && (
        <Button
          size="small"
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={createLanding}
        >
          {globalDictionary.get('get_object_landing', language)}
        </Button>
      )}
    </>
  );
});

export default LandingSubBlock;
