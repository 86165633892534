import {
    Box,
    Button,
    FormControl,
    Grid, IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {AIInput, BlockForm, ComplexApartInfoModal, ModalWindow} from "components";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import apartsType from "assets/translations/apartsType";
import globalDictionary from "assets/translations/globalDictionary";
import BlockList from "components/shared/BlockForm/BlockList";
import {FileLoader, ImageChecker} from "../../../../../../components";
import ImageCircle from "../../../../../../components/shared/PhotosLoader/image_circle.svg";
import TrashButton from "../../../../../../components/shared/PhotosLoader/trash_button.svg";
import EditCircle from "../../../../../../components/shared/BlockForm/edit_circle.svg";
import commonDictionary from "../../../../../../assets/translations/commonDictionary";
import projectDictionary from "../../../../../../assets/translations/Project/projectDictionary";

const PlansForm = observer(({store})=>{

    const wrongSymbols = ['e', 'E', '-', '+'];
    const [open, setOpen] = useState(false);
    const toggleModal = () => {
        setOpen(!open);
    }
    const floor = {
        number: '',
        floorPhotos: []
    }
    const [floorPlan, setFloorPlan] = useState(floor)

    const handleInputChangeFloor = (event) => {
        const {name, value} = event.target;
        setFloorPlan({...floorPlan, [name]: value})
    }
    const addFloor = () => {
        store.handleInputArrayElem('project.plans', floorPlan)
        setFloorPlan(floor)
    }
    const getMainPhoto = (item) => {
        const main = item['floorPhotos'].filter((item) => item.isMain)
        return main[0] ? main[0].url : item['floorPhotos'][0].url;
    }
    const getTextField = (item) => {
        return <Typography variant={'caption'} component={'h3'} sx={{
            fontSize: '14px',
        }}>
            {`${commonDictionary.get('floor')}: ${item['number']}`}
        </Typography>
    }

    return (
        <>
            <Box sx={{backgroundColor: 'white', padding: '20px', borderRadius: ' 5px 5px 5px 5px', width:'100%'}}>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            {projectDictionary.get('project_add_unit_floor_plans')}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label={commonDictionary.get('floor_number')}
                                name={'number'}
                                value={floorPlan?.number}
                                onChange={handleInputChangeFloor}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FileLoader
                            _data={floorPlan}
                            customHandleChange={(updatedData) => {
                                setFloorPlan((prev) => ({ ...prev, floorPhotos: updatedData.floorPhotos }));
                            }}

                            configs={{
                                field: 'floorPhotos',
                                minKey: 'complex_plans_min_key_photo',
                                maxKey: 'complex_plans_min_key_photo',
                                confName: 'project.unit',
                                buttonText:projectDictionary.get('project_add_unit_floor_plans_load'),
                            }}
                        />

                        <Button
                            variant={'contained'}
                            onClick={addFloor}
                            sx={{
                                display: floorPlan.number === '' || floorPlan.floorPhotos.length === 0 ? 'none' : 'flex'
                            }}
                            disabled={floorPlan.number === '' || floorPlan.floorPhotos.length === 0}
                        >
                            <img src={ImageCircle}/>
                            <Typography ml={'10px'}>
                                {projectDictionary.get('project_add_unit_floor_plans_save')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid container gridRow>
                {Array.isArray(store?.fields?.project?.plans) && store?.fields?.project?.plans?.map((item, index) => (
                    <Box sx={{
                        backgroundColor: 'white',
                        marginTop: '20px',
                        padding: '20px 20px 15px',
                        marginRight: '20px',
                        borderRadius: ' 10px 10px 10px 10px',
                        maxWidth: '220px',
                        cursor: 'pointer',
                        position: 'relative',
                        '&:hover *': {
                            visibility: 'visible'
                        },
                        '&:hover h3': {
                            color: '#D1B684'
                        }
                    }}>
                        <Grid
                            container
                            sx={{
                                borderRadius: '5px',
                                border: '1px solid #DEDEDE',
                                justifyContent: 'center',
                            }}
                        >
                            <IconButton
                                color="primary"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 1000,
                                    visibility: 'hidden',
                                }}
                                onClick={() => {
                                    store.handleDeleteArrayElem('project.plans', index)
                                }}
                                size="large"
                            >
                                <img src={TrashButton}/>
                            </IconButton>
                            <IconButton
                                color="primary"
                                sx={{
                                    position: 'absolute',
                                    top: 40,
                                    right: 0,
                                    zIndex: 1000,
                                    visibility: 'hidden',
                                }}
                                onClick={() => {
                                    setFloorPlan(store?.fields?.project?.plans[index])
                                    store.handleDeleteArrayElem('project.plans', index)
                                }}
                                size="large"
                            >
                                <img src={EditCircle}/>
                            </IconButton>

                            <ImageChecker
                                src={getMainPhoto(item)}
                                style={{
                                    objectFit: 'contain',
                                    display: 'block',
                                }}
                                height={120}
                                width={180}/>
                        </Grid>
                        <Typography
                            fullWidth
                            textAlign={'left'}
                            sx={{
                                mt: '15px'
                            }}
                        >
                            {getTextField(item)}
                        </Typography>
                    </Box>
                ))}
            </Grid>
        </>
    )
})

export default PlansForm;