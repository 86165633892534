// TranslationHandler
/**
 * @param {string} value - The value to be translated.
 * @param {function} setTranslation - A function to set the translated value.
 * @returns {void}
 */

// TranslationSetter
/**
 * @param {string} [translation] - The translated value. Optional.
 * @returns {void}
 */

// CacheProvider
/**
 * @typedef {Object} CacheProvider
 * @property {function} get - A function to retrieve a cached translation.
 *   @param {string} language - The language of the translation.
 *   @param {string} key - The key of the translation.
 *   @returns {Promise<string|undefined>} - A Promise that resolves to the cached translation or undefined.
 * @property {function} set - A function to set a cached translation.
 *   @param {string} language - The language of the translation.
 *   @param {string} key - The key of the translation.
 *   @param {string} translation - The translation to be cached.
 *   @returns {void}
 */

// TranslationOptions
/**
 * @typedef {Object} TranslationOptions
 * @property {string} to - The target language for translation.
 * @property {string} from - The source language of the translation.
 * @property {string} apiKey - The API key for the translation service.
 */
