import React from 'react';
import languageStore from "../languageStore";

const projectDictionary = {
    dictionary: {
        project_add_construction_title: {
            eng: 'Adding a development project',
            rus: 'Добавление девелоперского проекта',
            ukr: 'Додавання девелоперського проекту',
            deu: 'Hinzufügen eines Entwicklungsprojekts',
        },
        project_add_land_title: {
            eng: 'Adding a land plot',
            rus: 'Добавление земельного участка',
            ukr: 'Додавання земельної ділянки',
            deu: 'Hinzufügen eines Grundstücks',
        },
        project_add_unit_title: {
            eng: 'Adding individual units',
            rus: 'Добавление отдельных юнитов',
            ukr: 'Додавання окремих юнітів',
            deu: 'Hinzufügen einzelner Einheiten',
        },
        project_add_send_button: {
            eng: 'Adding a development project',
            rus: 'Отправить на токенизацию',
            ukr: 'Додавання девелоперського проекту',
            deu: 'Hinzufügen eines Entwicklungsprojekts',
        },
        common_step: {
            eng: 'General Information about the Object',
            rus: 'Общее об объекте',
            ukr: 'Загальна інформація про об\'єкт',
            deu: 'Allgemeine Informationen über das Objekt',
        },
        technical_step: {
            eng: 'Technical Parameters',
            rus: 'Технические параметры',
            ukr: 'Технічні параметри',
            deu: 'Technische Parameter',
        },
        financial_step: {
            eng: 'Financial Parameters',
            rus: 'Финансовые параметры',
            ukr: 'Фінансові параметри',
            deu: 'Finanzielle Parameter',
        },
        photo_step: {
            eng: 'Photos',
            rus: 'Фотографии',
            ukr: 'Фотографії',
            deu: 'Fotos',
        },
        documents_step: {
            eng: 'Documents',
            rus: 'Документы',
            ukr: 'Документи',
            deu: 'Dokumente',
        },
        plans_step: {
            eng: 'Floor plans',
            rus: 'Планировки',
            ukr: 'Планування',
            deu: 'Grundrisse',
        },
        tokenization_step: {
            eng: 'Tokenization',
            rus: 'Токенизация',
            ukr: 'Токенізація',
            deu: 'Tokenisierung',
        },
        project_add_common_title: {
            eng: 'General Information about the Object',
            rus: 'Общие сведения об объекте',
            ukr: 'Загальна інформація про об’єкт',
            deu: 'Allgemeine Informationen über das Objekt',
        },
        project_add_name: {
            eng: 'Project Name',
            rus: 'Название проекта',
            ukr: 'Назва проекту',
            deu: 'Projektname',
        },
        project_add_owner: {
            eng: 'Project Owner',
            rus: 'Собственник проекта',
            ukr: 'Власник проекту',
            deu: 'Projektinhaber',
        },
        project_add_builder: {
            eng: 'Construction Company',
            rus: 'Компания застройщик',
            ukr: 'Будівельна компанія',
            deu: 'Bauunternehmen',
        },
        project_add_architect: {
            eng: 'Chief Project Architect',
            rus: 'Главный архитектор проекта',
            ukr: 'Головний архітектор проєкту',
            deu: 'Hauptarchitekt des Projekts',
        },
        project_add_stage: {
            eng: 'Project Readiness Stage',
            rus: 'Стадия готовности проекта',
            ukr: 'Стадія готовності проєкту',
            deu: 'Projektbereitstellungsphase',
        },
        project_add_description: {
            eng: 'Object Description (Concept)',
            rus: 'Описание объекта (концепция)',
            ukr: 'Опис об\'єкта (концепція)',
            deu: 'Objektbeschreibung (Konzept)',
        },
        project_add_advantage: {
            eng: 'Project Advantages',
            rus: 'Преимущества проекта',
            ukr: 'Переваги проєкту',
            deu: 'Projektvorteile',
        },
        project_add_social: {
            eng: 'Social Efficiency',
            rus: 'Социальная эффективность',
            ukr: 'Соціальна ефективність',
            deu: 'Soziale Effizienz',
        },
        project_add_presentation: {
            eng: 'Object Presentation',
            rus: 'Презентация объекта',
            ukr: 'Презентація об\'єкта',
            deu: 'Objektpräsentation',
        },
        project_add_technical_title: {
            eng: 'Technical specifications',
            rus: 'Технические характеристики',
            ukr: 'Технічні характеристики',
            deu: 'Technische Spezifikationen',
        },
        project_add_projectType: {
            eng: 'Project Type*',
            rus: 'Тип проекта*',
            ukr: 'Тип проєкту*',
            deu: 'Projekttyp*',
        },
        project_add_floors: {
            eng: 'Number of Floors',
            rus: 'Количество этажей',
            ukr: 'Кількість поверхів',
            deu: 'Anzahl der Etagen',
        },
        project_add_finalDate: {
            eng: 'Project Completion Date',
            rus: 'Финальная дата реализации проекта',
            ukr: 'Кінцева дата реалізації проєкту',
            deu: 'Projektabschlussdatum',
        },
        project_add_area_total: {
            eng: 'Total Construction Area (sq.m)',
            rus: 'Общая площадь строительства (кв. м)',
            ukr: 'Загальна площа будівництва (кв.м)',
            deu: 'Gesamtbaufläche (qm)',
        },
        project_add_area_building: {
            eng: 'Building Area (sq.m)',
            rus: 'Площадь застройки (кв. м)',
            ukr: 'Площа забудови (кв.м)',
            deu: 'Bebauungsfläche (qm)',
        },
        project_add_area_effective: {
            eng: 'Effective Area (sq.m)',
            rus: 'Полезная площадь (кв. м)',
            ukr: 'Корисна площа (кв.м)',
            deu: 'Nutzfläche (qm)',
        },
        project_add_area_land: {
            eng: 'Land Area (sq.m)',
            rus: 'Площадь земельного участка (кв. м)',
            ukr: 'Площа земельної ділянки (кв.м)',
            deu: 'Grundstücksfläche (qm)',
        },
        project_add_liveArea_title: {
            eng: 'Residential Property Features',
            rus: 'Характеристики жилой недвижимости',
            ukr: 'Характеристики житлової нерухомості',
            deu: 'Wohnimmobilieneigenschaften',
        },
        project_add_liveArea_total: {
            eng: 'Residential Area',
            rus: 'Площадь жилой недвижимости',
            ukr: 'Площа житлової нерухомості',
            deu: 'Wohnfläche',
        },
        project_add_liveArea_count: {
            eng: 'Number of Apartments',
            rus: 'Квартиры данного типа',
            ukr: 'Квартири цього типу',
            deu: 'Anzahl der Wohnungen',
        },
        project_add_technical_office_title: {
            eng: 'Office Property Features',
            rus: 'Характеристики офисной недвижимости',
            ukr: 'Характеристики офісної нерухомості',
            deu: 'Büroimmobilieneigenschaften',
        },
        project_add_office_total: {
            eng: 'Total Area (sq.m)',
            rus: 'Общая площадь, кв.м',
            ukr: 'Загальна площа, кв.м',
            deu: 'Gesamtfläche (qm)',
        },
        project_add_office_effective: {
            eng: 'Effective Area (for rent) (sq.m)',
            rus: 'Полезная площадь (площадь под аренду), кв.м',
            ukr: 'Корисна площа (для оренди), кв.м',
            deu: 'Nutzfläche (zur Vermietung) (qm)',
        },
        project_add_office_rooms: {
            eng: 'Number of Rooms',
            rus: 'Количество кабинетов',
            ukr: 'Кількість кабінетів',
            deu: 'Anzahl der Räume',
        },
        project_add_storeys: {
            eng: 'Number of storeys',
            rus: 'Этажность',
            ukr: 'Поверховість',
            deu: 'Anzahl der Stockwerke',
        },
        project_add_utility: {
            eng: 'Utility System',
            rus: 'Коммуникации',
            ukr: 'Комунальні системи',
            deu: 'Versorgungssystem',
        },
        project_add_floorHeight: {
            eng: 'Floor Height',
            rus: 'Высота этажа',
            ukr: 'Висота поверху',
            deu: 'Geschosshöhe',
        },
        project_add_lift: {
            eng: 'Elevator',
            rus: 'Лифт',
            ukr: 'Ліфт',
            deu: 'Aufzug',
        },
        project_add_restaurant_cafe_title: {
            eng: 'Restaurant and Café Characteristics',
            rus: 'Характеристики ресторанов и кафе',
            ukr: 'Характеристики ресторанів і кафе',
            deu: 'Eigenschaften von Restaurants und Cafés',
        },
        project_add_restaurant_cafe_total: {
            eng: 'Total Area, sq.m',
            rus: 'Общая площадь, кв.м',
            ukr: 'Загальна площа, кв.м',
            deu: 'Gesamtfläche, qm',
        },
        project_add_restaurant_cafe_effective: {
            eng: 'Effective Area (Dining Hall Area), sq.m',
            rus: 'Полезная площадь (площадь зала), кв.м',
            ukr: 'Корисна площа (площа залу), кв.м',
            deu: 'Nutzfläche (Saalfläche), qm',
        },
        project_add_restaurant_cafe_seats: {
            eng: 'Number of Seats',
            rus: 'Количество посадочных мест',
            ukr: 'Кількість місць для сидіння',
            deu: 'Anzahl der Sitzplätze',
        },
        project_add_restaurant_cafe_kitchen: {
            eng: 'Kitchen Area, sq.m',
            rus: 'Площадь кухни, кв.м',
            ukr: 'Площа кухні, кв.м',
            deu: 'Küchenfläche, qm',
        },
        project_add_restaurant_cafe_store: {
            eng: 'Storage Area, sq.m',
            rus: 'Площадь складских помещений, кв.м',
            ukr: 'Площа складських приміщень, кв.м',
            deu: 'Lagerfläche, qm',
        },
        project_add_restaurant_cafe_storeNumber: {
            eng: 'Number of Storage Rooms',
            rus: 'Количество складских помещений',
            ukr: 'Кількість складських приміщень',
            deu: 'Anzahl der Lagerräume',
        },
        project_add_restaurant_cafe_cabinets: {
            eng: 'Office Area, sq.m',
            rus: 'Площадь кабинетов, кв.м',
            ukr: 'Площа кабінетів, кв.м',
            deu: 'Bürofläche, qm',
        },
        project_add_restaurant_cafe_cabinetsNumbers: {
            eng: 'Number of Offices',
            rus: 'Количество кабинетов',
            ukr: 'Кількість кабінетів',
            deu: 'Anzahl der Büros',
        },
        project_add_restaurant_cafe_floor: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Etage',
        },
        project_add_store_production_title: {
            eng: 'Warehouse and Production Property Features',
            rus: 'Характеристики складской и производственной недвижимости',
            ukr: 'Характеристики складської та виробничої нерухомості',
            deu: 'Merkmale von Lager- und Produktionsimmobilien',
        },
        project_add_store_production_total: {
            eng: 'Total Area (sq.m)',
            rus: 'Общая площадь объекта, кв.м',
            ukr: 'Загальна площа об’єкта, кв.м',
            deu: 'Gesamtfläche des Objekts (qm)',
        },
        project_add_store_production_land: {
            eng: 'Land Area (sq.m)',
            rus: 'Площадь земельного участка, кв.м',
            ukr: 'Площа земельної ділянки, кв.м',
            deu: 'Grundstücksfläche (qm)',
        },
        project_add_store_production_effective: {
            eng: 'Effective Area (for rent) (sq.m)',
            rus: 'Полезная площадь (площадь под аренду), кв.м',
            ukr: 'Корисна площа (для оренди), кв.м',
            deu: 'Nutzfläche (zur Vermietung) (qm)',
        },
        project_add_store_production_production: {
            eng: 'Production Area (sq.m)',
            rus: 'Площадь производственных помещений, кв.м',
            ukr: 'Площа виробничих приміщень, кв.м',
            deu: 'Produktionsfläche (qm)',
        },
        project_add_store_production_admin: {
            eng: 'Administrative Area (sq.m)',
            rus: 'Площадь админ помещений, кв.м',
            ukr: 'Площа адміністративних приміщень, кв.м',
            deu: 'Verwaltungsfläche (qm)',
        },
        project_add_store_production_store: {
            eng: 'Warehouse Area (sq.m)',
            rus: 'Площадь складских помещений, кв.м',
            ukr: 'Площа складських приміщень, кв.м',
            deu: 'Lagerfläche (qm)',
        },
        project_add_hotel_title: {
            eng: 'Hotel Property Features',
            rus: 'Характеристики гостинечной недвижимости',
            ukr: 'Характеристики готельної нерухомості',
            deu: 'Merkmale von Hotelimmobilien',
        },
        project_add_hotel_total: {
            eng: 'Total Area (sq.m)',
            rus: 'Общая площадь объекта, кв.м',
            ukr: 'Загальна площа об’єкта, кв.м',
            deu: 'Gesamtfläche des Objekts (qm)',
        },
        project_add_hotel_land: {
            eng: 'Land Area (sq.m)',
            rus: 'Площадь земельного участка, кв.м',
            ukr: 'Площа земельної ділянки, кв.м',
            deu: 'Grundstücksfläche (qm)',
        },
        project_add_hotel_numberFond: {
            eng: 'Room Area (sq.m)',
            rus: 'Площадь номерного фонда, кв.м',
            ukr: 'Площа номерного фонду, кв.м',
            deu: 'Zimmerfläche (qm)',
        },
        project_add_hotel_numbers: {
            eng: 'Room Fund',
            rus: 'Номерной фонд',
            ukr: 'Номерний фонд',
            deu: 'Zimmerbestand',
        },
        project_add_hotel_peoples: {
            eng: 'Capacity (persons)',
            rus: 'Вместимость, чел.',
            ukr: 'Місткість, осіб',
            deu: 'Kapazität (Personen)',
        },
        project_add_hotel_common: {
            eng: 'Common Area (sq.m)',
            rus: 'Площадь зон общего пользования, кв.м',
            ukr: 'Площа зон загального користування, кв.м',
            deu: 'Gemeinschaftsfläche (qm)',
        },
        project_add_parking_title: {
            eng: 'Parking Parameters',
            rus: 'Параметры паркингна',
            ukr: 'Параметри паркінгу',
            deu: 'Parkparameter',
        },
        project_add_parking_total: {
            eng: 'Total Parking Area (sq.m)',
            rus: 'Общая площадь паркинга (кв. м)',
            ukr: 'Загальна площа паркінгу (кв.м)',
            deu: 'Gesamtparkfläche (qm)',
        },
        project_add_parking_underground: {
            eng: 'Underground Parking',
            rus: 'Подземный паркинг',
            ukr: 'Підземний паркінг',
            deu: 'Tiefgarage',
        },
        project_add_parking_ground: {
            eng: 'Ground Parking',
            rus: 'Наземный паркинг',
            ukr: 'Наземний паркінг',
            deu: 'Parkplatz im Freien',
        },
        project_add_parking_area: {
            eng: 'Area (sq.m)',
            rus: 'Площадь кв.м',
            ukr: 'Площа кв.м',
            deu: 'Fläche (qm)',
        },
        project_add_parking_places: {
            eng: 'Parking Spaces',
            rus: 'Машино-места',
            ukr: 'Місця для паркування',
            deu: 'Parkplätze',
        },
        project_add_financial_title: {
            eng: 'Project Funding Sources',
            rus: 'Источники финансирования проекта',
            ukr: 'Джерела фінансування проєкту',
            deu: 'Finanzierungsquellen des Projekts',
        },
        project_add_financial_self: {
            eng: 'Own Funds (€)',
            rus: 'Собственные средства (€)',
            ukr: 'Власні кошти (€)',
            deu: 'Eigenmittel (€)',
        },
        project_add_financial_credits: {
            eng: 'Bank Loans (€)',
            rus: 'Банковские кредиты (€)',
            ukr: 'Банківські кредити (€)',
            deu: 'Bankdarlehen (€)',
        },
        project_add_financial_arend: {
            eng: 'Estimated Rent (€ / sq.m)',
            rus: 'Предполагаемая арендная ставка (€/кв.м)',
            ukr: 'Приблизна орендна ставка (€/кв.м)',
            deu: 'Geschätzte Miete (€/qm)',
        },
        project_add_financial_arend_info: {
            eng: 'Expected Property Profitability in the Future',
            rus: 'Предполагаемая рентабельность недвижимости в будушем',
            ukr: 'Очікувана рентабельність нерухомості у майбутньому',
            deu: 'Erwartete Immobilienrentabilität in der Zukunft',
        },
        project_add_financial_tokenization_title: {
            eng: 'Tokenization Conditions',
            rus: 'Условия для токенизации',
            ukr: 'Умови для токенізації',
            deu: 'Bedingungen für die Tokenisierung',
        },
        project_add_financial_tokenization_volume_attracted: {
            eng: 'Amount of Attracted Funding',
            rus: 'Объём привлекаемого финансирования',
            ukr: 'Обсяг залученого фінансування',
            deu: 'Angezogener Finanzierungsbetrag',
        },
        project_add_financial_tokenization_volume_attracted_info: {
            eng: 'Specify the Desired Amount of Funds You Want to Attract Using GREM',
            rus: 'Укажите желаемое количество средств, которые хотите привлечь с помощью GREM',
            ukr: 'Вкажіть бажану суму коштів, яку хочете залучити за допомогою GREM',
            deu: 'Geben Sie den gewünschten Betrag an, den Sie mit GREM einziehen möchten',
        },
        project_add_financial_tokenization_square: {
            eng: 'Number of sq.m for Tokenization',
            rus: 'Количество кв.м для токенизации',
            ukr: 'Кількість кв.м для токенізації',
            deu: 'Anzahl der qm für die Tokenisierung',
        },
        project_add_financial_tokenization_base_cost: {
            eng: 'Base Cost per sq.m (€)',
            rus: 'Базовая стоимость кв.м (€)',
            ukr: 'Базова вартість кв.м (€)',
            deu: 'Basispreis pro qm (€)',
        },
        project_add_photos: {
            eng: 'Project Photos',
            rus: 'Фотографии проекта',
            ukr: 'Фотографії проєкту',
            deu: 'Projektfotos',
        },
        project_add_documents_title: {
            eng: 'Land Plot Documents and Construction Permits',
            rus: 'Документы на земельный участок и разрешения на строительство',
            ukr: 'Документи на земельну ділянку та дозволи на будівництво',
            deu: 'Grundstücksdokumente und Baugenehmigungen',
        },
        project_add_documents_title_sub: {
            eng: 'Documents uploaded in this section will not be viewable by external users. These documents are necessary for the successful passing of maceration and project approval by the Expert Council',
            rus: 'Документы, загружаемые в этом разделе не будут доступны для просмотра сторонними пользователями. Данные документы необходимы для успешного прохождения мацерации и одобрения проекта Экспертным Советом',
            ukr: 'Документи, завантажені у цьому розділі, не будуть доступні для перегляду сторонніми користувачами. Ці документи необхідні для успішного проходження мацерації та затвердження проєкту Експертною радою',
            deu: 'In diesem Abschnitt hochgeladene Dokumente sind für externe Benutzer nicht einsehbar. Diese Dokumente sind für das erfolgreiche Bestehen der Mazeration und die Genehmigung des Projekts durch den Expertenrat erforderlich',
        },
        project_add_documents_owner_title: {
            eng: 'Land Plot Documents',
            rus: 'Документы на земельный участок',
            ukr: 'Документи на земельну ділянку',
            deu: 'Grundstücksdokumente',
        },
        project_add_documents_owner_title_sub: {
            eng: 'The Land Plot is in Your Ownership',
            rus: 'Земельный участок находится в вашей собственности',
            ukr: 'Земельна ділянка знаходиться у вашій власності',
            deu: 'Das Grundstück befindet sich in Ihrem Besitz',
        },
        project_add_documents_owner_dropzone: {
            eng: 'Land Ownership Documents',
            rus: 'Право собственности на земельный участок',
            ukr: 'Право власності на земельну ділянку',
            deu: 'Grundbesitzdokumente',
        },
        project_add_documents_agreement_dropzone: {
            eng: 'Agreement with the landowner',
            rus: 'Соглашение с собственником земельного участка',
            ukr: 'Угода із власником земельної ділянки',
            deu: 'Vereinbarung mit dem Grundstückseigentümer',
        },
        project_add_documents_plan_land: {
            eng: 'Land Plan',
            rus: 'План участка',
            ukr: 'План ділянки',
            deu: 'Grundstücksplan',
        },
        project_add_documents_plan_zoning: {
            eng: 'Zoning Plan',
            rus: 'План зоннинга',
            ukr: 'План зонування',
            deu: 'Zonierungsplan',
        },
        project_add_documents_experts_title: {
            eng: 'Expert Assessment',
            rus: 'Экспертная оценка',
            ukr: 'Експертна оцінка',
            deu: 'Gutachten',
        },
        project_add_documents_experts_title_sub: {
            eng: 'If you have an external professional valuation of the land plot and its characteristics conducted by a qualified expert, please attach it',
            rus: 'Если у вас есть сторонняя профессиональная оценка стоимости и характеристик земельного участка, проведенная квалифицированным экспертом, пожалуйста прикрепите ее',
            ukr: 'Якщо у вас є стороння професійна оцінка вартості та характеристик земельної ділянки, проведена кваліфікованим експертом, будь ласка, прикріпіть її',
            deu: 'Wenn Sie eine externe professionelle Bewertung des Grundstücks und seiner Eigenschaften durch einen qualifizierten Sachverständigen haben, fügen Sie diese bitte bei',
        },
        project_add_documents_experts_dropzone: {
            eng: 'Expert Assessment',
            rus: 'Экспертная оценка',
            ukr: 'Експертна оцінка',
            deu: 'Gutachten',
        },
        project_add_documents_permission_title: {
            eng: 'Construction Permit and Project',
            rus: 'Разрешение и проект на строительство',
            ukr: 'Дозвіл та проєкт на будівництво',
            deu: 'Baugenehmigung und Projekt',
        },
        project_add_documents_permission_title_sub: {
            eng: 'Do you have documents permitting construction on this land plot?',
            rus: 'Есть ли у вас документы, разрешающие строительство на данном земельном участке?',
            ukr: 'Чи є у вас документи, що дозволяють будівництво на цій земельній ділянці?',
            deu: 'Haben Sie Unterlagen, die den Bau auf diesem Grundstück genehmigen?',
        },
        project_add_documents_permission_dropzone: {
            eng: 'Construction Permit',
            rus: 'Разрешение на строительство',
            ukr: 'Дозвіл на будівництво',
            deu: 'Baugenehmigung',
        },
        project_add_documents_construction_title_sub: {
            eng: 'Do you have a construction project for this site?',
            rus: 'Есть ли у вас проект строительства на данном участке?',
            ukr: 'Чи є у вас проєкт будівництва на цій ділянці?',
            deu: 'Haben Sie ein Bauprojekt für dieses Grundstück?',
        },
        project_add_documents_construction_dropzone: {
            eng: 'Construction Plan for the Land Plot',
            rus: 'План строительства на земельном участке',
            ukr: 'План будівництва на земельній ділянці',
            deu: 'Bauplan für das Grundstück',
        },
        project_add_documents_pledge_title: {
            eng: 'Collateral',
            rus: 'Предмет залога',
            ukr: 'Предмет застави',
            deu: 'Sicherheitsleistung',
        },
        project_add_documents_pledge_title_sub: {
            eng: 'Indicate the asset or object that will serve as collateral for the execution of the smart contract',
            rus: 'Укажите актив или объект, который будет выступать в виде гарантии исполнения smart-контракта',
            ukr: 'Вкажіть актив або об’єкт, який буде слугувати заставою для виконання смарт-контракту',
            deu: 'Geben Sie das Vermögen oder Objekt an, das als Sicherheit für die Ausführung des Smart-Vertrags dient',
        },
        project_add_documents_pledge_description: {
            eng: 'Describe the Collateral',
            rus: 'Опишите предмет залога',
            ukr: 'Опишіть предмет застави',
            deu: 'Beschreiben Sie die Sicherheit',
        },
        project_add_documents_pledge_dropzone: {
            eng: 'Collateral Document',
            rus: 'Документ на предмет залога',
            ukr: 'Документ на предмет застави',
            deu: 'Sicherheitsdokument',
        },
        project_add_documents_extra_title: {
            eng: 'Additional Documents',
            rus: 'Дополнительные документы',
            ukr: 'Додаткові документи',
            deu: 'Zusätzliche Dokumente',
        },
        project_add_documents_extra_title_sub: {
            eng: 'You may upload additional documents that you believe are important for review by our',
            rus: 'Вы можете загрузить дополнительные документы, которые, по вашему мнению, важны для изучения нашим',
            ukr: 'Ви можете завантажити додаткові документи, які, на вашу думку, важливі для вивчення нашими',
            deu: 'Sie können zusätzliche Dokumente hochladen, die Ihrer Meinung nach für die Prüfung durch unser Team wichtig sind',
        },
        project_add_documents_extra_title_sub_experts: {
            eng: 'Expert Council',
            rus: 'Экспертным Советом',
            ukr: 'Експертною радою',
            deu: 'Expertenrat',
        },
        project_add_documents_extra_dropzone: {
            eng: 'Additional Document',
            rus: 'Дополнительный документ',
            ukr: 'Додатковий документ',
            deu: 'Zusätzliches Dokument',
        },
        project_add_documents_extra_comments: {
            eng: 'Comments',
            rus: 'Комментарии',
            ukr: 'Коментарі',
            deu: 'Kommentare',
        },
        project_moderation_active_title: {
            eng: 'Your project has been submitted for moderation',
            rus: 'Ваш проект отправлен на модерацию',
            ukr: 'Ваш проєкт відправлено на модерацію',
            deu: 'Ihr Projekt wurde zur Moderation eingereicht',
        },
        project_moderation_success_title: {
            eng: 'Your project did pass the Expert Council check',
            rus: 'Ваш проект прошёл проверку Экспертного совета',
            ukr: 'Ваш проект пройшов перевірку Експертної ради',
            deu: 'Ihr Projekt wurde vom Expertenrat begutachtet',
        },
        project_moderation_failed_title: {
            eng: 'Your project did not pass the Expert Council check',
            rus: 'Ваш проект не прошёл проверку Экспертного совета',
            ukr: 'Ваш проект не пройшов перевірку Експертної ради',
            deu: 'Ihr Projekt hat die Überprüfung des Expertenrats nicht bestanden',
        },
        project_moderation_failed_title_reason: {
            eng: 'Denied for cause:',
            rus: 'Отказано по причине: ',
            ukr: 'Відмовлено з причини:',
            deu: 'Aus wichtigem Grund abgelehnt:',
        },
        project_moderation_failed_title_info: {
            eng: 'During the verification process, some inconsistencies were found in the data provided by you. The GREM platform strives to ensure a high standard of quality. For any questions, you can contact customer support',
            rus: 'В процессе проверки выявлены некоторые несоответствия в предоставленных вами данных. Платформа GREM старается обеспечить высокий стандарт качества. По любым вопросам вы можете обратиться в службу поддержки клиентов',
            ukr: 'Під час перевірки виявлені деякі несоответстві в наданих вами даних. Платформа GREM намагається забезпечити високий стандарт якості. З будь-яких питань ви можете звернутися в службу підтримки клієнтів',
            deu: 'Während der Überprüfung wurden einige Unstimmigkeiten in den von Ihnen bereitgestellten Daten festgestellt. Die GREM-Plattform bemüht sich, einen hohen Qualitätsstandard zu gewährleisten. Bei Fragen können Sie sich an den Kundendienst wenden',
        },
        project_moderation_active_title_info: {
            eng: 'Your tokenization request has been successfully submitted to the Expert Council for review. If approved, your project will be tokenized and tokens will be credited to the corresponding project wallet.',
            rus: 'Ваша заявка на токенизацию успешно отправлена на рассмотрение Экспертному Совету. В случае положительного решения, Ваш проект будет токенизирован и токены начислены на кошелек соответствующего проекта',
            ukr: 'Ваш запит на токенізацію успішно відправлено на розгляд Експертної Ради. У разі позитивного рішення, ваш проєкт буде токенізовано і токени будуть зараховані на відповідний гаманець проєкту.',
            deu: 'Ihr Tokenisierungsantrag wurde erfolgreich an den Expertenrat zur Überprüfung übermittelt. Bei positiver Entscheidung wird Ihr Projekt tokenisiert und die Token werden dem entsprechenden Projekt-Wallet gutgeschrieben.',
        },
        project_moderation_wallet: {
            eng: 'Project Wallet',
            rus: 'Кошелек данного проекта',
            ukr: 'Гаманець цього проєкту',
            deu: 'Projektbrieftasche',
        },
        project_moderation_dashboard: {
            eng: 'Project Dashboard',
            rus: 'Дашборд данного проекта',
            ukr: 'Дашборд цього проєкту',
            deu: 'Projekt-Dashboard',
        },
        project_add_photos_title: {
            eng: 'Project photos',
            rus: 'Фотографии проекта',
            ukr: 'Фотографії проекту',
            deu: 'Projektfotos',
        },
        file_loader_drop_sub_title: {
            eng: 'drag the document here or',
            rus: 'перетащите документ сюда или',
            ukr: 'перетягніть документ сюди або',
            deu: 'ziehen Sie das Dokument hierher oder',
        },
        project_wallet_m2c: {
            eng: 'M2C Balance',
            rus: 'Баланс М2С',
            ukr: 'Баланс М2С',
            deu: 'M2C-Guthaben',
        },
        project_wallet_NFT: {
            eng: 'NFT unit balance',
            rus: 'Баланс NFT юнитов',
            ukr: 'Баланс NFT юнітів',
            deu: 'NFT-Einheiten-Guthaben',
        },
        project_wallet_m2: {
            eng: 'M2 Balance',
            rus: 'Баланс М2',
            ukr: 'Баланс М2',
            deu: 'M2-Guthaben',
        },
        project_wallet_available: {
            eng: 'Available for Sale',
            rus: 'Доступно к продаже',
            ukr: 'Доступно для продажу',
            deu: 'Zum Verkauf verfügbar',
        },
        project_wallet_used: {
            eng: 'Allocated to Smart Contracts',
            rus: 'Распределено в smart-контракты',
            ukr: 'Розподілено в smart-контракти',
            deu: 'In Smart-Verträge zugewiesen',
        },
        project_location: {
            eng: 'Project Location',
            rus: 'Расположение проекта',
            ukr: 'Розташування проєкту',
            deu: 'Projektstandort',
        },
        projects_common_advantages: {
            eng: 'Advantages of the project',
            rus: 'Преимущества проекта',
            ukr: 'Переваги проекту',
            deu: 'Vorteile des Projekts',
        },
        projects_common_efficiency: {
            eng: 'Social efficiency',
            rus: 'Социальная эффективность',
            ukr: 'Соціальна ефективність',
            deu: 'Soziale Effizienz',
        },
        project_builder: {
            eng: 'Construction Company',
            rus: 'Компания застройщик',
            ukr: 'Будівельна компанія',
            deu: 'Bauunternehmen',
        },
        project_projectType: {
            eng: 'Project Type',
            rus: 'Тип проекта',
            ukr: 'Тип проєкту',
            deu: 'Projekttyp',
        },
        project_storeys: {
            eng: 'Number of storeys',
            rus: 'Этажность',
            ukr: 'Поверховість',
            deu: 'Anzahl der Stockwerke',
        },
        project_area_total: {
            eng: 'Total Construction Area (sq.m)',
            rus: 'Общая площадь строительства (кв. м)',
            ukr: 'Загальна площа будівництва (кв.м)',
            deu: 'Gesamtbaufläche (qm)',
        },
        project_area_building: {
            eng: 'Building Area (sq.m)',
            rus: 'Площадь застройки (кв. м)',
            ukr: 'Площа забудови (кв.м)',
            deu: 'Bebauungsfläche (qm)',
        },
        project_area_effective: {
            eng: 'Effective Area (sq.m)',
            rus: 'Полезная площадь (кв. м)',
            ukr: 'Корисна площа (кв.м)',
            deu: 'Nutzfläche (qm)',
        },
        project_area_land: {
            eng: 'Land Area (sq.m)',
            rus: 'Площадь земельного участка (кв. м)',
            ukr: 'Площа земельної ділянки (кв.м)',
            deu: 'Grundstücksfläche (qm)',
        },
        project_finalDate: {
            eng: 'Completion date',
            rus: 'Сдача в єксплуатацию',
            ukr: 'Здача в експлуатацію',
            deu: 'Fertigstellungsdatum',
        },
        project_stage: {
            eng: 'Project Readiness Stage',
            rus: 'Стадия готовности проекта',
            ukr: 'Стадія готовності проєкту',
            deu: 'Projektbereitstellungsphase',
        },
        project_liveArea_title: {
            eng: 'Residential Property Features',
            rus: 'Характеристики жилой недвижимости',
            ukr: 'Характеристики житлової нерухомості',
            deu: 'Wohnimmobilieneigenschaften',
        },
        project_liveArea_total: {
            eng: 'Residential Area',
            rus: 'Площадь жилой недвижимости',
            ukr: 'Площа житлової нерухомості',
            deu: 'Wohnfläche',
        },
        project_totalAparts: {
            eng: 'Number of apartments',
            rus: 'Количество квартир',
            ukr: 'Кількість квартир',
            deu: 'Anzahl der Wohnungen',
        },
        project_office_title: {
            eng: 'Office Property Features',
            rus: 'Характеристики офисной недвижимости',
            ukr: 'Характеристики офісної нерухомості',
            deu: 'Büroimmobilieneigenschaften',
        },
        project_office_total: {
            eng: 'Total Area (sq.m)',
            rus: 'Общая площадь, кв.м',
            ukr: 'Загальна площа, кв.м',
            deu: 'Gesamtfläche (qm)',
        },
        project_office_effective: {
            eng: 'Effective Area (sq.m)',
            rus: 'Полезная площадь, кв.м',
            ukr: 'Корисна площа , кв.м',
            deu: 'Nutzfläche, (qm)',
        },
        project_effective: {
            eng: 'Effective Area (sq.m)',
            rus: 'Полезная площадь, кв.м',
            ukr: 'Корисна площа , кв.м',
            deu: 'Nutzfläche, (qm)',
        },
        project_office_rooms: {
            eng: 'Number of Rooms',
            rus: 'Количество кабинетов',
            ukr: 'Кількість кабінетів',
            deu: 'Anzahl der Räume',
        },
        project_cabinetsNumbers: {
            eng: 'Number of Offices',
            rus: 'Количество кабинетов',
            ukr: 'Кількість кабінетів',
            deu: 'Anzahl der Büros',
        },
        project_restaurant_cafe_title: {
            eng: 'Restaurant and Café Characteristics',
            rus: 'Характеристики ресторанов и кафе',
            ukr: 'Характеристики ресторанів і кафе',
            deu: 'Eigenschaften von Restaurants und Cafés',
        },
        project_restaurant_cafe_total: {
            eng: 'Total Area, sq.m',
            rus: 'Общая площадь, кв.м',
            ukr: 'Загальна площа, кв.м',
            deu: 'Gesamtfläche, qm',
        },
        project_restaurant_cafe_effective: {
            eng: 'Effective Area (Dining Hall Area), sq.m',
            rus: 'Полезная площадь (площадь зала), кв.м',
            ukr: 'Корисна площа (площа залу), кв.м',
            deu: 'Nutzfläche (Saalfläche), qm',
        },
        project_restaurant_cafe_seats: {
            eng: 'Number of Seats',
            rus: 'Количество посадочных мест',
            ukr: 'Кількість місць для сидіння',
            deu: 'Anzahl der Sitzplätze',
        },
        project_restaurant_cafe_kitchen: {
            eng: 'Kitchen Area, sq.m',
            rus: 'Площадь кухни, кв.м',
            ukr: 'Площа кухні, кв.м',
            deu: 'Küchenfläche, qm',
        },
        project_restaurant_cafe_store: {
            eng: 'Storage Area, sq.m',
            rus: 'Площадь складских помещений, кв.м',
            ukr: 'Площа складських приміщень, кв.м',
            deu: 'Lagerfläche, qm',
        },
        project_restaurant_cafe_storeNumber: {
            eng: 'Number of Storage Rooms',
            rus: 'Количество складских помещений',
            ukr: 'Кількість складських приміщень',
            deu: 'Anzahl der Lagerräume',
        },
        project_restaurant_cafe_cabinets: {
            eng: 'Office Area, sq.m',
            rus: 'Площадь кабинетов, кв.м',
            ukr: 'Площа кабінетів, кв.м',
            deu: 'Bürofläche, qm',
        },
        project_store_production_title: {
            eng: 'Warehouse and Production Property Features',
            rus: 'Характеристики складской и производственной недвижимости',
            ukr: 'Характеристики складської та виробничої нерухомості',
            deu: 'Merkmale von Lager- und Produktionsimmobilien',
        },
        project_store_production_total: {
            eng: 'Total Area (sq.m)',
            rus: 'Общая площадь объекта, кв.м',
            ukr: 'Загальна площа об’єкта, кв.м',
            deu: 'Gesamtfläche des Objekts (qm)',
        },
        project_store_production_land: {
            eng: 'Land Area (sq.m)',
            rus: 'Площадь земельного участка, кв.м',
            ukr: 'Площа земельної ділянки, кв.м',
            deu: 'Grundstücksfläche (qm)',
        },
        project_store_production_effective: {
            eng: 'Effective Area, (sq.m)',
            rus: 'Полезная площадь, кв.м',
            ukr: 'Корисна площа, кв.м',
            deu: 'Nutzfläche (qm)',
        },
        project_store_production_production: {
            eng: 'Production Area (sq.m)',
            rus: 'Площадь производственных помещений, кв.м',
            ukr: 'Площа виробничих приміщень, кв.м',
            deu: 'Produktionsfläche (qm)',
        },
        project_store_production_admin: {
            eng: 'Administrative Area (sq.m)',
            rus: 'Площадь админ помещений, кв.м',
            ukr: 'Площа адміністративних приміщень, кв.м',
            deu: 'Verwaltungsfläche (qm)',
        },
        project_store_production_store: {
            eng: 'Warehouse Area (sq.m)',
            rus: 'Площадь складских помещений, кв.м',
            ukr: 'Площа складських приміщень, кв.м',
            deu: 'Lagerfläche (qm)',
        },
        project_hotel_title: {
            eng: 'Hotel Property Features',
            rus: 'Характеристики гостинечной недвижимости',
            ukr: 'Характеристики готельної нерухомості',
            deu: 'Merkmale von Hotelimmobilien',
        },
        project_hotel_total: {
            eng: 'Total Area (sq.m)',
            rus: 'Общая площадь объекта, кв.м',
            ukr: 'Загальна площа об’єкта, кв.м',
            deu: 'Gesamtfläche des Objekts (qm)',
        },
        project_hotel_land: {
            eng: 'Land Area (sq.m)',
            rus: 'Площадь земельного участка, кв.м',
            ukr: 'Площа земельної ділянки, кв.м',
            deu: 'Grundstücksfläche (qm)',
        },
        project_hotel_numberFond: {
            eng: 'Room Area (sq.m)',
            rus: 'Площадь номерного фонда, кв.м',
            ukr: 'Площа номерного фонду, кв.м',
            deu: 'Zimmerfläche (qm)',
        },
        project_hotel_numbers: {
            eng: 'Room Fund',
            rus: 'Номерной фонд',
            ukr: 'Номерний фонд',
            deu: 'Zimmerbestand',
        },
        project_hotel_peoples: {
            eng: 'Capacity (persons)',
            rus: 'Вместимость, чел.',
            ukr: 'Місткість, осіб',
            deu: 'Kapazität (Personen)',
        },
        project_hotel_common: {
            eng: 'Common Area (sq.m)',
            rus: 'Площадь зон общего пользования, кв.м',
            ukr: 'Площа зон загального користування, кв.м',
            deu: 'Gemeinschaftsfläche (qm)',
        },
        project_parking_title: {
            eng: 'Parking Parameters',
            rus: 'Параметры паркингна',
            ukr: 'Параметри паркінгу',
            deu: 'Parkparameter',
        },
        project_parking_total: {
            eng: 'Total Parking Area (sq.m)',
            rus: 'Общая площадь паркинга (кв. м)',
            ukr: 'Загальна площа паркінгу (кв.м)',
            deu: 'Gesamtparkfläche (qm)',
        },
        project_parking_ground: {
            eng: 'above-ground parking area (sq. m)',
            rus: 'площадь наземного паркинга (кв. м)',
            ukr: 'площа наземного паркінгу (кв. м)',
            deu: 'Parkfläche überirdisch (qm)',
        },
        project_parking_ground_count: {
            eng: 'parking spaces (above-ground parking)',
            rus: 'паркоместа (наземный паркинг)',
            ukr: 'паркомісця (наземний паркінг)',
            deu: 'Parkplätze (überirdisch)',
        },
        project_parking_underground: {
            eng: 'underground parking area (sq. m)',
            rus: 'площадь подземного паркинга (кв. м)',
            ukr: 'площа підземного паркінгу (кв. м)',
            deu: 'Parkfläche unterirdisch (qm)',
        },
        project_parking_underground_count: {
            eng: 'parking spaces (underground parking)',
            rus: 'паркоместа (подземный паркинг)',
            ukr: 'паркомісця (підземний паркінг)',
            deu: 'Parkplätze (unterirdisch)',
        },
        project_placed: {
            eng: 'Project placed',
            rus: 'Проект размещен',
            ukr: 'Проєкт розміщено',
            deu: 'Projekt platziert',
        },
        project_updated: {
            eng: 'Project updated',
            rus: 'Проект обновлен',
            ukr: 'Проєкт оновлено',
            deu: 'Projekt aktualisiert',
        },
        project_add_land_common_title: {
            eng: 'Land Plot Characteristics',
            rus: 'Характеристики земельного участка',
            ukr: 'Характеристики земельної ділянки',
            deu: 'Grundstücksmerkmale',
        },
        project_add_land_name: {
            eng: 'Land Plot Name',
            rus: 'Название участка',
            ukr: 'Назва ділянки',
            deu: 'Grundstücksname',
        },
        project_add_land_areaTotal: {
            eng: 'Total Area, sq.m',
            rus: 'Площадь участка, кв.м',
            ukr: 'Площа ділянки, кв.м',
            deu: 'Gesamtfläche, qm',
        },
        project_add_land_marketValue: {
            eng: 'Market Value',
            rus: 'Рыночная стоимость',
            ukr: 'Ринкова вартість',
            deu: 'Marktwert',
        },
        project_add_land_purpose: {
            eng: 'Land Purpose',
            rus: 'Целевое назначение',
            ukr: 'Цільове призначення',
            deu: 'Zweckbestimmung',
        },
        project_add_land_locationDescription: {
            eng: 'Location Description',
            rus: 'Описание месторасположения',
            ukr: 'Опис місцезнаходження',
            deu: 'Lagebeschreibung',
        },
        project_add_land_concept: {
            eng: 'Development Concept',
            rus: 'Концепция развития',
            ukr: 'Концепція розвитку',
            deu: 'Entwicklungskonzept',
        },
        project_add_visualization_title: {
            eng: 'Real estate project visualization',
            rus: 'Визуализация будущего проекта недвижимости',
            ukr: 'Візуалізація майбутнього проекту нерухомості',
            deu: 'Visualisierung des zukünftigen Immobilienprojekts',
        },
        project_add_visualization_title_sub: {
            eng: 'Additional materials will allow the Expert Council to get an idea of the architectural features, location, layout, etc.',
            rus: 'Дополнительный материал позволит Экспертному Совету получить представление об архитектурных особенностях, расположению, планировке и т.п.',
            ukr: 'Додатковий матеріал дозволить Експертній Раді отримати уявлення про архітектурні особливості, розташування, планування і т.д.',
            deu: 'Zusätzliche Materialien ermöglichen dem Expertenrat einen Eindruck von den architektonischen Besonderheiten, der Lage, dem Layout usw. zu gewinnen.',
        },
        project_analytic_financing_call: {
            eng: 'financing attracted',
            rus: 'привлечено финансирования',
            ukr: 'залучено фінансування',
            deu: 'eingeworbene Finanzierung',
        },
        project_analytic_total: {
            eng: 'Total amount',
            rus: 'Общее количество',
            ukr: 'Загальна кількість',
            deu: 'Gesamtanzahl',
        },
        project_analytic_sold: {
            eng: 'Sold',
            rus: 'Продано',
            ukr: 'Продано',
            deu: 'Verkauft',
        },
        project_analytic_investors: {
            eng: 'Number of investors',
            rus: 'Количество инвесторов',
            ukr: 'Кількість інвесторів',
            deu: 'Anzahl der Investoren',
        },
        project_analytic_available: {
            eng: 'Available for sale',
            rus: 'Доступно к продаже',
            ukr: 'Доступно для продажу',
            deu: 'Zum Verkauf verfügbar',
        },
        project_analytic_finish_date: {
            eng: 'Smart contract completion',
            rus: 'Завершение smart-контракта',
            ukr: 'Завершення smart-контракту',
            deu: 'Abschluss des Smart-Vertrags',
        },
        project_analytic_success_tokenization: {
            eng: 'Your project has passed the Expert Council review and has been successfully tokenized, which means you can convert land tokens into project tokens or individual units according to your investment needs.',
            rus: 'Ваш проект прошел проверку Экспертного Совета и успешно токенизирован, что означает, что вы можете конвертировать токены земли в токены проекта или в отдельные юниты в соответствии с вашими инвестиционными потребностями.',
            ukr: 'Ваш проєкт пройшов перевірку Експертної ради та успішно токенізований, що означає, що ви можете конвертувати токени землі в токени проєкту або окремі одиниці відповідно до ваших інвестиційних потреб.',
            deu: 'Ihr Projekt hat die Prüfung des Expertengremiums bestanden und wurde erfolgreich tokenisiert, was bedeutet, dass Sie Land-Tokens in Projekt-Tokens oder einzelne Einheiten gemäß Ihren Investitionsbedürfnissen umwandeln können.',
        },
        project_analytic_troubleshoots: {
            eng: 'If you encounter difficulties when moving tokens to a new stage, our support team is always available to help resolve any issues.',
            rus: 'Если возникают затруднения при переводе токенов в новую стадию, наша служба поддержки всегда на связи и поможет решить любой вопрос.',
            ukr: 'Якщо виникають труднощі при переведенні токенів на нову стадію, наша служба підтримки завжди на зв’язку і допоможе вирішити будь-яке питання.',
            deu: 'Sollten Schwierigkeiten beim Übertragen von Tokens in eine neue Phase auftreten, steht Ihnen unser Support-Team jederzeit zur Verfügung, um jedes Problem zu lösen.',
        },
        project_analytic_units: {
            eng: 'Individual units',
            rus: 'Отдельные юниты',
            ukr: 'Окремі одиниці',
            deu: 'Einzelne Einheiten',
        },
        project_land_areaTotal: {
            eng: 'Total Area',
            rus: 'Площадь участка',
            ukr: 'Площа ділянки',
            deu: 'Gesamtfläche',
        },
        project_land_concept: {
            eng: 'Development Concept',
            rus: 'Концепция развития',
            ukr: 'Концепція розвитку',
            deu: 'Entwicklungskonzept',
        },
        project_land_purpose: {
            eng: 'Land Purpose',
            rus: 'Целевое назначение',
            ukr: 'Цільове призначення',
            deu: 'Zweckbestimmung',
        },
        project_add_unit_description: {
            eng: 'Object Description (Concept)',
            rus: 'Описание объекта (концепция)',
            ukr: 'Опис об\'єкта (концепція)',
            deu: 'Objektbeschreibung (Konzept)',
        },
        project_add_unit_name: {
            eng: 'Project Name',
            rus: 'Название проекта',
            ukr: 'Назва проекту',
            deu: 'Projektname',
        },
        project_add_unit_floors: {
            eng: 'Number of Floors',
            rus: 'Количество этажей',
            ukr: 'Кількість поверхів',
            deu: 'Anzahl der Etagen',
        },
        project_add_unit_finalDate: {
            eng: 'Commissioning',
            rus: 'Сдача в эксплуатацию',
            ukr: 'Введення в експлуатацію',
            deu: 'Inbetriebnahme',
        },
        project_add_unit_houseClass: {
            eng: 'House Class',
            rus: 'Класс дома',
            ukr: 'Клас будинку',
            deu: 'Hausklasse',
        },
        project_add_unit_sections: {
            eng: 'Number of Sections',
            rus: 'Количество секций',
            ukr: 'Кількість секцій',
            deu: 'Anzahl der Abschnitte',
        },
        project_add_unit_condition: {
            eng: 'Apartment Condition',
            rus: 'Состояние квартир',
            ukr: 'Стан квартир',
            deu: 'Wohnungszustand',
        },
        project_add_unit_area: {
            eng: 'Apartment Area',
            rus: 'Площадь квартир',
            ukr: 'Площа квартир',
            deu: 'Wohnfläche',
        },
        project_add_unit_type: {
            eng: 'Apartment types',
            rus: 'Типы квартир',
            ukr: 'Типи квартир',
            deu: 'Wohnungstypen',
        },
        project_add_unit_furniture: {
            eng: 'Furniture',
            rus: 'Мебель',
            ukr: 'Меблі',
            deu: 'Möbel',
        },
        project_add_unit_furniture_true: {
            eng: 'Yes',
            rus: 'Есть',
            ukr: 'Є',
            deu: 'Ja',
        },
        project_add_unit_furniture_false: {
            eng: 'Without furniture',
            rus: 'Без мебели',
            ukr: 'Без меблів',
            deu: 'Ohne Möbel',
        },
        project_add_unit_parking_title: {
            eng: 'Parking Parameters',
            rus: 'Параметры паркинга',
            ukr: 'Параметри паркінгу',
            deu: 'Parkparameter',
        },
        project_add_unit_parking_total: {
            eng: 'Total Parking Spaces',
            rus: 'Количество машино-мест',
            ukr: 'Кількість машиномісць',
            deu: 'Anzahl der Parkplätze',
        },
        project_add_unit_floor_plans: {
            eng: 'Floor plans',
            rus: 'Планировка этажей',
            ukr: 'Планування поверхів',
            deu: 'Grundrisse',
        },
        project_add_unit_floor_plans_save: {
            eng: 'Save Floor Plan',
            rus: 'Сохранить план этажа',
            ukr: 'Зберегти план поверху',
            deu: 'Grundriss speichern',
        },
        project_add_unit_floor_plans_load: {
            eng: 'Upload floor plan',
            rus: 'Загрузите план этажа',
            ukr: 'Завантажте план поверху',
            deu: 'Laden Sie den Grundriss hoch',
        },
        project_add_unit_item_number: {
            eng: 'Unit number',
            rus: 'Номер юнита',
            ukr: 'Номер юніта',
            deu: 'Einheitennummer',
        },
        project_add_unit_item_section: {
            eng: 'Section',
            rus: 'Секция',
            ukr: 'Секція',
            deu: 'Sektion',
        },
        project_add_unit_item_rooms: {
            eng: 'Number of rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Anzahl der Zimmer',
        },
        project_add_unit_item_windows: {
            eng: 'Number of windows',
            rus: 'Количество окон',
            ukr: 'Кількість вікон',
            deu: 'Anzahl der Fenster',
        },
        project_add_unit_item_numberOfBathrooms: {
            eng: 'Number of bathrooms',
            rus: 'Количество санузлов',
            ukr: 'Кількість санвузлів',
            deu: 'Anzahl der Badezimmer',
        },
        project_add_unit_item_spaceAll: {
            eng: 'Total Area, sq.m',
            rus: 'Общая площадь, кв.м',
            ukr: 'Загальна площа, кв.м',
            deu: 'Gesamtfläche, qm',
        },
        project_add_unit_item_spaceLiving: {
            eng: 'Living space (sq.m.)',
            rus: 'Жилая площадь (кв. м)',
            ukr: 'Житлова площа (кв. м)',
            deu: 'Wohnfläche (qm)',
        },
        project_add_unit_item_kitchen: {
            eng: 'Kitchen size:',
            rus: 'Площадь кухни:',
            ukr: 'Площа кухні:',
            deu: 'Küchengröße:',
        },
        project_add_unit_item_balcony: {
            eng: 'Balcony area',
            rus: 'Площадь балкона',
            ukr: 'Площа балкона',
            deu: 'Balkonfläche',
        },
        project_add_unit_item_terrace: {
            eng: 'Terrace area',
            rus: 'Площадь террасы',
            ukr: 'Площа тераси',
            deu: 'Terrassenfläche',
        },
        project_add_unit_item_button: {
            eng: 'Add unit',
            rus: 'Добавить юнит',
            ukr: 'Додати юніт',
            deu: 'Einheit hinzufügen',
        },
        project_add_unit_item_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        project_add_unit_item_windows_view: {
            eng: 'Window view',
            rus: 'Вид из окна',
            ukr: 'Вид з вікна',
            deu: 'Fensteransicht',
        },
        project_add_unit_item_photos: {
            eng: 'Unit photos',
            rus: 'Фотографии юнита',
            ukr: 'Фотографії юніта',
            deu: 'Einheitsfotos',
        },
        project_add_unit_item_plan: {
            eng: 'Unit layout',
            rus: 'Планировка юнита',
            ukr: 'Планування юніта',
            deu: 'Grundriss der Einheit',
        },
        project_add_unit_item_plan_button: {
            eng: 'Upload layout',
            rus: 'Загрузите планировку',
            ukr: 'Завантажте планування',
            deu: 'Grundriss hochladen',
        },
        project_add_unit_item_title: {
            eng: 'General tokenization terms',
            rus: 'Общие условия токенизации',
            ukr: 'Загальні умови токенізації',
            deu: 'Allgemeine Bedingungen der Tokenisierung',
        },
        project_add_unit_item_title_sub: {
            eng: 'To tokenize your assets as individual units and issue NFT tokens, you need to provide information separately for each apartment you wish to tokenize.',
            rus: 'Для токенизации Ваших активов в виде отдельных юнитов и выпуска  NFT токенов вам необходимо указать информацию отдельно по каждой квартире, которую вы желаете токенизировать.',
            ukr: 'Для токенізації ваших активів у вигляді окремих юнітів і випуску NFT токенів, вам необхідно вказати інформацію окремо по кожній квартирі, яку ви бажаєте токенізувати.',
            deu: 'Um Ihre Vermögenswerte als einzelne Einheiten zu tokenisieren und NFT-Token auszugeben, müssen Sie die Informationen für jede Wohnung, die Sie tokenisieren möchten, separat angeben.',
        },
        project_add_unit_item_companys: {
            eng: 'Construction participants',
            rus: 'Участники строительства',
            ukr: 'Учасники будівництва',
            deu: 'Bau-Teilnehmer',
        },
        project_add_unit_item_license: {
            eng: 'Participant construction license',
            rus: 'Лицензия участника на строительство',
            ukr: 'Ліцензія учасника на будівництво',
            deu: 'Bau-Lizenz des Teilnehmers',
        },
        project_add_unit_item_project_documentation_title: {
            eng: 'Project Documentation',
            rus: 'Проектная документация',
            ukr: 'Проєктна документація',
            deu: 'Projektdokumentation',
        },
        project_add_unit_item_project_documentation_dropzone: {
            eng: 'Project Document',
            rus: 'Проектный документ',
            ukr: 'Проєктний документ',
            deu: 'Projektdokument',
        },
        project_unit_sections: {
            eng: 'Number of Sections',
            rus: 'Количество секций',
            ukr: 'Кількість секцій',
            deu: 'Anzahl der Abschnitte',
        },
        project_unit_furniture_true: {
            eng: 'Yes',
            rus: 'Есть',
            ukr: 'Є',
            deu: 'Ja',
        },
        project_unit_furniture_false: {
            eng: 'Without furniture',
            rus: 'Без мебели',
            ukr: 'Без меблів',
            deu: 'Ohne Möbel',
        },
        project_unit_furniture: {
            eng: 'Furniture',
            rus: 'Мебель',
            ukr: 'Меблі',
            deu: 'Möbel',
        },
        project_unit_condition: {
            eng: 'Apartment Condition',
            rus: 'Состояние квартир',
            ukr: 'Стан квартир',
            deu: 'Wohnungszustand',
        },
        project_unit_area: {
            eng: 'Apartment area sq.m',
            rus: 'Площадь квартир кв.м',
            ukr: 'Площа квартир кв.м',
            deu: 'Wohnungsfläche qm',
        },
        project_unit_parking_underground: {
            eng: 'Underground Parking',
            rus: 'Подземный паркинг',
            ukr: 'Підземний паркінг',
            deu: 'Tiefgarage',
        },
        project_unit_parking_ground: {
            eng: 'Ground Parking',
            rus: 'Наземный паркинг',
            ukr: 'Наземний паркінг',
            deu: 'Parkplatz im Freien',
        },
        project_unit_plans_title: {
            eng: 'Floor plans',
            rus: 'Планировка этажей',
            ukr: 'Планування поверхів',
            deu: 'Grundrisse',
        },
        project_unit_item_windows_view: {
            eng: 'Window view',
            rus: 'Вид из окна',
            ukr: 'Вид з вікна',
            deu: 'Fensteransicht',
        },
        project_unit_item_unit: {
            eng: 'Unit',
            rus: 'Юнит',
            ukr: 'Юніт',
            deu: 'Einheit',
        },
        project_unit_item_section: {
            eng: 'Section',
            rus: 'Секция',
            ukr: 'Секція',
            deu: 'Sektion',
        },
        project_unit_item_rooms: {
            eng: 'Number of rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Anzahl der Zimmer',
        },
        project_unit_item_windows: {
            eng: 'Number of windows',
            rus: 'Количество окон',
            ukr: 'Кількість вікон',
            deu: 'Anzahl der Fenster',
        },
        project_unit_item_numberOfBathrooms: {
            eng: 'Number of bathrooms',
            rus: 'Количество санузлов',
            ukr: 'Кількість санвузлів',
            deu: 'Anzahl der Badezimmer',
        },
        project_unit_item_spaceAll: {
            eng: 'Total Area, sq.m',
            rus: 'Общая площадь, кв.м',
            ukr: 'Загальна площа, кв.м',
            deu: 'Gesamtfläche, qm',
        },
        project_unit_item_spaceLiving: {
            eng: 'Living space, sq.m',
            rus: 'Жилая площадь, кв.м',
            ukr: 'Житлова площа, кв.м',
            deu: 'Wohnfläche, qm',
        },
        project_unit_item_kitchen: {
            eng: 'Kitchen size, sq.m:',
            rus: 'Площадь кухни, кв.м',
            ukr: 'Площа кухні, кв.м',
            deu: 'Küchengröße, qm',
        },
        project_unit_item_balcony: {
            eng: 'Balcony area, sq.m',
            rus: 'Площадь балкона, кв.м',
            ukr: 'Площа балкона, кв.м',
            deu: 'Balkonfläche, qm',
        },
        project_unit_item_terrace: {
            eng: 'Terrace area, sq.m',
            rus: 'Площадь террасы, кв.м',
            ukr: 'Площа тераси, кв.м',
            deu: 'Terrassenfläche, qm',
        },
        project_unit_item_plans: {
            eng: 'Unit layouts',
            rus: 'Планировки юнитов',
            ukr: 'Планування юнітів',
            deu: 'Einheitenlayouts',
        },
        project_progress_title_sub: {
            eng: 'In this section, you can update information about the current stage of construction',
            rus: 'В этом разделе вы можете обновлять информацию о текущей стадии строительства',
            ukr: 'У цьому розділі ви можете оновлювати інформацію про поточний етап будівництва',
            deu: 'In diesem Abschnitt können Sie Informationen über den aktuellen Baufortschritt aktualisieren'
        },
        project_unit_item_: {
            eng: '',
            rus: '',
            ukr: '',
            deu: '',
        },
        project_add_unit_: {
            eng: '',
            rus: '',
            ukr: '',
            deu: '',
        },
        _: {
            eng: '',
            rus: '',
            ukr: '',
            deu: '',
        },






        Key_not_founded: {
            eng: 'Error: Text not found',
            rus: 'Ошибка: Текст не найден',
            ukr: 'Помилка: Текст не знайдено',
            deu: 'Fehler: Text nicht gefunden',
        },
    },

    get: function (key, lang) {
        lang = languageStore.activeLanguage;
        let dictionary = this.dictionary[key]
            ? this.dictionary[key]
            : this.dictionary['Key_not_founded'];
        let dictionaryItem = dictionary[lang]
            ? dictionary[lang]
            : this.dictionary['Key_not_founded']['eng'];

        return dictionaryItem;
    },
};

export default projectDictionary;