import React from 'react';
import {Box, Typography} from '@mui/material';
import {FileLoader} from 'components';
import {observer} from "mobx-react-lite";
import {useStore} from "Providers";
import ClipboardTextIcon from 'assets/icons/clipboard-text.svg'
import projectDictionary from "../../../../../../assets/translations/Project/projectDictionary";
import commonDictionary from "../../../../../../assets/translations/commonDictionary";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {AIInput} from "../../../../../../components";
import ExtraDocumentsBlock from "./documentsBlocks/ExtraDocumentsBlock";
const DocumentsForm = observer(({store}) => {

    const {auth} = useStore();
    return(
        <Box sx={{
            display:'flex',
            gap:'30px',
            flexDirection:'column'
        }}>
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px 10px 10px 10px',
            width:'100%'
        }}>
            <Box sx={{paddingLeft:'8px',marginBottom:'30px'}}>
            <Typography sx={{
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:700,
                lineHeight:'normal',
                marginBottom:'10px'
            }}>
                {projectDictionary.get('project_add_documents_title')}
            </Typography>
            <Typography sx={{
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:400,
                lineHeight:'150%'
            }}>
                {projectDictionary.get('project_add_documents_title_sub')}
            </Typography>
            </Box>


            <FileLoader
                _data={store.fields.project.documents}
                _setData={(file)=>{
                    store.uploadFile('project.documents.ownerDocument',file.ownerDocument)
                }}
                documentDropzone={true}
                configs={{
                    field: 'ownerDocument',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    title: projectDictionary.get('project_add_documents_owner_title'),
                    titleSub: projectDictionary.get('project_add_documents_owner_title_sub'),
                    documentDropzoneTitle:projectDictionary.get('project_add_documents_owner_dropzone'),
                    confName: 'project.land.documents',
                    buttonKey:'documents_load_button_key',
                    documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                }}
            >
                <Box marginTop={'20px'}>

                    <RadioGroup
                        name={'project.documents.owner'}
                        value={store.fields.project.documents?.owner.toString()}
                        onChange={store.handleInputRadio}
                        row>
                        <Typography alignItems={'center'}>
                            <Radio
                                sx={{width: '20px', mr: '10px'}}
                                value={'true'}
                                checked={store.fields.project.documents.owner  === true}
                            />
                            {commonDictionary.get('yes')}
                        </Typography>
                        <Typography ml={5} alignItems={'center'}>
                            <Radio
                                value={'false'}
                                checked={store.fields.project.documents.owner === false}
                            />
                            {commonDictionary.get('no')}
                        </Typography>
                    </RadioGroup>
                </Box>
            </FileLoader>
            <FileLoader
                _data={store.fields.project.documents}
                _setData={(file)=>{
                    store.uploadFile('project.documents.agreementDocument',file.agreementDocument)
                }}
                dropzone={store.fields.project.documents.owner === false}
                documentDropzone={true}
                configs={{
                    field: 'agreementDocument',
                    minKey: 'complex_progress_photo_min',
                    maxKey: 'complex_progress_photo_min',
                    //title: projectDictionary.get('project_add_documents_owner_title'),
                    //titleSub: projectDictionary.get('project_add_documents_owner_title_sub'),
                    documentDropzoneTitle:projectDictionary.get('project_add_documents_agreement_dropzone'),
                    confName: 'project.land.documents',
                    buttonKey:'documents_load_button_key',
                    documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                }}
            >
                <Box>
                </Box>
            </FileLoader>
        </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={store.setFields}
                        configs={{
                            field: 'landPlan',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            title: projectDictionary.get('project_add_documents_plan_land'),
                            confName: 'project.land.documents',
                            buttonKey:'plan_load_button_key'
                        }}
                    >
                        <Box>

                        </Box>
                    </FileLoader>
                </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={store.setFields}
                        configs={{
                            field: 'zoningPlan',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            title: projectDictionary.get('project_add_documents_plan_zoning'),
                            confName: 'project.land.documents',
                            buttonKey:'plan_load_button_key'
                        }}
                    >
                        <Box>

                        </Box>
                    </FileLoader>
                </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={(file)=>{
                            store.uploadFile('project.documents.experts',file.experts)
                        }}
                        documentDropzone={true}
                        configs={{
                            field: 'experts',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            title: projectDictionary.get('project_add_documents_experts_title'),
                            titleSub: projectDictionary.get('project_add_documents_experts_title_sub'),
                            documentDropzoneTitle:projectDictionary.get('project_add_documents_experts_dropzone'),
                            confName: 'project.land.documents',
                            buttonKey:'documents_load_button_key',
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}
                    >
                        <Box>

                        </Box>
                    </FileLoader>
                </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={(file)=>{
                            store.uploadFile('project.documents.permissionDocument',file.permissionDocument)
                        }}
                        dropzone={store.fields.project.documents.permission}
                        documentDropzone={true}
                        configs={{
                            field: 'permissionDocument',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            title: projectDictionary.get('project_add_documents_permission_title'),
                            titleSub: projectDictionary.get('project_add_documents_permission_title_sub'),
                            documentDropzoneTitle:projectDictionary.get('project_add_documents_permission_dropzone'),
                            confName: 'project.land.documents',
                            buttonKey:'documents_load_button_key',
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}
                    >
                        <Box marginTop={'20px'}>

                            <RadioGroup
                                name={'project.documents.permission'}
                                value={store.fields.project.documents.permission}
                                onChange={store.handleInputRadio}
                                row>
                                <Typography alignItems={'center'}>
                                    <Radio
                                        sx={{width: '20px', mr: '10px'}}
                                        value={true}
                                        checked={store.fields.project.documents.permission  === true}
                                    />
                                    {commonDictionary.get('yes')}
                                </Typography>
                                <Typography ml={5} alignItems={'center'}>
                                    <Radio
                                        value={false}
                                        checked={store.fields.project.documents.permission === false}
                                    />
                                    {commonDictionary.get('no')}
                                </Typography>
                            </RadioGroup>
                        </Box>
                    </FileLoader>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={(file)=>{
                            store.uploadFile('project.documents.constructionPlan',file.constructionPlan)
                        }}
                        documentDropzone={true}
                        dropzone={store.fields.project.documents.construction}
                        configs={{
                            field: 'constructionPlan',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            titleSub: projectDictionary.get('project_add_documents_permission_dropzone'),
                            documentDropzoneTitle:projectDictionary.get('project_add_documents_construction_dropzone'),
                            confName: 'project.land.documents',
                            buttonKey:'documents_load_button_key',
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}
                    >
                        <Box marginTop={'20px'}>

                            <RadioGroup
                                name={'project.documents.construction'}
                                value={store.fields.project.documents.construction}
                                onChange={store.handleInputRadio}
                                row>
                                <Typography alignItems={'center'}>
                                    <Radio
                                        sx={{width: '20px', mr: '10px'}}
                                        value={true}
                                        checked={store.fields.project.documents.construction  === true}
                                    />
                                    {commonDictionary.get('yes')}
                                </Typography>
                                <Typography ml={5} alignItems={'center'}>
                                    <Radio
                                        value={false}
                                        checked={store.fields.project.documents.construction === false}
                                    />
                                    {commonDictionary.get('no')}
                                </Typography>
                            </RadioGroup>
                        </Box>
                    </FileLoader>
                </Box>
        <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
            borderRadius: '10px 10px 10px 10px',
                    width:'100%'
                }}>
                    <FileLoader
                        _data={store.fields.project.documents}
                        _setData={(file)=>{
                            store.uploadFile('project.documents.pledgeDocument',file.pledgeDocument)
                        }}
                        documentDropzone={true}
                        configs={{
                            field: 'pledgeDocument',
                            minKey: 'complex_progress_photo_min',
                            maxKey: 'complex_progress_photo_min',
                            title: projectDictionary.get('project_add_documents_pledge_title'),
                            titleSub: projectDictionary.get('project_add_documents_pledge_title_sub'),
                            documentDropzoneTitle:projectDictionary.get('project_add_documents_pledge_dropzone'),
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title'),
                            confName: 'project.land.documents',
                            buttonKey:'documents_load_button_key'
                        }}
                    >
                        <Box sx={{
                            display:'flex',
                            width:'100%',
                            marginTop:'20px',
                        }}>
                            <AIInput
                                placeholder={projectDictionary.get('project_add_documents_pledge_description')}
                                label={`${projectDictionary.get('project_add_documents_pledge_description')}`}
                                name={'project.documents.pledge'}
                                value={store?.fields?.project?.documents?.pledge ?? ''}
                                onChange={store.handleInputChange}
                                maxTextLength={600}
                            />
                        </Box>
                    </FileLoader>
                </Box>

            <ExtraDocumentsBlock store={store}/>

        </Box>
    )
});

export default DocumentsForm;
