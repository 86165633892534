import React, { Fragment, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Alert, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import globalDictionary from 'assets/translations/globalDictionary';
import { getBase64FromFile } from 'utils';
import { getActiveLanguageFromLS } from 'utils/index';
import CloseIcon from '@mui/icons-material/Close';
import Image from 'mui-image';
import ImageConfigs from '../../assets/configs/image.configs';
import {ImageChecker} from "../index";
/**
 * @param data : useState
 * <br />
 * @param _setData : useState
 * <br />
 * @param isError : boolean
 * <br />
 * @param config : { <br />
 *    field: 'fieldName', <br />
 *    titleKey: 'title for form', <br />
 *    confName: 'parent config name from imageConfigs' <br />
 * } <br />
 * <br />
 * @returns {JSX.Element}
 * @constructor
 */

const ImageUploader = ({ _data, _setData, isError, config }) => {
  const imageConfigs = ImageConfigs.get(config.confName, config.field);
  const configs = config ? { ...imageConfigs, ...config } : ImageConfigs.defaultConfigs;
  const language = getActiveLanguageFromLS();
  const calcFileCount = Array.isArray(_data[config.field])
    ? _data[config.field].length
    : _data[config.field] !== ''
    ? 1
    : 0;
  const [uploadedFileCount, setUploadedFileCount] = useState(calcFileCount);
  const [error, setError] = useState(isError ? isError : false);

  useEffect(() => {
    setError(isError);
  }, [isError]);
  function get(object, path) {
    const pathParts = path.split('.');
    let result = object;

    for (const part of pathParts) {
      if (result && result.hasOwnProperty(part)) {
        result = result[part];
      } else {
        // Handle missing property
        return undefined;
      }
    }

    return result;
  }
  useEffect(() => {
    if (configs.minCount > 0) {
      uploadedFileCount < configs.minCount ? setError(true) : setError(false);
    }
  }, [uploadedFileCount]);
  const handleChange = async (file) => {
    const nestedFields = configs.field.split('.');
    const lastField = nestedFields.pop();

    let currentData = _data;
    nestedFields.forEach((nestedField) => {
      if (!currentData[nestedField]) {
        currentData[nestedField] = {};
      }
      currentData = currentData[nestedField];
    });

    if (configs.maxCount > 1) {
      const files = Array.from(file).slice(0, configs.maxCount - uploadedFileCount);
      const photos = await Promise.all(files.map(async (photo) => await getBase64FromFile(photo)));

      currentData[lastField] = [...currentData[lastField], ...photos];
      setUploadedFileCount(uploadedFileCount + files.length);
    } else if (configs.maxCount === 1) {
      const photo = await getBase64FromFile(file);
      currentData[lastField] = photo;
      setUploadedFileCount(1);
    }

    _setData({ ..._data });
  };

  const clearButton = () => {
    if (typeof get(_data, configs.field) === 'string' && get(_data, configs.field).trim() !== '') {
      return (
        <Button
          onClick={() => {
            _setData({ ..._data, [configs.field]: '' });
          }}
          sx={{ mt: 1 }}
          variant="contained"
        >
          {globalDictionary.get('image_uploader_clear', language)}
        </Button>
      );
    }
    if (Array.isArray(get(_data, configs.field)) && get(_data, configs.field).length > 0) {
      return (
        <Button
          onClick={() => {
            const fieldParts = configs.field.split('.');
            let currentData = _data;

            for (const part of fieldParts.slice(0, -1)) {
              currentData = currentData[part];
              if (!currentData) {
                console.error(`Data not found for field: ${part}`);
                return;
              }
            }

            const lastPart = fieldParts[fieldParts.length - 1];

            _setData((prevData) => {
              const newData = { ...prevData };
              let target = newData;

              for (let i = 0; i < fieldParts.length - 1; i++) {
                target = target[fieldParts[i]];
              }

              target[lastPart] = [];
              return newData;
            });

            _setData({ ..._data, [configs.field]: [] });
            setUploadedFileCount(0);
          }}
          sx={{ mt: 1 }}
          variant="contained"
        >
          {globalDictionary.get('image_uploader_clear', language)}
        </Button>
      );
    }
  };

  const showImages = () => {
    let images = get(_data, configs.field);
    if (typeof images === 'string' && images.trim() !== '') {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            color="primary"
            sx={{
              top: 5,
              left: 150,
              zIndex: 1000,
            }}
            onClick={() => {
              _setData({
                ..._data,
                [configs.field]: '',
              });
              setUploadedFileCount((prevState) => prevState - 1);
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <ImageChecker src={images} height={150} width={150} />
        </Grid>
      );
    }

    if (Array.isArray(images) && images.length > 0) {
      return images.map((photo) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            color="primary"
            sx={{
              top: 5,
              left: 150,
              zIndex: 1000,
            }}
            onClick={() => {
              const fieldParts = configs.field.split('.');
              let currentData = _data;

              for (const part of fieldParts.slice(0, -1)) {
                currentData = currentData[part];
                if (!currentData) {
                  console.error(`Data not found for field: ${part}`);
                  return;
                }
              }

              const lastPart = fieldParts[fieldParts.length - 1];
              const newPhotos = currentData[lastPart].filter((item) => item !== photo);

              _setData((prevData) => {
                const newData = { ...prevData };
                let target = newData;

                for (let i = 0; i < fieldParts.length - 1; i++) {
                  target = target[fieldParts[i]];
                }

                target[lastPart] = newPhotos;
                return newData;
              });

              setUploadedFileCount((prevCount) => prevCount - 1);
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <ImageChecker src={photo} style={{ objectFit: 'contain' }} height={150} width={150} />
        </Grid>
      ));
    }
  };
  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant={'h6'}>{globalDictionary.get(configs.titleKey, language)}</Typography>
      </Grid>
      {error && uploadedFileCount < configs.minCount && (
        <Alert severity="warning">
          <Typography variant={'h6'}>
            {globalDictionary.get('image_uploader_file_count_min_warning', language)}{' '}
            {configs.minCount}
          </Typography>
        </Alert>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FileUploader
          disabled={uploadedFileCount >= configs.maxCount}
          children={
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minWidth: '50px',
                minHeight: '150px',
                border: error
                  ? 'dashed 2px #ff9500'
                  : uploadedFileCount === configs.maxCount
                  ? 'dashed 2px #898f96'
                  : 'dashed 2px #0658c2',
                padding: '8px 16px 8px 8px',
                borderRadius: '5px',
                cursor: 'pointer',
                flexWrap: 'wrap',
                textAlign: 'center',
              }}
            >
              {uploadedFileCount === configs.maxCount ? (
                <Alert severity="info">
                  <Typography variant={'h6'}>
                    {globalDictionary.get('image_uploader_file_count_alarm', language)}
                  </Typography>
                  <Typography variant={'h4'}>
                    {configs.maxCount + '/' + configs.maxCount}
                  </Typography>
                </Alert>
              ) : (
                <Fragment>
                  <Typography variant={'h6'}>
                    {globalDictionary.get('image_uploader_drop', language)}
                  </Typography>
                  <Typography>
                    {globalDictionary.get('image_uploader_formats', language)}
                  </Typography>
                  {configs.minCount > 0 && (
                    <Typography>
                      {`(${globalDictionary.get('image_uploader_file_count_min', language)}: ${
                        configs.minCount
                      })`}
                    </Typography>
                  )}
                  <Typography>
                    {`(${globalDictionary.get('image_uploader_file_count', language)}: ${
                      configs.maxCount
                    })`}
                  </Typography>
                  <Typography>
                    {`(${globalDictionary.get('image_uploader_file_size', language)}: ${
                      configs.size
                    }Mb)`}
                  </Typography>
                </Fragment>
              )}
            </Box>
          }
          maxSize={configs.size}
          multiple={configs.maxCount > 1}
          handleChange={handleChange}
          name="file"
          types={['JPG', 'JPEG', 'PNG', 'WEBP']}
        />
        {clearButton()}
      </Grid>
      {showImages()}
    </Fragment>
  );
};

export default ImageUploader;
