import {Grid, TextField, Typography} from "@mui/material";
import React, {useContext} from "react";
import {FullAddressBlock} from "components/index";
import globalDictionary from "assets/translations/globalDictionary";
import {observer} from "mobx-react-lite";
import {useContractStore} from "../../ContractStore";

const CompanyMember = observer(() => {
    const formData = useContractStore();
    return (
        <Grid container spacing={'20px'} marginTop={'20px'}>

            <Grid item xs={12} sm={12} md={6} lg={6} gridColumn>

                <TextField
                    label={globalDictionary.get('contract_add_member_company_name')}
                    variant="outlined"
                    fullWidth
                    required
                    name="company.companyName"
                    value={formData.contract.company.companyName || ''}
                    onChange={formData.handleInputChange}
                    error={!!formData.contractErrors.company.companyName}
                    helperText={formData.contractErrors.company.companyName}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component={'p'} fontWeight={600} pb={'10px'}>
                    {globalDictionary.get('contract_add_member_company_address')}:
                </Typography>
                <FullAddressBlock
                    data={formData.contract.company.companyFullAddress}
                    parentField={'company.companyFullAddress'}
                    dataError={formData.contractErrors}
                    handleInputChange={formData.handleInputChange}
                    countryTranslateKey={'contract_add_company_country'}
                />

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography pb={'10px'} fontWeight={600}>
                    {globalDictionary.get('contract_add_member_registration_number')}:
                </Typography>
                <TextField
                    label={globalDictionary.get('contract_add_member_registration_number')}
                    variant="outlined"
                    fullWidth
                    required
                    name="company.registrationNumber"
                    value={formData.contract.company.registrationNumber || ''}
                    onChange={formData.handleInputChange}
                    error={!!formData.contractErrors.company.registrationNumber}
                    helperText={formData.contractErrors.company.registrationNumber}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography component={'p'} fontWeight={600} pb={'10px'}>
                        {globalDictionary.get("contract_add_company_contact")}:</Typography>
                    <TextField
                        label={globalDictionary.get("contract_add_company_contactFullName")}
                        variant="outlined"
                        fullWidth
                        required
                        name="company.contactFullName"
                        value={formData.contract.company.contactFullName || ''}
                        onChange={formData.handleInputChange}
                        error={!!formData.contractErrors.company.contactFullName}
                        helperText={formData.contractErrors.company.contactFullName}
                    />
                </Grid>
                <Grid  item xs={12} sm={12} md={6} lg={6} pt={'20px'}>
                    <TextField
                        label={globalDictionary.get('contract_add_member_acting_based')}
                        variant="outlined"
                        fullWidth
                        required
                        name="company.companyActingBased"
                        value={formData.contract.company.companyActingBased || ''}
                        onChange={formData.handleInputChange}
                        error={!!formData.contractErrors.company.companyActingBased}
                        helperText={formData.contractErrors.company.companyActingBased}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
})

export default CompanyMember;