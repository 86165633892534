import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Slider,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import {AIInput} from 'components/index';
import {observer} from "mobx-react-lite";
import LocationBlock from "components/shared/LocationBlock/LocationBlock";
import projectDictionary from "assets/translations/Project/projectDictionary";
import FileLoader from "../../../../../../components/shared/FileLoader/FileLoader";

const CommonForm = observer(({store}) => {
    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];

    return (
        <>
            <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: ' 0px 0px 10px 10px'}}>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography sx={{
                            fontSize:'16px',
                            fontWeight:'700',
                            marginBottom:'15px'
                        }}>
                            {projectDictionary.get('project_add_common_title')}
                        </Typography>
                    </Grid>

                    <Grid container spacing={2} sx={{marginLeft:'8px'}}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_name')}
                            label={projectDictionary.get('project_add_name')}
                            variant="outlined"
                            fullWidth
                            required
                            error={store.fieldsErrors?.name}
                            value={store.fields.name}
                            name={'name'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_owner')}
                            label={projectDictionary.get('project_add_owner')}
                            variant="outlined"
                            fullWidth
                            error={store.fieldsErrors?.project?.owner}
                            value={store.fields.project.owner}
                            name={'project.owner'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_builder')}
                            label={projectDictionary.get('project_add_builder')}
                            variant="outlined"
                            fullWidth
                            error={store.fieldsErrors?.project?.builder}
                            value={store.fields.project.builder}
                            name={'project.builder'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_architect')}
                            label={projectDictionary.get('project_add_architect')}
                            variant="outlined"
                            fullWidth
                            error={store.fieldsErrors?.project?.architect}
                            value={store.fields.project.architect}
                            name={'project.architect'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    </Grid>

                    <Grid item xs={4} sx={{
                        display:'flex',
                        flexDirection:'column',
                        marginLeft:'8px'
                    }}>
                        <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            justifyContent:'space-between'
                        }}
                        >
                        <Typography variant="body2" color="#A2A2A2">
                            {projectDictionary.get('project_add_stage')}
                        </Typography>

                        <Typography variant="body2"
                                    sx={{
                                        fontFamily:'Gilroy',
                                        fontSize:'14px',
                                        fontWeight:700,
                                        lineHeight:'150%'
                                    }}>
                            {`${store?.fields?.project?.stage && store?.fields?.project?.stage !== ''?store?.fields?.project?.stage : 15}%`}
                        </Typography>
                        </Box>
                        <Slider
                            value={store?.fields?.project?.stage && store?.fields?.project?.stage !== ''?store?.fields?.project?.stage : 15}
                            onChange={store.handleInputChange}
                            name={'project.stage'}
                            aria-labelledby="continuous-slider"
                            sx={{
                                color: 'transparent',
                                '& .MuiSlider-thumb': {
                                    height: "25px",
                                    width: "25px",
                                    backgroundColor: '#C5A465',
                                    border: '2px solid currentColor',
                                },
                                '& .MuiSlider-rail': {
                                    height: "15px",
                                    border: '2px solid #E0E0E0',
                                    backgroundColor: 'white',
                                    borderRadius:'5px'
                                },
                                '& .MuiSlider-track': {
                                    height:"15px",
                                    backgroundColor: '#C5A465',
                                    borderRadius:'5px'
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{marginLeft:'8px'}}>
                    <AIInput
                        placeholder={projectDictionary.get('project_add_description')}
                        label={`${projectDictionary.get('project_add_description')}`}
                        name={'project.description'}
                        value={store.fields.project.description}
                        onChange={store.handleInputChange}
                        maxTextLength={600}
                    />
                    </Grid>

                    <Grid item xs={12} sx={{marginLeft:'8px'}}>
                    <AIInput
                        placeholder={projectDictionary.get('project_add_advantage')}
                        label={`${projectDictionary.get('project_add_advantage')}`}
                        name={'project.advantage'}
                        value={store.fields.project.advantage}
                        onChange={store.handleInputChange}
                        maxTextLength={600}
                    />
                    </Grid>
                        <Grid item xs={12} sx={{marginLeft:'8px'}}>
                    <AIInput
                        placeholder={projectDictionary.get('project_add_social')}
                        label={`${projectDictionary.get('project_add_social')}`}
                        name={'project.social'}
                        value={store.fields.project.social}
                        onChange={store.handleInputChange}
                        maxTextLength={600}
                    />
                        </Grid>
                    <Grid item xs={12}>
                    <FileLoader
                        _data={store.fields.project}
                        _setData={(file)=>{
                            store.uploadFile('project.presentation',file.presentation)
                        }}
                        fileCount={store.fields?.project?.presentation?.url ? 1 : 0}
                        documentDropzone={true}
                        configs={{
                            field:'presentation',
                            confName:'project.construction',
                            documentDropzoneTitle: projectDictionary.get('project_add_presentation'),
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}

                    />
                    </Grid>

                </Grid>
            </Box>

            <Box
                sx={{backgroundColor: 'white', padding: '20px', borderRadius: '5px', marginTop: '20px', width: '100%'}}>
                <LocationBlock
                    data={store.fields}
                    setData={store.handleInputField}
                    errors={store?.fieldsErrors?.address}
                    field={'address'}
                />
            </Box>
        </>
    );
});

export default CommonForm;
