import React, {useCallback, useState} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Chip,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
    Box
} from '@mui/material';
import {Link} from 'react-router-dom';
import {MoreVert} from '@mui/icons-material';
import {getFormattedLocation, numberFormatter} from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';
import {snackActions} from 'utils/SnackBarUtils';
import {ImageChecker} from 'components';
import {getMainPhoto} from "utils";
import {useProjectStore} from "../../../pages/Services/Project/ProjectStore";
import {observer} from "mobx-react-lite";
import CustomProgressBar from "../../base/ProgressBar/ProgressBar";

const ProjectTags = ({projectStatus, isMyProject}) => {
    if (!isMyProject) return false;

    return (
        <Stack
            direction="column"
            sx={{
                position: 'absolute',
                maxHeight: '200px',
                top: '15px',
                left: '15px',
                zIndex: 1000,
            }}
            spacing={1}
        >
            <Chip
                label={projectStatus.title}
                style={{
                    backgroundColor: projectStatus.backgroundColor,
                    color: projectStatus.color,
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    border: 'none',
                }}
            />
        </Stack>
    );
}

const getProjectStatus = (configs) => {
    let projectStatus = {
        title: globalDictionary.get('object_status_not_available'),
        backgroundColor: '#EB5757',
        color: 'white'
    };

    if (configs?.user?.isDraft) {
        return {
            title: globalDictionary.get('object_status_draft'),
            backgroundColor: '#F2C94C',
            color: '#021228'
        };
    }

    if (configs?.moderation?.includes('active')) {
        return {
            title: globalDictionary.get('project_list_item_status_moderation'),
            backgroundColor: '#F2C94C',
            color: '#021228'
        };
    }

    if (configs?.moderation?.includes('failed')) {
        return {
            title: globalDictionary.get('project_list_item_status_moderation_failed'),
            backgroundColor: '#E5494D',
            color: 'white'
        };
    }

    if (!configs?.isFinancing) {
        projectStatus = {
            title: globalDictionary.get('project_list_item_status_financing_closed'),
            backgroundColor: '#E5494D',
            color: 'white'
        };
    }

    if (configs?.moderation?.includes('success')) {
        projectStatus = {
            title: globalDictionary.get('project_list_item_status_moderation'),
            backgroundColor: '#219653',
            color: 'white'
        };
    }

    if (configs?.isFinancing) {
        projectStatus = {
            title: globalDictionary.get('project_list_item_status_financing'),
            backgroundColor: '#219653',
            color: 'white'
        };
    }

    return projectStatus;
};

const ProjectListItem = observer(({project: projectItem = {}, isMyProject = false, fetchProjects}) => {
    const projectStore = useProjectStore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [project, setProject] = useState({
        ...projectItem,
    });

    const {
        name = '',
        duration = 0,
        yield: yieldValue = 0,
        m2Cost = 0,
        fundingRaised = 0,
        fundingProgress = 0,
        photos = [],
        address = {},
        configs = {},
        _id: projectId = '',
    } = project || {};

    const objectLink = configs?.user?.isDraft ? `/project/form/${project._id}` : `/project/${project._id}`;
    const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), []);
    const handleContextMenuClose = useCallback(() => setAnchorEl(null), []);

    const copyProjectLinkToClipboard = useCallback(() => {
        const link = `https://${process.env.REACT_APP_CABINET_URL}/project/${projectId}`;
        navigator.clipboard.writeText(link).then(() => {
            snackActions.success(globalDictionary.get('share_success'));
            handleContextMenuClose();
        });
    }, [projectId, handleContextMenuClose]);

    let projectStatus = getProjectStatus(configs);

    const deleteDraft = async () => {
        try {
            const result = await projectStore.deleteDraft(projectId);
            if (result.code !== 200) {
                if (result?.errors && result.errors.length === 0) {
                    throw new Error(globalDictionary.get('object_status_update_error'));
                } else {
                    result.errors.map(error => snackActions.error(error.message))
                }
            } else {
                fetchProjects(true, 1);
            }
        } catch (error) {
            snackActions.error(error.message);
        }
    }

    const toggleProjectFlags = async (flag, level = 'user') => {
        try {
            const payload = {
                [flag]: !project.configs[level][flag],
                _id: projectId
            }
            const formData = new FormData();
            formData.append('data', JSON.stringify(payload));
            formData.append('_id', projectId);

            const result = await projectStore.updateProject(formData);
            if (result.code !== 200) {
                if (result?.errors && result.errors.length === 0) {
                    throw new Error(globalDictionary.get('object_status_update_error'));
                } else {
                    result.errors.map(error => snackActions.error(error.message))
                }
            }

            if (result.code === 200) {
                setProject((prev) => (
                    {
                        ...prev,
                        configs: {
                            [level]: {
                                ...configs[level],
                                [flag]: !prev.configs[level][flag]
                            }
                        },
                    }
                ));
            }
        } catch (error) {
            snackActions.error(error.message);
        }
    };

    const contextMenuItems = [
        {
            onClick: () => {
                copyProjectLinkToClipboard();
            },
            content: globalDictionary.get('context_list_item_share'),
            showCondition: !configs?.user?.isDraft && configs?.user?.isAvailable,
        },
        {
            content: (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={'5px'}
                    onClick={deleteDraft}
                >
                    {globalDictionary.get('object_list_item_delete_draft')}
                </Stack>
            ),
            showCondition: isMyProject && configs?.user?.isDraft,
        },
    ];

    return (
        <Card
            sx={{
                alignItems: 'space-between',
                height: '100%',
                '&:hover a > button': {
                    backgroundColor: '#D1B684'
                },
                '&:hover h2': {
                    color: '#C5A465'
                }
            }}
        >
            <ProjectTags projectStatus={projectStatus} isMyProject={isMyProject}/>
            <IconButton
                onClick={handleClick}
                sx={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    zIndex: 1000,
                    width: '30px',
                    height: '30px',
                    backgroundColor: 'rgba(255, 255, 255, 0.60)',
                    borderRadius: '25px'
                }}
                size="large"
            >
                <MoreVert
                    sx={{
                        width: '15px',
                        height: '15px',
                        color: '#021228',
                    }}
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleContextMenuClose}
            >
                {contextMenuItems.map(
                    (menuItem, index) =>
                        menuItem.showCondition && (
                            <MenuItem
                                key={`project-list-item-context-${index}`}
                                onClick={menuItem.onClick}
                                component={menuItem.to ? Link : undefined}
                                to={menuItem.to}
                                style={{width: '100%'}}
                            >
                                {menuItem.content}
                            </MenuItem>
                        )
                )}
            </Menu>

            <Link
                to={objectLink}
                style={{
                    overflow: 'hidden',
                }}
            >
                <ImageChecker
                    src={getMainPhoto(photos)?.url}
                    alt={name}
                    variant={'rounded'}
                    sx={{
                        width: '100%',
                        height: 200,
                        borderRadius: 0,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '4px',
                        borderColor: isMyProject ? projectStatus.backgroundColor : '#BDBDBD',
                    }}
                    imgProps={{
                        objectfit: 'cover',
                    }}
                    text={name}
                />
            </Link>

            <Grid container gridColumn columns={1} pt={'11px'}>
                <Grid item xs={12}>
                    <Typography
                        component={'h2'}
                        fontSize={'20px'}
                        fontWeight={700}
                        lineHeight={'24px'}
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            minHeight: '24px',
                            transition: 'color 0.1s',
                        }}
                    >
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize={'14px'}
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    minHeight: '16px',
                                }}
                    >
                        {getFormattedLocation(address)}
                    </Typography>
                </Grid>

                <Grid item xs={12} gridRow sx={{
                    opacity: configs?.user?.isDraft || configs?.moderation?.includes('active') ? 0.4 : 1,
                }}>
                    <Typography sx={{
                        fontSize: '14px',
                        lineHeight: '21px',
                        mt: '15px'
                    }}>
                        {`${globalDictionary.get('project_list_item_funding_raised')}: ${numberFormatter(fundingRaised)} M2C`}
                    </Typography>
                    <Box mt={'8px'}>
                        <CustomProgressBar value={fundingProgress} maxValue={100} type={'small'} maxLabel={
                            <Box sx={{
                                display: 'flex',
                            }}>
                                <Typography
                                    sx={{
                                        color: '#A2A2A2',
                                        fontFamily: 'Sofia Sans Condensed',
                                        fontSize: '11px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {`${globalDictionary.get('project_list_item_funding_progress')}: `}
                                </Typography>
                                <Typography
                                    component={'span'}
                                    sx={{
                                        color: '#C5A465',
                                        fontFamily: 'Sofia Sans Condensed',
                                        fontSize: '11px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    &nbsp;
                                    {`${fundingProgress}%`}
                                </Typography>
                            </Box>
                        }/>
                    </Box>
                </Grid>

                {
                    !isMyProject && (
                        <Grid item xs={12} width={'100%'} gridRow>
                            <Divider sx={{mt: '15px'}}/>
                            <Grid container gridRow spacing={1} pt={1} minHeight={'60px'}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Typography
                                        variant={'subtitle1'}>
                                        {globalDictionary.get('project_list_item_contract_duration')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        fontSize={'14px'}
                                        lineHeight={'120%'}
                                    >
                                        {duration ? `${duration} ${globalDictionary.get('year_s')}` : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Typography
                                        variant={'subtitle1'}>
                                        {globalDictionary.get('project_list_item_yield')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        fontSize={'14px'}
                                        lineHeight={'120%'}
                                    >
                                        {yieldValue ? `${yieldValue}%` : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Typography
                                        variant={'subtitle1'}>
                                        {globalDictionary.get('project_list_item_m2_cost')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        fontSize={'14px'}
                                        lineHeight={'120%'}
                                    >
                                        {m2Cost ? `${m2Cost} M2C` : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }

                <Grid container mt={'10px'}>
                    <Grid item xs={12}>
                        <Link to={objectLink}>
                            <Button
                                size="small"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                            >
                                {globalDictionary.get('projects_list_item_more')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
});

export default ProjectListItem;
