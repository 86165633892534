import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {getActiveLanguageFromLS} from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';
import {useStore} from 'Providers';

function VerificationWarning() {
    const language = getActiveLanguageFromLS();
    const {auth} = useStore();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '50px',
                height: '100%',
            }}
        >
            {auth?.user?.commId === 'pending' && (
                <>
                    <Typography
                        variant={'h6'}
                        sx={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        {globalDictionary.get('profile_verification_wait_text', language)}
                    </Typography>
                    <Link to={'/support/tickets'}>
                        <Button variant={'contained'}>
                            {globalDictionary.get('profile_verification_wait_button', language)}
                        </Button>
                    </Link>
                </>
            )}
            {auth?.user?.commId === '' && (
                <>
                    <Typography
                        variant={'h6'}
                        sx={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        {globalDictionary.get('profile_verification_text', language)}
                    </Typography>
                </>
            )}
            {auth?.user?.commId !== 'pending' && (
                <Link to={'/support/tickets'}>
                    <Button variant={'contained'}>
                        {globalDictionary.get('profile_verification_wait_button', language)}
                    </Button>
                </Link>
            )}
        </Box>
    );
}

export default VerificationWarning;
