import {GremService} from 'services';
import {getUserToken} from 'utils';
import {server} from '../config';

class LandingService {
    constructor() {
        this._apiBase = server;
        this.service = new GremService();
    }

    addLink = async (id, data = {}) => {
        const res = await this.service.sendRequest(`/landing/link/${id}`,
            'PUT',
            data,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    getLink = async (id, short) => {
        const res = await this.service.sendRequest(`/landing/link/${id}?utm_short=${short}`,
            'GET',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    resetLink = async (id, short) => {
        const res = await this.service.sendRequest(`/landing/link/${id}?utm_short=${short}`,
            'PATCH',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }

    deleteLink = async (id, short) => {
        const res = await this.service.sendRequest(`/landing/link/${id}?utm_short=${short}`,
            'DELETE',
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            });
        return res;
    }
}

export default LandingService
